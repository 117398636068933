<mat-button-toggle-group #selector="matButtonToggleGroup" (change)="changeFilter($event)" value="day" name="fontStyle"
  aria-label="Font Style" class="filter-opt">
  <mat-button-toggle value="day">Dia</mat-button-toggle>
  <mat-button-toggle value="period">Período</mat-button-toggle>
</mat-button-toggle-group>
<span *ngIf="selector.value == 'day'">
  <mat-form-field>
    <input matInput #datePickerOnbar1 placeholder="Data" [matDatepicker]="picker1" [formControl]="dayStartDate">
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  <button mat-icon-button aria-label="Data para exibição" (click)="picker1.open()"
    matTooltip="Escolha uma data para filtrar">
    <mat-icon>calendar_today</mat-icon>
  </button>
  <button mat-button color="primary" (click)="dayFilter()">
    <mat-icon>filter_list</mat-icon>
    Filtrar
  </button>
</span>
<span *ngIf="selector.value == 'period'">
  <mat-form-field>
    <input matInput #datePickerOnbar2 placeholder="Data inicial" [matDatepicker]="picker2"
      [formControl]="periodStartDate">
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <button mat-icon-button aria-label="Data para exibição" (click)="picker2.open()"
    matTooltip="Data inicial para filtrar">
    <mat-icon>calendar_today</mat-icon>
  </button>
  <mat-form-field>
    <input matInput #datePickerOnbar3 placeholder="Data final" [matDatepicker]="picker3" [formControl]="periodEndDate">
    <mat-datepicker #picker3></mat-datepicker>
  </mat-form-field>
  <button mat-icon-button aria-label="Data para exibição" (click)="picker3.open()" matTooltip="Data final para filtrar">
    <mat-icon>calendar_today</mat-icon>
  </button>

  <button mat-button color="primary" (click)="periodFilter()">
    <mat-icon>filter_list</mat-icon>
    Filtrar
  </button>


</span>

<div fxLayout="row wrap" fxLayoutGap="25px">

  <mat-card class="example-card" fxFlex="calc(33%-25px)" fxFlex.xs="calc(100%-15px)" fxFlex.lt-sm="calc(50%-25px)"
    fxFlex.sm="calc(50%-25px)" fxFlex.md="calc(33%-25px)" fxFlex.lg="calc(33%-25px)" fxFlex.xl="calc(33%-25px)">
    <div class="card_top">
      <mat-card-header class="foto">
        <div class="texto_top">
          <span>Tempo médio para direcionar Veículos</span>
        </div>
      </mat-card-header>
      <div class="fill"></div>
      <div class="circulo c-orange">
        <i class="material-icons icon_cor36">
          directions_car
        </i>
      </div>
    </div>
    <mat-card-content>
      <div class="mat-display-4">
        {{tempo_direcionar_veiculos_hoje ? tempo_direcionar_veiculos_hoje : '00:00'}}
      </div>
    </mat-card-content>

    <mat-card-content class="footer_card" *ngIf="showCompareLastDay">
      <div class="indicacao_card">
        <mat-icon [ngClass]="tempo_direcionar_veiculos_ontem > 0 ? 'verde' : 'vermelho'">
          {{tempo_direcionar_veiculos_ontem > 0 ? setaup : setadown}}</mat-icon>
      </div>
      <p><span
          [ngClass]="tempo_direcionar_veiculos_ontem > 0 ? 'verde' : 'vermelho'">{{tempo_direcionar_veiculos_ontem | number:'1.1-2'}}%</span>
        Em relação ao dia anterior</p>
    </mat-card-content>
  </mat-card>

  <mat-card class="example-card" fxFlex="calc(33%-25px)" fxFlex.xs="calc(100%-15px)" fxFlex.lt-sm="calc(50%-25px)"
    fxFlex.sm="calc(50%-25px)" fxFlex.md="calc(33%-25px)" fxFlex.lg="calc(33%-25px)" fxFlex.xl="calc(33%-25px)">
    <div class="card_top">
      <mat-card-header class="foto">
        <div class="texto_top">
          Tempo médio para liberar Motoristas
        </div>
      </mat-card-header>
      <div class="fill"></div>
      <div class="circulo c-orange">
        <i class="material-icons icon_cor36">
          person
        </i>
      </div>
    </div>
    <mat-card-content>
      <div class="mat-display-4 ">
        {{tempo_liberar_motoristas_hoje ? tempo_liberar_motoristas_hoje : '00:00'}}
      </div>
    </mat-card-content>

    <mat-card-content class="footer_card" *ngIf="showCompareLastDay">
      <div class="indicacao_card">
        <mat-icon [ngClass]="tempo_liberar_motoristas_ontem > 0 ? 'verde' : 'vermelho'">
          {{tempo_liberar_motoristas_ontem > 0 ? setaup : setadown}}</mat-icon>
      </div>
      <p><span
          [ngClass]="tempo_liberar_motoristas_ontem > 0 ? 'verde' : 'vermelho'">{{tempo_liberar_motoristas_ontem | number:'1.1-2'}}%</span>
        Em relação ao dia anterior</p>
    </mat-card-content>
  </mat-card>

  <!-- <mat-card class="example-card"
        fxFlex="calc(33%-25px)"
        fxFlex.xs="calc(100%-15px)"
        fxFlex.lt-sm="calc(50%-25px)"
        fxFlex.sm="calc(50%-25px)"
        fxFlex.md="calc(33%-25px)"
        fxFlex.lg="calc(33%-25px)"
        fxFlex.xl="calc(33%-25px)"  >
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">
            Número de Pendências geradas
          </div>
        </mat-card-header>
        <div class="fill"></div>
          <div class="circulo36">
            <i class="material-icons icon_cor36">
                notifications
            </i>
          </div>
      </div>
      <mat-card-content >
        <div class="mat-display-4 ">
          {{numero_de_pendencias_hoje}}
        </div>
      </mat-card-content>

      <mat-card-content class="footer_card">
        <div class="indicacao_card">
          <mat-icon [ngClass]="numero_de_pendencias_ontem > 0 ? 'verde' : 'vermelho'">{{numero_de_pendencias_ontem > 0 ? setaup : setadown}}</mat-icon>
        </div>
        <p ><span [ngClass]="numero_de_pendencias_ontem > 0 ? 'verde' : 'vermelho'">{{numero_de_pendencias_ontem | number:'1.1-2'}}%</span> Em relação ao dia anterior</p>
      </mat-card-content>
    </mat-card> -->


  <mat-card class="example-card" fxFlex="calc(33%-25px)" fxFlex.xs="calc(100%-15px)" fxFlex.lt-sm="calc(50%-25px)"
    fxFlex.sm="calc(50%-25px)" fxFlex.md="calc(33%-25px)" fxFlex.lg="calc(33%-25px)" fxFlex.xl="calc(33%-25px)">
    <div class="card_top">
      <mat-card-header class="foto">
        <div class="texto_top_docas">
          Tempo médio de permanência<br>após finalizar manifesto
        </div>
      </mat-card-header>
      <div class="fill"></div>
      <div class="circulo c-blue">
        <i class="material-icons icon_cor45">
          directions
        </i>
      </div>
    </div>
    <mat-card-content>
      <div class="mat-display-4 ">
        {{tempo_permanencia_apos_manifesto_hoje ? tempo_permanencia_apos_manifesto_hoje : '00:00'}}
      </div>
    </mat-card-content>

    <mat-card-content class="footer_card" *ngIf="showCompareLastDay">
      <div class="indicacao_card">
        <mat-icon [ngClass]="tempo_permanencia_apos_manifesto_ontem > 0 ? 'verde' : 'vermelho'">
          {{tempo_permanencia_apos_manifesto_ontem > 0 ? setaup : setadown}}</mat-icon>
      </div>
      <p><span
          [ngClass]="tempo_permanencia_apos_manifesto_ontem > 0 ? 'verde' : 'vermelho'">{{tempo_permanencia_apos_manifesto_ontem | number:'1.1-2'}}%</span>
        Em relação ao dia anterior</p>
    </mat-card-content>
  </mat-card>

  <mat-card class="example-card" fxFlex="calc(33%-25px)" fxFlex.xs="calc(100%-15px)" fxFlex.lt-sm="calc(50%-25px)"
    fxFlex.sm="calc(50%-25px)" fxFlex.md="calc(33%-25px)" fxFlex.lg="calc(33%-25px)" fxFlex.xl="calc(33%-25px)">
    <div class="card_top">
      <mat-card-header class="foto">
        <div class="texto_top">
          Tempo médio em cada etapa das docas
        </div>
      </mat-card-header>
      <div class="fill"></div>
      <div class="circulo c-blue">
        <i class="material-icons icon_cor45">
          query_builder
        </i>
      </div>
    </div>
    <mat-card-content class="meio_docas">
      <div class="etapa-docas">
        <div class="um_terco">Picking
          <div class="font_docas">{{t_medio_picking_hoje ? t_medio_picking_hoje : '00:00'}}</div>
        </div>
        <div class="dois_tercos">Dupla <br>Conferência
          <div class="font_docas">{{t_medio_dupla_hoje ? t_medio_dupla_hoje : '00:00'}}</div>
        </div>
        <div class="tres_tercos">Carregamento
          <div class="font_docas">{{t_medio_carregamento_hoje ? t_medio_carregamento_hoje : '00:00'}}</div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-content class="footer_card" *ngIf="showCompareLastDay">

      <div class="doca1">
        <mat-icon [ngClass]="t_medio_picking_ontem > 0 ? 'verde' : 'vermelho'">
          {{t_medio_picking_ontem > 0 ? setaup : setadown}}</mat-icon>
        <span
          [ngClass]="t_medio_picking_ontem > 0 ? 'verde' : 'vermelho'">{{t_medio_picking_ontem | number:'1.1-2'}}%</span>
        Em relação ao dia anterior
      </div>

      <div class="doca2">
        <mat-icon [ngClass]="t_medio_dupla_ontem > 0 ? 'verde' : 'vermelho'">
          {{t_medio_dupla_ontem > 0 ? setaup : setadown}}</mat-icon>
        <span
          [ngClass]="t_medio_dupla_ontem > 0 ? 'verde' : 'vermelho'">{{t_medio_dupla_ontem | number:'1.1-2'}}%</span> Em
        relação ao dia anterior
      </div>

      <div class="doca3">
        <mat-icon [ngClass]="t_medio_carregamento_ontem > 0 ? 'verde' : 'vermelho'">
          {{t_medio_carregamento_ontem > 0 ? setaup : setadown}}</mat-icon>
        <span
          [ngClass]="t_medio_carregamento_ontem > 0 ? 'verde' : 'vermelho'">{{t_medio_carregamento_ontem | number:'1.1-2'}}%</span>
        Em relação ao dia anterior
      </div>

    </mat-card-content>
  </mat-card>

  <mat-card class="example-card" fxFlex="calc(33%-25px)" fxFlex.xs="calc(100%-15px)" fxFlex.lt-sm="calc(50%-25px)"
    fxFlex.sm="calc(50%-25px)" fxFlex.md="calc(33%-25px)" fxFlex.lg="calc(33%-25px)" fxFlex.xl="calc(33%-25px)">
    <div class="card_top">
      <mat-card-header class="foto">
        <div class="texto_top">
          <span>Veículos no bolsão</span>
        </div>
      </mat-card-header>
      <div class="fill"></div>
      <div class="circulo c-green">
        <i class="material-icons icon_cor">
          directions_car
        </i>
      </div>
    </div>
    <mat-card-content>
      <div class="mat-display-4">
        {{quant_veiculo_bolsao_hoje ? quant_veiculo_bolsao_hoje : '0'}}
      </div>
    </mat-card-content>

    <mat-card-content class="footer_card" *ngIf="showCompareLastDay">
      <div class="indicacao_card">
        <mat-icon [ngClass]="quant_veiculo_bolsao_ontem > 0 ? 'verde' : 'vermelho'">
          {{quant_veiculo_bolsao_ontem > 0 ? setaup : setadown}}</mat-icon>
      </div>
      <p><span
          [ngClass]="tempo_direcionar_veiculos_ontem > 0 ? 'verde' : 'vermelho'">{{quant_veiculo_bolsao_ontem | number:'1.1-2'}}%</span>
        Em relação ao dia anterior</p>
    </mat-card-content>
  </mat-card>


  <mat-card class="example-card" fxFlex="calc(33%-25px)" fxFlex.xs="calc(100%-15px)" fxFlex.lt-sm="calc(50%-25px)"
    fxFlex.sm="calc(50%-25px)" fxFlex.md="calc(33%-25px)" fxFlex.lg="calc(33%-25px)" fxFlex.xl="calc(33%-25px)">
    <div class="card_top">
      <mat-card-header class="foto">
        <div class="texto_top">
          <span>Quantidade de boxes em análise/criados nos últimos 5 dias</span>
        </div>
      </mat-card-header>
      <div class="fill"></div>
      <div class="circulo c-green">
        <i class="material-icons icon_cor">
          directions_car
        </i>
      </div>
    </div>
    <mat-card-content class="box-list">
      <div class="box-container" *ngFor="let item of quant_box_finalizado">
        <div class="box-item box-l">{{item?.created_at | date:'shortDate'}}</div>
        <div class="box-item box-r"><button mat-flat-button class="btn-justify"
            (click)="modalAnalysisDetails(item?.created_at, item.total)">{{item?.amount}}/{{item.total}}</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>


</div>
