import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Permission } from '../permission';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(
    private http: HttpClient,
    private ngxPermissionService: NgxPermissionsService
  ) { }

  index(options?: any): Observable<Permission> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/permissions`;

    return this.http.get<Permission>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, id: string, params?: any): Observable<Permission> {
    const url = `${environment.apiUrl}/permissions/${id}`;
    const options = {params: params};

    return this.http.put<Permission>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  create(data: any, params?: any): Observable<Permission> {
    const url = `${environment.apiUrl}/permissions`;
    const options = {params: params};

    return this.http.post<Permission>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/permissions/${id}`;
    const options = {params: params};

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  /*
    Para utilizar o conceito de Gestão de Grupos do Maestro alguns metodos do pacote ngx-permissions serão
    comentados ou refatorados.
    checkSomePermission refatorado
  */
  checkSomePermission(values): Observable<boolean> {
    return new Observable(obs => {
      obs.next(true);
      /*this.ngxPermissionService.permissions$.subscribe( (items) => {
        const permissions = Object.values(items).map(item => item.name);
        obs.next(values.some(item => permissions.includes(item)));
      });*/
    });
  }

  toggleSomePermission(permissions, columns, column) {
    this.checkSomePermission(permissions).subscribe((has: boolean) => {
      const index = columns.findIndex(item => item === column);

      if (has && index === -1) {
        columns.push(column);
      } else if (!has && index !== -1) {
        columns.splice(index, 1);
      }
    });
  }

  /*
    Para utilizar o conceito de Gestão de Grupos do Maestro alguns metodos do pacote ngx-permissions serão
    comentados ou refatorados.
    checkDialogPermission refatorado
  */
  checkDialogPermission(mappings, action, dialogRef, form) {
    return;
    const permissions = Object.values(this.ngxPermissionService.getPermissions()).map(item => item.name);

    if (!permissions.includes('superuser')) {
      const update = mappings.find(item => item.action === 'EDIT');
      if (action === 'EDIT' && !permissions.includes(update.permission)) {
        action = 'VIEW';
      }

      const mapping = mappings.find(item => item.action === action);
      if (!permissions.includes(mapping.permission)) {
        setTimeout(() => dialogRef.close(), 0);
      }
    }

    if (action === 'VIEW') {
      form.disable();
    }
  }
}
