import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionRoutingModule } from './permission-routing.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { RoleListComponent } from '../permission/role-list/role-list.component';
import { RoleDetailComponent } from '../permission/role-detail/role-detail.component';
import { RoleService } from '../permission/shared/role.service';
import { PermissionService } from './shared/permission.service';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        PermissionRoutingModule,
    ],
    declarations: [
        RoleListComponent,
        RoleDetailComponent,
    ],
    providers: [
        RoleService,
        PermissionService,
    ]
})
export class PermissionModule { }
