import { Component, Inject, OnInit } from '@angular/core';
import { DockService } from '../shared/dock.service';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { Carrier } from '../../carrier/carrier';
import { CarrierService } from '../../carrier/shared/carrier.service';
import { debounceTime, map, startWith, switchMap, filter, tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dock-detail',
  templateUrl: './dock-detail.component.html',
  styleUrls: ['./dock-detail.component.scss']
})
export class DockDetailComponent implements OnInit {
  form = this.formBuilder.group({
    name: [this.data.item.name, [Validators.required]],
    carrier_id: [this.data.item.carrier_id, [Validators.required]],
  });
  errors: any = {};
  carriers: Carrier[] = [];
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  protected _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<DockDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private dockService: DockService,
    private carrierService: CarrierService
  ) { }

  onSubmit() {
    if (this.data.action === 'EDIT') {
      this.dockService.update(this.form.value, this.data.item.id)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    } else if (this.data.action === 'ADD') {
      this.dockService.create(this.form.value)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    }
  }

  ngOnInit() {
    this.carrierFilter.valueChanges
    .pipe(
      filter(search => !!search),
      tap(() => this.searching = true),
      takeUntil(this._onDestroy),
      startWith({}),
      debounceTime(500),
      switchMap(() => {
        const options = {};

        if (this.carrierFilter.value) {
          Object.assign(options, {
            search: this.carrierFilter.value,
          });
        }

        return this.carrierService.index(options);
      }),
      map((response: any) => response.data)
    ).subscribe(data => this.carriers = data);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
