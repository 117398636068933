<ng-template #templateFilter>
  <form
    [formGroup]="filterGroup"
    (ngSubmit)="shiftSubject$.next()"
    style="display: flex; column-gap: 8px; align-items: baseline;"
  >
    <mat-form-field style="max-width: 90px;" (click)="$event.stopPropagation()">
      <mat-label>Ano exceção</mat-label>
      <mat-select formControlName="date_exception_year">
        <mat-option [value]="''">Todos</mat-option>
        <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="max-width: 90px;" (click)="$event.stopPropagation()">
      <mat-label>Mês exceção</mat-label>
      <mat-select formControlName="date_exception_month">
        <mat-option [value]="''">Todos</mat-option>
        <mat-option *ngFor="let month of months" [value]="month.value">{{month.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button color="primary" type="submit">
      <mat-icon>filter_list</mat-icon>
    </button>
  </form>
</ng-template>
<ng-template #tabLabel>
  <mat-icon *ngIf="trashed" style="margin-right: 8px;" [ngClass]="{'trashed': trashed}">delete</mat-icon>
  <span [ngClass]="{'trashed': trashed}">Dias operacionais dos turnos {{trashed ? "- Inativos" : ""}}</span>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon [ngClass]="{'trashed': trashed}">more_vert</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <mat-list role="list">
      <mat-list-item role="listitem">
        <mat-slide-toggle
          color="primary"
          [checked]="trashed"
          (change)="ontrashed()"
        >
          Registros inativos
        </mat-slide-toggle>
      </mat-list-item>
      <mat-list-item style="height: 80px;" class="filter" role="list">
        <ng-container *ngTemplateOutlet="templateFilter"></ng-container>
      </mat-list-item>
    </mat-list>
  </mat-menu>
</ng-template>

<table #sort="matSort" mat-table [dataSource]="shift.dataSource" class="mat-elevation-z8" style="width: 100%;" matSort>
  <ng-container [matColumnDef]="column.key" *ngFor="let column of shift.columns;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sort" class="aumentar">
      {{column.name}}
    </th>
    <td mat-cell *matCellDef="let element" class="aumentar">
      {{column.key == "date_exception" ? (element.resource[column.key] | date: 'dd/MM/yyyy') : element.resource[column.key]}}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions" end>
    <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
    <td mat-cell *matCellDef="let element" style="white-space: nowrap">
      <button mat-icon-button matTooltip="Abrir" (click)="open(trashed ? actionEnum.VIEW : actionEnum.EDIT, element)">
        <mat-icon>folder_open</mat-icon>
      </button>

      <button *ngIf="!trashed" mat-icon-button matTooltip="Deletar" (click)="destroy(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="shift.displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns:shift.displayedColumns"></tr>
</table>
<mat-paginator #paginator [pageSize]="pageSize" showFirstLastButtons></mat-paginator>

<div
  class="mat-fab-bottom-right add-edit-button"
>
  <button
    *ngIf="activatedTab && !trashed"
    mat-mini-fab
    (click)="open(actionEnum.ADD)"
    color="primary">
    <mat-icon>add</mat-icon>
  </button>
</div>
