<h2 mat-dialog-title>Box físico: {{data.box}} | UC: {{data.pallet}} | Box virtual: {{data.vbox}}</h2>
<mat-dialog-content class="mat-typography">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="item">
      <th mat-header-cell *matHeaderCellDef> Item </th>
      <td mat-cell *matCellDef="let element"> {{element.item}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="lot">
      <th mat-header-cell *matHeaderCellDef> Lote </th>
      <td mat-cell *matCellDef="let element"> {{element.lot}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="amount_confered">
      <th mat-header-cell *matHeaderCellDef> Qtde </th>
      <td mat-cell *matCellDef="let element"> {{element.amount_confered}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="volume">
      <th mat-header-cell *matHeaderCellDef> Volume </th>
      <td mat-cell *matCellDef="let element"> {{element.volume}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="operator">
      <th mat-header-cell *matHeaderCellDef> Operador </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>FECHAR</button>
  <button mat-button [mat-dialog-close]="true" color="primary" (click)="print()">IMPRIMIR</button>
</mat-dialog-actions>
