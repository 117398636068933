import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { AccountGuardService, RouteGuardService } from '../account/shared/account-guard.service';

const dashboardRoutes: Routes = [
  { runGuardsAndResolvers: 'always',
    path: '',
    redirectTo: '/admin/dashboard',
    pathMatch: 'full' },
  {
    path: 'admin',
    canActivate: [AccountGuardService, RouteGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'dashboard',
        component: DashboardListComponent,
        data: {
          title: 'Dashboard',
          search: false,
          refresh: false
        },
      }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(dashboardRoutes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule {
}
