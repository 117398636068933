
<h2 mat-dialog-title>Dia sem operação</h2>
<form [formGroup]="downtimeForm" (ngSubmit)="onSubmit()" *ngIf="data.action == 1">
  <div class="row-times">

    <mat-form-field apearance="outline">
      <mat-label>Data inicial</mat-label>
      <input
        matInput
        [ngxMatDatetimePicker]="dt1"
        placeholder="Data e hora"
        [disabled]="disabled"
        [max]="downtimeForm.get('end').value"
        formControlName="start"
      >
      <mat-datepicker-toggle matSuffix [for]="dt1"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #dt1></ngx-mat-datetime-picker>
    </mat-form-field>

    <mat-form-field apearance="outline">
      <mat-label>Data final</mat-label>
      <input
        matInput
        [ngxMatDatetimePicker]="dt2"
        placeholder="Data e hora"
        [disabled]="disabled"
        [min]="downtimeForm.get('start').value"
        formControlName="end"
      >
      <mat-datepicker-toggle matSuffix [for]="dt2"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #dt2></ngx-mat-datetime-picker>
    </mat-form-field>

    <div class="example-button-container">
      <button mat-mini-fab color="primary" [disabled]="!this.downtimeForm.valid" (click)="addDowntime()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</form>
<mat-dialog-content class="mat-typography" [ngClass]="{disable: saving}">
  <div>
    <div *ngFor="let downtime of downtimes;let i = index" class="row-times">
      <mat-form-field>
        <mat-label>Data inicial</mat-label>
        <input
          matInput
          [ngxMatDatetimePicker]="picker1"
          placeholder="Data e hora"
          [disabled]="disabled"
          [value]="downtime[0]"
          [max]="downtime[1]"
          (dateInput)="downtimeChange(i, 0, $event)"
        >
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Data final</mat-label>
        <input
          matInput
          [ngxMatDatetimePicker]="picker2"
          placeholder="Data e hora"
          [disabled]="disabled"
          [value]="downtime[1]"
          [min]="downtime[0]"
          (dateInput)="downtimeChange(i, 1, $event)"
        >
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker2></ngx-mat-datetime-picker>
      </mat-form-field>
      <button *ngIf="dialogAction.VIEW != data.action" mat-mini-fab color="primary" (click)="removeDowntime(i)">
        <mat-icon>delete</mat-icon>
      </button>

      </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="refreshOnClose">
    {{ data.action != dialogAction.VIEW ? "Cancelar" : "Fechar" }}
  </button>
  <button
    *ngIf="data.action != dialogAction.VIEW"
    mat-button
    cdkFocusInitial
    color="primary"
    [disabled]="!downtimes.length > 0"
    (click)="onSubmit()"
  >
    Salvar
  </button>
</mat-dialog-actions>
