import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { InvoiceService } from '../shared/invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Invoice } from '../invoice';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { LoadsDialogComponent } from '../../manifest/loads-dialog/loads-dialog.component';
import { SearchService } from '../../shared/search.service';
import { Search } from '../../shared/search';
import { MaskUtil } from '../../shared/util/mask.util';
import { RefreshService } from '../../shared/refresh.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FirebaseService } from '../../shared/firebase.service';
import { InvoiceNotesComponent } from '../invoice-notes/invoice-notes.component';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  maskUtil = MaskUtil;
  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  search: string;
  pageSize: number;
  length: number;
  activeMediaQuery = '';
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;

  dataSource = new MatTableDataSource<Invoice>();
  data: Invoice[] = [];
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private invoiceService: InvoiceService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private searchService: SearchService,
    private refreshService: RefreshService,
    public breakpointObserver: BreakpointObserver,
    private firebaseService: FirebaseService
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }
  activateLayout() {

    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.displayedColumns = ['dock', 'box', 'client', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.displayedColumns = ['dock', 'box', 'client', 'load', 'pin', 'status', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['dock', 'box', 'client', 'load', 'pin', 'datetime', 'obs', 'status', 'actions'];
    }
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  ngOnInit() {
    this.sort.sort(<MatSortable>{
      id: 'created_at',
      start: 'desc'
    }
    );

    // If the manifest changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.searchWatcher = this.searchService.value
      .subscribe((search: Search) => {
        this.search = search.value;
        this.reload({ search: search.value });
      });

    this.refreshWatcher = this.refreshService.refresh
      .subscribe((refresh: Event) => {
        this.reload({ refresh: refresh });
      });

    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: this.sort.active,
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            with: 'shipment.shipmentLoad;shipment.dock.wait.boardHorse;shipment.dock.wait.carrier'
            // with: 'shipment.dock;shipment.steps;boardHorse;lobby;operation;carrier',
            // with: 'shipment.dock;shipment.steps;boardHorse;lobby;operation;carrier',
          };

          if (this.search) {
            Object.assign(options, {
              search: this.search,
            });
          }

          return this.invoiceService.index(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.showListEmpty = response.data.length === 0;
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          // const filterData = [];
          // response.data.forEach(function (item) {
          //     if (item.shipment && item.shipment.finish_at) {
          //         filterData.push(item);
          //     }
          // });
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => this.dataSource.data = data);
  }

  saveNotes(element) {
    console.log('element-----');
    console.log(element);
    const dialogRef = this.dialog.open(InvoiceNotesComponent, {
      panelClass: ['dialog-small', 'dialog-fullscreen'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element))
      }
    });
    dialogRef.afterClosed().subscribe(confirm => {
      console.log('fechou');
      this.reload();
      this.snackBar.open('Observação registrada com sucesso!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    });
  }

  showLoad($event, element: any = {}) {
    console.log('mostra as cargas');

    const dialogRef = this.dialog.open(LoadsDialogComponent, {
      panelClass: ['dialog-small'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element))
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('fechou');
    });

  }

}
