<div class="dialog-height">
  <h1 mat-dialog-title>Associar Carga do Box {{data.item.resource.box}}</h1>
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="formWave">
      <form [formGroup]="formWave">
        <ng-template matStepLabel>Selecione as cargas</ng-template>
        <mat-selection-list #waves formControlName="wave">
          <!-- <mat-list-option *ngFor="let item of wavesInBox" [value]="item.id" [selected]="item.wait_id"
            (click)="checkWaves(waves.selectedOptions.selected)"> -->
          <mat-list-option *ngFor="let item of wavesInBox" [value]="item.id" [selected]="item.wait_id"
            (click)="checkCarrier(item.id, item.carrier.id)" class="tkg-optlist">
            Onda: {{item.wave}} | Transportadora: {{item.carrier.name}} {{showWait(item)}}
          </mat-list-option>
        </mat-selection-list>
        <div class="stepper-btn">
          <button type="button" mat-button color="warn" mat-dialog-close><mat-icon>close</mat-icon> <span *ngIf="viewType !== 1">Cancelar</span></button>
          <button mat-stroked-button matStepperNext [disabled]="!block_step || carriersOnWave.length == 0"
            (click)="getWaits()">
            <!-- <button mat-stroked-button matStepperNext> -->
              <span *ngIf="viewType !== 1">Próximo</span> <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="formDriver">
      <form [formGroup]="formDriver">
        <ng-template matStepLabel click="disabled()">Selecione o motorista</ng-template>
        <!-- <mat-form-field appearance="outline"> -->
        <mat-label>Placa</mat-label>
        <mat-select formControlName="wait_id">
          <mat-option>Nenhum</mat-option>
          <mat-option *ngFor="let element of waits" [value]="element.id" (click)="setCarrier(element)"
            style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;">
            <span class="driver-name">{{element.board_cart | uppercase}}</span>
            <br>
            <small class="driver-info"> Transportadora: {{element.carrier.name}} | Placa carreta: {{element.board_cart}}
              |
              Placa cavalo: {{element.board_horse}}</small>

          </mat-option>
        </mat-select>
        <!-- </mat-form-field> -->
        <div class="stepper-btn">
          <button type="button" mat-button color="warn" mat-dialog-close><mat-icon>close</mat-icon> <span *ngIf="viewType !== 1">Cancelar</span></button>
          <button mat-stroked-button matStepperPrevious>
            <mat-icon>chevron_left</mat-icon> <span *ngIf="viewType !== 1">Anterior</span>
          </button>
          <button mat-stroked-button [disabled]="!formDriver.valid"
            (click)="assign(waves.selectedOptions.selected)"><span *ngIf="viewType !== 1">Associar</span> <mat-icon>save
            </mat-icon>
          </button>
        </div>
      </form>

    </mat-step>
  </mat-horizontal-stepper>
</div>
