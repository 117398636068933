
<h2 mat-dialog-title>Filtros</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="outline" class="tkg-input-select">
    <mat-label>Etapas</mat-label>
    <mat-select multiple [formControl]="stepsFilter">
      <mat-option *ngFor="let step of steps" [value]="step.value">{{step.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="tkg-input-select">
    <mat-label>Transportadora</mat-label>
    <mat-select multiple [formControl]="carrierFilter">
      <mat-option *ngFor="let carrier of carriers" [value]="carrier.id">
        {{carrier.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="warn" class="tkg-btn">X</button>
  <button mat-flat-button mat-dialog-close (click)="clearFilters()" color="accent" class="tkg-btn-accent tkg-btn">Limpar</button>
  <button mat-flat-button cdkFocusInitial class="tkg-btn-primary tkg-btn" (click)="applyFilters()">Aplicar</button>
</mat-dialog-actions>
