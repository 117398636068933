<div class="main-gap-only-secure">
  <div>
    <div>
      <img
        class="logo"
        src="assets/img/logo-trackage-vertical.svg"
        alt="Trackage"
      />
      <mat-card>
        <mat-card-title-group>
          <h2 mat-card-title>Redefinir Senha</h2>
        </mat-card-title-group>
        <mat-card-content>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div>
              <mat-form-field>
                <input
                  [type]="hidePassword ? 'password' : 'text'"
                  formControlName="password"
                  matInput
                  placeholder="Digite a Senha"
                />
                <mat-hint ngClass="mat-error">
                  <div *ngFor="let error of errors?.password">{{ error }}</div>
                </mat-hint>
                <mat-icon
                  matSuffix
                  style="cursor: pointer"
                  (click)="hidePassword = !hidePassword"
                  >{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon
                >
              </mat-form-field>
            </div>
            <!-- <div>
              <mat-form-field>
                <input
                  [type]="hidePassword ? 'password' : 'text'"
                  formControlName="password_confirmation"
                  matInput
                  placeholder="Confirme a Senha"
                />
                <mat-hint ngClass="mat-error">
                  <div *ngFor="let error of errors?.password_confirmation">
                    {{ error }}
                  </div>
                </mat-hint>
                <mat-icon
                  matSuffix
                  style="cursor: pointer"
                  (click)="hidePassword = !hidePassword"
                  >{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon
                >
              </mat-form-field>
            </div> -->

            <!-- <div>
              <span>
                <button
                  type="button"
                  mat-button
                  [matMenuTriggerFor]="menu"
                  color="primary"
                >
                  Mais opções
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item routerLink="/auth/login">Entrar</button>
                  <button mat-menu-item routerLink="/auth/register">
                    Registrar
                  </button>
                </mat-menu>
              </span> -->
            <div>
              <span>
                <button
                  type="submit"
                  [disabled]="!form.valid"
                  mat-button
                  mat-raised-button
                  color="primary"
                >
                  ENTRAR
                </button>
              </span>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
