import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Media } from '../media.interface';
import { Notificacao } from '../notificacao.interface';
import { Boxes } from '../boxes.interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  direcionamento(options?: any): Observable<Media> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/media_direcionamento`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  liberacao(options?: any): Observable<Media> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/media_liberacao`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  pendencias(options?: any): Observable<Media> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/pendencias`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  notificacoes(options?: any): Observable<Notificacao> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/notificacoes`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  picking(options?: any): Observable<Media> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/media_picking`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  duplaConferencia(options?: any): Observable<Media> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/media_dupla_conferencia`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  carregamento(options?: any): Observable<Media> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/media_carregamento`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  manifestoSaida(options?: any): Observable<Media> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/media_manifesto_saida`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  bolsao(options?: any): Observable<Media> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/veiculos_bolsao`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  boxesFinalizados(options?: any): Observable<Boxes> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/boxes_finalizados`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  justificativas(options?: any): Observable<Boxes> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/justificativas`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }
}
