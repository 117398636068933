<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon
        *ngIf="!activatedStaff"
        style="margin-right: 8px; width: 32px;"
        [ngClass]="{'active-carrier-switcher': !activatedStaff}"
      >
        not_interested
      </mat-icon>
      <span
        [ngClass]="{'active-carrier-switcher': !activatedStaff}"
      >
        Colaboradores {{!activatedStaff ? "- Inativos" : ""}}
      </span>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon [ngClass]="{'active-carrier-switcher': !activatedStaff}">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <mat-list role="list">
          <mat-list-item role="listitem">
            <mat-slide-toggle
              color="primary"
              [checked]="!activatedStaff"
              (change)="activeStaffSwitcher()"
            >
              Registros inativos
            </mat-slide-toggle>
          </mat-list-item>
        </mat-list>
      </mat-menu>
    </ng-template>
    <app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" [hidden]="showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Nome</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="320px" class="ellipsis aumentar">{{element.name}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="provider_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Equipe</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="320px" class="ellipsis aumentar">{{element.provider_name}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">UPIN</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="320px" class="ellipsis aumentar">{{element.code}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="shift_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Turno</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="320px" class="ellipsis aumentar">{{formatShiftName(element.shift_id)}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" end>
      <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button matTooltip="Abrir" (click)="open($event, 'EDIT', element)">
          <mat-icon>folder_open</mat-icon>
        </button>

        <button mat-icon-button matTooltip="Deletar" (click)="delete($event, element)" data-ngxPermissionsOnly="['superuser', 'users.destroy']">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

</div>

<button mat-mini-fab (click)="open($event, 'ADD')" class="mat-fab-bottom-right" color="primary" data-ngxPermissionsOnly="['superuser', 'users.store']">
  <mat-icon>add</mat-icon>
</button>
  </mat-tab>
</mat-tab-group>

