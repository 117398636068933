
<h2 mat-dialog-title>{{ data.type == "shift" ? "Turno" : "Pausa" }}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="shiftForm" (ngSubmit)="onSubmit()">
    <div>
      <mat-form-field appearance="outline" fxFlex="100%" style="width: 100%;">
        <mat-label>Nome</mat-label>
        <input formControlName="name" type="text" placeholder="Nome" matInput>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline">
        <mat-label>Turno</mat-label>
        <mat-select formControlName="shift_shifts_id">
          <mat-option>Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let item of shiftShifts" [value]="item.id">
            {{item.name | uppercase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style="margin-bottom: 24px;">
      <mat-button-toggle-group
        formControlName="weekday"
        name="weekday"
        aria-label="Dias da semana"
        style="flex-wrap:wrap; width: 100%; justify-content: center;"
        multiple
      >
        <mat-button-toggle [value]="weekDay.Monday">Segunda</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Tuesday">Terça</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Wednesday">Quarta</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Thursday">Quinta</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Friday">Sexta</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Saturday">Sábado</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Sunday">Domingo</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="row-times">
      <mat-form-field appearance="outline">
        <mat-label>Início</mat-label>
        <input
          maxlength="5"
          type="time"
          formControlName="start"
          placeholder="00:00"
          min="00:00"
          max="23:59"
          onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 58'
          matInput
          [format]="24"
          [ngxMatTimepicker]="start"
          readonly
        />
        <ngx-mat-timepicker #start></ngx-mat-timepicker>
        <mat-icon matSuffix>access_time</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Termino</mat-label>
        <input
          maxlength="5"
          type="time"
          formControlName="end"
          placeholder="00:00"
          min="00:00"
          max="23:59"
          onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 58'
          matInput
          [format]="24"
          [ngxMatTimepicker]="end"
          readonly
        />

        <ngx-mat-timepicker #end></ngx-mat-timepicker>
        <mat-icon matSuffix>access_time</mat-icon>
      </mat-form-field>

    </div>

    <div class="row-times">
      <div>
        <mat-slide-toggle
          color="primary"
          [checked]="exception"
          (change)="onExceptionDate($event.checked)"
          style="margin: 15px 0 30px 0;"
        >
          Exceção
        </mat-slide-toggle>
      </div>

      <mat-form-field appearance="outline" [ngStyle]="{'visibility':exception ? 'visible' : 'hidden', 'max-width':'225px'}">
        <mat-label>Data de exceção</mat-label>
        <input matInput formControlName="date_exception" [matDatepicker]="dateException">
        <mat-datepicker-toggle matSuffix [for]="dateException"></mat-datepicker-toggle>
        <mat-datepicker #dateException></mat-datepicker>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="legacy" style="width: 40px;">
        <mat-label>Duração</mat-label>
        <input matInput placeholder="00:00" formControlName="duration" matInput [readonly]="true">
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">
    {{ data.action != dialogAction.VIEW ? "Cancelar" : "Fechar" }}
  </button>
  <button
    *ngIf="data.action != dialogAction.VIEW"
    mat-button
    cdkFocusInitial
    color="primary"
    [disabled]="!this.shiftForm.valid || this.saving"
    (click)="onSubmit()"
  >
    Salvar
  </button>
</mat-dialog-actions>
