import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { NewDashboardRoutingModule } from './new-dashboard-routing.module'
import { NgApexchartsModule } from "ng-apexcharts";


@NgModule({
  declarations: [DashboardListComponent],
  imports: [
    CommonModule,
    NewDashboardRoutingModule,
    CoreModule,
    SharedModule,
    NgApexchartsModule
  ]
})
export class NewDashboardModule { }
