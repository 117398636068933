<mat-tab-group #tabGroup [selectedIndex]="0">
  <!-- Cadastro de turnos -->
  <mat-tab label="Cadastro de turnos">
    <app-shifts-records-tab #shiftsRecords [activatedTab]="tabGroup.selectedIndex == 0">
    </app-shifts-records-tab>
  </mat-tab>

  <!-- Shift - Dias operacionais dos turnos -->
  <mat-tab data-key="shift">
    <app-shifts-shifts-tab #shiftsShifts [activatedTab]="tabGroup.selectedIndex == 1">
      <ng-template mat-tab-label>
        <ng-container [ngTemplateOutlet]="shiftsShifts.tabLabel"></ng-container>
      </ng-template>
    </app-shifts-shifts-tab>
  </mat-tab>

  <!-- Break - Pausa -->
  <mat-tab  data-key="break">
    <app-shifts-break-tab #shiftBreak [activatedTab]="tabGroup.selectedIndex == 2">
      <ng-template mat-tab-label>
        <ng-container [ngTemplateOutlet]="shiftBreak.tabLabel"></ng-container>
      </ng-template>
    </app-shifts-break-tab>
  </mat-tab>

  <!-- Downtime - Dias sem operações -->
  <mat-tab data-key="downtime">
    <app-shifts-downtime-tab #shiftDowntime [activatedTab]="tabGroup.selectedIndex == 3">
      <ng-template mat-tab-label>
        <ng-container [ngTemplateOutlet]="shiftDowntime.tabLabel"></ng-container>
      </ng-template>
    </app-shifts-downtime-tab>
  </mat-tab>
</mat-tab-group>
