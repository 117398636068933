import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountGuardService } from '../account/shared/account-guard.service';
import { StepsListComponent } from './steps-list/steps-list.component';

const stepsRoute: Routes = [
  { runGuardsAndResolvers: 'always',
    path: '',
    redirectTo: '/admin/steps-analysis',
    pathMatch: 'full' },
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'steps-analysis',
        component: StepsListComponent,
        data: {
          title: 'Análise de Etapas',
          search: false,
          refresh: false
        },
      }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(stepsRoute, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [
    RouterModule
  ]
})
export class StepsRoutingModule {
}
