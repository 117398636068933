import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Release } from '../release';

@Injectable({
  providedIn: 'root'
})
export class WaitReleaseService {

  constructor(
    private http: HttpClient
  ) { }

  index(wait: string|number, options?: any): Observable<Release> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/waits/${wait}/releases`;

    return this.http.get<Release>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, wait: string|number, id: string, params?: any): Observable<Release> {
    const url = `${environment.apiUrl}/waits/${wait}/releases/${id}`;
    const options = {params: params};

    return this.http.put<Release>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  create(wait: string|number, data?: any, params?: any): Observable<Release> {
    const url = `${environment.apiUrl}/waits/${wait}/releases`;
    const options = {params: params};

    return this.http.post<Release>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(wait: string|number, id: string, params?: any) {
    const url = `${environment.apiUrl}/waits/${wait}/releases/${id}`;
    const options = {params: params};

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }
}
