<div class="tkg-form-row top-bar-filters">
  <div class="tkg-form-item-40">
    <mat-button-toggle-group #selector="matButtonToggleGroup" (change)="changeFilter($event)" value="day" name="fontStyle"
      aria-label="Font Style" class="filter-opt">
      <mat-button-toggle value="day">Dia</mat-button-toggle>
      <mat-button-toggle value="period">Período</mat-button-toggle>
    </mat-button-toggle-group>
    <span *ngIf="selector.value == 'day'">
      <mat-form-field>
        <input matInput #datePickerOnbar1 placeholder="Data" [matDatepicker]="picker1" [formControl]="dayStartDate">
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <button mat-icon-button aria-label="Data para exibição" (click)="picker1.open()"
        matTooltip="Escolha uma data para filtrar">
        <mat-icon>calendar_today</mat-icon>
      </button>
      <button mat-button color="primary" (click)="dayFilter()">
        <mat-icon>filter_list</mat-icon>
        Filtrar
      </button>
    </span>
    <span *ngIf="selector.value == 'period'">
      <mat-form-field>
        <input matInput #datePickerOnbar2 placeholder="Data inicial" [matDatepicker]="picker2"
          [formControl]="periodStartDate">
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
      <button mat-icon-button aria-label="Data para exibição" (click)="picker2.open()"
        matTooltip="Data inicial para filtrar">
        <mat-icon>calendar_today</mat-icon>
      </button>
      <mat-form-field>
        <input matInput #datePickerOnbar3 placeholder="Data final" [matDatepicker]="picker3"
          [formControl]="periodEndDate">
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <button mat-icon-button aria-label="Data para exibição" (click)="picker3.open()"
        matTooltip="Data final para filtrar">
        <mat-icon>calendar_today</mat-icon>
      </button>

      <button mat-button color="primary" (click)="periodFilter()">
        <mat-icon>filter_list</mat-icon>
        Filtrar
      </button>


    </span>
  </div>
    <div fxFlex="25%" class="tkg-form-item-25" [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Transportadora</mat-label>
        <mat-select formControlName="carrier_id">
          <!-- #substituir -->
          <!-- <ngx-mat-select-search [formControl]="carrierFilter" placeholderLabel="Buscar transportadora"
            [noEntriesFoundLabel]="''"></ngx-mat-select-search> -->
          <mat-option (click)="cleanCarrierFilter()">Limpar Filtro</mat-option>
          <mat-option *ngFor="let element of carriers" [value]="element.id" (click)="getFilterCarrier()">
            {{element.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="tkg-form-item-25 btn-download">
      <button mat-button (click)="downloadReport()" matTooltip="Baixar CSV"
        [disabled]="loading">
        <mat-icon aria-label="Baixar CSV">get_app</mat-icon>
        Baixar CSV
      </button>
    </div>

</div>
<app-list-empty *ngIf="showListEmpty"></app-list-empty>
<div class="box-container mat-elevation-z8" [hidden]="showListEmpty">

  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <ng-container matColumnDef="box" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header="box" class="col-5 aumentar">Box F</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{element.resource.box}}</td>
    </ng-container>


    <ng-container matColumnDef="carrier_carriers:carrier_id|carrier_carriers.name">
      <th mat-header-cell *matHeaderCellDef class="col-10 aumentar" mat-sort-header="carrier_carriers.name">Transportadora</th>
      <!-- <td mat-cell *matCellDef="let element" class="aumentar">{{element.resource.carrier?.name}}</td> -->
      <td mat-cell *matCellDef="let element" class="col-10 aumentar">{{getCarrier(element.resource)}}</td>
    </ng-container>

    <ng-container matColumnDef="assigned">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Associado</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{checkIfAssigned(element.resource.vbox)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="vboxes">
      <th mat-header-cell *matHeaderCellDef class="col-10 aumentar">Boxes Virtuais</th>
      <td mat-cell *matCellDef="let element" class="col-10 aumentar">{{showBoxes(element.resource.vbox)}}</td>
    </ng-container>


    <ng-container matColumnDef="tvolume">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Vol Total</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{sumOrderVolums(element)}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Status</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{showMaxStatus(element.resource)}}</td>
    </ng-container>

    <ng-container matColumnDef="invoice">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">NF</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">
        {{element.resource.has_invoice ? 'Sim' : 'Não'}}
      </td>
    </ng-container>


    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Obs</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{element.notes ? element.notes.notes : '-'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="just">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Qtd. Erros</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{element.resource.justification_list ? element.resource.justification_list.length : '-'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="arrival_prev">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Previsão chegada</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar" [matTooltip]="element?.arrival_prev | date:'short'">
        {{element?.arrival_prev | date:'shortTime'}}</td>
    </ng-container>
    <ng-container matColumnDef="min_dt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="min_dt" class="col-5 aumentar">Início sep.</th>
      <!-- <td mat-cell *matCellDef="let element" class="aumentar">{{element.min_dt | date:'shortTime'}}</td> -->
      <td mat-cell *matCellDef="let element" class="col-5 aumentar"
        [matTooltip]="getMinMoment(element.resource.vbox ) | date:'short'">
        {{ getMinMoment(element.resource.vbox ) | date:'shortTime'}}</td>
    </ng-container>
    <ng-container matColumnDef="max_dt">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Última bipagem</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar"
        [matTooltip]="getMaxMoment(element.resource.vbox ) | date:'short'">
        {{ getMaxMoment(element.resource.vbox ) | date:'shortTime'}}</td>
    </ng-container>
    <ng-container matColumnDef="print_map">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Impressão mapa</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar" [matTooltip]="printMapTime(element) | date:'short'">
        {{printMapTime(element) | date:'shortTime'}}</td>
    </ng-container>
    <ng-container matColumnDef="dc_end">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Final DC</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">
        {{element.end_dc | date:'shortTime' }}</td>
    </ng-container>
    <ng-container matColumnDef="load_end">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Final carregamento</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">
        {{element.end_step | date:'shortTime'}}</td>
    </ng-container>
    <ng-container matColumnDef="steps" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="col-10 aumentar">Etapa</th>
      <td mat-cell *matCellDef="let element" class="col-10 aumentar">
        <mat-chip-list aria-label="Steps">
          <mat-chip ngClass="btn-{{element.step}}">
            <div>{{showStep(element.step)}}</div>
            <div class="step-datetime">{{showStepTime(element.steps) | date:'short':'-0300'}} </div>
          </mat-chip>
        </mat-chip-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="col-7 aumentar">Ações</th>
      <td mat-cell *matCellDef="let element" class="col-7 aumentar">
        <mat-menu #menu1="matMenu">
          <button mat-menu-item (click)="printConferenceMap(element.resource.box_id)">
            <mat-icon>print</mat-icon>
            <span>Imprimir Mapa da DC</span>
          </button>
          <button mat-menu-item (click)="printMap(element.resource)">
            <mat-icon>print</mat-icon>
            <span>Imprimir Mapa de Picking</span>
          </button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="menu1">
          <mat-icon>more_vert</mat-icon>
        </button>

        <button type="button" mat-icon-button (click)="expandedElement = expandedElement === element ? null : element"
          [matTooltip]="element == expandedElement ? 'Recolher' : 'Expandir'">
          <mat-icon>{{ element == expandedElement ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumns.length">
        <div class="element-detail mat-elevation-z4 margin-bottom"
          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

          <table mat-table [dataSource]="element.resource.vbox">

            <ng-container matColumnDef="box">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Box V.</th>
              <td mat-cell *matCellDef="let element" class="aumentar">{{ element.vbox }}</td>
            </ng-container>

            <ng-container matColumnDef="vbox_min_dt">
              <th mat-header-cell *matHeaderCellDef class="aumentar">H. Início</th>
              <td mat-cell *matCellDef="let element" class="aumentar"
                [matTooltip]="element.min_start_at | date:'short'">
                {{ element.min_start_at | date:'shortTime' }}</td>
            </ng-container>

            <ng-container matColumnDef="wave">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Onda</th>
              <td mat-cell *matCellDef="let element" class="aumentar">{{ element.wave }}</td>
            </ng-container>

            <ng-container matColumnDef="client">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Cliente</th>
              <td mat-cell *matCellDef="let element" class="aumentar">{{ element.operation?.client_name }}</td>
            </ng-container>

            <ng-container matColumnDef="volumes">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Vol. Pedido</th>
              <td mat-cell *matCellDef="let element" class="aumentar">{{ element.operation?.order_vol }}</td>
            </ng-container>

            <ng-container matColumnDef="volume">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Vol. Conferido</th>
              <td mat-cell *matCellDef="let element" class="aumentar">
                {{ element.order_vol_confered ? element.order_vol_confered  : 0 }}</td>
            </ng-container>

            <ng-container matColumnDef="vol_percent">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Vol. %</th>
              <td mat-cell *matCellDef="let element" class="aumentar">
                {{ (element.order_vol_confered * 100)/element.operation?.order_vol | number:'1.1-2' }}%</td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Qtd. Pedido</th>
              <td mat-cell *matCellDef="let element" class="aumentar">{{ element.operation?.order_amount }}</td>
            </ng-container>

            <ng-container matColumnDef="amount_confered">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Qtd. Conferido</th>
              <td mat-cell *matCellDef="let element" class="aumentar">
                {{ element.order_amount_confered ? element.order_amount_confered : 0 }}</td>
            </ng-container>

            <ng-container matColumnDef="qtd_percent">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Qtd. %</th>
              <td mat-cell *matCellDef="let element" class="aumentar">
                {{ (element.order_amount_confered * 100)/element.operation?.order_amount | number:'1.1-2' }}%</td>
            </ng-container>
            <ng-container matColumnDef="vboxStatus">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Status</th>
              <td mat-cell *matCellDef="let element" class="aumentar">
                {{ element.operation?.minor_status }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element; let index = index;">
                <mat-menu #menu="matMenu">
                  <button mat-menu-item>
                    <mat-icon>info</mat-icon>
                    <span [translate]="'dock.action.pendencies'"></span>
                  </button>
                </mat-menu>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row
              *matHeaderRowDef="displayedShipmentColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);">
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedShipmentColumns;"></tr>
          </table>

        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);">
    </tr>
    <tr mat-row class="element-row" [class.expanded-row]="expandedElement === element"
      *matRowDef="let element; columns: displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; let even = even; columns: ['expandedDetail']" class="detail-row"
      [ngClass]="{gray: even}"></tr>
  </table>
  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>
