import { DowntimeInterface, ShiftsDialogAction } from '../shift';
import { ShiftService } from '../shared/shift.service';
import { FormBuilder, Validators } from '@angular/forms';
import {  Component, Inject, OnInit } from '@angular/core';
//import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-downtime-dialog',
  templateUrl: './downtime-dialog.component.html',
  styleUrls: ['./downtime-dialog.component.scss']
})
export class DowntimeDialogComponent implements OnInit {
  downtimes:([Date,Date,string|number]|[Date,Date])[] = []
  dialogAction = ShiftsDialogAction
  downtimeForm = this.formBuilder.group({
    start:[ null, [Validators.required]],
    end:[ null, [Validators.required]]
  })

  refreshOnClose = false;
  saving = false;

  constructor(
    private dialog: MatDialog,
    private shiftService:ShiftService,
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DowntimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DowntimeInterface
  ) {}

  ngOnInit() {
    if([ShiftsDialogAction.EDIT, ShiftsDialogAction.VIEW].includes(this.data.action)){
      this.downtimes = this.data.data.map(item=>[
          moment(item.resource.start).toDate(),
          moment(item.resource.end).toDate(),
          item.id
        ]
      ) as [Date, Date, number][]
    }

    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(result => {
      this.dialogRef.close(this.refreshOnClose);
    });
  }

  addDowntime(){
    this.downtimes.push([
      this.downtimeForm.get("start").value,
      this.downtimeForm.get("end").value
    ]);
    this.downtimeForm.reset();
  }

  removeDowntime(index:number){

    if(this.data.action === ShiftsDialogAction.EDIT){
      this.dialog.open(DialogConfirmComponent, {
        data:{
          title:"Exclusão de downtime.",
          message:"Deseja realmente excluir este item?"
        }
      })
      .beforeClosed()
      .subscribe((destroy:boolean)=>{
        const id = this.downtimes[index][2] as string|number;
        if(destroy){
          this.saving = true;
          this.shiftService
          .destroy(id)
          .subscribe(()=>{
            this.downtimes = this.downtimes.filter((_item, i)=> i != index);
            this.refreshOnClose = true;
            this.saving = false;
            this.downtimes.length == 0 && this.dialogRef.close(true);
          },()=>{
            this.saving = false;
          })
        }
      })
    }else{
      this.downtimes = this.downtimes.filter((_item, i)=> i !== index);
    }

  }

  downtimeChange(i:number, j:number, event:any){
    const downtimes = this.downtimes;
    downtimes[i][j] = event.value;
    this.downtimes = downtimes;
  }

  onSubmit(){
    const resource = this.downtimes.map(([start, end, id]:[Date, Date, string|number])=>{
      const duration = moment.duration(moment(end).diff(moment(start)));
      const durationAsHM = Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(':mm:00');

      const downtime:any = {
        name:"downtime",
        resource:{
          duration:durationAsHM,
          start:moment(start).format("YYYY-MM-DD HH:mm:00"),
          end:moment(end).format("YYYY-MM-DD HH:mm:00")
        }
      };

      id && (downtime.id = id);
      return downtime;
    });

    const data = {
      resource,
      name:"downtime"
    }

    if(this.data.action === ShiftsDialogAction.ADD)
      this.shiftService.create(data).subscribe((data:any)=>{
        this.dialogRef.close(true)
      })

    if(this.data.action === ShiftsDialogAction.EDIT)
      this.shiftService.updateDowntimes(data).subscribe((data:any)=>{
        this.dialogRef.close(true)
      })
  }
}
