import { BoxService } from './../../dock/shared/box.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

export interface UCItens {
  item: string;
  lot: string;
  amount_confered: number;
  volume: number;
}



@Component({
  selector: 'app-conference-uc-detail',
  templateUrl: './conference-uc-detail.component.html',
  styleUrls: ['./conference-uc-detail.component.scss']
})
export class ConferenceUcDetailComponent implements OnInit {
  displayedColumns: string[] = ['item', 'lot', 'amount_confered', 'volume', 'operator'];
  // ELEMENT_DATA: UCItens[] = [];
  dataSource: UCItens[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private boxService: BoxService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.getUCDetails();
  }

  getUCDetails() {
    const options = {
      box: this.data.box,
      vbox: this.data.vbox,
      pallet: this.data.pallet
    }
    this.boxService.boxUcDetail(options).subscribe((r: any) => {
      this.dataSource = r.data;
    });
  }

  print() {
    this.makeConferenceMapPDF(this.dataSource);
  }

  makeConferenceMapPDF(data: any) {
    console.log('data----')
    console.log(data);
    let i;
    let doc = new jsPDF('p', 'pt', 'a4');
    doc.setFont('Helvetica');
    doc.setFontStyle('bold');
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(`Box Físico: ${data[0].dock} | UC: ${data[0].pallet}`, 20, 40, null, 0);

    doc.setFont('Helvetica');
    doc.setFontStyle('bold');
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
    doc.text('Item', 20, 95);// +90
    doc.text('Lote', 110, 95);
    doc.text('Quantidade', 200, 95);
    doc.text('Volume', 290, 95);
    doc.text('Operador', 380, 95);

    doc.setLineWidth(1.0);
    doc.line(20,100,570,100);
    doc.setLineWidth(0.5);
    i = 110;

    let count = 0;

    data.forEach(item => {
      count = count + item.amount;
      doc.setFontStyle('normal');
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(item.item, 20, i);
      doc.text(item.lot, 110, i);
      doc.text(item.amount_confered.toString(), 200, i);
      doc.text(item.volume.toString(), 290, i);
      doc.text(item.name.toString(), 380, i);

      i = i + 4;
      doc.line(20, i, 570, i);
      i = i + 10;
      if(i > 820) {
        doc.addPage();
        i = 50;
      }
    });
    doc.output('dataurlnewwindow');
  }

}
