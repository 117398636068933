<h1 class="mat-align-center" mat-dialog-title>{{ data.action === 'EDIT' ? 'Editar' : 'Adicionar'}} grupo
</h1>

<div mat-dialog-content>


  <form [formGroup]="form">
    <div class="tkg-form-row">
      <mat-form-field appearance="fill" fxFlex="100%" style="width: 100%;">
        <mat-label>Nome do grupo</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
    </div>
    <div class="tkg-form-row">
      <mat-form-field class="tkg-form-item-70">
        <mat-label>Módulo</mat-label>
        <mat-select formControlName="module">
          <mat-option *ngFor="let item of pageModules" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-select>

      </mat-form-field>
      <div class="tkg-from-item-30 tkg-btn-add-page">
        <button mat-stroked-button color="primary" (click)="addPage()">
          <mat-icon>add_box</mat-icon>
          Adicionar
        </button>
      </div>
    </div>



    <mat-card>
      <span class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon" *ngFor="let item of modules; index as idx">
        {{item.name}}
        <span class="material-icons tkg-chips-icon" (click)="removePage(idx)">
          cancel
        </span>
      </span>
    </mat-card>

  </form>

</div>

<div mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" mat-dialog-close="true">Cancelar</button>
  <button type="submit" mat-button cdkFocusInitial color="primary" [disabled]="!form.valid"
    (click)="onSubmit()">Salvar</button>
</div>
