import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../shared/user.service';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {DialogAlertComponent} from '../../shared/dialog-alert/dialog-alert.component';
import {NgxPermissionsService} from 'ngx-permissions';
import {PermissionService} from '../../permission/shared/permission.service';
import { CarrierService } from '../../carrier/shared/carrier.service'

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  form = this.formBuilder.group({
    first_name: [this.data.item.first_name, [Validators.required]],
    last_name: [this.data.item.last_name, [Validators.required]],
    email: [this.data.item.email, [Validators.required, Validators.email]],
    group_id: [this.data.item.group_id, [Validators.required]],
    is_superuser: [this.data.item.is_superuser || false, [Validators.required]],
    is_staff: [this.data.item.is_staff || false, [Validators.required]],
    is_active: [this.data.item.is_active || false, [Validators.required]],
    carrier_user: [this.data.item.carrier_id ? true : false, [Validators.required]],
    carrier_id: [this.data.item.carrier_id],
    is_alert_mail: [this.data.item.is_alert_mail || false, [Validators.required]],
    is_alert_phone: [this.data.item.is_alert_phone || false, [Validators.required]],
    retention_list_limit: [this.data.item.retention_list_limit || false, [Validators.required]],
  });
  errors: any = {};
  hidePassword = true;
  groups: any;
  carrierList: any;

  constructor(
    public dialogRef: MatDialogRef<UserDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private permissionService: PermissionService,
    private carrierService: CarrierService
  ) { }

  onSubmit() {
    if(this.form.value.carrier_user === false) {
      this.form.value.carrier_id = null;
    }
    if (this.data.action === 'EDIT') {
      this.userService.update(this.form.value, this.data.item.id)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    } else if (this.data.action === 'ADD') {
      this.userService.create(this.form.value)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    }
  }

  checkPermission() {
    const permissions = [
      {action: 'ADD', permission: 'users.store'},
      {action: 'EDIT', permission: 'users.update'},
      {action: 'VIEW', permission: 'users.show'},
    ];

    this.permissionService.checkDialogPermission(permissions, this.data.action, this.dialogRef, this.form);
  }

  ngOnInit() {
    this.checkPermission();
    this.loadUserGroups();
    this.getCarrierList();

    if (this.data.action === 'ADD') {
      this.form.addControl('password', new UntypedFormControl('', [Validators.required]));
    }
  }

  loadUserGroups() {
    const options = { pageSize: 200 };
    return this.userService.userGroups(options).subscribe(
      (r: any) => {
        this.groups = r.data;
      },
      (e: any) => {
        console.log(e);
      }
    );
  }

  getCarrierList() {
    this.carrierService.index({pageSize: 1000}).subscribe((r: any) => {
      this.carrierList = r.data;
    });
  }

}
