import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Account } from '../account';
import { Token } from '../token';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  helper: JwtHelperService = new JwtHelperService();
  @Output() event: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient
  ) { }

  login(data): Observable<Token> {
    const url = `${environment.apiUrl}/oauth/token`;
    const body = {
      grant_type: environment.oauth.grantType,
      client_id: environment.oauth.clientId,
      client_secret: environment.oauth.clientSecret,
      username: data.email,
      password: data.password,
      scope: environment.oauth.scope,
    };

    return this.http.post<Token>(url, body).pipe(
      tap((response: Token) => {
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);
        this.event.emit({action: 'login'});
      }),
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  token(): any {
    const helper = new JwtHelperService();
    return helper.decodeToken(localStorage.getItem('access_token'));
  }

  logout() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(localStorage.getItem('access_token'));
    const url = `${environment.apiUrl}/oauth/tokens/${decodedToken.jti}`;

    // return this.http.delete(url).pipe(
    //   map((response: Response | any) => response || {}),
    //   catchError(err => throwError(err || 'Server error')),
    //   finalize(() => {
    //     ['access_token', 'refresh_token', 'account'].forEach(key => {
    //       localStorage.removeItem(key);
    //     });
    //     this.event.emit({action: 'logout'});
    //   })
    // );
    ['access_token', 'refresh_token', 'account'].forEach(key => {
      localStorage.removeItem(key);
    });
  }

  isAuthenticated(): boolean {
    return !this.helper.isTokenExpired(localStorage.getItem('access_token'));
  }

  create(data: any, params?: any): Observable<Account> {
    const url = `${environment.apiUrl}/users`;
    const options = {params: params};

    return this.http.post<Account>(url, data, options).pipe(
      tap((response: Response | any) => {
        this.event.emit({
          action: 'create',
          data: Object.assign( response, {
            email: data.email,
            password: data.password,
          })
        });
      }),
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  passwordToken(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/accounts/password/token`;
    const options = {params: params};

    return this.http.post<any>(url, data, options).pipe(
      tap((response: Response | any) => {
        this.event.emit({
          action: 'password_token',
          data: data,
        });
      }),
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  passwordReset(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/accounts/password/reset`;
    const options = {params: params};

    return this.http.post<any>(url, data, options).pipe(
      tap((response: Response | any) => {
        this.event.emit({
          action: 'password_reset',
          data: data,
        });
      }),
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  identity(renew?: boolean): Promise<Account> {
    return new Promise<Account>((resolve, reject) => {
      if (localStorage.getItem('access_token') !== null) {
        if (localStorage.getItem('account') !== null && !renew) {
          resolve(JSON.parse(localStorage.getItem('account')));
        } else {
          this.show().subscribe((account: Account) => {
            resolve(account);
          });
        }
      } else {
        resolve(null);
      }
    });
  }

  show(options?: any): Observable<Account> {
    const token = this.helper.decodeToken(localStorage.getItem('access_token')).jti;
    const url = `${environment.apiUrl}/accounts/${token}`;
    const httpParams = Object.assign({}, options);
    const params = new HttpParams({fromObject: httpParams});

    return this.http.get<Account>(url, {params}).pipe(
      tap((response: Account) => {
        localStorage.setItem('account', JSON.stringify(response));
        this.event.emit({action: 'show', data: response});
      }),
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, id: string, params?: any): Observable<Account> {
    const url = `${environment.apiUrl}/accounts/${id}`;
    const options = {params: params};

    return this.http.put<Account>(url, data, options).pipe(
      tap((response: Account) => {
        localStorage.setItem('account', JSON.stringify(response));
        this.event.emit({action: 'update', data: response});
      }),
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }
}
