<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <h2 mat-dialog-title>Enviar para Nota Fiscal?</h2>

  <mat-dialog-content class="mat-typography">

    <!-- <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <textarea formControlName="notes" matInput placeholder="Observações"></textarea>
      </mat-form-field>
    </div> -->
    <H3>Tem certeza que deseja passar o box <b>{{data.item.name}}</b> para NF?</H3>
    <mat-form-field class="select">
      <mat-label>PIN</mat-label>
      <mat-select formControlName="pin">
        <mat-option *ngFor="let pin of pins" [value]="pin.value">
          {{pin.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
    <button type="submit" mat-button color="primary" [disabled]="!form.valid">Salvar</button>
  </mat-dialog-actions>
</form>
