export const env = {
  production: false,
  appTitle: 'Dashboard',
  apiUrl: 'https://3m.trackage.io/api',
  wsUrl: 'wss://3m.trackage.io/ws',
  whitelistedDomains: ['3m.trackage.io', 'localhost:4200'],
  oauth: {
    grantType: 'password',
    clientId: 2,
    clientSecret: 'eQegBky0dkJCax4hDchdw9g1axiriez5l7NUEUT6',
    scope: ''
  }
};
