import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms'

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  calendar = new UntypedFormControl(new Date());
  constructor() { }

  ngOnInit() {
    this.calendar.valueChanges
      .subscribe(newValue => {
        // this.searchService.onChange(newValue, this.scope);
        console.log(newValue);
      });
  }

  teste() {
    return 'teste-teste';
  }

}
