<!-- <h1 mat-dialog-title>Detalhes</h1>
<div mat-dialog-content>
  <p>Box físico</p>


</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="">Fechar</button>
</div> -->

<h2 mat-dialog-title>Detalhes</h2>
<mat-dialog-content class="mat-typography">
  <p><b>Box físico</b> {{this.data.resource.box}}</p>
  <p><b>Transportadora</b> {{getCarrier(this.data.resource)}}</p>
  <p><b>Associado</b> {{checkIfAssigned(this.data.resource.vbox)}}</p>
  <p><b>Box V.</b> {{showBoxes(this.data.resource.vbox)}}</p>
  <p><b>Vol. total</b> {{sumOrderVolums(this.data)}}</p>
  <p><b>Status</b> {{showStatus(this.data.resource)}}</p>
  <p><b>NF</b> {{this.data.resource.has_invoice ? 'Sim' : 'Não'}}</p>
  <p><b>OBS</b> {{this.data.notes ? this.data.notes.notes : '-'}}</p>
  <p><b>Prev. chegada</b> {{this.data?.arrival_prev | date:'shortTime'}}</p>
  <p><b>Ini. separação</b> {{ this.data.min_start | date:'shortTime'}}</p>
  <p><b>Ultima bipagem</b> {{ getMaxMoment(this.data.resource.vbox ) | date:'shortTime'}}</p>
  <p><b>Imp. mapa</b> {{printMapTime(this.data) | date:'shortTime'}}</p>
  <p><b>Final dupla contagem</b> {{this.data.end_dc | date:'shortTime' }}</p>
  <p><b>Final carregamento</b> {{this.data.end_step | date:'shortTime'}}</p>

  <div *ngFor="let item of this.data.resource.vbox" class="vbox-detail">
    <b>Box Virtual {{item.vbox}}</b>
    <hr>
    <p><b>Hora de início</b> {{item.min_start_at | date:'shortTime'}}</p>
    <p><b>Onda</b> {{ item.wave }}</p>
    <p><b>Cliente</b> {{ item.operation?.client_name }}</p>
    <p><b>Vol. Pedido</b> {{ item.operation?.order_vol }}</p>
    <p><b>Vol. Conferido</b> {{ item.order_vol_confered ? item.order_vol_confered  : 0 }}</p>
    <p><b>Vol. %</b> {{ (item.order_vol_confered * 100)/item.operation?.order_vol | number:'1.1-2' }}%</p>
    <p><b>Qtd. Pedido</b> {{ item.operation?.order_amount }}</p>
    <p><b>Qtd. Conferido</b> {{ item.order_amount_confered ? item.order_amount_confered : 0 }}</p>
    <p><b>Qtd %</b> {{ (item.order_amount_confered * 100)/item.operation?.order_amount | number:'1.1-2' }}%</p>
    <p><b>Status</b> {{ item.operation?.minor_status }}</p>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Fechar</button>
</mat-dialog-actions>
