<div>
  <h2 mat-dialog-title>Pendências do box virtual {{data.dock.box}}, onda {{data.dock.wave}}</h2>
  <mat-dialog-content class="mat-typography">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="conference" class="coluna20">
        <th mat-header-cell *matHeaderCellDef> Conferido</th>
        <td mat-cell *matCellDef="let element"> {{element.volume ? element.volume : 0}} volumes de {{element.volumes}} </td>
      </ng-container>

      <ng-container matColumnDef="missing" class="coluna16">
        <th mat-header-cell *matHeaderCellDef> Falta </th>
        <td mat-cell *matCellDef="let element"> {{element.volumes - element.volume}} </td>
      </ng-container>

      <ng-container matColumnDef="item" class="coluna16">
        <th mat-header-cell *matHeaderCellDef> Item </th>
        <td mat-cell *matCellDef="let element"> {{element.item_code}} </td>
      </ng-container>

      <ng-container matColumnDef="outputArea" class="coluna16">
        <th mat-header-cell *matHeaderCellDef> Área de Saída </th>
        <td mat-cell *matCellDef="let element"> {{element.path}}  </td>
      </ng-container>

      <ng-container matColumnDef="justification" class="coluna16">
        <th mat-header-cell *matHeaderCellDef> Observação </th>
        <td mat-cell *matCellDef="let element"> {{element.justification}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
          <td mat-cell *matCellDef="let element" class="aumentar">
  
            <button mat-icon-button matTooltip="Comentários" (click)="manage($event, 'EDIT', element)">
              <mat-icon>comment</mat-icon>
            </button>
            
      

          </td>
        </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </mat-dialog-content>
</div>
<mat-dialog-actions align="end">
  <button type="button" mat-stroked-button mat-dialog-close id="btn-fechar">Fechar</button>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close (click)='generatePDF()'>Gerar PDF</button>
</mat-dialog-actions>
