import { Component, Input, OnInit } from '@angular/core';
import { DockService } from 'src/app/dock/shared/dock.service';


@Component({
  selector: 'app-picking-percentage',
  templateUrl: './picking-percentage.component.html',
  styleUrls: ['./picking-percentage.component.scss']
})
export class PickingPercentageComponent implements OnInit {
  @Input('box_id') box_id;
  percentage: number = 0;
  constructor(
    private dockService: DockService
  ) { }

  ngOnInit(): void {
    this.getDockPercentage();
    setInterval(() => {
      this.getDockPercentage();
    }, 60000 * 3);
  }

  getDockPercentage() {
    const options = {
      box_id: this.box_id
    }
    this.dockService.pickingPercentage(options).subscribe((r: any) => {
      this.percentage = r.data;
    });
  }

}
