import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../loader.service';
import { Subscription } from 'rxjs';
import { LoaderState } from '../loader-state';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  private loaderWatcher: Subscription;

  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loaderWatcher = this.loaderService.loaderState
      .subscribe((state: LoaderState) => setTimeout(() => this.show = state.show, 0));
  }

  ngOnDestroy() {
    this.loaderWatcher.unsubscribe();
  }
}
