<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <h2 mat-dialog-title>
    {{ (form.value.name) ? form.value.name : ((data.action == 'EDIT') ? 'Salvar' : 'Adicionar') }}
  </h2>
  <mat-dialog-content class="mat-typography">

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <input type="text" formControlName="name" matInput placeholder="Nome">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>

      <mat-form-field fxFlex.gt-sm="30">
        <!-- #substituir -->
        <!-- <input type="text" formControlName="document" [textMask]="{mask: maskUtil.maskCNPJ()}" matInput
          placeholder="CNPJ"> -->
        <input type="text" formControlName="document" matInput
          placeholder="CNPJ">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.document">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
    <button type="submit" mat-button color="primary"
      [disabled]="!form.valid">{{ (data.action == 'EDIT') ? 'Salvar' : 'Adicionar' }}</button>
  </mat-dialog-actions>
</form>
