import { Component, Inject, OnInit } from '@angular/core';
import { DockService } from '../shared/dock.service';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { ShipmentService } from '../../shipment/shared/shipment.service';
// import { merge, of as observableOf, Subject } from 'rxjs';
import { debounceTime, filter, tap, takeUntil, startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Subject, merge, of as observableOf } from 'rxjs';
// import { catchError, debounceTime, map, startWith, switchMap, filter, tap, takeUntil } from 'rxjs/operators';
import { LoadService } from '../../shipment/shared/load.service';
import { Load } from '../../shipment/load';
import { notInArrayValidator } from '../../shared/validators/not-in-array.directive';

@Component({
  selector: 'app-operation-detail',
  templateUrl: './operation-detail.component.html',
  styleUrls: ['./operation-detail.component.scss']
})
export class OperationDetailComponent implements OnInit {
  form = this.formBuilder.group({
    box: [this.data.dock.shipment_box, [Validators.required]],
    wave: [this.data.dock.wave, [Validators.required]],
    client: []
  });

  loads: Load[] = [];
  LoadsFiltered: Load[] = [];
  LoadedWaves = [];
  loadFilter: UntypedFormControl = new UntypedFormControl();
  errors: any = {};
  searching = false;
  protected _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<OperationDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private dockService: DockService,
    private shipmentService: ShipmentService,
    private loadService: LoadService,
  ) { }

  onSubmit() {
    this.dialogRef.close(this.form.value);
    const resource = {
      wave: this.form.value.wave,
      carrier_id: this.data.dock.carrier_id,
      box: this.form.value.box,
      dock_id: this.data.dock.id,
      wait_id: this.data.dock.wait ? this.data.dock.wait.id : null,
      client: this.form.value.client
    };
    this.shipmentService.create(resource)
      .subscribe(
        value => this.nextCallback(value),
        error => this.errorCallback(error)
      );
  }

  nextCallback(value) {
    this.dialogRef.close(value);
    this.snackBar.open('Operacao realizada com sucesso!', 'OK', {
      duration: 3000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
    });
  }

  errorCallback(error) {
    if (error.status === 422) {
      this.errors = error.error.errors;
    } else {
      this.dialog.open(DialogAlertComponent, {
        data: { title: error.statusText, message: error.error.message }
      });
    }
  }

  showAction(title?) {
    if (title) {
      return title;
    } else {
      return { 'ADD': 'Adicionar', 'EDIT': 'Salvar', 'FINISH': 'Finalizar' }[this.data.action];
    }
  }

  getLoads(search?: string) {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            with: 'carrier',
            unique: true,
            carrier_id: this.data.dock.carrier_id,
            wave: search ? search : ''
          };

          // const searchQuery = [`carrier_id:${this.data.dock.carrier_id}`];
          // // const searchQuery = [];

          // if (search) {
          //   searchQuery.push(`wave:${search}`);
          // }

          // Object.assign(options, {
          //   search: searchQuery.join(';'),
          // });

          return this.loadService.index(options);
        }),
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.loads = data;
        this.loadWaves();
      });
  }

  ngOnInit() {
    this.getLoads();
    this.loadFilter.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        startWith({}),
        debounceTime(500),
        switchMap(() => {
          const options = {};

          if (this.loadFilter.value) {
            Object.assign(options, {
              search: `name:${this.loadFilter.value}`,
            });
          }
          return this.loadService.index(options);
        }),
        map((response: any) => response.data)
      ).subscribe(data => {
        this.getLoads(this.loadFilter.value);
      });
  }

  loadWaves() {
    this.shipmentService.waveLoaded(this.data.dock.id).subscribe((r: any) => {
      console.log(r);
      r.data.forEach(element => {
        this.LoadedWaves.push(element.wave);
      });
      this.filterLoads(this.loads);
    }, (e: any) => {
      console.log('error');
      console.log(e);
    });
  }

  filterLoads(loads: any) {
    this.LoadsFiltered = loads.filter((item) => {
      return this.LoadedWaves.includes(item.wave) !== true;
    });
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
