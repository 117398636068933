import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';

const invoiceRoutes: Routes = [
  { runGuardsAndResolvers: 'always',
    path: '',
    redirectTo: '/admin/invoices',
    pathMatch: 'full' },
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'invoices',
        component: InvoiceListComponent,
        data: {
          title: 'Shipping',
          filter: false,
          search: true,
          refresh: false,
        },
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(invoiceRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class InvoiceRoutingModule { }
