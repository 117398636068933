import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManifestService } from '../shared/manifest.service';
import { Loads } from '../loads.interface';
import { format, parse, parseISO } from 'date-fns';

@Component({
  selector: 'app-loads-dialog',
  templateUrl: './loads-dialog.component.html',
  styleUrls: ['./loads-dialog.component.scss']
})
export class LoadsDialogComponent implements OnInit {
  displayedColumns: string[] = ['load_number', 'amount', 'volumes', 'load_status'];
  dataSource: Loads;
  constructor(
    public dialogRef: MatDialogRef<LoadsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private manifestService: ManifestService
  ) { }

  ngOnInit() {
    this.getLoads();
    console.log('--------');
    console.log(this.data);
  }

  getLoads() {
    const params = {
      wave: this.data.item.wave,
      client: this.data.item.client_name,
      created_at: format(parseISO(this.data.item.created_at), "yyyy-MM-dd")
    };


    this.manifestService.getLoads(params).subscribe((r: Loads) => {
      this.dataSource = r;
    });
  }
}
