<ng-template #tabLabel>
  <mat-icon *ngIf="trashedDowntime" style="margin-right: 8px;" [ngClass]="{'trashed': trashedDowntime}">delete</mat-icon>
  <span [ngClass]="{'trashed': trashedDowntime}">Dias sem operações {{trashedDowntime ? "- Inativos" : ""}}</span>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon [ngClass]="{'trashed': trashedDowntime}">more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <mat-list role="list">
      <mat-list-item role="listitem">
        <mat-slide-toggle
          color="primary"
          [checked]="trashedDowntime"
          (change)="onTrashedDowntime()"
        >
          Registros inativos
        </mat-slide-toggle>
      </mat-list-item>
    </mat-list>
  </mat-menu>
</ng-template>

<!--Calendário-->
<div [ngClass]="{'hidden': trashedDowntime}" class="downtime">
  <div class="calendar">
    <mat-card class="calendar-card">
      <mat-calendar
        #calendar
        (monthSelected)="downtimeSubject$.next()"
      ></mat-calendar>
    </mat-card>
    <mat-card class="date-time">
      <mat-card-title>Data e hora</mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div *ngFor="let downtime of downtimes;let i = index" class="row-times">
          <mat-form-field>
            <mat-label>Data inicial</mat-label>
            <input
              matInput
              [ngxMatDatetimePicker]="picker"
              placeholder="Data e hora"
              [value]="momentRef(downtime.resource?.start).toDate()"
              readonly
            >
            <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="true"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Data final</mat-label>
            <input
              matInput
              [ngxMatDatetimePicker]="picker1"
              placeholder="Data e hora"
              readonly
              [value]="momentRef(downtime.resource?.end).toDate()"
            >
            <mat-datepicker-toggle matSuffix [for]="picker1" [disabled]="true"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
        <div class="no-data" *ngIf="!downtimes?.length">
          {{ searching ? "Buscando resultados ..." : "Sem registros" }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<!--Tabela dos items excluídos dos dias sem operações-->
<div [ngClass]="{'hidden': !trashedDowntime}">
  <table #sortDowntime="matSort" mat-table [dataSource]="downtimes" class="mat-elevation-z8" style="width: 100%;" matSort>
    <!-- Data inicial -->
    <ng-container matColumnDef="start">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="aumentar"> Data inicial </th>
      <td mat-cell *matCellDef="let element" class="aumentar"> {{element.resource.start | date: 'dd/MM/yyyy' }} </td>
    </ng-container>

    <!-- Horário inicial -->
    <ng-container matColumnDef="start_hour">
      <th mat-header-cell *matHeaderCellDef class="aumentar"> Horário inicial </th>
      <td mat-cell *matCellDef="let element" class="aumentar"> {{element.resource.start  | date: 'HH:mm' }} </td>
    </ng-container>

    <!-- Data final -->
    <ng-container matColumnDef="end">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="aumentar"> Data final </th>
      <td mat-cell *matCellDef="let element" class="aumentar"> {{element.resource.end  | date: 'dd/MM/yyyy' }} </td>
    </ng-container>

    <!-- Horário final -->
    <ng-container matColumnDef="end_hour">
      <th mat-header-cell *matHeaderCellDef class="aumentar"> Horário final </th>
      <td mat-cell *matCellDef="let element" class="aumentar"> {{element.resource.end | date: 'HH:mm' }} </td>
    </ng-container>

    <!-- Duração -->
    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef class="aumentar"> Duração </th>
      <td mat-cell *matCellDef="let element" class="aumentar"> {{ element.resource.duration }} </td>
    </ng-container>

    <!-- Ações -->
    <ng-container matColumnDef="actions" end>
      <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button matTooltip="Abrir" (click)="open(actionEnum.VIEW, [element])">
          <mat-icon>folder_open</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="downtimeColumns"></tr>
    <tr mat-row *matRowDef="let row; columns:downtimeColumns"></tr>

  </table>
  <mat-paginator #paginatorDowntime [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
</div>
<!-- Botões flutuantes -->
<div *ngIf="activatedTab && !trashedDowntime"
  class="mat-fab-bottom-right add-edit-button"
>
  <button
    [disabled]="downtimes.length == 0"
    mat-mini-fab (click)="open(actionEnum.EDIT, downtimes)"
    color="primary"
  >
    <mat-icon>folder_open</mat-icon>
  </button>
  <button
    mat-mini-fab
    (click)="open(actionEnum.ADD)"
    color="primary">
    <mat-icon>add</mat-icon>
  </button>
</div>
