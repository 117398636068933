import { TkgBarcodeScannerComponent } from './../tkg-barcode-scanner/tkg-barcode-scanner.component';
import { CarrierService } from 'src/app/carrier/shared/carrier.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-conference-dialog',
  templateUrl: './conference-dialog.component.html',
  styleUrls: ['./conference-dialog.component.scss']
})
export class ConferenceDialogComponent implements OnInit {
  palletList = [];
  palletListFt = [];
  formValues: any = {};
  supervisorMsg = false;
  actionClass = 'actionOk';
  filter = false;
  filterValue = '';
  showBtnBarcode = false;
  showBtnSave = true;

  msgClass = 'msg-ok';
  msg = 'Quantidade divergente';
  showMsg = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private carrierService: CarrierService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ConferenceDialogComponent>
  ) { }

  ngOnInit() {
    this.getConferences();
  }

  getConferences() {
    this.carrierService.showConferenceFromBox(this.data.box_id).subscribe((r: any) => {
      this.palletList = this.sortList(r.data);
      this.palletListFt = this.palletList;
      if(this.filter) {
        this.palletListFt = this.applyFilter(this.filterValue);
      }
      this.palletList.forEach((i: any) => {
        this.formValues[i.id] = {value: i.value, match: false};
      });
      this.showBtnBarcode = true;
      this.showBtnSave = false;
      this.checkConferred();

    });
  }

  sortList(list: any){
    return list.sort((a, b) => Number(a.confered) - Number(b.confered));
  }

  saveConference(i: number, id: number) {
    this.showBtnSave = false;
    if (i) {
      this.carrierService.updateConferenceFromBox({conference_id: id, amount_confered: i}).subscribe((r: any) => {
        // Libera botão save

        if (r.match) {
          // Mostra mensagem ok com timout 1000
          this.showMsg = true;
          this.msg = r.msg;
          this.msgClass = 'msg-ok';
          setTimeout(()=>{
            this.showMsg = false;
            this.showBtnSave = true;
          }, 1000);
          this.getConferences();
          this.cleanFt();
        } else {
          // Mostra mensagem error com timout 3000

          this.showMsg = true;
          this.msg = r.msg;
          this.msgClass = 'msg-error';
          setTimeout(()=>{
            this.showBtnSave = true;
            this.showMsg = false;
          }, 3000);

          this.getConferences();
          if (r.call) {
            this.supervisorMsg = true;
          }
        }
      });
    }
  }

  showAttempts(resource: any) {
    if (resource) {
      let list = '';
      resource.forEach(element => {
        if (element.match === false) {
          if (list.length === 0) {
            list = `${element.reported_amount}`;
          } else {
            list = `${list} / ${element.reported_amount}`;
          }
        }
      });
      list = `Quantidade informada: ${list}`;
      return list.length > 22 ? list : '';
    } else {
      return '';
    }
  }

  openBarcodeScanner() {
    this.palletListFt = this.palletList;
    const dialogRef = this.dialog.open(TkgBarcodeScannerComponent, {
      panelClass: ['dialog-fullscreen'],
      data: [...this.palletList]
    });

    dialogRef.afterClosed().subscribe(data => {
      if(data.find){
        this.filter = true;
        this.filterValue = data.pallet;
        this.palletListFt = this.applyFilter(data.pallet);
      }
    });
  }

  applyFilter(value: string) {
    return this.palletListFt.filter((item: any) => item.pallet === value)
  }

  cleanFt(){
    this.filter = false;
    this.filterValue = '';
    this.getConferences();
  }

  checkConferred(){
    const conf = [];
    const total = [];
    this.palletList.forEach((item: any) =>{
      total.push(1);
      if(item.confered) {
        conf.push(1);
      }
    });

    if(conf.length == total.length) {
      this.dialogRef.close();
    }
  }

}
