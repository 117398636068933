<mat-grid-list cols="3" rowHeight="10:2">
  <mat-grid-tile>
    <div class="tkg-register-item" routerLink="/admin/carriers">
      <mat-icon aria-hidden="false" fontIcon="local_shipping"></mat-icon>
      <span class="tkg-register-item-title">Transportadoras</span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="tkg-register-item" routerLink="/admin/users">
      <mat-icon aria-hidden="false" fontIcon="person"></mat-icon>
      <span class="tkg-register-item-title">Usuários</span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="tkg-register-item" routerLink="/admin/groups">
      <mat-icon aria-hidden="false" fontIcon="group"></mat-icon>
      <span class="tkg-register-item-title">Grupos</span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="tkg-register-item" routerLink="/admin/staff">
      <mat-icon aria-hidden="false" fontIcon="engineering"></mat-icon>
      <span class="tkg-register-item-title">Colaboradores</span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="tkg-register-item" routerLink="/admin/shifts">
      <mat-icon aria-hidden="false" fontIcon="update"></mat-icon>
      <span class="tkg-register-item-title">Gestão de Turnos</span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="tkg-register-item" routerLink="/admin/support-data/times">
      <mat-icon aria-hidden="false" fontIcon="diversity_3"></mat-icon>
      <span class="tkg-register-item-title">Equipe</span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="tkg-register-item" routerLink="/admin/support-data/justify_analysis">
      <mat-icon aria-hidden="false" fontIcon="error"></mat-icon>
      <span class="tkg-register-item-title">Justificativas para Análises</span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="tkg-register-item" routerLink="/admin/support-data/justifications_load_cancel">
      <mat-icon aria-hidden="false" fontIcon="cancel"></mat-icon>
      <span class="tkg-register-item-title">Justificativas de Cancelamento</span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="tkg-register-item" routerLink="/admin/step-change-map">
      <mat-icon aria-hidden="false" fontIcon="cancel"></mat-icon>
      <span class="tkg-register-item-title">Mapa de mudança de etapas</span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="tkg-register-item" routerLink="/admin/steps">
      <mat-icon aria-hidden="false" fontIcon="garage_home"></mat-icon>
      <span class="tkg-register-item-title">Etapas</span>
    </div>
  </mat-grid-tile>
  <!-- <mat-grid-tile>
    <div class="tkg-register-item">
      <mat-icon aria-hidden="false" fontIcon="account_tree"></mat-icon>
      <span class="tkg-register-item-title">Etapas</span>
    </div>
  </mat-grid-tile> -->
</mat-grid-list>
