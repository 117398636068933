import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ConfigurationService } from '../shared/configuration.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Configuration } from '../configuration';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { SearchService } from '../../shared/search.service';
import { Search } from '../../shared/search';
import { MaskUtil } from '../../shared/util/mask.util';
import { RefreshService } from '../../shared/refresh.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PermissionService } from '../../permission/shared/permission.service';

@Component({
  selector: 'app-configuration-list',
  templateUrl: './configuration-list.component.html',
  styleUrls: ['./configuration-list.component.scss']
})
export class ConfigurationListComponent implements OnInit, OnDestroy {
  maskUtil = MaskUtil;
  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  search: string;
  pageSize: number;
  length: number;
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  // dataSource = new MatTableDataSource<Configuration>();
  // data: Configuration[] = [];
  // @Output() reloadEvent = new EventEmitter();
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;

  constructor(

    private permissionService: PermissionService,
    public breakpointObserver: BreakpointObserver,
  ) {
    // this.breakpointObserver.observe([
    //   Breakpoints.Handset,
    //   Breakpoints.Tablet,
    //   Breakpoints.Web,
    // ]).subscribe(result => {
    //   if (result.matches) {
    //     this.activateLayout();
    //   }
    // });
  }

  // activateLayout() {
  //   if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
  //     this.displayedColumns = ['title', 'actions'];
  //   } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
  //     this.displayedColumns = ['name', 'title', 'created_at', 'actions'];
  //   } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
  //     this.displayedColumns = ['name', 'title', 'created_at', 'actions'];
  //   }

  //   const permissions = ['superuser', 'configurations.show', 'configurations.update'];
  //   this.permissionService.toggleSomePermission(permissions, this.displayedColumns, 'actions');
  // }


  ngOnInit() {

  }

  ngOnDestroy() {
    // this.searchWatcher.unsubscribe();
    // this.refreshWatcher.unsubscribe();
  }
}
