<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" [hidden]="showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="board_horse">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Placa da Carreta</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{element.board_cart | uppercase}}</td>
    </ng-container>

    <ng-container matColumnDef="dock">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Doca</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{ dockById(element.direction?.dock_number)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="arrival_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">H. Chegada</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{element.arrival_at | date: 'shortTime'}}</td>
    </ng-container>

    <ng-container matColumnDef="directed_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">H. Direc.</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{element.directed_at | date: 'shortTime'}}</td>
    </ng-container>

    <ng-container matColumnDef="driver">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Motorista</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        <div>
          <span class="ellipsis" fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="180px"
            class="aumentar">{{element.driver | uppercase}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">
        {{'wait.wait-list.column.client' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="ellipsis" fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="180px"
            class="aumentar">{{element.client | uppercase}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="charge_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">
        OBS</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="ellipsis" fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="180px"
            class="aumentar">{{element.charge_number | uppercase}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="carrier_carriers:carrier_id|carrier_carriers.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Transportadora</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        <div>
          <span class="ellipsis" fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="180px"
            class="aumentar">
            {{element.carrier ? element.carrier.name : '' | uppercase}}
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Status</th>
      <td mat-cell *matCellDef="let element" class="aumentar-chips">
        <mat-chip-list>
          <mat-chip [ngClass]="{
            'app-chip-yellow': !element.direction && !element.release,
            'app-chip-blue': element.direction && !element.release,
            'app-chip-green': element.direction && element.release
          }">{{showStatus(element)}}</mat-chip>
        </mat-chip-list>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button [matTooltip]="showTooltip(element)" (click)="onDirect($event, element)">
          <mat-icon>directions_car</mat-icon>
        </button>
      </td>
    </ng-container> -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element;">
        <mat-menu #menu="matMenu">
          <button mat-menu-item [matTooltip]="showTooltip(element)" (click)="onDirect($event, element)">
            <mat-icon>directions_car</mat-icon>
            <span>Direcionar veículo</span>
          </button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!-- <mat-paginator [pageSize]="pageSize" showFirstLastButtons></mat-paginator> -->

  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>


</div>
