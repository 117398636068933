import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { DockService } from '../shared/dock.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { PendencyService } from '../../shipment/shared/pendency.service';
// import { Location } from '@angular/common';

@Component({
  selector: 'app-pendency-detail',
  templateUrl: './pendency-detail.component.html',
  styleUrls: ['./pendency-detail.component.scss']
})
export class PendencyDetailComponent implements OnInit {
  form = this.formBuilder.group({
    name: [this.data.item.name, [Validators.required]],
    assigned_by: [this.data.item.assigned_by],
  });
  errors: any = {};

  constructor(
    public dialogRef: MatDialogRef<PendencyDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private dockService: DockService,
    private pendencyService: PendencyService,
  ) { }
  onSubmit() {
    this.dialogRef.close(this.form.value);

    if (this.data.action === 'FINISH') {
      const resource = {
          name: this.form.value.name,
          assigned_by: this.form.value.assigned_by,
          finish_at: new Date(),
      };
      this.pendencyService.update(resource, this.data.item.id)
          .subscribe(
              data => {
                  this.dialogRef.close(data);
                  this.snackBar.open('Pendência finalizada com sucesso!', 'OK', {
                      duration: 3000,
                      horizontalPosition: 'left',
                      verticalPosition: 'bottom',
                  });
              },
              err => {
                  if (err.status === 422) {
                      this.errors = err.error.errors;
                  } else {
                      this.dialog.open(DialogAlertComponent, {
                          data: {title: err.statusText, message: err.error.message}
                      });
                  }
              }
          );
    } else if (this.data.action === 'EDIT') {
      this.pendencyService.update(this.form.value, this.data.item.id)
          .subscribe(
              data => {
                this.dialogRef.close(data);
                this.snackBar.open('Pendência atualizada com sucesso!', 'OK', {
                  duration: 3000,
                  horizontalPosition: 'left',
                  verticalPosition: 'bottom',
                });
              },
              err => {
                if (err.status === 422) {
                  this.errors = err.error.errors;
                } else {
                  this.dialog.open(DialogAlertComponent, {
                    data: {title: err.statusText, message: err.error.message}
                  });
                }
              }
            );
    } else if (this.data.action === 'ADD') {
      const resource = {
          shipment_id: this.data.shipment.current.id,
          step_id: this.data.shipment.current.steps[0].id,
          name: this.form.value.name,
          assigned_by: this.form.value.assigned_by,
      };

      this.pendencyService.create(resource, this.data.item.id)
          .subscribe(
              data => {
                  this.dialogRef.close(data);
                  this.snackBar.open('Pendência adicionada com sucesso!', 'OK', {
                      duration: 3000,
                      horizontalPosition: 'left',
                      verticalPosition: 'bottom',
                  });
              },
              err => {
                  if (err.status === 422) {
                      this.errors = err.error.errors;
                  } else {
                      this.dialog.open(DialogAlertComponent, {
                          data: {title: err.statusText, message: err.error.message}
                      });
                  }
              }
          );
    }
  }

  showAction(title?) {
    if (title) {
      return title;
    } else {
      return {'ADD': 'Adicionar', 'EDIT': 'Salvar', 'FINISH': 'Finalizar'}[this.data.action];
    }
  }

  ngOnInit() {
  }
}
