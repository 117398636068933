import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountGuardService } from '../account/shared/account-guard.service';
import { RegistrationListComponent } from './registration-list/registration-list.component';
import { SupportDataListComponent } from './support-data-list/support-data-list.component';
import { StepsListComponent } from '../registration/steps-list/steps-list.component';
import { StepChangeMapListComponent } from './step-change-map-list/step-change-map-list.component';

const registrationRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'registrations',
        component: RegistrationListComponent,
        data: {
          title: 'Cadastros',
          search: false,
          refresh: false,
        },
      },
      {
        path: 'support-data/:name',
        component: SupportDataListComponent,
        data: {
          title: 'Cadastros',
          search: false,
          refresh: false,
        },
      },
      {
        path: 'steps',
        component: StepsListComponent,
        data: {
          title: 'Etapas',
          search: false,
          refresh: false,
        },
      },
      {
        path: 'step-change-map',
        component: StepChangeMapListComponent,
        data: {
          title: 'Mapa de mudança de etapas',
          search: false,
          refresh: false,
        },
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(registrationRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RegistrationRoutingModule {}
