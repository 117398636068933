<h2 mat-dialog-title>
    Cargas
  </h2>
  <mat-dialog-content class="mat-typography">

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="load_number">
            <th mat-header-cell *matHeaderCellDef> Carga </th>
            <td mat-cell *matCellDef="let element"> {{element.load_number}} </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Quantidade </th>
            <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
          </ng-container>

          <ng-container matColumnDef="volumes">
            <th mat-header-cell *matHeaderCellDef> Volume </th>
            <td mat-cell *matCellDef="let element"> {{element.volumes}} </td>
          </ng-container>

          <ng-container matColumnDef="load_status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.load_status}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="primary" mat-dialog-close>Fechar</button>
  </mat-dialog-actions>




