<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Gerenciar Espera</h2>
  <mat-dialog-content class="mat-typography">

    <div class="tkg-form">

      <mat-form-field class="tkg-form-item-30">
        <mat-label>Código Portaria</mat-label>
        <select matNativeControl formControlName="lobby_id">
          <option value="1">001</option>
        </select>

        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.lobby_id">{{ error }}</div>
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="tkg-form-item-30">

        <mat-label>Transportadora</mat-label>
        <mat-select formControlName="carrier_id">
          <!-- #substituir -->
          <!-- <ngx-mat-select-search [formControl]="carrierFilter" placeholderLabel="Buscar Transportadora"
            [noEntriesFoundLabel]="''"></ngx-mat-select-search> -->
          <mat-option *ngFor="let element of carriers" [value]="element.id">
            {{element.name}}
          </mat-option>
        </mat-select>

      </mat-form-field>

      <mat-form-field class="tkg-form-item-30">
        <input type="text" formControlName="driver" matInput placeholder="Motorista" pattern="[A-Z]*">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.driver">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="tkg-form">
      <mat-form-field class="tkg-form-item-30">
        <input type="text" formControlName="authorized_by" matInput placeholder="Autorizador" pattern="[A-Z]*">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.authorized_by">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="tkg-form-item-30">
        <input type="text" formControlName="board_horse" matInput placeholder="Placa do Cavalo">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.board_horse">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="tkg-form-item-30">
        <input type="text" formControlName="board_cart" matInput placeholder="Placa da Carreta">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.board_cart">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="tkg-form">

      <mat-form-field class="tkg-form-item-30">
        <input matInput type="text" formControlName="client" matInput placeholder="Cliente" pattern="[A-Z]*">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.client">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="tkg-form-item-60">
        <input matInput type="text" formControlName="charge_number" matInput placeholder="OBS">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.charge_number">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="tkg-form">

      <mat-form-field class="tkg-form-item-30">
        <!-- <input matInput placeholder="Data e Hora da Chegada" formControlName="arrival_at" [owlDateTimeTrigger]="dt1"
          [owlDateTime]="dt1"> -->
        <!-- <input matInput placeholder="Data e Hora da Chegada" formControlName="arrival_at"> -->
        <!-- <owl-date-time #dt1></owl-date-time> -->

        <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Data e Hora da Chegada" formControlName="arrival_at">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker1 [showSpinners]="true" [showSeconds]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field class="tkg-form-item-30">
        <!-- <input matInput placeholder="Data e Hora da Entrada" formControlName="entry_at" [owlDateTimeTrigger]="dt2"
          [owlDateTime]="dt2"> -->
        <!-- <input matInput placeholder="Data e Hora da Entrada" formControlName="entry_at"> -->
        <!-- <owl-date-time #dt2></owl-date-time> -->


        <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Data e Hora da Entrada" formControlName="entry_at">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker2 [showSpinners]="true" [showSeconds]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>


      <mat-form-field class="tkg-form-item-30">
        <!-- <input matInput placeholder="Data e Hora da Saída" formControlName="output_at" [owlDateTimeTrigger]="dt3"
          [owlDateTime]="dt3"> -->
        <!-- <input matInput placeholder="Data e Hora da Saída" formControlName="output_at"> -->
        <!-- <owl-date-time #dt3></owl-date-time> -->

        <input matInput [ngxMatDatetimePicker]="picker3" placeholder="Data e Hora da Saída" formControlName="output_at">
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker3 [showSpinners]="true" [showSeconds]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>


    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button mat-dialog-close color="warn">Cancelar</button>
    <button type="submit" mat-button cdkFocusInitial
      color="primary">{{ (data.action == 'EDIT') ? 'Salvar' : 'Adicionar' }}</button>
  </mat-dialog-actions>

</form>
