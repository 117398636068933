import { Component, OnInit, EventEmitter, ViewChild, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of as observableOf, Subscription, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, tap, debounceTime } from 'rxjs/operators';
import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { UntypedFormControl } from '@angular/forms';
import { UserService } from '../shared/user.service';
import { GroupsDetailComponent } from '../groups-detail/groups-detail.component';
import { AccountService } from 'src/app/account/shared/account.service';

export interface Groups {
  can_delete:boolean;
  created_at:string;
  id:number;
  menu_pages_id:string;
  name:string;
  updated_at:string
}

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss']
})
export class GroupsListComponent implements OnInit {
  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  accountWatcher: Subscription;
  search: string;
  pageSize: number;
  length: number;
  optionsFilter: any = [];
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<any>();
  dataSourceAll = new MatTableDataSource<any>();
  searchFilter: UntypedFormControl = new UntypedFormControl();
  account: any;
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  searching: boolean;

  subscription:Subject<Subscription>;

  constructor(
    private userService: UserService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  activateLayout() {
    this.displayedColumns = ['name', 'created_at', 'actions'];
  }


  addEdit(action, element: any = {}) {
    const dialogRef = this.dialog.open(GroupsDetailComponent, {
      panelClass: ['dialog-small'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
        action: action
      }

    });

    dialogRef.afterClosed().subscribe(res => {
      console.log('fechou modal lista razoes');
      console.log(res);
      if (res) {
        this.snackBar.open(res.message, 'OK', {
          duration: 3000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
        });

        this.subscription.next();
      }
    });
  }

  delete(event, data:Groups) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o item ${data.name}?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.userService.deleteGroups(data.id).subscribe((response) => {
          this.reload();
          this.snackBar.open(response.message || "Grupo removido com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        });
      }
    });
  };

  ngOnInit() {
    this.accountService.identity().then(identity => {
      if (identity) {
        this.account = identity;
      }
    });
    this.loadGroups(true);
  }

  loadGroups(loadData:boolean=false) {

    if(!this.subscription){
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(
        this.sort.sortChange,
        this.paginator.page,
        this.reloadEvent
      ).subscribe(()=>this.subscription.next());
    }

    this.subscription = this.searchFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: this.sort.active || 'name',
          sortedBy: this.sort.direction || 'asc',
          pageSize: this.paginator.pageSize || 10,
          page: this.paginator.pageIndex + 1,
          search: this.searchFilter.value || ''
        };
        return this.userService.userGroups(options);
      }),
      tap((response: Response | any) => {
        if (!response.data.length && this.paginator.hasPreviousPage()) {
          this.paginator.previousPage();
        }

        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
      }),
      map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;

        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        /*
          Quando ocorre algum erro no observable automaticamente ele é unsubscribed
          Portanto realizamos o subscribe novamente
        */
        this.loadGroups();
        return observableOf([]);
      })
    )  as Subject<Subscription>


    this.subscription.subscribe((data:Groups[]|[]|unknown) => {
      this.dataSource.data = data as [];
      this.dataSourceAll.data = data as [];
    });

    loadData && this.searchFilter.setValue('');
  }
}
