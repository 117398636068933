import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ShiftsDialogAction } from '../shift';
import { Observable, of } from 'rxjs';
import { ShiftService } from '../shared/shift.service';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-shift-record-dialog',
  templateUrl: './shift-record-dialog.component.html',
  styleUrls: ['./shift-record-dialog.component.scss']
})
export class ShiftRecordDialogComponent implements OnInit {

  saving = false;
  refreshOnClose = false;
  dialogAction = ShiftsDialogAction

  form = this.formBuilder.group({
    name:[ this.data?.data?.name, [Validators.required]]
  })

  constructor(
    private dialog: MatDialog,
    private shiftService:ShiftService,
    public dialogRef: MatDialogRef<any>,
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {}

  onSubmit(){
    const data = this.form.value;
    const save = ()=>{
      if(this.data.action === ShiftsDialogAction.ADD)
      return this.shiftService.createShift(data)

      if(this.data.action === ShiftsDialogAction.EDIT)
        return this.shiftService.updateShift({...data, id:this.data.data.id})

      return of(null)
    }

    save().subscribe((data:any)=>{
      if(data){
        this.dialog.open(DialogAlertComponent,{
          data:{
            title:"Turno",
            message:"Dados salvos com sucesso."
          }
        })
        .beforeClosed()
        .subscribe(()=>this.dialogRef.close(true))
      }
    })
  }
}
