import { AccountService } from './../../account/shared/account.service';
import { Component, OnInit } from '@angular/core';
import { Account } from './../../account/account';
@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {
  account: Account;
  constructor(
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.accountService.identity().then(identity => {
      this.account = identity;
      console.log(this.account);

    });
  }

}
