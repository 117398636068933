import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Shipment } from '../shipment';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  constructor(
    private http: HttpClient
  ) { }

  index(options?: any): Observable<Shipment> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/shipments`;

    return this.http.get<Shipment>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, id: string, params?: any): Observable<Shipment> {
    const url = `${environment.apiUrl}/shipments/${id}`;
    const options = {params: params};

    return this.http.put<Shipment>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  create(data: any, params?: any): Observable<Shipment> {
    const url = `${environment.apiUrl}/shipments`;
    const options = {params: params};

    return this.http.post<Shipment>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/shipments/${id}`;
    const options = {params: params};

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroyBox(data: any, params?: any) {
    const url = `${environment.apiUrl}/docks-remove`;
    const options = {params: params};

    return this.http.put(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  waveLoaded(id: string) {
    const url = `${environment.apiUrl}/shipments-loaded-waves/${id}`;

    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  mapa(data: any, params?: any): Observable<Shipment> {
    const url = `${environment.apiUrl}/shipment-map-live`;
    const options = {params: params};

    return this.http.post<Shipment>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  mapaPickingStored(data: any, params?: any): Observable<Shipment> {
    const url = `${environment.apiUrl}/shipment-map-stored`;
    const options = {params: params};

    return this.http.post<Shipment>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }
}
