import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
// import { EChartsOption } from 'echarts';
import * as moment from "moment";
import { NewDashService } from "../shared/new-dash.service";
import { CarrierService } from "../../carrier/shared/carrier.service";
import { SupportDataService } from "../../shared/support-data.service";
import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexGrid,
  ApexAnnotations,
} from "ng-apexcharts";

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  annotations: ApexAnnotations;
};

@Component({
  selector: "app-dashboard-list",
  templateUrl: "./dashboard-list.component.html",
  styleUrls: ["./dashboard-list.component.scss"],
})
export class DashboardListComponent implements OnInit {
  // @ViewChild('chart') chart: ChartComponent;
  public chartYardOptions: Partial<ChartOptions>;
  public chartCarrierOptions: Partial<ChartOptions>;
  public chartPickingOptions: Partial<ChartOptions>;
  public chartStatusOptions: Partial<ChartOptions>;
  qtd_process = 0;
  qtd_on_time = 0;
  qtd_not_on_time = 0;
  total_average_time = "00:00";
  parked_vehicles = 0;

  form = new UntypedFormGroup({
    year: new UntypedFormControl({ value: null, disabled: false }),
    month: new UntypedFormControl({ value: null, disabled: false }),
    week: new UntypedFormControl({ value: null, disabled: false }),
    day: new UntypedFormControl({ value: null, disabled: false }),
    carrier: new UntypedFormControl({ value: null, disabled: false }),
    status: new UntypedFormControl({ value: null, disabled: false }),
    dock: new UntypedFormControl({ value: null, disabled: false }),
  });
  formStep = new UntypedFormControl({ value: "pk", disabled: false });

  steps = [
    { name: "PICKING", value: "pk" },
    { name: "DUPLA CONFERÊNCIA", value: "dc" },
    { name: "ANALISAR", value: "ana" },
    { name: "AG. IMPRESSÃO NF", value: "anf" },
    { name: "AG. VEÍCULO", value: "ave" },
    { name: "CARREGAMENTO", value: "ld" },
  ];

  carriers: any;
  docks;
  any;

  // chartOption1: EChartsOption = {};
  // chartOption2: EChartsOption = {};
  // chartOption3: EChartsOption = {};
  // chartOption4: EChartsOption = {};
  constructor(
    private formBuilder: UntypedFormBuilder,
    private dashboardService: NewDashService,
    private carrierService: CarrierService,
    private supportDataService: SupportDataService
  ) {}

  ngOnInit() {
    this.form.patchValue({
      year: moment().year().toString(),
      month: moment().month().toString(),
      step: "cc",
    });
    this.getCarriers();
    this.getDocks();
    this.firstLoadCharts();
  }

  firstLoadCharts() {
    this.getQtdProcess();
    this.getQtdOnTime();
    this.getQtdNotOnTime();
    this.getTotalAverageTime();
    this.getParkedVehicles();
    this.getYardManagemet();
    this.getCarrierAvg();
    this.getPicking();
    this.getProcessStatus();
  }

  refreshCharts() {
    this.getQtdProcess();
    this.getQtdOnTime();
    this.getQtdNotOnTime();
    this.getTotalAverageTime();
    this.getParkedVehicles();
    this.getYardManagemet();
    this.getCarrierAvg();
    this.getPicking();
    this.getProcessStatus();
  }

  getQtdProcess() {
    const options = {
      ...this.form.value,
      ...{ "chart-name": "qtd-process" },
    };
    this.dashboardService.dinamicCharts(options).subscribe((r: any) => {
      this.qtd_process = r.data[0].count;
    });
  }

  getQtdOnTime() {
    const options = {
      ...this.form.value,
      ...{ "chart-name": "qtd-on-time" },
    };
    this.dashboardService.dinamicCharts(options).subscribe((r: any) => {
      this.qtd_on_time = r.data[0].count;
    });
  }

  getQtdNotOnTime() {
    const options = {
      ...this.form.value,
      ...{ "chart-name": "qtd-not-on-time" },
    };
    this.dashboardService.dinamicCharts(options).subscribe((r: any) => {
      this.qtd_not_on_time = r.data[0].count;
    });
  }

  getParkedVehicles() {
    const options = {
      ...this.form.value,
      ...{ "chart-name": "parked-vehicles" },
    };
    this.dashboardService.dinamicCharts(options).subscribe((r: any) => {
      this.parked_vehicles = r.data[0].count;
    });
  }

  getTotalAverageTime() {
    const options = {
      ...this.form.value,
      ...{ "chart-name": "total-average-time" },
    };
    this.dashboardService.dinamicCharts(options).subscribe((r: any) => {
      this.total_average_time = moment()
        .startOf("day")
        .add(r.data[0].value_minutes, "minutes")
        .format("HH:mm");
    });
  }

  getYardManagemet() {
    const options = {
      ...this.form.value,
      ...{ "chart-name": "yard-management" },
    };

    this.dashboardService.dinamicCharts(options).subscribe((r: any) => {
      this.chartYardOptions = {
        series: [
          {
            name: "Minutes",
            data: this.prepareSeries(r.data),
          },
        ],
        chart: {
          toolbar: {
            show: false,
          },
          height: 250,
          type: "bar",
        },
        colors: this.prepareColors(r.data),
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.prepareData(r.data),
        },
        annotations: {
          yaxis: [
            {
              y: 40,
              borderColor: "#c2c2c2",
            },
          ],
        },
      };
    });
  }

  getCarrierAvg() {
    const options = {
      ...this.form.value,
      ...{ "chart-name": "carrier-avg" },
    };

    this.dashboardService.dinamicCharts(options).subscribe((r: any) => {
      this.chartCarrierOptions = {
        series: [
          {
            name: "Minutes",
            data: this.prepareSeries(r.data),
          },
        ],
        chart: {
          toolbar: {
            show: false,
          },
          height: 250,
          type: "bar",
        },
        colors: this.prepareColors(r.data),
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.prepareData(r.data),
        },
        annotations: {
          yaxis: [
            {
              y: 40,
              borderColor: "#c2c2c2",
            },
          ],
        },
      };
    });
  }

  getPicking() {
    console.log(this.form.value);
    const options = {
      ...this.form.value,
      ...{ "chart-name": "picking" },
      step: this.formStep.value,
    };

    this.dashboardService.dinamicCharts(options).subscribe((r: any) => {
      this.chartPickingOptions = {
        series: [
          {
            name: "Minutes",
            data: this.prepareSeries(r.data),
          },
        ],
        chart: {
          toolbar: {
            show: false,
          },
          height: 250,
          type: "bar",
        },
        colors: this.prepareColors(r.data),
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.prepareData(r.data),
        },
        annotations: {
          yaxis: [
            {
              y: 40,
              borderColor: "#c2c2c2",
            },
          ],
        },
      };
    });
  }

  prepareData(data: any) {
    let resData = [];
    data.forEach((element) => {
      resData.push(element.x_axis);
    });

    return resData;
  }

  prepareSeries(series: any): any {
    let newSeries = [];
    series.forEach((element) => {
      newSeries.push(Math.round(element.value_minutes));
    });

    return newSeries;
  }

  prepareColors(data: any): any {
    let colors = [];
    data.forEach((element) => {
      if (Math.round(element.value_minutes) > 40) {
        colors.push("#ee6666");
      } else {
        colors.push("#91cc75");
      }
    });

    return colors;
  }

  cleanFilter(filter) {
    this.form.patchValue({ filter: null });
    this.refreshCharts();
  }

  getCarriers() {
    const options = {
      pageSize: 1000,
      orderBy: "name",
      sortedBy: "ASC",
    };
    this.carrierService.index(options).subscribe((r: any) => {
      this.carriers = r.data;
    });
  }

  getDocks() {
    this.supportDataService.dockSpaces().subscribe((r: any) => {
      this.docks = r.data.resource;
    });
  }

  prepareSeriesGroup(
    data: any,
    group: { key: string; label: string; type: string }[]
  ) {
    return group.map((g) => ({
      name: g.label,
      data: data.map((item) => item[g.key]),
      type: g.type,
    }));
  }
  getProcessStatus() {
    console.log(this.form.value);
    const options = {
      ...this.form.value,
      ...{ "chart-name": "process-status" },
    };

    this.dashboardService.dinamicCharts(options).subscribe((r: any) => {
      this.chartStatusOptions = {
        series: this.prepareSeriesGroup(r.data, [
          { key: "on_time", label: "On time", type: "column" },
          { key: "no_time", label: "Not on time", type: "column" },
          { key: "on_time_percent", label: "% On time", type: "line" },
        ]),
        chart: {
          height: 300,
          type: "line",
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#91cc75", "#ee6666", "#E69F49"],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.prepareData(r.data),
        },
        annotations: {
          yaxis: [
            {
              y: 40,
              borderColor: "#c2c2c2",
            },
          ],
        },
      };
      console.log(this.chartStatusOptions);
    });
  }
}
