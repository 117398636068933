import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { WaitService } from '../shared/wait.service';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { MaskUtil } from '../../shared/util/mask.util';
import { Dock } from '../../dock/dock';
import { Box } from '../../dock/box';
import { WaitDetailComponent } from '../wait-detail/wait-detail.component';
import { CarrierService } from '../../carrier/shared/carrier.service';
import { Carrier } from '../../carrier/carrier';
import { debounceTime, filter, tap, takeUntil, startWith, switchMap, map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-wait-manage',
  templateUrl: './wait-manage.component.html',
  styleUrls: ['./wait-manage.component.scss']
})
export class WaitManageComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    id: null,
    driver: [this.data.item.driver || '', [Validators.required]],
    manifest: [this.data.item.manifest || ''],
    client: [this.data.item.manifest || ''],
    charge_number: [this.data.item.manifest || ''],
    seal1: [this.data.item.seal1 || ''],
    seal2: [this.data.item.seal2 || ''],
    authorized_by: [this.data.item.authorized_by || '', [Validators.required]],
    arrival_at: [this.data.item.arrival_at || '', [Validators.required]],
    entry_at: [this.data.item.entry_at || ''],
    output_at: [this.data.item.output_at || ''],
    carrier_id: [this.data.item.carrier_id || '', [Validators.required]],
    // carrier_name: [this.data.item.carrier_name || ''],
    operation_id: [{ value: 1, disabled: this.data.item.id } || 1],
    board_horse: [this.data.item.board_horse || '', [Validators.required]],
    board_cart: [this.data.item.board_cart || ''],
    lobby_id: [this.data.item.lobby_id || 1],
    released_at: [this.data.item.released_at || ''],
    directed_at: [this.data.item.directed_at || '']
  });
  // addNewCarrier = false;
  formCarrier = this.formBuilder.group({
    name: [this.form.value.carrier_name, [Validators.required]],
    id_external: ['', [Validators.required]],
    is_casual: ['', [Validators.required]],
    // addNewCarrier: [false]
  });

  docks: Dock[] = [];
  boxes: Box[] = [];
  errors: any = {};
  maskUtil = MaskUtil;
  carriers: Carrier[] = [];
  carrierNotFound = false;
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  protected _onDestroy = new Subject<void>();


  constructor(
    public dialogRef: MatDialogRef<WaitDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private waitService: WaitService,
    private carrierService: CarrierService,
  ) {
  }

  ngOnInit() {
    this.getCarriers();

    this.carrierFilter.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        startWith({}),
        debounceTime(500),
        switchMap(() => {
          const options = {};

          if (this.carrierFilter.value) {
            Object.assign(options, {
              search: this.carrierFilter.value,
            });
          }

          return this.carrierService.index(options);
        }),
        map((response: any) => response.data)
      ).subscribe(data => {
        this.carriers = data;
      });
    if (this.data.item.id) {
      this.form.patchValue(this.data.item);
    }
  }

  onSubmit() {
    const form = this.form.getRawValue();
    const id = this.data.item.id;

    if (!id) {
      return this.waitService.create(form)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: { title: err.statusText, message: err.error.message }
              });
            }
          }
        );
    } else {
      const form = this.form.value;
      const obj = Object.assign(form, { id: id });
      // Remove valores nulos do objeto
      Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key]);
      return this.waitService.update(obj, id)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: { title: err.statusText, message: err.error.message }
              });
            }
          }
        );
    }

  }

  getCarriers(search?: string) {
    const options = (search) ? { search: search } : undefined;
    this.carrierService.index(options)
      .subscribe((response: Response | any) => {
        this.carriers = response.data;
      });
  }

  addCarrier() {
    this.carrierService.create(this.formCarrier.value)
      .subscribe((response: Response | any) => {
        this.getCarriers(this.formCarrier.value.name);
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
