import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { PendencyObsComponent } from '../pendency-obs/pendency-obs.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PendencyService } from '../../shipment/shared/pendency.service';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-pendency-list',
  templateUrl: './pendency-list.component.html',
  styleUrls: ['./pendency-list.component.scss']
})
export class PendencyListComponent implements OnInit {
  displayedColumns: string[] = ['conference', 'missing', 'item', 'outputArea', 'justification', 'actions'];
  dataSource: any;

  @Output() reloadEvent = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<PendencyListComponent>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pendencyService: PendencyService,
  ) { }


  getPendencies() {
    console.log('---pendencias');
    console.log(this.data);
    const options = {
      wave: this.data.dock.wave
    };

    // if (this.data.dock.dock_id) {
    this.pendencyService.shipmentPendencies(options).subscribe((pendencies: any) => {
      this.dataSource = pendencies.data;
    });
    // }
  }

  manage(event, action: string, item: any = {}) {
    const dialogRef = this.dialog.open(PendencyObsComponent, {
      panelClass: ['dialog-small', 'dialog-fullscreen'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.snackBar.open('Observação atualizada com sucesso!', 'OK', {
          duration: 3000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
        });
        this.getPendencies();
      }
    });
  }


  generatePDF() {
    let doc = new jsPDF('l', 'pt', 'a4');
    let dataBody = [];
    this.dataSource.forEach(element => {
      dataBody.push([
        element.volume ? element.volume : 0 + ' volumes de ' + element.volumes,
        element.volumes - element.volume,
        element.item_code,
        element.path,
        ''
      ])
    });
    doc.text("Pendências do box virtual " + this.data.dock.box + ", onda " + this.data.dock.wave, 40, 40, null, 0);
    doc.autoTable({
      startY: 60,
      head: [
        ['Conferido', 'Falta', 'Item', 'Área de Saída', 'Obs'],
      ],
      body: dataBody,
    });
    doc.save('pendencias.pdf');
  }

  ngOnInit() {
    console.log('-----');
    console.log(this.data);
    this.getPendencies();
  }
}
