import { filter } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { StepsListDialogComponent } from '../steps-list-dialog/steps-list-dialog.component';

@Component({
  selector: 'app-step-change-map-dialog',
  templateUrl: './step-change-map-dialog.component.html',
  styleUrls: ['./step-change-map-dialog.component.scss']
})
export class StepChangeMapDialogComponent implements OnInit {
  form = this.formBuilder.group({
    from: [this.data.item.id || null],
    to: [this.data.item.steps_id ],
  });
  stepsFrom: any;
  allSteps: any;
  storedSteps: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supportDataService: SupportDataService,
    public dialogRef: MatDialogRef<StepsListDialogComponent>,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.allSteps = this.data.allSteps;
    this.storedSteps = this.data.storedSteps;
    if(this.data.action === 'EDIT') {
      this.stepsFrom = JSON.parse(JSON.stringify(this.allSteps));
      this.form.get('from').disable();
    } else {
      this.removeStoredSteps();
    }

  }

  submit() {
    const option = {
      list_name: this.data.list_name,
      key: this.form.value.from? this.form.value.from : this.data.item.id,
      value: this.form.value.to
    }
    this.supportDataService.updateObject(option).subscribe((r: any) => {
      if(r.status == 'success') {
        this.dialogRef.close(r.data);
      }
    });
  }

  removeStoredSteps() {
    const stored = JSON.parse(JSON.stringify(this.storedSteps));
    const all = JSON.parse(JSON.stringify(this.allSteps));
    const list: any = []
    stored.forEach((item: any) => {
      list.push(item.id);
    });
    this.stepsFrom = all.filter((i:any) => !(list.includes(i.id)));
  }

}
