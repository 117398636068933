import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DockService } from '../shared/dock.service';

@Component({
  selector: 'app-box-fisico',
  templateUrl: './box-fisico.component.html',
  styleUrls: ['./box-fisico.component.scss']
})
export class BoxFisicoComponent implements OnInit {
  form = this.formBuilder.group({
    name: [this.data.item.name, [Validators.required]]
  });
  constructor(
    public dialogRef: MatDialogRef<BoxFisicoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private dockService: DockService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    const data = {
      id: this.data.item.id,
      name: this.form.value.name
    }
    this.dockService.updateBoxFisico(data).subscribe((r: any) => {
      this.dialogRef.close(data);
    }, (e: any) => {
      console.log('--erro--');
      console.log(e);
    });

  }
}
