import { env } from '../../.env';

export const environment = {
  production: env.production,
  appTitle: env.appTitle,
  version: require('../../package.json').version,
  apiUrl: env.apiUrl,
  wsUrl: env.wsUrl,
  whitelistedDomains: env.whitelistedDomains,
  oauth: {
    grantType: env.oauth.grantType,
    clientId: env.oauth.clientId,
    clientSecret: env.oauth.clientSecret,
    scope: env.oauth.scope
  }
};
