import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ShiftsDialogAction } from '../../shift';
import { ShiftRecordDialogComponent } from '../../shift-record-dialog/shift-record-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ShiftService } from '../../shared/shift.service';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, Subject, merge, of } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-shifts-records-tab',
  templateUrl: './shifts-records-tab.component.html',
  styleUrls: ['./shifts-records-tab.component.scss']
})
export class ShiftsRecordsTabComponent implements OnInit {
  @Input() activatedTab:boolean = false;

  @ViewChild('sort', {static:true}) sort: MatSort;
  @ViewChild('paginator', {static:true}) paginator: MatPaginator;

  pageSize = 1;
  actionEnum = ShiftsDialogAction;
  shiftSubject$ = new Subject<any>();

  columns=[
    ["Nome","name", true],
    ["Criado em", "created_at", false]
  ];

  shift = {
    columns:this.columns.map(item=>({
      key:item[1],
      name:item[0],
      sort:item[2]
    })),
    displayedColumns:[...this.columns.map(item=>item[1]),'actions'],
    dataSource:[]
  }

  constructor(
    private dialog: MatDialog,
    private shiftService:ShiftService
  ) { }

  ngOnInit(): void {
    this.initTable()
    .subscribe((data)=>{
      this.shift.dataSource = data;
    })
  }

  open(action: ShiftsDialogAction, element?:any){
    return this.dialog.open(ShiftRecordDialogComponent, {
      data:{
        action,
        data: element
      },
      maxWidth:540,
      minWidth:320,
      panelClass: "dialog-100-per"
    })
    .beforeClosed()
    .subscribe((reload:boolean)=>{
      reload && this.shiftSubject$.next();
    })
  }

  private destroy(element:any){
    const message = `Deseja excluir o item "${element.name}"?`;
    const title = "Exclusão de turno.";

    this.dialog.open(DialogConfirmComponent, {
      data:{
        title,
        message
      }
    })
    .beforeClosed()
    .subscribe((destroy:boolean)=>{
      destroy && this.shiftService
      .destroyShift(element.id)
      .subscribe(()=>this.shiftSubject$.next())
    })

  }

  private initTable():Observable<any>{
    this.sort.sort(<MatSortable>{
        id: 'name',
        start: 'asc'
      }
    );

    this.sort.sortChange.subscribe(() =>this.paginator.pageIndex = 0);

    return  merge(...[this.sort.sortChange, this.paginator.page, this.shiftSubject$].filter(i=>i))
      .pipe(
        startWith(''),
        switchMap(() => {
          this.shift.dataSource = [];

          const options:any = {
            page: this.paginator.pageIndex + 1,
            orderBy: this.sort.active,
            sortedBy: this.sort.direction
          };

          return this.shiftService.indexShift(options);
        }),

        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return of([]);
        })
      );
  }
}
