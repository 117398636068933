<form [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <div fxFlex="25%">
      <mat-form-field appearance="outline">
        <mat-label>Year</mat-label>
        <mat-select formControlName="year" (selectionChange)="refreshCharts()">
          <mat-option (click)="cleanFilter('year')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option value="2020">2020</mat-option>
          <mat-option value="2021">2021</mat-option>
          <mat-option value="2022">2022</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="25%">
      <mat-form-field appearance="outline">
        <mat-label>Mês</mat-label>
        <mat-select
          formControlName="month"
          (selectionChange)="refreshCharts()"
          [disabled]="!form.value.year"
        >
          <mat-option (click)="cleanFilter('month')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option value="0">Todos os meses</mat-option>
          <mat-option value="1">Janeiro</mat-option>
          <mat-option value="2">Fevereiro</mat-option>
          <mat-option value="3">Março</mat-option>
          <mat-option value="4">Abril</mat-option>
          <mat-option value="5">Maio</mat-option>
          <mat-option value="6">Junho</mat-option>
          <mat-option value="7">Julho</mat-option>
          <mat-option value="8">Agosto</mat-option>
          <mat-option value="9">Setembro</mat-option>
          <mat-option value="10">Outubro</mat-option>
          <mat-option value="11">Novembro</mat-option>
          <mat-option value="12">Dezembro</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="25%">
      <mat-form-field appearance="outline">
        <mat-label>Semana</mat-label>
        <mat-select
          formControlName="week"
          (selectionChange)="refreshCharts()"
          [disabled]="!form.value.year || !form.value.month"
        >
          <mat-option (click)="cleanFilter('week')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option value="0">Todas as semanas</mat-option>
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
          <mat-option value="5">5</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="25%">
      <mat-form-field appearance="outline">
        <mat-label>Transportadora</mat-label>
        <mat-select
          formControlName="carrier"
          (selectionChange)="refreshCharts()"
          [disabled]="!form.value.year || !form.value.month"
        >
          <mat-option (click)="cleanFilter('carrier')"
            >Limpar filtro</mat-option
          >
          <mat-divider></mat-divider>
          <mat-option *ngFor="let carrier of carriers" [value]="carrier.id">
            {{ carrier.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="25%">
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select
          formControlName="status"
          (selectionChange)="refreshCharts()"
          [disabled]="!form.value.year || !form.value.month"
        >
          <mat-option (click)="cleanFilter('status')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option value="1">Dentro do Tempo Alvo</mat-option>
          <mat-option value="2">Fora do Tempo Alvo</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="25%">
      <mat-form-field appearance="outline">
        <mat-label>Doca</mat-label>
        <mat-select
          formControlName="dock"
          (selectionChange)="refreshCharts()"
          [disabled]="!form.value.year || !form.value.month"
        >
          <mat-option (click)="cleanFilter('dock')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let dock of docks" [value]="dock.id">
            {{ dock.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>

<!---kpissssss-->

<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
  <mat-card class="tkg-kpi-card" fxFlex="18%">
    <mat-card-header>
      <mat-card-title class="tkg-card-title">Direcionamentos</mat-card-title>
      <mat-card-title class="tkg-card-subtitle">Veículos direcionados</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="tkg-kpi-value">
        {{ qtd_process }}
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="tkg-kpi-card" fxFlex="18%">
    <mat-card-header>
      <mat-card-title class="tkg-card-title">Direcionamentos no Tempo Alvo (<40min)</mat-card-title>
      <mat-card-title class="tkg-card-subtitle">Veículos direcionados em até 40min de espera</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="tkg-kpi-value">
        {{ qtd_on_time }}
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="tkg-kpi-card" fxFlex="18%">
    <mat-card-header>
      <mat-card-title class="tkg-card-title">Direcionamentos fora do Tempo Alvo (>40min)</mat-card-title>
      <mat-card-title class="tkg-card-subtitle">Veículos direcionados após 40min de espera</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="tkg-kpi-value">
        {{ qtd_not_on_time }}
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="tkg-kpi-card" fxFlex="18%">
    <mat-card-header>
      <mat-card-title class="tkg-card-title">Tempo Médio de Direcionamento</mat-card-title>
      <mat-card-title class="tkg-card-subtitle">Tempo médio de espera para o direcionamento de veículos</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="tkg-kpi-value">
        {{ total_average_time }}
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="tkg-kpi-card" fxFlex="18%">
    <mat-card-header>
      <mat-card-title class="tkg-card-title">Entrada de Veículos</mat-card-title>
      <mat-card-title class="tkg-card-subtitle">Total de veículos com entrada registrada</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="tkg-kpi-value">
        {{ parked_vehicles }}
      </div>
    </mat-card-content>
  </mat-card>
</div>
<br />
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
  <mat-card class="card-charts" fxFlex="50%">
    <mat-card-header>
      <mat-card-title class="tkg-card-title">Tempo Médio de Direcionamento por Dia (Minutos)</mat-card-title>
    </mat-card-header>
    <mat-card-content class="charts">
      <apx-chart
        [series]="chartYardOptions?.series"
        [chart]="chartYardOptions?.chart"
        [dataLabels]="chartYardOptions?.dataLabels"
        [plotOptions]="chartYardOptions?.plotOptions"
        [yaxis]="chartYardOptions?.yaxis"
        [xaxis]="chartYardOptions?.xaxis"
        [legend]="chartYardOptions?.legend"
        [colors]="chartYardOptions?.colors"
        [grid]="chartYardOptions?.grid"
        [annotations]="chartYardOptions?.annotations"
      ></apx-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="card-charts" fxFlex="50%">
    <mat-card-header>
      <mat-card-title class="tkg-card-title">Tempo Médio de Direcionamento de Trasportadora por Dia (Minutos)</mat-card-title>
    </mat-card-header>
    <mat-card-content class="charts">
      <apx-chart
        [series]="chartCarrierOptions?.series"
        [chart]="chartCarrierOptions?.chart"
        [dataLabels]="chartCarrierOptions?.dataLabels"
        [plotOptions]="chartCarrierOptions?.plotOptions"
        [yaxis]="chartCarrierOptions?.yaxis"
        [xaxis]="chartCarrierOptions?.xaxis"
        [legend]="chartCarrierOptions?.legend"
        [colors]="chartCarrierOptions?.colors"
        [grid]="chartCarrierOptions?.grid"
        [annotations]="chartCarrierOptions?.annotations"
      ></apx-chart>
    </mat-card-content>
  </mat-card>
</div>
<br />
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
  <mat-card class="card-charts" fxFlex="50%">
    <mat-card-header>
      <mat-card-title class="tkg-card-chart-title"
        >Average Picking Time (Minutes)</mat-card-title
      >
      <mat-form-field appearance="outline">
        <mat-label>Step</mat-label>
        <mat-select [formControl]="formStep" (selectionChange)="getPicking()">
          <mat-option [value]="step.value" *ngFor="let step of steps">{{step.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-header>
    <!-- <mat-card-content echarts [options]="chartOption3" class="charts">
    </mat-card-content> -->
    <mat-card-content class="charts">
      <apx-chart
        [series]="chartPickingOptions?.series"
        [chart]="chartPickingOptions?.chart"
        [dataLabels]="chartPickingOptions?.dataLabels"
        [plotOptions]="chartPickingOptions?.plotOptions"
        [yaxis]="chartPickingOptions?.yaxis"
        [xaxis]="chartPickingOptions?.xaxis"
        [legend]="chartPickingOptions?.legend"
        [colors]="chartPickingOptions?.colors"
        [grid]="chartPickingOptions?.grid"
        [annotations]="chartPickingOptions?.annotations"
      ></apx-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="card-charts card-4" fxFlex="50%">
    <mat-card-header>
      <mat-card-title class="tkg-card-chart-title"
        >Quantidade de direcionamentos por Status/Dia</mat-card-title
      >
    </mat-card-header>
    <!-- <mat-card-content echarts [options]="chartOption3" class="charts">
    </mat-card-content> -->
    <mat-card-content class="charts">
      <apx-chart
        [series]="chartStatusOptions?.series"
        [chart]="chartStatusOptions?.chart"
        [dataLabels]="chartStatusOptions?.dataLabels"
        [plotOptions]="chartStatusOptions?.plotOptions"
        [yaxis]="chartStatusOptions?.yaxis"
        [xaxis]="chartStatusOptions?.xaxis"
        [legend]="chartStatusOptions?.legend"
        [colors]="chartStatusOptions?.colors"
        [grid]="chartStatusOptions?.grid"
        [annotations]="chartStatusOptions?.annotations"
      ></apx-chart>
    </mat-card-content>
  </mat-card>
</div>


