import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffListComponent } from './staff-list/staff-list.component';
import { StaffDialogComponent } from './staff-dialog/staff-dialog.component';
import { StaffService } from './shared/staff.service';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { StaffRoutingModule } from './staff-routing.module';
// import { SsStaffComponent } from './shared/ss-staff/ss-staff.component';



@NgModule({
  declarations: [
    StaffListComponent,
    StaffDialogComponent,
    // SsStaffComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    StaffRoutingModule,
  ],
  providers: [
      StaffService
  ],
  // exports: [SsStaffComponent]
})
export class StaffModule { }
