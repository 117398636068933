<!--
  Para utilizar o conceito de Gestão de Grupos do Maestro alguns metodos do pacote ngx-permissions serão
  comentados.
  *ngxPermissionsOnly comentado para data-ngxPermissionsOnly
-->
<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" [hidden]="showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id_external">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Código</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{element.id_external}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Nome</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="ellipsis" fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="180px" class="aumentar">{{element.name}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Criado em</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{element.created_at | date}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button matTooltip="Abrir" (click)="onOpen($event, 'EDIT', element)" data-ngxPermissionsOnly="['superuser', 'roles.show', 'roles.update']">
          <mat-icon>folder_open</mat-icon>
        </button>

        <button mat-icon-button matTooltip="Deletar" (click)="delete($event, element)" data-ngxPermissionsOnly="['superuser', 'roles.destroy']">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
</div>

<button mat-mini-fab (click)="onOpen($event, 'ADD')" class="mat-fab-bottom-right" color="primary" data-ngxPermissionsOnly="['superuser', 'roles.store']">
  <mat-icon>add</mat-icon>
</button>
