import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationRoutingModule } from '../configuration/configuration-routing.module';
import { ConfigurationListComponent } from '../configuration/configuration-list/configuration-list.component';
import { ConfigurationService } from '../configuration/shared/configuration.service';
import { DocksSettingsComponent } from '../configuration/docks-settings/docks-settings.component';
import { MobileErrorComponent } from './mobile-error/mobile-error.component';
import { StartPageComponent } from './start-page/start-page.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        ConfigurationRoutingModule,
    ],
    declarations: [
        ConfigurationListComponent,
        DocksSettingsComponent,
        MobileErrorComponent,
        StartPageComponent
    ],
    providers: [
        ConfigurationService,
    ]
})
export class ConfigurationModule { }
