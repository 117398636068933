import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { CoreModule } from '../core/core.module';
import { AnaliseDetailsDialogComponent } from './analise-details-dialog/analise-details-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        DashboardRoutingModule,
    ],
    declarations: [DashboardListComponent, AnaliseDetailsDialogComponent]
})
export class DashboardModule { }
