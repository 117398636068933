import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import * as moment from 'moment';
import { Subject, merge, of } from 'rxjs';
import { DowntimeInterface, ShiftsDialogAction } from '../../shift';
import { MatDialog } from '@angular/material/dialog';
import { ShiftService } from '../../shared/shift.service';
import { debounceTime, delay, map, startWith, switchMap, tap } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DowntimeDialogComponent } from '../../downtime-dialog/downtime-dialog.component';

@Component({
  selector: 'app-shifts-downtime-tab',
  templateUrl: './shifts-downtime-tab.component.html',
  styleUrls: ['./shifts-downtime-tab.component.scss']
})
export class ShiftsDowntimeTabComponent{
  @Input() activatedTab:boolean = false;

  @ViewChild('sortDowntime') sortDowntime: MatSort;
  @ViewChild('paginatorDowntime') paginatorDowntime: MatPaginator;
  @ViewChild('tabLabel', {static: true}) tabLabel:ElementRef<any>;

  @ViewChild(MatCalendar) downtimeCalendar: MatCalendar<Date>;
  @ViewChild('calendar', { read: ElementRef }) calendar: ElementRef;

  pageSize = 10;
  searching = false;
  momentRef = moment;
  trashedDowntime = false;
  actionEnum = ShiftsDialogAction;

  downtimeSubject$ = new Subject<any>();
  downtimes:DowntimeInterface["data"] = [];
  downtimeColumns = ['start', 'start_hour', 'end', 'end_hour', 'duration'];

  constructor(
    private dialog: MatDialog,
    private renderer:Renderer2,
    private cd: ChangeDetectorRef,
    private shiftService:ShiftService,
    public breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  ngAfterViewInit(): void {

    this.sortDowntime.sort(<MatSortable>{
        id: 'created_at',
        start: 'asc'
      }
    );

    this.sortDowntime.sortChange.subscribe(() =>this.paginatorDowntime.pageIndex = 0);

    merge(this.sortDowntime.sortChange, this.paginatorDowntime.page, this.downtimeSubject$)
      .pipe(
        debounceTime(500),
        startWith(''),
        tap(()=>{
          this.searching = true;
        }),
        switchMap(() => {
          this.downtimes = [];
          this.handleMonthSelected();

          const options:any = {
            trashed: this.trashedDowntime,
            name: "downtime",
          }

          if(options.trashed){
            options.page = this.paginatorDowntime.pageIndex + 1;
            options.orderBy = this.sortDowntime.active || 'created_at';
            options.sortedBy = this.sortDowntime.direction || 'desc';
          }else{
            options.month = this.trashedDowntime ? "" : moment(this.downtimeCalendar.activeDate).format("YYYY-MM-DD")
          }

          return this.shiftService.index(options);
        }),
        tap((response: Response | any) => {
          if(this.trashedDowntime){
            if (!response.data.length && this.paginatorDowntime.hasPreviousPage()) {
              this.paginatorDowntime.previousPage();
            }

            this.paginatorDowntime.length = response.total;
            this.paginatorDowntime.pageSize = response.per_page;
          }
        }),
        map((response)=>response.data||[])
      )
      .subscribe({
        next:(data:any[])=>{
          this.searching = false;
          this.downtimes = data.sort((a:any, b:any)=> moment(a.resource.start).diff(moment(b.resource.start)));
          this.handleMonthSelected();
        },
        error:()=>this.searching = false
      })

    const BACK_BUTTON = this.calendar.nativeElement.querySelector('.mat-calendar-previous-button');
    const FORWARD_BUTTON = this.calendar.nativeElement.querySelector('.mat-calendar-next-button');

    if (BACK_BUTTON) {
      this.renderer.listen(BACK_BUTTON, 'click', (e)=>{this.downtimeSubject$.next()});

    }
    if (FORWARD_BUTTON) {
      this.renderer.listen(FORWARD_BUTTON, 'click', (e)=>{this.downtimeSubject$.next()});
    }

  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.downtimeColumns = ['start', 'start_hour', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.downtimeColumns = ['start', 'end', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.downtimeColumns = ['start', 'start_hour', 'end', 'end_hour', 'duration'];
    }
  }

  open(action: ShiftsDialogAction, element?:any){

      return this.dialog.open(DowntimeDialogComponent, {
        data:{
          action,
          data:element,
          type: "downtime"
        },
        maxWidth:540,
        minWidth:320,
        panelClass:"dialog-100-per"
      })
      .beforeClosed()
      .subscribe((reload:boolean)=>{
        reload && this.downtimeSubject$.next()
      });
  }

  handleMonthSelected(){
    if(!this.trashedDowntime){
      of(null)
      .pipe(
        debounceTime(500),
        map(()=>this.downtimeCalendar.updateTodaysDate()),
        delay(500),
        switchMap(()=>{
          return this.downtimes;
        })
      )
      .subscribe(({resource})=>{
        const start = moment(this.utilDay(resource.start, "start"), "YYYY-MM-DD");
        const end = moment(this.utilDay(resource.end, "end"), "YYYY-MM-DD");
        const calendar = moment(this.downtimeCalendar.activeDate, "YYYY-MM-DD");
        this.calendar.nativeElement.querySelectorAll("tbody > tr > td > button")
        .forEach((element:HTMLElement)=>{
          const date = [
            calendar.year(),
            `0${calendar.month() + 1}`.substr(-2),
            `0${element.textContent.replace(/[^\d]/g,'')}`.substr(-2)
          ]
          .join("-");

          const currentDate = moment(date);
          const isBetween = currentDate.isBetween(start, end,'days', '[]');
          if(isBetween){
            const circle = element.firstElementChild as HTMLElement;
            circle.style.background = "red";
            circle.style.color = "white";
            circle.style.zIndex = "1";
            if(currentDate.isSame(start)){
              !currentDate.isSame(end) && circle.closest("td")
              .classList.add("range-start");

            } else if(currentDate.isSame(end)){
              circle.closest("td").classList.add("range-end");
            }else{
              circle.closest("td").classList.add("range-middle");
            }
          }
        });
      })
    }
  }

  onTrashedDowntime(){
    this.trashedDowntime = !this.trashedDowntime;
    this.downtimeSubject$.next();
  }

  private utilDay(dateString:string, base: "start"|"end"):string{
    var minutesOfDay = (m:moment.Moment)=>m.minutes() + m.hours() * 60;

    let date = moment(dateString, "YYYY-MM-DDTHH:mm:ss");
    if(![6,7].includes(date.isoWeekday())){
      const time = minutesOfDay(date);
      const baseTime = minutesOfDay(moment("12:00:00","HH:mm:ss"));

      if(base == "start" && time >= baseTime ){
        date = date.add(1, "day");
      }else if(base == "end" && time <= baseTime){
        date = date.subtract(1, "day");
      }

    }
    return date.format('YYYY-MM-DDTHH:mm:ss');
  }
}
