import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { DashboardService } from '../shared/dashboard.service';
import { Media } from '../media.interface';
import { Notificacao } from '../notificacao.interface';
import * as moment from 'moment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UntypedFormControl } from '@angular/forms';
// import { FirebaseService } from '../../shared/firebase.service';
import { Boxes } from '../boxes.interface';
import { Pendency } from 'src/app/shipment/pendency';
import { MatDialog } from '@angular/material/dialog';
import { AnaliseDetailsDialogComponent } from '../analise-details-dialog/analise-details-dialog.component';

@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit, OnDestroy {
  tempo_direcionar_veiculos_hoje: string;
  tempo_direcionar_veiculos_ontem: number;
  tempo_liberar_motoristas_hoje: string;
  tempo_liberar_motoristas_ontem: number;
  numero_de_pendencias_hoje: number;
  numero_de_pendencias_ontem: number;
  tempo_permanencia_apos_manifesto_hoje: string;
  tempo_permanencia_apos_manifesto_ontem: number;
  t_medio_picking_hoje: string;
  t_medio_picking_ontem: number;
  t_medio_dupla_hoje: string;
  t_medio_dupla_ontem: number;
  t_medio_carregamento_hoje: string;
  t_medio_carregamento_ontem: number;
  quant_alerta_parada: number;
  quant_saida_efetiva: number;
  quant_veiculo_bolsao_hoje: number;
  quant_veiculo_bolsao_ontem: number;
  quant_box_finalizado: any;
  // se o valor for um aumento setar UP
  setaup = 'call_made';
  // se o valor for uma queda setar DOWN
  setadown = 'call_received';
  breakpoints = Breakpoints;
  screenRatio: string;
  showCompareLastDay = true;

  dayStartDate = new UntypedFormControl(new Date());
  periodStartDate = new UntypedFormControl();
  periodEndDate = new UntypedFormControl();
  // @ViewChild('selector') selector: ElementRef;
  // To be destroyed on page exit
  dc: any;
  direct: any;
  release: any;
  pendency: any;
  manifest: any;
  loading: any;
  notify: any;
  pk: any;
  park_wait: any;
  bx: any;

  constructor(
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public dashboard: DashboardService,
    // private firebaseService: FirebaseService,
    public breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });

  }

  ngOnInit() {
    // this.listening4Updates();


  }

  loadCards() {
    this.direcionamento();
    this.liberacao();
    this.pendencia();
    this.manifesto();
    // this.notificacao();
    this.picking();
    this.duplaConferencia();
    this.carregamento();
    this.bolsao();
    this.boxes();
  }

  dayFilter() {
    const day = {
      period: false,
      date: this.datepipe.transform(this.dayStartDate.value, 'yyyy-MM-dd')
    }
    this.direcionamento(day);
    this.liberacao(day);
    this.pendencia(day);
    this.manifesto(day);
    // this.notificacao(day);
    this.picking(day);
    this.duplaConferencia(day);
    this.carregamento(day);
    this.bolsao(day);
    this.boxes(day);
  }


  periodFilter() {
    const periodDates = {
      period: true,
      startDate: this.datepipe.transform(this.periodStartDate.value, 'yyyy-MM-dd'),
      endDate: this.datepipe.transform(this.periodEndDate.value, 'yyyy-MM-dd')
    }
    if (!this.periodStartDate.value && !this.periodEndDate.value) {
      this.snackBar.open('Escolha a data inicial e final para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else if (!this.periodStartDate.value) {
      this.snackBar.open('Escolha a data inicial para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else if (!this.periodEndDate.value) {
      this.snackBar.open('Escolha a data final para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else {
      this.direcionamento(periodDates);
      this.liberacao(periodDates);
      this.pendencia(periodDates);
      this.manifesto(periodDates);
      // this.notificacao(periodDates);
      this.picking(periodDates);
      this.duplaConferencia(periodDates);
      this.carregamento(periodDates);
      this.bolsao(periodDates);
    }
  }

  changeFilter(e: any) {
    const obj = {
      'period': false,
      'day': true
    }
    this.showCompareLastDay = obj[e.value];
  }


  direcionamento(date?) {
    this.direct = this.dashboard.direcionamento(date).subscribe((media: Media) => {
      this.tempo_direcionar_veiculos_hoje = this.segundosParaHoraMinuto(parseInt(media.hoje));
      const ontem = (parseInt(media.hoje) * 100 / parseInt(media.ontem)) - 100;
      this.tempo_direcionar_veiculos_ontem = ontem ? ontem : 0;
      return this.direct;
    });
  }

  liberacao(date?) {
    this.release = this.dashboard.liberacao(date).subscribe((media: Media) => {
      this.tempo_liberar_motoristas_hoje = this.segundosParaHoraMinuto(parseInt(media.hoje));
      const ontem = (parseInt(media.hoje) * 100 / parseInt(media.ontem)) - 100;
      this.tempo_liberar_motoristas_ontem = ontem ? ontem : 0;
      return this.release;
    });
  }

  pendencia(date?) {
    this.pendency = this.dashboard.pendencias(date).subscribe((media: Media) => {
      this.numero_de_pendencias_hoje = parseInt(media.hoje);
      const ontem = (parseInt(media.hoje) * 100 / parseInt(media.ontem)) - 100;
      this.numero_de_pendencias_ontem = ontem ? ontem : 0;
      return Pendency;
    });
  }

  manifesto(date?) {
    this.manifest = this.dashboard.manifestoSaida(date).subscribe((media: Media) => {
      this.tempo_permanencia_apos_manifesto_hoje = this.segundosParaHoraMinuto(parseInt(media.hoje));
      const ontem = (parseInt(media.hoje) * 100 / parseInt(media.ontem)) - 100;
      this.tempo_permanencia_apos_manifesto_ontem = ontem ? ontem : 0;

      return this.manifest;
    });
  }

  notificacao(date?) {
    this.notify = this.dashboard.notificacoes(date).subscribe((notificacao: Notificacao) => {
      this.quant_alerta_parada = notificacao.waiting;
      this.quant_saida_efetiva = notificacao.directed;

      return this.notify;
    });
  }

  picking(date?) {
    this.pk = this.dashboard.picking(date).subscribe((media: Media) => {
      this.t_medio_picking_hoje = this.segundosParaHoraMinuto(parseInt(media.hoje));
      const ontem = (parseInt(media.hoje) * 100 / parseInt(media.ontem)) - 100;
      this.t_medio_picking_ontem = ontem ? ontem : 0;

      return this.pk;
    });
  }

  duplaConferencia(date?) {
    this.dc = this.dashboard.duplaConferencia(date).subscribe((media: Media) => {
      this.t_medio_dupla_hoje = this.segundosParaHoraMinuto(parseInt(media.hoje));
      const ontem = (parseInt(media.hoje) * 100 / parseInt(media.ontem)) - 100;
      this.t_medio_dupla_ontem = ontem ? ontem : 0;

      return this.dc;
    });
  }

  carregamento(date?) {
    this.loading = this.dashboard.carregamento(date).subscribe((media: Media) => {
      this.t_medio_carregamento_hoje = this.segundosParaHoraMinuto(parseInt(media.hoje));
      const ontem = (parseInt(media.hoje) * 100 / parseInt(media.ontem)) - 100;
      this.t_medio_carregamento_ontem = ontem ? ontem : 0;

      return this.loading;
    });
  }

  bolsao(date?) {
    this.park_wait = this.dashboard.bolsao(date).subscribe((media: Media) => {
      this.quant_veiculo_bolsao_hoje = parseInt(media.hoje);
      const ontem = (parseInt(media.hoje) * 100 / parseInt(media.ontem)) - 100;
      this.quant_veiculo_bolsao_ontem = ontem ? ontem : 0;

      return this.park_wait;
    });
  }

  boxes(date?) {
    this.bx = this.dashboard.boxesFinalizados(date).subscribe((r: Boxes) => {
      this.quant_box_finalizado = r;
    });

    return this.bx;
  }

  segundosParaHoraMinuto(segundos: number) {
    if (segundos) {
      return moment.utc(segundos * 1000).format('HH:mm');
    } else {
      return null;
    }
  }

  modalAnalysisDetails(date: any, total: number) {
    const dialogRef = this.dialog.open(AnaliseDetailsDialogComponent, {
      panelClass: ['dialog-small'],
      disableClose: true,
      data: {
        date: date,
        total: total
      }
    });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
      this.screenRatio = '4:3';
    } else if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.screenRatio = '7:6';
    } else {
      this.screenRatio = '1:1';
    }
  }

  // listening4Updates() {
  //   const user = JSON.parse(localStorage.getItem('account'));
  //   this.firebaseService.getImportsUpdates().subscribe((r: any) => {
  //     console.log(r)
  //     if (r[0].user_id != user.id) {
  //       this.loadCards();
  //     }
  //   });
  // }

  ngOnDestroy() {
    if (this.dc) {
      this.dc.unsubscribe();
    }
    if (this.direct) {
      this.direct.unsubscribe();
    }
    if (this.release) {
      this.release.unsubscribe();
    }
    if (this.pendency) {
      this.pendency.unsubscribe();
    }
    if (this.manifest) {
      this.manifest.unsubscribe();
    }
    if (this.loading) {
      this.loading.unsubscribe();
    }
    if (this.notify) {
      this.notify.unsubscribe();
    }
    if (this.pk) {
      this.pk.unsubscribe();
    }
    if (this.park_wait) {
      this.park_wait.unsubscribe();
    }
    if (this.bx) {
      this.bx.unsubscribe();
    }
  }
}
