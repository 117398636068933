import { MobileErrorComponent } from './../configuration/mobile-error/mobile-error.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfigurationListComponent } from './configuration-list/configuration-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { DocksSettingsComponent } from './docks-settings/docks-settings.component'

const configurationRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'configurations',
        component: DocksSettingsComponent,
        data: {
          title: 'Configurações',
          filter: false,
          search: true,
          refresh: true,
        },
      },
      {
        path: 'mobile-error',
        component: MobileErrorComponent,
        data: {
          title: 'Tela indisponível',
          filter: false,
          search: false,
          refresh: false,
        },
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(configurationRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ConfigurationRoutingModule {}
