import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WaitService } from '../../wait/shared/wait.service';
import { DockService } from '../../dock/shared/dock.service';
import { Subject } from 'rxjs';
import { debounceTime, filter, tap, takeUntil, startWith, switchMap, map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-assign-charge',
  templateUrl: './assign-charge.component.html',
  styleUrls: ['./assign-charge.component.scss']
})
export class AssignChargeComponent implements OnInit {
  waits: any;
  wavesInBox: any;

  waitFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  block_step = true;
  isLinear = true;
  formWave: UntypedFormGroup;
  formDriver: UntypedFormGroup;
  carriersOnWave = [];
  carrierSelected = [];
  carrier: number;
  viewType: number = 0;
  protected _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<AssignChargeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private waitService: WaitService,
    private dockService: DockService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      console.log('handset');
      this.viewType = 1;
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      console.log('tablet_portrait');
      this.viewType = 2;
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      console.log('tablet_landscape');
      this.viewType = 3;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      console.log('web');
      this.viewType = 4;
    }
  }

  ngOnInit() {
    console.log('------')
    console.log(this.data)
    this.formWave = this.formBuilder.group({
      wave: ['', [Validators.required]],
    });
    this.formDriver = this.formBuilder.group({
      wait_id: ['', [Validators.required]],
    });

    this.getWavesInBox();
  }

  getWaits() {
    const options = {
      with: 'carrier;release',
      pageSize: 50,
      carrier_id: this.carrierSelected[0]
    }
    this.waitService.index(options).subscribe((r: any) => {
      this.waits = r.data;
    })
  }

  getWavesInBox() {
    const options = {
      dock_id: this.data.item.resource.box_id
    }
    this.dockService.wavesInBox(options).subscribe((result: any) => {
      this.wavesInBox = result;
    });
  }

  // Remover se não estiver sendo utilizado
  checkCarrier(wave_id: number, carrier_id: number) {
    const item = {
      wave_id: wave_id,
      carrier_id: carrier_id
    };
    const carrier = this.carriersOnWave.find(element => element.wave_id === wave_id && element.carrier_id === carrier_id);
    if (carrier) {
      const idx = this.carriersOnWave.indexOf(item);
      this.carriersOnWave.splice(idx, 1);
    } else {
      this.carriersOnWave.push(item);
    }

    this.carriersOnWave.forEach((r) => {
      if (!this.carrierSelected.includes(r.carrier_id)) {
        this.carrierSelected.push(r.carrier_id);
      }
    });
    if (this.carrierSelected.length > 1) {
      this.block_step = false;
    } else {
      this.block_step = true;
    }
    console.log('++++++')
    console.log(this.carrierSelected)
  }

  setCarrier(element: any) {
    console.log(element);
    this.carrier = element.carrier_id;
  }

  assign(waves) {
    console.log('associa');
    let itens = [];
    waves.forEach(element => {
      itens.push(element.value);
    });
    console.log(itens);
    const options = {
      carrier_id: this.carrier,
      dock_id: this.data.item.resource.box_id,
      wave_id: itens,
      wait_id: this.formDriver.value.wait_id
    }

    this.dockService.assignWait2Waves(options).subscribe((result: any) => {
      if (result.success === true) {
        this.dialogRef.close(result.success);
      }
    });
  }

  showWait(element) {
    console.log('show wait');
    let text: string = '';
    if(element.hasOwnProperty('wait')) {
      text = ` | Motorista: ${element.wait.driver} | Carreta: ${element.wait.board_cart} | Cavalo: ${element.wait.board_horse}`;
    }
    return text;
  }

}
