import { StaffDialogComponent } from './../staff-dialog/staff-dialog.component';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { StaffService } from '../shared/staff.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component';
import { SearchService } from '../../shared/search.service';
import { Search } from '../../shared/search';
import { AccountService } from '../../account/shared/account.service';
import { RefreshService } from '../../shared/refresh.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ShiftService } from '../../shifts/shared/shift.service';
// import { PermissionService } from '../../permission/shared/permission.service';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.scss']
})
export class StaffListComponent implements OnInit {
  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  accountWatcher: Subscription;
  search: string = '';
  pageSize: number;
  length: number;
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<any>();
  shiftList: any[] = [];
  providerList: any[] = [
    {id: 1, name: '3M'},
    {id: 2, name: 'TPC'}
  ]
  activatedStaff = true;
  activeStaffSubject$ = new Subject<any>();

  // data: User[] = [];
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(
    private staffService: StaffService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private refreshService: RefreshService,
    private searchService: SearchService,
    public breakpointObserver: BreakpointObserver,
    private shiftService: ShiftService
    // private permissionService: PermissionService,
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.displayedColumns = ['name', 'provider_id', 'code', 'shift_id', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.displayedColumns = ['name', 'provider_id', 'code', 'shift_id', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['name', 'provider_id', 'code', 'shift_id', 'actions'];
    }

    // const permissions = ['superuser', 'users.show', 'users.update', 'users.destroy'];
    // this.permissionService.toggleSomePermission(permissions, this.displayedColumns, 'actions');
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  open(event, action: string, item: any = {}) {
    const dialogRef = this.dialog.open(StaffDialogComponent, {
      panelClass: ['dialog-small'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (action === 'EDIT') {
          this.reload();
          this.snackBar.open('Colaborador atualizado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        } else if (action === 'ADD') {
          // If has previous page navigate to first page or reload in self page.
          (this.paginator.hasPreviousPage()) ? this.paginator.firstPage() : this.reload();
          this.snackBar.open('Colaborador criado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  delete(event, data) {
    console.log(data);
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirma a exclusão?',
        message: `Tem certeza que deseja excluir ${data.name.toUpperCase()}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.staffService.destroy(data.id).subscribe(response => {
          this.reload();
          this.snackBar.open('Colaborador removido com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        });
      }
    });
  }

  ngOnInit() {
    this.getShifts();
    this.sort.sort(<MatSortable>{
        id: 'created_at',
        start: 'desc'
      }
    );

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.searchWatcher = this.searchService.value
      .subscribe((search: Search) => {
        this.search = search.value;
        this.reload({search: search.value});
      });

    this.refreshWatcher = this.refreshService.refresh
      .subscribe((refresh: Event) => {
        this.reload({refresh: refresh});
      });

    this.accountWatcher = this.accountService.event
      .subscribe(event => {
        const index = this.dataSource.data.findIndex(item => item.id === event.data.id);
        if (index !== -1) {
          Object.assign(this.dataSource.data[index], event.data);
        }
      });

    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent, this.activeStaffSubject$)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.pageSize = this.paginator.pageSize || 25;
          const options = {
            active: this.activatedStaff,
            page: this.paginator.pageIndex + 1,
            orderBy: 'created_at',
            sortedBy: this.sort.direction,
            pageSize: this.pageSize,
            search: this.search
          }
          return this.staffService.index(options);
        }),

        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.showListEmpty = response.data.length === 0;
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => this.dataSource.data = data);
  }

  getShifts() {
    this.shiftService.indexShift({orderBy: 'name', sortedBy: 'ASC'}).subscribe((r: any) => {
      this.shiftList = r.data;
    });
  }

  formatShiftName(shift_id: number) {
    if(shift_id) {
      const shiftObj = this.shiftList.filter((item: any) => item.id === shift_id);
      if(shiftObj.length === 1) {
        return shiftObj[0].name;
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  formatProviderName(provider_id: number) {
    if(provider_id) {
      let providerO: any = this.providerList.filter((item: any) => item.id === provider_id);
      if(providerO.length > 0) {
        providerO = providerO[0];
      }
      return providerO.name;
    } else {
      return '-';
    }
  }

  ngOnDestroy() {
    this.searchWatcher.unsubscribe();
    this.accountWatcher.unsubscribe();
    this.refreshWatcher.unsubscribe();
  }

  activeStaffSwitcher(){
    this.activatedStaff = !this.activatedStaff;
    this.activeStaffSubject$.next();
  }
}
