import { Color } from '@angular-material-components/color-picker';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SupportDataService } from 'src/app/shared/support-data.service';

@Component({
  selector: 'app-steps-list-dialog',
  templateUrl: './steps-list-dialog.component.html',
  styleUrls: ['./steps-list-dialog.component.scss']
})
export class StepsListDialogComponent implements OnInit {
  public color: ThemePalette = 'primary';
  public touchUi = false;
  public disabled = false;
  form = this.formBuilder.group({
    id: [this.data.item.id || null],
    name: [this.data.item.name, [Validators.required]],
  });
  rgbColor: any = this.data.item.color ? this.hexToRgb(this.data.item.color) : this.hexToRgb('#000000');
  colorCtr: AbstractControl = new FormControl(new Color(this.rgbColor.r, this.rgbColor.g, this.rgbColor.b), [Validators.required]);
  constructor(
    public dialogRef: MatDialogRef<StepsListDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supportDataService: SupportDataService
  ) {

  }

  ngOnInit(): void {
    // this.rgbColor = this.hexToRgb(this.data.item.color);
  }
  onDisabledChanged(value: boolean) {
    if (!value) {
      this.colorCtr.enable();
    } else {
      this.colorCtr.disable();
    }
  }
  submit() {
    const item = {
      id: this.form.value.id,
      list_name: this.data.list_name,
      name: this.form.value.name,
      color: `#${this.colorCtr.value.hex}`
    }
    if(this.data.action === 'ADD') {
      this.supportDataService.addNewItem2List(item).subscribe((r: any) => {
        this.dialogRef.close(r.data);
      });
    } else {
      this.supportDataService.editItemOnList(item).subscribe((r: any) => {
        this.dialogRef.close(r.data);
      });
    }
  }

  hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
}
