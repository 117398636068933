import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { ShipmentService } from '../shipment/shared/shipment.service';
import { StepService } from './shared/step.service';
import { LoadService } from './shared/load.service';
import { ShipmentDoubleCountService } from './shared/shipment-double-count.service';
import { ShipmentLoadingService } from './shared/shipment-loading.service';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
    ],
    declarations: [],
    providers: [
        ShipmentService,
        ShipmentDoubleCountService,
        ShipmentLoadingService,
        StepService,
        LoadService,
    ]
})
export class ShipmentModule { }
