import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../account/shared/account.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-password-token-detail',
  templateUrl: './password-token-detail.component.html',
  styleUrls: ['./password-token-detail.component.scss']
})
export class PasswordTokenDetailComponent implements OnInit {
  form: UntypedFormGroup;
  errors: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  onSubmit() {
    this.accountService.passwordToken(this.form.value)
      .subscribe(data => {
          this.dialog.open(DialogAlertComponent, {
            data: {message: data.message},
          }).afterClosed().subscribe(observer => this.router.navigate(['auth/login']));
        },
        err => {
          if (err.status === 422) {
            this.errors = err.error.errors;
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: {title: err.statusText, message: err.error.message}
            });
          }
        }
      );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
}
