import { Injectable } from '@angular/core';
// import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    // private fbDb: AngularFireDatabase
    ) { }

  // getImportsUpdates() {
  //   return this.fbDb.list('imports').valueChanges();
  // }

  // getWaitUpdates() {
  //   return this.fbDb.list('wait').valueChanges();
  // }

  // getDirectUpdates() {
  //   return this.fbDb.list('direct').valueChanges();
  // }
}
