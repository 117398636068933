import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceRoutingModule } from '../invoice/invoice-routing.module';
import { InvoiceNotesComponent } from './invoice-notes/invoice-notes.component';
@NgModule({
    declarations: [InvoiceListComponent, InvoiceNotesComponent],
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        InvoiceRoutingModule
    ],
    providers: []
})
export class InvoiceModule { }
