<h1 mat-dialog-title>Quantidade de justificativas por tipo</h1>
<h4>Boxes finalizados no dia {{data.date | date:'shortDate'}}: {{data.total}}</h4>

<mat-dialog-content class="mat-typography">

  <div class="box-list">
    <div class="box-container" *ngFor="let item of justifications_list">
      <div class="box-item box-l">{{item.name}}</div>
      <div class="box-item box-r">{{item.count}}</div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="submit" mat-button color="primary" mat-dialog-close>OK</button>
</mat-dialog-actions>
