import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CarrierService } from '../shared/carrier.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Carrier } from '../carrier';
import { merge, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { CarrierDetailComponent } from '../carrier-detail/carrier-detail.component';
import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component';
import { SearchService } from '../../shared/search.service';
import { Search } from '../../shared/search';
import { MaskUtil } from '../../shared/util/mask.util';
import { RefreshService } from '../../shared/refresh.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PermissionService } from '../../permission/shared/permission.service';

@Component({
  selector: 'app-carrier-list',
  templateUrl: './carrier-list.component.html',
  styleUrls: ['./carrier-list.component.scss']
})
export class CarrierListComponent implements OnInit, OnDestroy {
  maskUtil = MaskUtil;
  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  search: string;
  pageSize: number;
  length: number;
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<Carrier>();
  data: Carrier[] = [];
  activatedCarriers = true;
  activeCarrrierSubject$ = new Subject<any>();
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private carrierService: CarrierService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private searchService: SearchService,
    private refreshService: RefreshService,
    private permissionService: PermissionService,
    public breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.displayedColumns = ['name', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.displayedColumns = ['id_external', 'name',  'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['id_external', 'name',  'actions'];
    }

    const permissions = ['superuser', 'carriers.show', 'carriers.update'];
    this.permissionService.toggleSomePermission(permissions, this.displayedColumns, 'actions');
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  onOpen(event, action: string, item: any = {}) {
    const dialogRef = this.dialog.open(CarrierDetailComponent, {
      panelClass: ['dialog-small', 'dialog-fullscreen'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (action === 'EDIT') {
          this.reload();
          this.snackBar.open('Transportadora atualizada com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        } else if (action === 'ADD') {
          // If has previous page navigate to first page or reload in self page.
          (this.dataSource.paginator.hasPreviousPage()) ? this.dataSource.paginator.firstPage() : this.reload();
          this.snackBar.open('Transportadora criada com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  delete(event, data) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirma a exclusão?',
        message: `Tem certeza que deseja excluir o item ${data.name}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.carrierService.destroy(data.id).subscribe(response => {
          this.reload();
          this.snackBar.open('Transportadora removida com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        });
      }
    });
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;

    this.dataSource.sort.sort(<MatSortable>{
        id: 'created_at',
        start: 'desc'
      }
    );

    // If the carrier changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.searchWatcher = this.searchService.value
      .subscribe((search: Search) => {
        this.search = search.value;
        this.reload({search: search.value});
      });

    this.refreshWatcher = this.refreshService.refresh
      .subscribe((refresh: Event) => {
        this.reload({refresh: refresh});
      });

    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent, this.activeCarrrierSubject$)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            is_active: this.activatedCarriers,
            orderBy: this.sort.active,
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          };

          if (this.search) {
            Object.assign(options, {
              search: this.search,
            });
          }

          return this.carrierService.index(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }
          this.showListEmpty = response.data.length === 0;
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => this.dataSource.data = data);
  }

  ngOnDestroy() {
    this.searchWatcher.unsubscribe();
    this.refreshWatcher.unsubscribe();
  }

  activeCarriersSwitcher(){
    this.activatedCarriers = !this.activatedCarriers;
    this.activeCarrrierSubject$.next();
  }
}
