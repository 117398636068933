import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DockListComponent } from './dock-list/dock-list.component';
import { DockListMobileComponent } from './dock-list-mobile/dock-list-mobile.component';
import { DockListClosedComponent } from './dock-list-closed/dock-list-closed.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { environment } from 'src/environments/environment';
import { ImportComponent } from './import/import.component';
// import { TesteComponent } from './teste/teste.component';

const dockRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'docks',
        component: DockListComponent,
        data: {
          title: 'Boxes',
          filter: false,
          search: false,
          refresh: false,
          export: {
            show: true,
            url: `${environment.apiUrl}/export/docks`,
          },
        },
      },
      {
        path: 'docks-mobile',
        component: DockListMobileComponent,
        data: {
          title: 'Boxes',
          filter: true,
          search: false,
          refresh: false,
          export: {
            show: false,
            url: '',
          },
        },
      },
      {
        path: 'closed-docks',
        component: DockListClosedComponent,
        data: {
          title: 'Boxes Fechados',
          search: true,
          refresh: false,
          export: {
            show: false,
            url: ``,
          },
        },
      },
      {
        path: 'process-files',
        component: ImportComponent,
        data: {
          title: 'Processa Arquivos',
          search: true,
          refresh: false,
          export: {
            show: false,
            url: ``,
          },
        },
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(dockRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DockRoutingModule { }
