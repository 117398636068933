import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Account } from '../account';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class AccountGuardService implements CanActivate {

  constructor(
    private accountService: AccountService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (!this.accountService.isAuthenticated()) {
      this.router.navigate(['auth/login']);
      return false;
    }
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {
  private identity:Account;
  constructor(
    private accountService: AccountService,
    private router:Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.identity = (this.identity || await this.accountService.identity());
    const activeRoute = state.url.replace(/^\/?|\/?$/g,'');
    const menus = this.identity.menu.map((menu:any)=>menu.resource.route.replace(/^\/?|\/?$/g,''))
   
    const allowed = menus.includes(activeRoute);

    if(!allowed)
      this.router.navigate([menus.shift()])

    return allowed;
  }
}
