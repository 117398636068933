import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WaitListComponent } from './wait-list/wait-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { DirectListComponent } from './direct-list/direct-list.component';
import { environment } from '../../environments/environment';

const waitRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'waits',
        component: WaitListComponent,
        data: {
          title: 'Portaria',
          search: true,
          refresh: false,
          export: {
            show: false,
            url: `${environment.apiUrl}/export/waits`,
          }
        },
      },
      {
        path: 'directs',
        component: DirectListComponent,
        data: {
          title: 'Direcionamentos',
          search: true,
          refresh: false,
          export: {
            show: false,
            url: `${environment.apiUrl}/export/directs`,
          },
        },
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(waitRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class WaitRoutingModule { }
