import { Component, Inject, OnInit } from '@angular/core';
import { CarrierService } from '../shared/carrier.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { MaskUtil } from '../../shared/util/mask.util';
// import { AmazingTimePickerService } from 'amazing-time-picker';
import { PermissionService } from '../../permission/shared/permission.service';

@Component({
  selector: 'app-carrier-detail',
  templateUrl: './carrier-detail.component.html',
  styleUrls: ['./carrier-detail.component.scss']
})
export class CarrierDetailComponent implements OnInit {
  form = this.formBuilder.group({
    name: [this.data.item.name, [Validators.required]],
    id_external: [(this.data.item) ? this.data.item.id_external : null, [Validators.required]],
    is_casual: [this.data.item.is_casual || false, [Validators.required]],
    is_active: [this.data.item.is_active || false],
    operation_start: [this.data.item.operation_start],
    operation_end: [this.data.item.operation_end],
  });
  errors: any = {};
  maskUtil = MaskUtil;

  constructor(
    // private atp: AmazingTimePickerService,
    public dialogRef: MatDialogRef<CarrierDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private carrierService: CarrierService,
    private permissionService: PermissionService,
  ) { }

  // maskStartTime() {
  //   const amazingTimePicker = this.atp.open(
  //       {
  //           time: this.form.get('operation_start').value,
  //           theme: 'material-green'
  //       }
  //   );
  //   amazingTimePicker.afterClose().subscribe(time => {
  //       this.form.get('operation_start').setValue(time);
  //   });
  // }

  // maskEndTime() {
  //     const amazingTimePicker = this.atp.open(
  //         {
  //             time: this.form.get('operation_end').value,
  //             theme: 'material-green'
  //         }
  //     );
  //     amazingTimePicker.afterClose().subscribe(time => {
  //         this.form.get('operation_end').setValue(time);
  //     });
  // }

  onSubmit() {
    if (this.data.action === 'EDIT') {
      this.carrierService.update(this.form.value, this.data.item.id)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    } else if (this.data.action === 'ADD') {
      this.carrierService.create(this.form.value)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    }
  }

  checkPermission() {
    const permissions = [
      {action: 'ADD', permission: 'carriers.store'},
      {action: 'EDIT', permission: 'carriers.update'},
      {action: 'VIEW', permission: 'carriers.show'},
    ];

    this.permissionService.checkDialogPermission(permissions, this.data.action, this.dialogRef, this.form);
  }

  ngOnInit() {
    this.checkPermission();
  }
}
