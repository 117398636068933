import { DockFiltersMobileComponent } from "./../dock-filters-mobile/dock-filters-mobile.component";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { DockService } from "../shared/dock.service";
import { BoxFilterService } from "../../shared/box-filter.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { JustifyStepComponent } from "./../justify-step/justify-step.component";
import { DialogAnaliseComponent } from "../dialog-analise/dialog-analise.component";
import { DockDetailsMobileComponent } from "../dock-details-mobile/dock-details-mobile.component";
import { environment } from "./../../../environments/environment.prod";
import { webSocket } from "rxjs/webSocket";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { AssignChargeComponent } from "../assign-charge/assign-charge.component";
import { CarrierService } from "src/app/carrier/shared/carrier.service";
import { BoxNoteComponent } from "../box-note/box-note.component";
import { SupportDataService } from "src/app/shared/support-data.service";

@Component({
  selector: "app-dock-list-mobile",
  templateUrl: "./dock-list-mobile.component.html",
  styleUrls: ["./dock-list-mobile.component.scss"],
})
export class DockListMobileComponent implements OnInit, OnDestroy {
  columnSize = 2;
  // steps = [
  //   { name: 'PICKING', value: 'pk', class: 'btn-pk' },
  //   { name: 'PICKING CONCLUÍDO', value: 'pkc', class: 'btn-pkc' },
  //   { name: 'DUPLA CONFERÊNCIA', value: 'dc', class: 'btn-dc' },
  //   { name: 'ANALISAR', value: 'ana', class: 'btn-ana' },
  //   { name: 'REQ. ESPECIAL', value: 'req', class: 'btn-req' },
  //   { name: 'AG. IMPRESSÃO NF', value: 'anf', class: 'btn-anf' },
  //   { name: 'CONSULTA PIN', value: 'cpi', class: 'btn-cpi' },
  //   { name: 'LEITURA ETQ.', value: 'let', class: 'btn-let' },
  //   { name: 'AG. VEÍCULO', value: 'ave', class: 'btn-ave' },
  //   { name: 'CARREGAMENTO', value: 'ld', class: 'btn-ld' },
  //   { name: 'CONF. RELATÓRIO', value: 'rel', class: 'btn-rel' },
  //   { name: 'CONCLUÍDO', value: 'cc', class: 'btn-cc' }
  // ];
  steps: any[] = [];
  boxList: any = [];
  ftOptions: any;
  filterWatcher: Subscription;

  constructor(
    private supportDataService: SupportDataService,
    private breakpointObserver: BreakpointObserver,
    private dockService: DockService,
    private carrierService: CarrierService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private boxFilterService: BoxFilterService,
  ) {
    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.Web,
      ])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      console.log("smart phone");
      this.columnSize = 1;
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      console.log("tablet");
      this.columnSize = 2;
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      this.columnSize = 3;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.router.navigate(["admin/docks"]);
    }
  }

  ngOnInit() {
    this.getStepsList();
    const subject = webSocket({
      url: `${environment.wsUrl}/import/csv`,
      deserializer: () => {},
    });

    subject.subscribe(
      (msg) => {
        console.log("atualizou via ws");
        this.reload();
      },
      (err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      () => console.log("complete"), // Called when connection is closed (for whatever reason).
    );
    this.reload();

    this.filterWatcher = this.boxFilterService.show.subscribe((filter: any) => {
      console.log("Filter watcher");
      console.log(filter);
      this.manageFilters();
    });
  }

  ngOnDestroy() {
    this.filterWatcher.unsubscribe();
  }

  reload() {
    // console.log('busca box');
    const options = {
      orderBy: "box",
      sortedBy: "",
      page: 1,
      pageSize: 100,
      export: false,
      carrier_id: null,
    };
    this.dockService.boxes(options).subscribe((r: any) => {
      this.boxList = r.data;
      console.log(this.boxList);
    });
  }

  showBoxes(element) {
    const boxes = [];
    element.forEach((e) => {
      boxes.push(e.vbox);
    });

    return boxes.toString().replace(/,/g, ", ");
  }

  getCarrier(resource: any) {
    const carriers = [];
    resource.vbox.forEach((item) => {
      if (item.operation.carrier) {
        if (!carriers.includes(item.operation.carrier.name)) {
          carriers.push(item.operation.carrier.name);
        }
      } else {
        carriers.push(resource.carrier.name);
      }
    });

    if (carriers.length > 0) {
      return carriers[0];
    } else {
      return "";
    }
  }

  onStartStep(element, step) {
    console.log("+++++++++++");
    console.log(element);
    console.log(step);
    if (element.step === "ana") {
      this.onStartStepAnalise(element, step);
    } else {
      const data = {
        box_id: element.resource.box_id,
        step: step,
        justify_id: element.justify_id ? element.justify_id : "",
      };

      this.dockService.saveStep(data).subscribe((r: any) => {
        let message;
        if (r.success === true) {
          message = "Etapa registrada com sucesso!";
          this.showMessage(message);
          this.reload();
        } else {
          if (r.showDialog) {
            this.justify(r.message, data);
          } else {
            message = r.message;
            this.showMessage(message);
          }
        }
      });
    } //
  }

  onStartStepAnalise(element, step) {
    element.toStep = step;
    const dialogRef = this.dialog.open(DialogAnaliseComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
        toStep: step,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.dockService.saveStep(data).subscribe((r: any) => {
        let message;
        if (r.success === true) {
          message = "Etapa registrada com sucesso!";
          this.reload();
        } else {
          message = r.message;
        }
        this.snackBar.open(message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      });
    });
  }

  showMessage(message) {
    this.snackBar.open(message, "OK", {
      duration: 3000,
      horizontalPosition: "left",
      verticalPosition: "bottom",
    });
  }

  assingCharge(event, element) {
    const dialogRef = this.dialog.open(AssignChargeComponent, {
      panelClass: ["dialog-large"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.snackBar.open("Carga associada com sucesso", "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
        this.reload();
      }
    });
  }

  justify(message, data) {
    const dialogRef = this.dialog.open(JustifyStepComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        message: message,
        step: data.step,
        box_id: data.box_id,
        justifications: [
          {
            id: 1,
            name: "Teste",
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      console.log(data);
      if (data) {
        this.onStartStep(data, data.step);
      }
    });
  }

  details(data) {
    // console.log(data);
    const dialogRef = this.dialog.open(DockDetailsMobileComponent, {
      panelClass: ["dialog-fullscreen"],
      disableClose: false,
      data: { ...data },
    });

    dialogRef.afterClosed().subscribe((r) => {
      console.log(r);
      if (r) {
        // this.onStartStep(r, data.step);
      }
    });
  }

  manageFilters() {
    console.log("- set filters");
    const dialogRef = this.dialog.open(DockFiltersMobileComponent, {
      data: { ...this.ftOptions },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("---");
      console.log(result);
      if (result) {
        this.ftOptions = {
          export: false,
          orderBy: "box",
          last_step: result.steps ? result.steps : "",
          carrier_id: result.carriers ? result.carriers : "",
          sortedBy: "ASC",
          page: 1,
          pageSize: 100,
        };
        const qtdSteps =
          result.steps.length > 0 ? result.steps.split(",").length : 0;
        const qtdCarrier =
          result.carriers.length > 0
            ? result.carriers.split(",").map(Number).length
            : 0;
        this.boxFilterService.onChange(qtdSteps + qtdCarrier, "");
        this.dockService.boxes(this.ftOptions).subscribe((r: any) => {
          this.boxList = r.data;
        });
      }
    });
  }

  showStep(step) {
    const stepFt: any = this.steps.filter((ft: any) => step === ft.id);
    if (stepFt.length > 0) {
      return stepFt[0];
    } else {
      return "-";
    }
  }

  showStepTime(steps) {
    return moment(steps[0]["ts"]);
  }

  justifyAnalisis(element) {
    //novo
    console.log("element");
    console.log(element);
    const dialogRef = this.dialog.open(DialogAnaliseComponent, {
      panelClass: ["dialog-medium"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
        toStep: element.step,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.reload();
    });
  }

  disableAuthorizeDC(element: any) {
    const has_dc_list = element.resource.hasOwnProperty("has_dc_list");
    if (element.step === "pk" || element.step === "ana") {
      if (has_dc_list) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  authorizeDC(element: any) {
    const data = {
      box_id: element.resource.box_id,
    };
    this.carrierService.createConferenceFromBox(data).subscribe((r: any) => {
      this.snackBar.open("Conferência da transportadora liberada", "OK", {
        duration: 3000,
        horizontalPosition: "left",
        verticalPosition: "bottom",
      });
      this.reload();
    });
  }

  registerNotes(event, element) {
    const dialogRef = this.dialog.open(BoxNoteComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
      },
    });

    dialogRef.afterClosed().subscribe((r: any) => {
      this.reload();
    });
  }

  getStepsList() {
    this.supportDataService.resourceName("steps-list").subscribe((r: any) => {
      this.steps = r.data.resource;
    });
  }
}
