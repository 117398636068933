import { ConferenceValidationComponent } from './conference-validation/conference-validation.component';
import { ConferenceListComponent } from './conference-list/conference-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarrierListComponent } from './carrier-list/carrier-list.component';
import { AccountGuardService, RouteGuardService } from '../account/shared/account-guard.service';
// import { environment } from '../../environments/environment';

const carrierRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService, RouteGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'carriers',
        component: CarrierListComponent,
        data: {
          title: 'Transportadoras',
          search: true,
          refresh: true,
          // export: {
          //   show: true,
          //   url: `${environment.apiUrl}/carriers/download`,
          // }
        },
      },
      {
        path: 'carrier_conference',
        component: ConferenceListComponent,
        data: {
          title: 'Dupla Conferência',
          search: true,
          refresh: true,
          // export: {
          //   show: true,
          //   url: `${environment.apiUrl}/carriers/download`,
          // }
        },
      },
      {
        path: 'conference_validation',
        component: ConferenceValidationComponent,
        data: {
          title: 'Validação da conferência',
          search: true,
          refresh: true,
          // export: {
          //   show: true,
          //   url: `${environment.apiUrl}/carriers/download`,
          // }
        },
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(carrierRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CarrierRoutingModule {}
