import { Component, Inject, OnInit } from '@angular/core';
import { WaitService } from '../shared/wait.service';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { MaskUtil } from '../../shared/util/mask.util';
import { Dock } from '../../dock/dock';
import { DockService } from '../../dock/shared/dock.service';
import { ShipmentService } from '../../shipment/shared/shipment.service';
// import { merge, Observable, of, of as observableOf, pipe } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WaitDirectionService } from '../shared/wait-direction.service';
import { SupportDataService } from '../../shared/support-data.service';

@Component({
  selector: 'app-direct-detail',
  templateUrl: './direct-detail.component.html',
  styleUrls: ['./direct-detail.component.scss']
})
export class DirectDetailComponent implements OnInit {
  form = this.formBuilder.group({
    dock_number: [[Validators.required]]
  });

  docks: Dock[] = [];
  errors: any = {};
  maskUtil = MaskUtil;
  search: string;
  dockFilter: UntypedFormControl = new UntypedFormControl();
  wavesLoaded: any;
  dockNumbers: any;

  constructor(
    public dialogRef: MatDialogRef<DirectDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private waitService: WaitService,
    private dockService: DockService,
    private shipmentService: ShipmentService,
    private waitDirectionService: WaitDirectionService,
    private supportDataService: SupportDataService
  ) { }

  onSubmit() {
    // const values = this.form.value;

    const params = {
      current_dock: this.data.item.direction ? this.data.item.direction.dock_number : null,
      dock_number: this.form.value.dock_number
    }

    this.waitDirectionService.create(this.data.item.id, params)
      .subscribe(
        value => this.nextCallback(value),
        error => this.errorCallback(error)
      );
  }

  nextCallback(value) {
    this.dialogRef.close(value);
  }

  errorCallback(error) {
    if (error.status === 422) {
      this.errors = error.error.errors;
    } else {
      this.dialog.open(DialogAlertComponent, {
        data: { title: error.statusText, message: error.error.message }
      });
    }
  }

  ngOnInit() {
    this.loadDocks();
  }

  loadDocks() {
    this.supportDataService.dockSpaces().subscribe((r: any) => {
      const bolsao = { 'id': 0, 'name': 'Bolsão', 'busy': false };
      const docks = [];
      r.data.resource.forEach(element => {
        if (element.busy == false) {
          docks.push(element);
        }
      });
      docks.push(bolsao);
      this.dockNumbers = docks;
    }, (e: any) => {
      console.log('error');
      console.log(e);
    });
  }

}
