import {Component, Inject, OnInit} from '@angular/core';
import { StaffService} from '../shared/staff.service';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {DialogAlertComponent} from '../../shared/dialog-alert/dialog-alert.component';
import {NgxPermissionsService} from 'ngx-permissions';
import {PermissionService} from '../../permission/shared/permission.service';
import { ShiftService } from 'src/app/shifts/shared/shift.service';
import { SupportDataService } from 'src/app/shared/support-data.service';

@Component({
  selector: 'app-staff-dialog',
  templateUrl: './staff-dialog.component.html',
  styleUrls: ['./staff-dialog.component.scss']
})
export class StaffDialogComponent implements OnInit {
  form = this.formBuilder.group({
    name: [this.data.item.name, [Validators.required]],
    code: [this.data.item.code, [Validators.required]],
    provider_id: [this.data.item.provider_id, [Validators.required]],
    shift_id: [this.data.item.shift_id, [Validators.required]],
    active: [this.data.item.active],
  });
  errors: any = {};
  hidePassword = true;
  groups: any;
  carrierList: any;
  shiftList: any[] = [];
  providerList: any[] = []

  constructor(
    public dialogRef: MatDialogRef<StaffDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private staffService: StaffService,
    private shiftService: ShiftService,
    private supportDataService: SupportDataService,
    private permissionService: PermissionService
  ) { }

  onSubmit() {
    if(this.form.value.carrier_user === false) {
      this.form.value.carrier_id = null;
    }
    if (this.data.action === 'EDIT') {
      this.staffService.update(this.form.value, this.data.item.id)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    } else if (this.data.action === 'ADD') {
      this.staffService.create(this.form.value)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    }
  }

  // checkPermission() {
  //   const permissions = [
  //     {action: 'ADD', permission: 'users.store'},
  //     {action: 'EDIT', permission: 'users.update'},
  //     {action: 'VIEW', permission: 'users.show'},
  //   ];

  //   this.permissionService.checkDialogPermission(permissions, this.data.action, this.dialogRef, this.form);
  // }

  getShifts() {
    this.shiftService.indexShift({orderBy: 'name', sortedBy: 'ASC'}).subscribe((r: any) => {
      this.shiftList = r.data;
    });
  }

  ngOnInit() {
    this.getShifts();
    this.getTime();
  }

  getTime() {
    this.supportDataService.resourceName('times').subscribe((r: any) => {
      this.providerList = r.data.resource.filter((ft: any) => ft.active === true);
    });
  }


}
