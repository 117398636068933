import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { DoubleCount } from '../double-count';

@Injectable({
  providedIn: 'root'
})
export class ShipmentDoubleCountService {

  constructor(
    private http: HttpClient
  ) { }

  // index(shipment: string|number, options?: any): Observable<DoubleCount> {
  //   const httpParams = Object.assign({
  //     page: 1,
  //     orderBy: 'created_at',
  //     sortedBy: 'desc',
  //   }, options);

  //   const params = new HttpParams({fromObject: httpParams})

  //   const url = `${environment.apiUrl}/shipments/${shipment}/double_counts`;

  //   return this.http.get<DoubleCount>(url, {params}).pipe(
  //     map((response: Response | any) => response || {}),
  //     catchError(err => throwError(err || 'Server error'))
  //   );
  // }

  // update(data: any, shipment: string|number, id: string, params?: any): Observable<DoubleCount> {
  //   const url = `${environment.apiUrl}/shipments/${shipment}/double_counts/${id}`;
  //   const options = {params: params};

  //   return this.http.put<DoubleCount>(url, data, options).pipe(
  //     map((response: Response | any) => response || {}),
  //     catchError(err => throwError(err || 'Server error'))
  //   );
  // }

  // create(shipment: string|number, data?: any, params?: any): Observable<DoubleCount> {
  //   const url = `${environment.apiUrl}/shipments/${shipment}/double_counts`;
  //   const options = {params: params};

  //   return this.http.post<DoubleCount>(url, data, options).pipe(
  //     map((response: Response | any) => response || {}),
  //     catchError(err => throwError(err || 'Server error'))
  //   );
  // }

  // destroy(shipment: string|number, id: string, params?: any) {
  //   const url = `${environment.apiUrl}/shipments/${shipment}/double_counts/${id}`;
  //   const options = {params: params};

  //   return this.http.delete(url, options).pipe(
  //     map((response: Response | any) => response || {}),
  //     catchError(err => throwError(err || 'Server error'))
  //   );
  // }

  // startStop(data: any) {
  //   const url = `${environment.apiUrl}/shipments-steps`;

  //   return this.http.put<DoubleCount>(url, data).pipe(
  //     map((response: Response | any) => response || {}),
  //     catchError(err => throwError(err || 'Server error'))
  //   );
  // }
}
