export class MaskUtil {
  private static PHONE_BIG = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private static PHONE_SMALL = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private static RG = [ /\d/ , /\d/ , /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/];
  private static CPF = [ /\d/ , /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/ , /\d/, /\d/, '-', /\d/, /\d/];
  private static CNPJ = [ /\d/ , /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/ , /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  private static CEP = [/\d/ , /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  private static TIME = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];

  static maskPhone(number: number | string) {
    const numbers = number.toString().match(/\d/g);
    if (numbers && numbers.toString().length > 10) {
      return MaskUtil.PHONE_BIG;
    } else {
      return MaskUtil.PHONE_SMALL;
    }
  }

  static maskRG() {
    return MaskUtil.RG;
  }

  static maskCPF() {
    return MaskUtil.CPF;
  }

  static maskCNPJ() {
    return MaskUtil.CNPJ;
  }

  static maskCEP() {
    return MaskUtil.CEP;
  }

  static maskTime() {
    return MaskUtil.TIME;
  }
}
