import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DockService } from '../shared/dock.service';
import { CarrierService } from '../../carrier/shared/carrier.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Dock } from '../dock';
import { Subject, merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, tap, takeUntil, startWith, switchMap, map } from 'rxjs/operators';
import { SearchService } from '../../shared/search.service';
import { Search } from '../../shared/search';
import { RefreshService } from '../../shared/refresh.service';
import { ConfigurationService } from '../../configuration/shared/configuration.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { InvoiceService } from '../../invoice/shared/invoice.service';
// import { FirebaseService } from '../../shared/firebase.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ShipmentService } from 'src/app/shipment/shared/shipment.service';

@Component({
  selector: 'app-dock-list-closed',
  templateUrl: './dock-list-closed.component.html',
  styleUrls: ['./dock-list-closed.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DockListClosedComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    carrier_id: [''],
  });

  dayStartDate = new UntypedFormControl(new Date());
  periodStartDate = new UntypedFormControl();
  periodEndDate = new UntypedFormControl();
  options: any = {
    last_step: 'cc',
    orderBy: '',
    carrier_id: this.form.value.carrier_id,
    sortedBy: 'asc',
    date: moment().format('YYYY-MM-DD'),
    page: 1,
    pageSize: 100
  };

  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  search: string;
  pageSize: number;
  last_step = '';

  length: number;
  val: number;
  displayedColumns: string[];
  displayedShipmentColumns: string[];
  expandedElement = null;
  showListEmpty = false;
  breakpoints = Breakpoints;
  configurationRegressiveCounter: any;
  dataSource = new MatTableDataSource<Dock>();
  data: Dock[] = [];
  export = false;
  lastUpdate: any;
  refreshEnabled: any;
  loading: any;
  element: any;
  stepsFiltered = "";
  steps = [
    { name: 'PICKING', value: 'pk', class: 'btn-pk' },
    { name: 'PICKING CONCLUÍDO', value: 'pkc', class: 'btn-pkc' },
    { name: 'DUPLA CONFERÊNCIA', value: 'dc', class: 'btn-dc' },
    { name: 'ANALISAR', value: 'ana', class: 'btn-ana' },
    { name: 'REQ. ESPECIAL', value: 'req', class: 'btn-req' },
    { name: 'AG. IMPRESSÃO NF', value: 'anf', class: 'btn-anf' },
    { name: 'CONSULTA PIN', value: 'cpi', class: 'btn-cpi' },
    { name: 'LEITURA ETQ.', value: 'let', class: 'btn-let' },
    { name: 'AG. VEÍCULO', value: 'ave', class: 'btn-ave' },
    { name: 'CARREGAMENTO', value: 'ld', class: 'btn-ld' },
    { name: 'CONF. RELATÓRIO', value: 'rel', class: 'btn-rel' },
    { name: 'CONCLUÍDO', value: 'cc', class: 'btn-cc' }
  ];
  carriers: any;
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  protected _onDestroy = new Subject<void>();

  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  showCompareLastDay: any;

  constructor(
    private dockService: DockService,
    private carrierService: CarrierService,
    private invoiceService: InvoiceService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private searchService: SearchService,
    private refreshService: RefreshService,
    public datePipe: DatePipe,
    private shipmentService: ShipmentService,
    private configurationService: ConfigurationService,
    // private firebaseService: FirebaseService,
    public breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.displayedColumns = ['box', 'actions'];
      this.displayedShipmentColumns = ['box', 'wave', 'volumes', 'carrier', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.displayedColumns = ['box', 'actions'];
      this.displayedShipmentColumns = ['box', 'wave', 'volumes', 'carrier', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['box', 'carrier_carriers:carrier_id|carrier_carriers.name', 'assigned', 'vboxes', 'tvolume', 'status', 'invoice', 'notes', 'just', 'arrival_prev', 'min_dt', 'max_dt', 'print_map', 'dc_end', 'load_end', 'steps', 'actions'];
      this.displayedShipmentColumns = ['box', 'vbox_min_dt', 'wave', 'client', 'volumes', 'volume', 'vol_percent', 'amount', 'amount_confered', 'qtd_percent', 'vboxStatus', 'actions'];
    }
  }

  ngOnInit() {
    // this.listening4Updates();
    this.getCarrierList();

    this.carrierFilter.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        startWith({}),
        debounceTime(500),
        switchMap(() => {
          const options = {};

          if (this.carrierFilter.value) {
            Object.assign(options, {
              search: this.carrierFilter.value,
            });
          }

          return this.carrierService.index(options);
        }),
        map((response: any) => response.data)
      ).subscribe(data => {
        this.carriers = data;
      });

    this.sort.sort(<MatSortable>{
      id: 'box_boxes.created_at',
      start: 'desc'
    });

    // If the dock changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.searchWatcher = this.searchService.value
      .subscribe((search: Search) => {
        this.search = search.value;
        this.reload({ search: search.value });
      });

    this.refreshWatcher = this.refreshService.refresh
      .subscribe((refresh: Event) => {
        this.reload({ refresh: refresh });
      });

    // this.getConfigRegressiveCounter();
    this.getClosedBoxes();


    this.showLastUpdate();
  }

  getClosedBoxes(filter_options?) {
    // merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
    //   .pipe(
    //     startWith({}),
    //     switchMap(() => {
    //       this.options = {
    //         orderBy: this.sort.active,
    //         carrier_id: this.form.value.carrier_id,
    //         date: this.options.date,
    //         sortedBy: this.sort.direction,
    //         page: this.paginator.pageIndex + 1,
    //         pageSize: this.paginator.pageSize ? this.paginator.pageSize : 100,
    //       };

    //       if (this.search) {
    //         Object.assign(this.options, {
    //           search: this.search,
    //         });
    //       }

    //       return this.dockService.closeds(this.options);
    //     }),
    //     tap((response: Response | any) => {
    //       if (!response.data.length && this.paginator.hasPreviousPage()) {
    //         this.paginator.previousPage();
    //       }

    //       this.showListEmpty = response.data.length === 0;
    //       this.paginator.length = response.total;
    //       this.paginator.pageSize = response.per_page;
    //     }),
    //     map((response: Response | any) => {
    //       return response.data;
    //     }),
    //     catchError(() => {
    //       return observableOf([]);
    //     })
    //   ).subscribe(data => {
    //     this.dataSource.data = data;
    //   });


    /// Ao entrar na página
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {

          this.options.orderBy = this.sort.active;
          this.options.sortedBy = this.sort.direction;
          this.options.page = this.paginator.pageIndex + 1;
          this.options.pageSize = this.paginator.pageSize ? this.paginator.pageSize : 10;


          Object.assign(this.options, filter_options);

          return this.dockService.closeds(this.options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {

          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource.data = data;
      });
  }

  reload(params?: any) {
    this.showLastUpdate();
    return this.reloadEvent.emit(params);
  }


  dayFilter() {
    this.options.period = false
    this.options.date = this.datePipe.transform(this.dayStartDate.value, 'yyyy-MM-dd')

    // this.dockService.closeds(this.options).subscribe((r: any) => {
    //   this.dataSource.data = r.data;
    // });
    this.getClosedBoxes();
  }

  periodFilter() {
    if (!this.periodStartDate.value && !this.periodEndDate.value) {
      this.snackBar.open('Escolha a data inicial e final para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else if (!this.periodStartDate.value) {
      this.snackBar.open('Escolha a data inicial para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else if (!this.periodEndDate.value) {
      this.snackBar.open('Escolha a data final para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else {
      this.options.period = true;
      this.options.date = "";
      this.options.startDate = this.datePipe.transform(this.periodStartDate.value, 'yyyy-MM-dd');
      this.options.endDate = this.datePipe.transform(this.periodEndDate.value, 'yyyy-MM-dd');

      this.getClosedBoxes();
    }
  }

  showStatus(element) {
    const status = ['CQ', 'PR', 'CK', 'NR', 'NH', 'NA', 'NF'];
    let st_names = [];
    let st_idxs = [];
    element.vbox.forEach(element => {
      if (!st_names.includes(element.operation.minor_status)) {
        st_names.push(element.operation.minor_status);
        st_idxs.push(status.indexOf(element.operation.minor_status));
      }
    });
    const min = Math.min.apply(null, st_idxs);

    return status[min];
  }

  showMaxStatus(element) {
    const status = ['CQ', 'PR', 'CK', 'NR', 'NH', 'NA', 'NF'];
    let st_names = [];
    let st_idxs = [];
    element.vbox.forEach(element => {
      if (!st_names.includes(element.operation.minor_status)) {
        st_names.push(element.operation.minor_status);
        st_idxs.push(status.indexOf(element.operation.minor_status));
      }
    });
    const max = Math.max.apply(null, st_idxs);

    return status[max];
  }

  showStep(step) {
    return this.dockService.showStep(step);
  }

  showStepTime(steps) {
    return moment(steps[0]['ts']);
  }

  showNow() {
    return moment();
  }

  formatTime(date) {
    return moment(date, "HH:mm").format("hh:mm");
  }

  sumConference(shipment) {
    return shipment.shipment_load.conferences.reduce((prev, current) => prev + current.volume, 0);
  }

  percentCompleted(shipment) {
    const processed = shipment.shipment_load.conferences.reduce((prev, current) => prev + current.volume, 0);
    const total = shipment.shipment_load.volumes;

    return ((processed / total) * 100).toFixed(2);
  }

  getFilterStep(step) {
    this.last_step = step;
    this.reload();
  }

  getCarrierList() {
    this.carrierService.index().subscribe((r: any) => {
      this.carriers = r.data;
    })
  }

  getFilterCarrier() {
    this.options.carrier_id = this.form.value.carrier_id
    this.getClosedBoxes()
  }

  cleanCarrierFilter() {
    this.reload();
  }

  printMapTime(element) {
    const filtered = element.steps.filter(time => time.step === 'dc');
    if (filtered.length > 0) {
      return filtered[0].ts;
    } else {
      return '';
    }
  }

  showLastUpdate() {
    this.dockService.getLastUpdate().subscribe((r: any) => {
      this.lastUpdate = r;
    });
  }

  formatPlate(plate: string) {
    if (plate) {
      let p1 = plate.substring(0, 3).toUpperCase();
      let p2 = plate.substring(3, 7);
      return `${p1}-${p2}`;
    } else {
      return '';
    }

  }


  ngOnDestroy() {
    this.searchWatcher.unsubscribe();
    this.refreshWatcher.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  round(number: number) {
    return Math.round(number);
  }

  formatData(dt) {
    return this.datePipe.transform(dt, 'dd/MM HH:mm');
  }

  showBoxes(element) {
    let boxes = [];
    element.forEach(e => {
      boxes.push(e.vbox);
    });

    return boxes.toString().replace(/,/g, ', ');
  }

  sumOrderVolums(element) {
    const volume = element.resource.vbox.reduce((prev, current) => {
      if (current.operation) {
        return prev + current.operation.order_vol;
      } else {
        return 0;
      }
    }, 0);
    return volume;
  }

  // listening4Updates() {
  //   const user = JSON.parse(localStorage.getItem('account'));
  //   this.firebaseService.getImportsUpdates().subscribe((r: any) => {
  //     if (r[0].user_id != user.id) {
  //       this.reload();
  //     }
  //   });
  // }

  getMinMoment(list) {
    let mList = [];
    list.forEach(element => {
      if (element) {
        const minDt = element.min_start_at;
        if (minDt) {
          mList.push(moment(minDt));
        }
      }
    });

    return moment.min(mList);
  }

  getMaxMoment(list) {
    let mList = [];
    list.forEach(element => {
      if (element) {
        const maxDt = element.max_end_at;
        if (maxDt) {
          mList.push(moment(maxDt));
        }
      }
    });

    return moment.max(mList);
  }

  getCarrier(resource: any) {
    const carriers = [];
    resource.vbox.forEach(item => {
      if (item.operation.carrier) {
        if (!carriers.includes(item.operation.carrier.name)) {
          carriers.push(item.operation.carrier.name);
        }
      }
    });

    if (carriers.length === 1) {
      return carriers[0];
    } else {
      return '';
    }
  }

  checkIfAssigned(vbox: any) {
    let assigneds = [];
    let size = vbox.length;
    vbox.forEach(element => {
      if (element.assigned) {
        assigneds.push(1);
      }
    });
    if (assigneds.length === size) {
      return 'Sim';
    } else {
      return 'Não';
    }
  }

  changeFilter(e: any) {
    const obj = {
      'period': false,
      'day': true
    };
    this.showCompareLastDay = obj[e.value];
  }

  downloadReport(): void {
    this.options.export = true;

    this.dockService.closeds(this.options)
      .subscribe(
        (r: any) => {
          this.createFileFromBlob(r.csv, 'report.csv');
        });
  }

  createFileFromBlob(file, fileName) {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    const blob = new Blob(['\uFEFF' + file], { type: 'text/csv; charset=utf-18' });
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
    this.options.export = false;
  }

  printConferenceMap(box_id: number) {
    this.carrierService.getConferenceMap({box_id: box_id}).subscribe((r: any) =>{
      if(r.data.length > 0) {
        this.makeConferenceMapPDF(r.data);
      } else {
        this.snackBar.open('Conferência da transportadora não iniciada', 'OK', {
          duration: 3000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
        });
      }

    });
  }

  printMap(box: any) {
    const waves = [];
    let waveVbox = {}
    box.vbox.forEach(element => {
      waves.push(element.wave)
      waveVbox[element.wave] = element.vbox.toString()
    });

    const data = {
      box_id: box.box_id
    }

    this.shipmentService.mapaPickingStored(data).subscribe((response: any) => {
      const printItens: any[] = response.data;
      this.makeMapPDF(printItens, waveVbox, box.box, box.vbox[0].operation.carrier.name);
    });
  }

  makeConferenceMapPDF(data: any) {
    let i;
    let doc = new jsPDF('p', 'pt', 'a4');
    let day = this.datePipe.transform(new Date(), 'dd/M/Y HH:mm');
    doc.setFontSize(8);
    let pageLabel = `Página ${doc.page}`
    doc.text(pageLabel, 570 - (doc.getStringUnitWidth(pageLabel) * doc.internal.getFontSize()), 820);
    doc.text(`Relatório gerado em ${day}`, 20, 820);

    doc.setFont('Helvetica');
    doc.setFontStyle('bold');
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(`Mapa de Conferência | Box Físico: ${data[0].box} | Box Virtual: ${this.listVboxStr(data)}`, 20, 40, null, 0);
    doc.text(`UCs: ${data[0].uc_conf}/${data[0].uc_total} = ${((data[0].uc_conf * 100)/data[0].uc_total).toFixed(2)}% | Volumes: ${data[0].volume_conf}/${data[0].volume_total} = ${((data[0].volume_conf * 100)/data[0].volume_total).toFixed(2)}%`, 20, 55, null, 0)
    doc.text('Transportadora: ', 20, 70, null, 0).setFontStyle('normal');
    doc.text(`${data[0].carrier}`, 120, 70, null, 0);
    doc.setFont('Helvetica');
    doc.setFontStyle('bold');
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
    doc.text('UC', 20, 95);
    doc.text('Box V.', 120, 95);
    doc.text('Conferente', 210, 95);
    doc.text('Data e Hora', 350, 95);
    doc.text('Quantidade', 500, 95);

    doc.setLineWidth(1.0);
    doc.line(20,100,570,100);
    doc.setLineWidth(0.5);
    i = 110;

    let count = 0;

    data.forEach(item => {
      count = count + item.amount;
      doc.setFontStyle('normal');
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(item.pallet, 20, i);
      doc.text(item.vbox, 120, i);
      doc.text(`${item.first_name} ${item.last_name}`, 210, i);
      doc.text(item.updated_at ? this.datePipe.transform(item.updated_at, 'dd/M/Y HH:mm') : '', 350, i);
      doc.text(item.amount.toString(), 500, i);

      i = i + 4;
      doc.line(20, i, 570, i);
      i = i + 10;
      if(i > 820) {
        doc.addPage();
        i = 50;
      }
    });
    doc.output('dataurlnewwindow');
  }

  makeMapPDF(data: any, vbox: any, box: any, carrier: string) {
    console.log(data);

    let i;
    let doc = new jsPDF('p', 'pt', 'a4');
    doc.page = 1;
    // Write the first page number
    doc.setFontSize(8);
    let day = this.datePipe.transform(new Date(), 'dd/M/Y HH:mm');
    let pageLabel = `Página ${doc.page}`
    doc.text(pageLabel, 570 - (doc.getStringUnitWidth(pageLabel) * doc.internal.getFontSize()), 820);
    doc.text(`Relatório gerado em ${day}`, 20, 820);
    doc.setFont('Helvetica');
    doc.setFontStyle('bold');
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);


    // get Vbox
    const vboxes: any[] = Object.values(vbox);
    let volumes = data.reduce(function (acc, obj) { return acc + obj.volume; }, 0);
    let vboxStr = vboxes.join(', ');
    // let vboxStr = '12, 13, 14, 15, 16, 16, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 45';
    let title = `Mapa de Picking | Box Físico: ${box} | Box Virtual: ${vboxStr}`
    let lineSize = Math.round(doc.internal.pageSize.width - 40);
    let titleLines: any[] = [[],[]];
    let titleArray = title.split(',');
    titleArray.forEach((i: any) => {
      if(((doc.getStringUnitWidth(titleLines[0].join(',')) * doc.internal.getFontSize()) + (doc.getStringUnitWidth(i.toString()) * doc.internal.getFontSize())) < lineSize) {
        titleLines[0].push(i)
      } else {
        titleLines[1].push(i)
      }
    });

    let nextLine = 0;

    doc.text(titleLines[0].join(','), 20, 40, null, 0);
    if(titleLines[1].length > 0) {
      nextLine = 15;
      doc.text(titleLines[1].join(',').trim(), 20, 40 + nextLine, null, 0);
    }
    doc.text(`UCs: ${data.length} | Volumes: ${volumes}`, 20, 55 + nextLine, null, 0);
    doc.text('Transportadora: ', 20, 70 + nextLine, null, 0).setFontStyle('normal');
    doc.text(`${carrier}`, 120, 70 + nextLine, null, 0);

    i = 95 + nextLine;
    doc.setFontStyle('bold');
    doc.setFontSize(10);
    doc.text('Seq.', 20, i, null, 0);
    doc.text('Início bip.', 55, i, null, 0);
    doc.text('Final bip.', 140, i, null, 0);
    doc.text('Pallet ID', 225, i, null, 0);
    doc.text('Usuário', 290, i, null, 0);
    doc.text('Box v.', 480, i, null, 0)
    doc.text('Vol.', 535, i, null, 0);
    i = i + 4
    doc.line(20, i, 570, i);
    i = i + 10;

    // let volume = 0;
    data.forEach(item => {
      doc.setFontStyle('normal');
      doc.setFontSize(10);
      doc.text(item.row_number.toString(), 20, i, null, 0);
      const start = item.start_at ? this.datePipe.transform(item.start_at, 'dd/M/Y HH:mm') : '-';
      doc.text(start, 55, i, null, 0);
      const end = item.end_at ? this.datePipe.transform(item.end_at, 'dd/M/Y HH:mm') : '-';
      doc.text(end, 140, i, null, 0);
      doc.text(item.pallet, 225, i, null, 0);
      doc.text((item.name.length > 30 ? item.name.substring(0,30) : item.name), 290, i, null, 0);
      doc.text(item.vbox, 480, i, null, 0);
      // volume = volume + item.volume;
      doc.text(parseInt(item.volume).toString(), 570 - (doc.getStringUnitWidth(parseInt(item.volume).toString()) * doc.internal.getFontSize()), i, null, 0);
      i = i + 4
      doc.line(20, i, 570, i);
      i = i + 10;
      if(i > 800) {
        doc.addPage();
        doc.page ++;
        doc.setFontSize(8);
        pageLabel = `Página ${doc.page}`;
        doc.text(pageLabel, 570 - (doc.getStringUnitWidth(pageLabel) * doc.internal.getFontSize()), 820);
        i = 50;
      }
    });

    doc.output('dataurlnewwindow');
  }

  listVboxStr(resource: any) {
    let vbox = [];
    resource.forEach(element => {
      if(!vbox.includes(element.vbox)) {
        vbox.push(element.vbox)
      }
    });

    return vbox.toString();
  }
}
