import { CarrierService } from 'src/app/carrier/shared/carrier.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { SupportDataService } from 'src/app/shared/support-data.service';

@Component({
  selector: 'app-carrier-conference-validation-dialog',
  templateUrl: './carrier-conference-validation-dialog.component.html',
  styleUrls: ['./carrier-conference-validation-dialog.component.scss']
})
export class CarrierConferenceValidationDialogComponent implements OnInit {
  saveBtnDisabled = false;
  msgClass = 'msg-ok';
  msg = 'Quantidade divergente';
  showMsg = false;
  form = this.formBuilder.group({
    amount_confered: [null, Validators.required]
  });
  has_justification_list = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supportDataService: SupportDataService,
    private carrierService: CarrierService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CarrierConferenceValidationDialogComponent>,
  ) { }

  ngOnInit() {
    if(!this.data.item.resource.hasOwnProperty('justification_list')) {
      this.msg = "É necessário cadastrar pelo menos uma justificativa para validar a conferência.";
      this.showMsg = true;

    } else {
      if(!(this.data.item.resource.justification_list.length > 0)) {
        this.msg = "É necessário cadastrar pelo menos uma justificativa para validar a conferência.";
        this.showMsg = true;
      } else {
        this.has_justification_list = true;
      }
    }
    // this.getJustifyList();
  }

  validate() {
    this.saveBtnDisabled = true;
    const data = {
      id: this.data.item.id,
      amount_confered: this.form.value.amount_confered
    };
    console.log('validate');
    console.log(data);
    this.carrierService.updateConferenceValidation(data).subscribe((r: any) => {
      if(r.match) {
        this.showMsg = true;
        this.msg = r.msg;
        this.msgClass = 'msg-ok';
        setTimeout(()=>{
          this.showMsg = false;
          this.dialogRef.close(r);
        }, 1000);
      } else {
        this.saveBtnDisabled = false;
        this.form.patchValue({amount_confered: null});
        this.showMsg = true;
        this.msg = r.msg;
        this.msgClass = 'msg-error';
        setTimeout(()=>{
          this.showMsg = false;
        }, 3000);
      }
    });
  }
  close() {
    const options = {
      show_justify: !this.has_justification_list
    }
    this.dialogRef.close(options);
  }
}
