import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../user';
import { AccountService } from '../../account/shared/account.service';
import { Account } from '../../account/account';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private accountService: AccountService
  ) { }

  index(order: string = 'created_at', sort: string = 'desc', page: number = 1, search: string = ''): Observable<User> {
    const httpParams = {
      page: page.toString(),
      orderBy: order,
      sortedBy: sort
    };

    search = search.trim();

    if (search) {
      Object.assign(httpParams, {search: search});
    }

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/users`;

    return this.http.get<User>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, id: string, params?: any): Observable<User> {
    const url = `${environment.apiUrl}/users/${id}`;
    const options = {params: params};

    return this.http.put<User>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      tap((response: User) => {
        this.accountService.identity().then((account: Account) => {
          if (response.id === account.id) {
            this.accountService.identity(true).then((renewed: Account) => {
              localStorage.setItem('account', JSON.stringify(renewed));
              this.accountService.event.emit({action: 'update', data: renewed});
            });
          }
        });
      }),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  create(data: any, params?: any): Observable<User> {
    const url = `${environment.apiUrl}/users`;
    const options = {params: params};

    return this.http.post<User>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/users/${id}`;
    const options = {params: params};

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  pageModules(options?: any): Observable<any> {

    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/modules`;

    return this.http.get<User>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  createGroups(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/user-groups`;
    const options = { params: params };
    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  updateGroups(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/user-groups`;
    const options = { params: params };

    return this.http.put<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  deleteGroups(id: string|number, params?: any){
    const url = `${environment.apiUrl}/user-groups/${id}`;
    const options = { params: params };


    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  userGroups(options?: any): Observable<any> {

    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/user-groups`;

    return this.http.get<User>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }
}
