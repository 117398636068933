<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ (form.value.first_name) ? form.value.first_name + ' ' + ((form.value.last_name) ? form.value.last_name : '') : 'Editar' }}
  </h2>
  <mat-dialog-content class="mat-typography">

    <div class="tkg-form-row">
      <mat-form-field class="tkg-form-item-100">
        <input type="text" formControlName="first_name" matInput placeholder="Nome">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.first_name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="tkg-form-item-100">
        <input type="text" formControlName="last_name" matInput placeholder="Sobrenome">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.last_name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="tkg-form-item-100">
        <input type="email" formControlName="email" matInput placeholder="E-mail">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.email">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="tkg-form-row">
      <div class="tkg-form-item-100">
        <mat-slide-toggle (click)="togglePassword()">Alterar senha</mat-slide-toggle>
      </div>
    </div>

    <div class="tkg-form-row" *ngIf="form.get('password').enabled">
      <mat-form-field class="tkg-form-item-100">
        <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" matInput placeholder="Senha">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.password">{{ error }}</div>
        </mat-hint>
        <mat-icon matSuffix style="cursor: pointer;" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>
      Fechar
    </button>
    <button type="submit" mat-button color="primary" cdkFocusInitial [disabled]="!form.valid">Salvar</button>
  </mat-dialog-actions>
</form>
