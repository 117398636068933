<br/>
<div class="top-bar-filters">
  <div class="tkg-form-row">

    <!-- Type Spaces -->
    <!-- <div fxFlex="25%" fxFlex.xs="calc(50%-25px)">
      <mat-form-field appearance="outline">
        <mat-label>Tipo</mat-label>
        <mat-select [formControl]="filterTypeSelected" (selectionChange)="loadDocks()">
          <mat-option [value]="">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let item of typeSpaces" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->

    <!-- Status -->
    <div class="tkg-form-item-25">
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select (selectionChange)="filterStatus($event.value)">
          <mat-option [value]="" (click)="loadDocks()">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option value="true">
            Ocupadas
          </mat-option>
          <mat-option value="false">
            Desocupadas
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="tkg-form-item-25">
      <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter">
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon matSuffix (click)="clearFilter($event)" matTooltip="Limpar pesquisa" ngClass="app-search-clear"
          *ngIf="searchFilter.value">clear</mat-icon>
      </mat-form-field>
    </div>

    <!-- Actions -->
    <div fxFlex="3%" fxFlex.xs="calc(50%-3px)">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="salvar('ocupar')">
          Ocupar vaga
        </button>
        <button mat-menu-item (click)="salvar('liberar')">
          Desocupar vaga
        </button>
        <!-- <button mat-menu-item (click)="salvar('desativar')">
          Colocar vaga para manutenção
        </button>
        <button mat-menu-item (click)="salvar('ativar')">
          Retirar vaga da manutenção
        </button> -->
        </mat-menu>
    </div>
  </div>
</div>

<table mat-table [dataSource]="docas" class="mat-elevation-z8">

  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
      [checked]="selection.hasValue() && isAllSelected()"
      [indeterminate]="selection.hasValue() && !isAllSelected()"
      [aria-label]="checkboxLabel()">
    </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row.name)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Dock Column -->
  <ng-container matColumnDef="dock">
    <th mat-header-cell *matHeaderCellDef> Doca </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    <td mat-footer-cell *matFooterCellDef> </td>
  </ng-container>

  <!-- busy Column -->
  <ng-container matColumnDef="busy">
    <th mat-header-cell *matHeaderCellDef> Ocupada </th>
    <td mat-cell *matCellDef="let element"> {{element.busy === true? 'Sim' : 'Não'}} </td>
    <td mat-footer-cell *matFooterCellDef> </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      (click)="selection.toggle(row)">
  </tr>
</table>
