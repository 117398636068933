import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Step } from '../step';

@Injectable({
  providedIn: 'root'
})
export class StepService {

  constructor(
    private http: HttpClient
  ) { }

  index(options?: any): Observable<Step> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/steps`;

    return this.http.get<Step>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, id: string, params?: any): Observable<Step> {
    const url = `${environment.apiUrl}/steps/${id}`;
    const options = {params: params};

    return this.http.put<Step>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  create(data: any, params?: any): Observable<Step> {
    const url = `${environment.apiUrl}/steps`;
    const options = {params: params};

    return this.http.post<Step>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/steps/${id}`;
    const options = {params: params};

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }
}
