import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { ShiftsDialogAction, months, weekDay } from '../../shift';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import * as moment from 'moment';
import { Observable, Subject, merge, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ShiftService } from '../../shared/shift.service';
import { ShiftBreakDialogComponent } from '../../shift-break-dialog/shift-break-dialog.component';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-shifts-shifts-tab',
  templateUrl: './shifts-shifts-tab.component.html',
  styleUrls: ['./shifts-shifts-tab.component.scss']
})
export class ShiftsShiftsTabComponent implements OnInit {
  @Input() activatedTab:boolean = false;

  @ViewChild('sort', {static:true}) sort: MatSort;
  @ViewChild('paginator', {static:true}) paginator: MatPaginator;
  @ViewChild('tabLabel', {static: true}) tabLabel:ElementRef<any>;

  pageSize = 10;
  months = months;
  trashed = false;
  actionEnum = ShiftsDialogAction;
  shiftSubject$ = new Subject<any>();
  years = Array.from({length: (moment().year() + 1) - 2021}, (v, k) => 2022+k);

  shiftBreakColumns=[
    ["Nome","name", true],
    ["Dias da semana", "weekdayNamely", false],
    ["Turno", "shift_name", false],
    ["Início","start", true],
    ["Termino","end", true],
    ["Duração","duration", true],
    ["Data de exceção","date_exception",true]
  ];

  shift: Record<string, any> = {
    name:"shift",
    columns:this.shiftBreakColumns.map(item=>({
      key:item[1],
      name:item[0],
      sort:item[2]
    })),
    displayedColumns:[...this.shiftBreakColumns.map(item=>item[1]),'actions'],
    dataSource:[]
  };

  filterGroup = new FormGroup({
    date_exception_year: new FormControl(moment().year()),
    date_exception_month: new FormControl(moment().month() + 1),
  });

  constructor(
    private dialog: MatDialog,
    private shiftService:ShiftService,
    public breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  ngOnInit(): void {
    this.initTable()
    .subscribe((data)=>{
      this.shift.dataSource = data;
    })
  }

  ontrashed(){
    this.trashed = !this.trashed;
    this.shiftSubject$.next();
  }

  open(action: ShiftsDialogAction, element?:any){

      return this.dialog.open(ShiftBreakDialogComponent, {
        data:{
          action,
          data: element,
          type: this.shift.name
        },
        maxWidth:540,
        minWidth:320,
        panelClass: "dialog-100-per"
      })
      .beforeClosed()
      .subscribe((reload:boolean)=>{
        reload && this.shiftSubject$.next();
      })

  }

  private destroy(element:any){
    const message = `Deseja excluir o item "${element.resource.name}"?`;
    const title = "Exclusão de turno.";

    this.dialog.open(DialogConfirmComponent, {
      data:{
        title,
        message
      }
    })
    .beforeClosed()
    .subscribe((destroy:boolean)=>{
      destroy && this.shiftService.destroy(element.id).subscribe(()=>this.shiftSubject$.next())
    })

  }

  private initTable():Observable<any>{
    this.sort.sort(<MatSortable>{
        id: 'name',
        start: 'asc'
      }
    );

    this.sort.sortChange.subscribe(() =>this.paginator.pageIndex = 0);

    return  merge(...[this.sort.sortChange, this.paginator.page, this.shiftSubject$].filter(i=>i))
      .pipe(
        startWith(''),
        switchMap(() => {
          this.shift.dataSource = [];

          const options:any = {
            trashed: this.trashed,
            page: this.paginator.pageIndex + 1,
            orderBy: this.sort.active || 'created_at',
            sortedBy: this.sort.direction || 'desc',
            date_exception_year: this.filterGroup.get("date_exception_year")?.value || '',
            date_exception_month: this.filterGroup.get("date_exception_month")?.value || '',
            name: this.shift.name
          };

          return this.shiftService.index(options);
        }),

        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return of([]);
        })
      ).pipe(map((data:any[])=>{
        if(data){
          data.forEach((item)=>{
            if(item.resource.weekday)
              item.resource.weekdayNamely = item.resource.weekday
                .sort()
                .map((indice:number)=>weekDay[indice])
                .join(", ");

          })
        }
        return data;
      }))
  }

  private activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.shift.displayedColumns = ["name", "actions"];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.shift.displayedColumns = ["name", "start", "end", "actions"];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.shift.displayedColumns = [
        "name","weekdayNamely","shift_name", "start","end","duration","date_exception", "actions"
      ];
    }
  }

}
