import { MediaMatcher } from "@angular/cdk/layout";
import { BoxFilterService } from "./shared/box-filter.service";
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
// import { ObservableMedia } from '@angular/flex-layout';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { environment } from "../environments/environment";
import { Title } from "@angular/platform-browser";
import { filter } from "rxjs/operators";
import { AccountDetailComponent } from "./account/account-detail/account-detail.component";
// import { ExportDataComponent } from './shared/export-data/export-data.component';
import { AccountService } from "./account/shared/account.service";
import { Account } from "./account/account";
import { SearchService } from "./shared/search.service";
import { Subscription } from "rxjs";
import { LoaderState } from "./core/loader-state";
import { LoaderService } from "./core/loader.service";
import { RefreshService } from "./shared/refresh.service";
import { NgxPermissionsService } from "ngx-permissions";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  @HostBinding("class.auth") isAuth: boolean;
  @HostBinding("class.admin") isAdmin: boolean;
  appTitle: string;
  headerTitle: string;
  showLogo = true;
  account: Account;
  searchShow: boolean;
  refreshEnabled: boolean;
  searchWatcher: Subscription;
  searchEnabled: boolean;
  filterEnabled = false;
  loaderWatcher: Subscription;
  filterWatcher: Subscription;
  filterCount: String = "filter_none";
  loading: boolean;
  routeData: any;
  export: boolean;
  sideNavOpened: boolean;
  matcher!: MediaQueryList;
  menuList: any[] = [];
  mediaQueryListener: () => void;
  displaySearch = false;
  constructor(
    // public media: ObservableMedia,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private platformTitle: Title,
    private renderer: Renderer2,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private searchService: SearchService,
    private boxFilterService: BoxFilterService,
    private loaderService: LoaderService,
    private refreshService: RefreshService,
    private translate: TranslateService,
    private permissionsService: NgxPermissionsService,
    changeDetectorRef: ChangeDetectorRef,
    public mediaMatcher: MediaMatcher,
  ) {
    this.appTitle = environment.appTitle;
    translate.setDefaultLang("pt-BR");

    this.matcher = mediaMatcher.matchMedia("(min-width: 992px)");
    this.mediaQueryListener = () => {
      changeDetectorRef.detectChanges();
      console.log("Match?: ", this.matcher.matches);
      if (this.matcher.matches) {
        console.log("mostra logo");
        this.showLogo = true;
      } else {
        console.log("nao mostra logo");
        this.showLogo = false;
      }
    };
    this.matcher.addListener(this.mediaQueryListener);
  }

  ngOnDestroy() {
    // this.matcher.removeEventListener('change', this.mediaListener);
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  openSearch(event) {
    this.displaySearch = true;
    this.searchService.onOpen();
  }

  openFilter(event) {
    this.boxFilterService.onOpen();
  }

  onRefresh(event?) {
    this.refreshService.onRefresh(event);
  }

  accountDetail(event) {
    const dialogRef = this.dialog.open(AccountDetailComponent, {
      panelClass: ["dialog-small", "dialog-fullscreen"],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.snackBar.open("Conta atualizada com sucesso!", "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
    });
  }

  exportDetails() {
    console.log("exporta");
    // const dialogExport = this.dialog.open(ExportDataComponent, {
    //   disableClose: true,
    //   data: {
    //     titlePage: this.appTitle,
    //     routeData: this.routeData,
    //     page: this.router.url
    //   }
    // });

    // dialogExport.afterClosed().subscribe(data => {
    //   if (data) {
    //     this.snackBar.open('Arquivo exportado com sucesso', 'OK', {
    //       duration: 3000,
    //       horizontalPosition: 'left',
    //       verticalPosition: 'bottom',
    //     });
    //   }
    // });
  }

  getDataRoute() {
    const data = [];
    const activeRoutes: ActivatedRoute[] = this.route.children;
    activeRoutes.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      data.push(activeRoute.snapshot.data);
    });

    return data.find((value: any) => {
      return value.title;
    });
  }

  logout() {
    // this.accountService.logout().subscribe(data => { });
    this.accountService.logout();
    this.router.navigate(["auth/login"]);
  }

  /**
   * Para utilizar o conceito de Gestão de Grupos do Maestro alguns metodos serão
   * comentados ou refatorados.
   * loadPermissions comentado
   */
  loadPermissions() {
    /*if (this.accountService.isAuthenticated()) {
      this.accountService.show({ 'with': 'roles.permissions;permissions' }).subscribe((account: any) => {
        const permissions = [];

        if (account.is_superuser) {
          permissions.push('superuser');
        } else {
          account.roles.forEach(function (role) {
            role.permissions.forEach(function (permission) {
              if (permissions.findIndex(item => item === permission.name) === -1) {
                permissions.push(permission.name);
              }
            });
          });

          account.permissions.forEach(function (permission) {
            if (permissions.findIndex(item => item === permission.name) === -1) {
              permissions.push(permission.name);
            }
          });
        }

        this.permissionsService.loadPermissions(permissions);
      });
    }*/
  }

  ngOnInit() {
    this.headerTitle = environment.appTitle;
    this.accountService.identity().then((identity) => {
      if (identity) {
        this.account = identity;
        this.menuList = this.account.menu.filter(
          (i: any) => i.resource.showOnMenu === true,
        );
      }
    });
    // this.loadPermissions();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.searchService.onClose();
        this.searchService.onClear();
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isAuth = this.router.isActive("auth", false);
        this.isAdmin = this.router.isActive("admin", false);

        if (window.matchMedia("screen and (max-width: 959px)").matches) {
          this.sidenav.close();
        }

        this.routeData = this.getDataRoute();
        this.export =
          typeof this.routeData.export !== "undefined" &&
          this.routeData.export.show;

        if (this.routeData) {
          this.platformTitle.setTitle(
            `${this.routeData.title} | ${environment.appTitle}`,
          );

          this.searchEnabled = this.routeData.search;
          this.filterEnabled = this.routeData.filter;
          this.refreshEnabled = this.routeData.refresh;

          if (this.routeData.title.toLowerCase() === "dashboard") {
            this.appTitle = "Dashboard";
          } else {
            this.appTitle = this.routeData.title;
          }
        }
      });

    // Account events
    this.accountService.event.subscribe((event) => {
      if (event.action === "login") {
        this.accountService.identity().then((identity) => {
          this.account = identity;
          this.menuList = this.account.menu.filter(
            (i: any) => i.resource.showOnMenu === true,
          );

          // this.loadPermissions();
          this.router.navigate([""]);
        });
      } else if (event.action === "logout") {
        this.router.navigate(["auth/login"]);
      } else if (event.action === "update") {
        this.account = event.data;
      } else if (event.action === "create") {
        this.accountService
          .login({
            email: event.data.email,
            password: event.data.password,
          })
          .subscribe();
      } else if (event.action === "password_reset") {
        this.accountService
          .login({
            email: event.data.email,
            password: event.data.password,
          })
          .subscribe();
      }
    });

    // Search events
    this.searchWatcher = this.searchService.show.subscribe((show: boolean) => {
      this.searchShow = show;
      this.displaySearch = show;
    });

    // Loader events
    this.loaderWatcher = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => setTimeout(() => (this.loading = state.show), 0),
    );

    // Filter events
    this.filterWatcher = this.boxFilterService.value.subscribe((ft: any) => {
      if (parseInt(ft.value) > 0 && parseInt(ft.value) < 10) {
        this.filterCount = `filter_${ft.value}`;
      } else if (parseInt(ft.value) > 9) {
        this.filterCount = `filter_9_plus`;
      } else {
        this.filterCount = `filter_none`;
      }
    });
  }

  go2Route(route) {
    this.router.navigate(route.split(","));
    this.sideNavOpened = false;
  }
}
