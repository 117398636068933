import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { ManifestRoutingModule } from '../manifest/manifest-routing.module';
import { ManifestListComponent } from '../manifest/manifest-list/manifest-list.component';
import { ManifestDetailComponent } from '../manifest/manifest-detail/manifest-detail.component';
import { ManifestService } from '../manifest/shared/manifest.service';
import { LoadsDialogComponent } from './loads-dialog/loads-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        ManifestRoutingModule,
    ],
    declarations: [
        ManifestListComponent,
        ManifestDetailComponent,
        LoadsDialogComponent,
    ],
    providers: [
        ManifestService,
    ]
})
export class ManifestModule { }
