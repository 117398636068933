import { Component, Inject, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
})
export class ExportDataComponent {
  form = this.formBuilder.group({
    startDate: [null, Validators.required],
    endDate: [null, Validators.required]
  });
  helper: JwtHelperService = new JwtHelperService();
  format: any = 'yyyy-MM-dd';
  errors: any = {};
  hidePassword = true;
  serviceExport;

  constructor(
    public dialogRef: MatDialogRef<ExportDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private datePipeCommon: DatePipe,
  ) { }

  onSubmit(): void {
    this.downloadFile();
  }

  getParams(): any {
    const startDate = this.datePipeCommon.transform(this.form.value.startDate, this.format);
    const endDate = this.datePipeCommon.transform(this.form.value.endDate, this.format);
    const paramsObject = {
      startDate,
      endDate,
      'type': 'csv',
      'token': this.getTokenId()
    };

    return new HttpParams({fromObject: paramsObject}).toString();
  }

  downloadFile(): void {
    window.open(`${this.data.routeData.export.url}?${this.getParams()}`, '_blank' );
    this.dialogRef.close(this.data);
  }

  getTokenId() {
    return this.helper.decodeToken(localStorage.getItem('access_token')).jti;
  }

  ngDestroy(): void {
    this.serviceExport.unsubscribe();
  }
}
