import { NgModule } from '@angular/core';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';

import { CoreModule } from '../core/core.module';
import { WaitModule } from '../wait/wait.module';
import { SharedModule } from '../shared/shared.module';
import { ShiftsRoutingModule } from './shifts-routing.module';
import { ShiftsListComponent } from './shifts-list/shifts-list.component';
import { DowntimeDialogComponent } from './downtime-dialog/downtime-dialog.component';
import { ShiftBreakDialogComponent } from './shift-break-dialog/shift-break-dialog.component';
import { ShiftsBreakTabComponent } from './shifts-list/shifts-break-tab/shifts-break-tab.component';
import { ShiftsShiftsTabComponent } from './shifts-list/shifts-shifts-tab/shifts-shifts-tab.component';
import { ShiftsRecordsTabComponent } from './shifts-list/shifts-records-tab/shifts-records-tab.component';
import { ShiftsDowntimeTabComponent } from './shifts-list/shifts-downtime-tab/shifts-downtime-tab.component';
import { ShiftRecordDialogComponent } from './shift-record-dialog/shift-record-dialog.component';

@NgModule({
  imports: [
    CoreModule,
    WaitModule,
    SharedModule,
    NgxMatMomentModule,
    ShiftsRoutingModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule
  ],
  declarations: [
    ShiftsListComponent,
    DowntimeDialogComponent,
    ShiftsBreakTabComponent,
    ShiftsShiftsTabComponent,
    ShiftBreakDialogComponent,
    ShiftsRecordsTabComponent,
    ShiftsDowntimeTabComponent,
    ShiftRecordDialogComponent
  ],
  providers: [],
  entryComponents: [
    DowntimeDialogComponent,
    ShiftBreakDialogComponent
  ],
})
export class ShiftsModule { }
