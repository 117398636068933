import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SupportDataService } from 'src/app/shared/support-data.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { StepsListDialogComponent } from '../steps-list-dialog/steps-list-dialog.component';

@Component({
  selector: 'app-steps-list',
  templateUrl: './steps-list.component.html',
  styleUrls: ['./steps-list.component.scss']
})
export class StepsListComponent implements OnInit {
  dataSource: any[] = [];
  pageParam: string = 'steps-list';

  constructor(
    private supportDataService: SupportDataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,

  ) { }


  drop(event: CdkDragDrop<string[]>) {
    console.log(event.previousIndex)
    console.log(event.currentIndex)
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
    console.log(this.dataSource);
    console.log(this.adjustOrder(this.dataSource));
    this.updateList();
  }

  adjustOrder(list: any) {
    let newList: any[] = [];
    // const aList = JSON.parse(JSON.stringify(list));
    list.forEach((item: any, idx: number) => {
      item.order = idx;
      newList.push(item);
    });
    return newList;
  }

  ngOnInit(): void {

    this.getDataSource();
  }

  getDataSource() {
    this.supportDataService.resourceName(this.pageParam).subscribe((r: any) => {
      this.dataSource = r.data.resource.filter((ft: any) => ft.active === true);
    });
  }

  addEdit(event, action: string, item: any = {}) {
    const dialogRef = this.dialog.open(StepsListDialogComponent, {
      panelClass: ['dialog-small', 'dialog-fullscreen'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        list_name: this.pageParam,
        action: action
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (action === 'EDIT') {
          this.snackBar.open('Registro atualizado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
          this.dataSource = data.resource.filter((ft: any) => ft.active === true);
        } else if (action === 'ADD') {
          // If has previous page navigate to first page or reload in self page.
          this.dataSource = data.resource.filter((ft: any) => ft.active === true);
          // (this.dataSource.paginator.hasPreviousPage()) ? this.dataSource.paginator.firstPage() : this.getJustifications();
          this.snackBar.open('Registro criado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  delete(event, data) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirma a exclusão?',
        message: `Tem certeza que deseja excluir o item ${data.name}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const item = {
          list_name: this.pageParam,
          id: data.id
        }

        this.supportDataService.deleteItemOnList(item).subscribe((r: any) => {
          this.dataSource = r.data.resource.filter((ft: any) => ft.active === true);
          this.snackBar.open('Item removido com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        });
      }
    });
  }

  updateList() {
    const options = {
      list_name: this.pageParam,
      resource: this.dataSource
    }
    this.supportDataService.updateList(options).subscribe((r: any) => {
      console.log(r);
    });
  }

}
