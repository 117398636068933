
<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" *ngIf="!showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="carrier_name">
      <th mat-header-cell *matHeaderCellDef> Transportadora </th>
      <td mat-cell *matCellDef="let element"> {{element.carrier_name}} </td>
    </ng-container>

    <ng-container matColumnDef="conf_user">
      <th mat-header-cell *matHeaderCellDef> Conferente </th>
      <td mat-cell *matCellDef="let element"> {{element.conf_user}} </td>
    </ng-container>

    <ng-container matColumnDef="pallet">
      <th mat-header-cell *matHeaderCellDef> UC </th>
      <td mat-cell *matCellDef="let element"> {{element.pallet}} </td>
    </ng-container>

    <ng-container matColumnDef="box">
      <th mat-header-cell *matHeaderCellDef> Box </th>
      <td mat-cell *matCellDef="let element"> {{element.box}} </td>
    </ng-container>

    <ng-container matColumnDef="vbox">
      <th mat-header-cell *matHeaderCellDef> Vbox </th>
      <td mat-cell *matCellDef="let element"> {{element.vbox}} </td>
    </ng-container>

    <ng-container matColumnDef="updated_at">
      <th mat-header-cell *matHeaderCellDef> Horário </th>
      <td mat-cell *matCellDef="let element"> {{element.updated_at | date:'short'}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="validate(element)">
            <mat-icon>assignment_turned_in</mat-icon>
            <span>Validar</span>
          </button>
          <button mat-menu-item (click)="viewDetails(element)">
            <mat-icon>visibility</mat-icon>
            <span>Detalhes</span>
          </button>
          <button mat-menu-item (click)="deleteConferenceItem(element.id)" *ngIf="account.group_id == 5">
            <mat-icon>delete</mat-icon>
            <span>Apagar</span>
          </button>
          <button mat-menu-item (click)="justifyAnalisis(element)">
            <mat-icon>list</mat-icon>
            <span>Justificativas</span>
          </button>
        </mat-menu>

        <!-- <button mat-icon-button matTooltip="Validar" (click)="validate(element.id)">
          <mat-icon>assignment_turned_in</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Detalhes" (click)="viewDetails(element)">
          <mat-icon>visibility</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Apagar" (click)="deleteConferenceItem(element.id)" *ngIf="account.group_id == 5">
          <mat-icon>delete</mat-icon>
        </button> -->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>
