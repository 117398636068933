<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="box">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Box </th>
    <td mat-cell *matCellDef="let element"> {{element.resource.box}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="step">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Step </th>
    <td mat-cell *matCellDef="let element"> {{element.step}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="min_start">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Min </th>
    <td mat-cell *matCellDef="let element"> {{element.min_start}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
