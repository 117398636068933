import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SupportDataService {

  constructor(
    private http: HttpClient
  ) { }


  dockSpaces() {
    const url = `${environment.apiUrl}/support-data?name=dock_space`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  getDockSpaces(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/dock-space-all`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  updateDock(data: any, id?: string, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/setting-dock-space`;
    const options = { params: params };

    return this.http.put<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }


  resourceName(name: string) {
    const url = `${environment.apiUrl}/support-data?name=${name}`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  addNewItem2List(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/addNewItem2List`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  editItemOnList(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/editItemOnList`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  deleteItemOnList(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/deleteItemOnList`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  deleteItemOnObject(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/deleteItemOnObject`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  updateList(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/update-list`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  updateObject(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/update-object`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

}
