import { Component, Inject, OnInit } from '@angular/core';
import { WaitService } from '../shared/wait.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { MaskUtil } from '../../shared/util/mask.util';
import { Dock } from '../../dock/dock';
import { DockService } from '../../dock/shared/dock.service';
import { Box } from '../../dock/box';
import { BoxService } from '../../dock/shared/box.service';

@Component({
  selector: 'app-wait-detail',
  templateUrl: './wait-detail.component.html',
  styleUrls: ['./wait-detail.component.scss']
})
export class WaitDetailComponent implements OnInit {
  form = this.formBuilder.group({
    dock_id: [this.data.item.dock_id, [Validators.required]],
    box_id: [this.data.item.box_id, [Validators.required]],
  });
  docks: Dock[] = [];
  boxes: Box[] = [];
  errors: any = {};
  maskUtil = MaskUtil;

  constructor(
    public dialogRef: MatDialogRef<WaitDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private waitService: WaitService,
    private dockService: DockService,
    private boxService: BoxService
  ) { }

  onSubmit() {
    if (this.data.action === 'EDIT') {
      this.waitService.update(this.form.value, this.data.item.id)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    } else if (this.data.action === 'ADD') {
      this.waitService.create(this.form.value)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    }
  }

  getDocks(search?: string) {
    const options = (search) ? {search: search} : undefined;
    this.dockService.index(options).subscribe((response: Response | any) => {
      this.docks = response.data;
    });
  }

  getBoxes(search?: string) {
    const options = (search) ? {search: search} : undefined;
    this.boxService.index(options).subscribe((response: Response | any) => {
      this.boxes = response.data;
    });
  }

  ngOnInit() {
    this.getDocks();
    this.getBoxes();
  }
}
