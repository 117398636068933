import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { JwtModule } from '@auth0/angular-jwt';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { ReportsModule } from './reports/reports.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { NewDashboardModule } from './new-dashboard/new-dashboard.module';
import { StepsModule } from './steps/steps.module';
import { UserModule } from './user/user.module';
import { PermissionModule } from './permission/permission.module';
import { AccountModule } from './account/account.module';
import { SharedModule } from './shared/shared.module';
import { CarrierModule } from './carrier/carrier.module';
import { InvoiceModule } from './invoice/invoice.module';
import { WaitModule } from './wait/wait.module';
import { DockModule } from './dock/dock.module';
import { ManifestModule } from './manifest/manifest.module';
import { ShipmentModule } from './shipment/shipment.module';
import { StatusModule } from './status/status.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { StaffModule } from './staff/staff.module';
// import { AmazingTimePickerModule } from 'amazing-time-picker';
// import { NotificationModule } from './notification/notification.module';
// import { CommentModule } from './comment/comment.module';
// import { NgxMaskModule, IConfig } from 'ngx-mask'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShiftsModule } from './shifts/shifts.module';
import { RegistrationModule } from './registration/registration.module';


export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: environment.whitelistedDomains,
      }
    }),
    CoreModule,
    SharedModule,
    AccountModule,
    DashboardModule,
    NewDashboardModule,
    StepsModule,
    UserModule,
    PermissionModule,
    CarrierModule,
    InvoiceModule,
    WaitModule,
    DockModule,
    ManifestModule,
    ShipmentModule,
    StatusModule,
    ConfigurationModule,
    StaffModule,
    // AmazingTimePickerModule,
    // NotificationModule,
    // CommentModule,
    // NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    ReportsModule,
    ShiftsModule,
    RegistrationModule
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
