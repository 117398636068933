<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-select>
    <div class="input-search" *ngIf="fe_search || be_search">
      <input matInput placeholder="Pesquisar" value="" [(ngModel)]="search" (ngModelChange)="searchString.next($event)">
      <button mat-icon-button aria-label="Clear data" [disabled]="search.length === 0" (click)="clearSearchString()">
        <mat-icon class="search-button-icon">close</mat-icon>
      </button>
    </div>
    <mat-option *ngFor="let item of list" [value]="item.id">
      {{item.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
