<!-- TODO: Resolver Busca de Lista de Grupos -->
<!-- <div class="top-bar-filters">
  <div fxLayout="row" fxFlex fxLayoutAlign="space-between" fxLayoutGap="8px">
    <div fxFlex="25%" fxFlex.xs="calc(50%-25px)">
    </div>
    <div fxFlex="25%" fxFlex.xs="calc(50%-25px)">
      <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter" (keyup)="getSearch($event)">
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon matSuffix (click)="clearFilter($event)" matTooltip="Limpar pesquisa" ngClass="app-search-clear"
          *ngIf="searchFilter.value">clear</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div> -->

<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" [hidden]="showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="ellipsis" fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px"
            fxFlex.gt-md="180px">{{element.name}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Criado em</th>
      <td mat-cell *matCellDef="let element">{{element.created_at | date}}</td>
    </ng-container>

    <ng-container matColumnDef="actions" end>
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button matTooltip="Abrir" (click)="addEdit('EDIT', element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Deletar" (click)="delete($event, element)" [disabled]="element.can_delete === false">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);"
    >
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

<button mat-mini-fab (click)="addEdit('ADD')" class="mat-fab-bottom-right" color="primary"
  *ngIf="account?.is_superuser">
  <mat-icon>add</mat-icon>
</button>
