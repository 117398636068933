import { AfterViewChecked, AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { RoleService } from '../shared/role.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { PermissionService } from '../shared/permission.service';
import { Permission } from '../permission';
import { UserService } from '../../user/shared/user.service';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { User } from '../../user/user';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-role-detail',
  templateUrl: './role-detail.component.html',
  styleUrls: ['./role-detail.component.scss']
})
export class RoleDetailComponent implements OnInit, AfterViewChecked {
  form = this.formBuilder.group({
    name: [this.data.item.name, [Validators.required]],
    guard_name: [this.data.item.guard_name || 'web', [Validators.required]],
    permissions: [(this.data.item.permissions || []).map(item => item.name)],
    users: [(this.data.item.users || []).map(item => item.id)]
  });
  errors: any = {};
  permissionsDataSource = [];
  usersDataSource = [];
  usersShowListEmpty = false;
  pageSize: any;

  @ViewChild(MatPaginator) usersPaginator: MatPaginator;
  @ViewChild(MatSort) usersSort: MatSort;
  @ViewChild(MatSelectionList) usersSelectionList: MatSelectionList;

  constructor(
    public dialogRef: MatDialogRef<RoleDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private roleService: RoleService,
    private permissionService: PermissionService,
    private ngxPermissionService: NgxPermissionsService,
    private userService: UserService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  onSubmit() {
    if (this.data.action === 'EDIT') {
      this.roleService.update(this.form.value, this.data.item.id)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: { title: err.statusText, message: err.error.message }
              });
            }
          }
        );
    } else if (this.data.action === 'ADD') {
      this.roleService.create(this.form.value)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: { title: err.statusText, message: err.error.message }
              });
            }
          }
        );
    }
  }

  isUserSelected(user: User) {
    return (<Array<number>>this.form.get('users').value).findIndex(item => item === user.id) !== -1;
  }

  onUserToggle(event) {
    const index = (<Array<number>>this.form.get('users').value).findIndex(item => item === event.option.value.id);

    if (index !== -1) {
      (<Array<number>>this.form.get('users').value).splice(index, 1);
    } else {
      (<Array<number>>this.form.get('users').value).push(event.option.value.id);
    }
  }

  isPermissionExpanded(permission) {
    return (permission.expanded) ? permission.expanded : false;
  }

  onPermissionExpandedToggle(permission) {
    permission.expanded = !permission.expanded;
  }

  isPermissionSelected(permission: Permission) {
    return (<Array<string>>this.form.get('permissions').value).findIndex(item => item === permission.name) !== -1;
  }

  onPermissionToggle(event) {
    const permission = (event.option) ? event.option.value.name : event.name;

    const index = (<Array<string>>this.form.get('permissions').value).findIndex(item => item === permission);

    if (index !== -1) {
      (<Array<string>>this.form.get('permissions').value).splice(index, 1);
    } else {
      (<Array<string>>this.form.get('permissions').value).push(permission);
    }
  }

  isPermissionIndeterminate(permissions): boolean {
    return this.isPermissionPartiallySelected(permissions);
  }

  isPermissionAllSelected(permissions): boolean {
    return permissions.every(permission =>
      this.isPermissionSelected(permission)
    );
  }

  onPermissionAllToggle(permissions) {
    if (!this.isPermissionAllSelected(permissions)) {
      permissions.forEach(function (permission) {
        if (!this.isPermissionSelected(permission)) {
          this.onPermissionToggle(permission);
        }
      }, this);
    } else {
      permissions.forEach(function (permission) {
        this.onPermissionToggle(permission);
      }, this);
    }
  }

  isPermissionPartiallySelected(permissions): boolean {
    const result = permissions.some(permission => this.isPermissionSelected(permission));
    return result && !this.isPermissionAllSelected(permissions);
  }

  ngAfterViewChecked() {
    const listItems = this.elementRef.nativeElement.querySelectorAll('.roles .mat-list-item-content') as HTMLElement[];
    listItems.forEach(listItem => {
      this.renderer.setStyle(listItem, 'padding-left', '55px');
    });
  }

  checkPermission() {
    const permissions = [
      { action: 'ADD', permission: 'roles.store' },
      { action: 'EDIT', permission: 'roles.update' },
      { action: 'VIEW', permission: 'roles.show' },
    ];

    this.permissionService.checkDialogPermission(permissions, this.data.action, this.dialogRef, this.form);
  }

  ngOnInit() {
    this.checkPermission();

    this.permissionService.index().subscribe((response: any) => {
      this.permissionsDataSource = response.data;
    });

    merge(this.usersPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.userService.index('created_at', 'desc', this.usersPaginator.pageIndex + 1);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.usersPaginator.hasPreviousPage()) {
            this.usersPaginator.previousPage();
          }

          this.usersShowListEmpty = response.data.length === 0;
          this.usersPaginator.length = response.total;
          this.usersPaginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => this.usersDataSource = data);
  }
}
