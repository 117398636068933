import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CarrierService } from "../../carrier/shared/carrier.service";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StepsService } from '../shared/steps.service';
import { SupportDataService } from '../../shared/support-data.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
@Component({
  selector: 'app-steps-list',
  templateUrl: './steps-list.component.html',
  styleUrls: ['./steps-list.component.scss']
})
export class StepsListComponent implements OnInit {
  dayStartDate = new UntypedFormControl(new Date());
  periodStartDate = new UntypedFormControl();
  periodEndDate = new UntypedFormControl();
  carriers: any;
  stepsList: any;
  showCompareLastDay = true;
  // carrierForm = new FormControl();
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  stepsFilter: UntypedFormControl = new UntypedFormControl();
  carrierFiltered = "";
  stepsListFiltered = "";
  displayedColumns: string[];
  dataSource = new MatTableDataSource<any>();
  activeTab = 0;
  ftOptions: any = {
    export: false,
    period: false,
    startDate: '',
    endDate: '',
    date: new Date().toISOString().split('T')[0],
    carrier_id: ''
  }

  constructor(
    private carrierService: CarrierService,
    private datepipe: DatePipe,
    private snackBar: MatSnackBar,
    private stepsService: StepsService,
    private supportData: SupportDataService,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Output() selectedTabChange: EventEmitter<MatTabChangeEvent>
  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.getCarriers();
    this.getStepsReport(this.ftOptions);
    this.getStepsList();
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      console.log('smart phone');
      this.router.navigate(['admin/mobile-error']);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.router.navigate(['admin/mobile-error']);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['box_fisico', 'transportadora', 'criado_em','finalizado_em', 'vbox', 'waves', 'client_name', 'count', 'volumes', 'etapas', 'tempo_medio', 'justificativas','box_id'];
    }
  }

  getStepsList() {
    this.supportData.resourceName('steps-list').subscribe((r: any) => {
      this.stepsList = r.data.resource;
    });
  }

  changeTab(event: any) {
    this.activeTab = event.index;
  }

  getStepsReport(options?) {
    this.stepsService.steps(options).subscribe((r: any) => {
      this.dataSource.data = r.data;
    })
  }


  dayFilter() {
    console.log('day filter')
    this.ftOptions.startDate = '';
    this.ftOptions.endDate = '';
    this.ftOptions.period = false;
    this.ftOptions.date = this.datepipe.transform(this.dayStartDate.value, 'yyyy-MM-dd');
    this.getStepsReport(this.ftOptions);
  }

  periodFilter() {
    console.log('period filter');
    // const periodDates = {
    //   period: true,
    //   startDate: this.datepipe.transform(this.periodStartDate.value, 'yyyy-MM-dd'),
    //   endDate: this.datepipe.transform(this.periodEndDate.value, 'yyyy-MM-dd'),
    //   date: ''
    // }
    this.ftOptions.period = true;
    this.ftOptions.startDate = this.datepipe.transform(this.periodStartDate.value, 'yyyy-MM-dd');
    this.ftOptions.endDate = this.datepipe.transform(this.periodEndDate.value, 'yyyy-MM-dd');
    this.ftOptions.date = '';
    if (!this.periodStartDate.value && !this.periodEndDate.value) {
      this.snackBar.open('Escolha a data inicial e final para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else if (!this.periodStartDate.value) {
      this.snackBar.open('Escolha a data inicial para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else if (!this.periodEndDate.value) {
      this.snackBar.open('Escolha a data final para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else {
      console.log('tudo ok')
      this.getStepsReport(this.ftOptions);
    }
  }

  changeFilter(e: any) {
    const obj = {
      'period': false,
      'day': true
    }
    this.showCompareLastDay = obj[e.value];
  }

  getCarriers() {
    const options = {
      pageSize: 1000,
      orderBy: "name",
      sortedBy: "ASC",
    };
    this.carrierService.index(options).subscribe((r: any) => {
      this.carriers = r.data;
    });
  }

  cleanFilter(filter) {
    // this.form.patchValue({ filter: null });
    // this.refreshCharts();
  }

  getFilterCarrier() {
    this.carrierFiltered = this.carrierFilter.value.toString();
    this.ftOptions.carrier_id = this.carrierFiltered ? this.carrierFiltered : '';
  }

  getFilterStepsList() {
    this.stepsListFiltered = this.stepsFilter.value.toString();
    this.ftOptions.steps = this.stepsListFiltered ? this.stepsListFiltered : '';
  }

  downloadReport(): void {
    console.log('clicou');
    this.ftOptions.export = true;
    this.stepsService.steps(this.ftOptions).subscribe((r: any) => {
      this.createFileFromBlob(r.csv, 'etapas.csv');
      this.ftOptions.export = false;
    });
  }

  createFileFromBlob(file, fileName) {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    const blob = new Blob(['\uFEFF' + file], { type: 'text/csv; charset=utf-18' });
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }
}
