import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportListComponent } from './report-list/report-list.component';
import { StepsTimeComponent } from './steps-time/steps-time.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { environment } from 'src/environments/environment';

const reportsRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'reports',
        component: ReportListComponent,
        data: {
          title: 'Relatórios',
          search: false,
          filter: false,
          refresh: false,
          export: {
            show: false,
            url: ``,
          },
        },
      },
      {
        path: 'report-step-time',
        component: StepsTimeComponent,
        data: {
          title: 'Tempo entre Etapas',
          search: true,
          refresh: false,
          export: {
            show: false,
            url: ``,
          },
        },
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(reportsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ReportsRoutingModule { }
