import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Loading } from '../loading';

@Injectable({
  providedIn: 'root'
})
export class ShipmentLoadingService {

  constructor(
    private http: HttpClient
  ) { }

  index(shipment: string|number, options?: any): Observable<Loading> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/shipments/${shipment}/loadings`;

    return this.http.get<Loading>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, shipment: string|number, id: string, params?: any): Observable<Loading> {
    const url = `${environment.apiUrl}/shipments/${shipment}/loadings/${id}`;
    const options = {params: params};

    return this.http.put<Loading>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  create(shipment: string|number, data?: any, params?: any): Observable<Loading> {
    const url = `${environment.apiUrl}/shipments/${shipment}/loadings`;
    const options = {params: params};

    return this.http.post<Loading>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(shipment: string|number, id: string, params?: any) {
    const url = `${environment.apiUrl}/shipments/${shipment}/loadings/${id}`;
    const options = {params: params};

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }
}
