<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" [hidden]="showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container
      matColumnDef="carrier_carriers:carrier_id|carrier_carriers.name"
    >
      <th mat-header-cell *matHeaderCellDef class="aumentar">Transportadora</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{ element.carrier.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dock">
      <th mat-header-cell *matHeaderCellDef class="aumentar">B. Físico</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{ element.box?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="box">
      <th mat-header-cell *matHeaderCellDef class="aumentar">B. Virtual</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{ element.vbox }}
      </td>
    </ng-container>

    <ng-container matColumnDef="wave">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Onda</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{ element.wave }}
      </td>
    </ng-container>

    <ng-container
      matColumnDef="vehicle_vehicles:board_horse_id|vehicle_vehicles.board"
    >
      <th mat-header-cell *matHeaderCellDef class="aumentar">Placa</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{ element.wait.board_cart | uppercase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="volume">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Vol. Original</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{ element.load?.volume }}
      </td>
    </ng-container>

    <ng-container matColumnDef="time_end">
      <th mat-header-cell *matHeaderCellDef class="aumentar">H. Final</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{ element.created_at | date: "shortTime" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="stopwatch">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Cronômetro</th>
      <td mat-cell *matCellDef="let element" class="aumentar">

        <div *ngIf="element.finished_at">
          {{ calcTime(element.finished_at, element.created_at) }}
        </div>
        <div *ngIf="element.finished_at"></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status_invoice">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Status NF</th>
      <td
        mat-cell
        *matCellDef="let element"
        [matTooltip]="showStatusInvoice(element)"
        class="aumentar"
      >
        {{ element.load?.load_status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            matTooltip="Carga"
            (click)="showLoad($event, element)"
          >
            <mat-icon>list_alt</mat-icon>
            <span>Ver carga</span>
          </button>
          <button
            mat-menu-item
            matTooltip="Finalizar manifesto"
            (click)="onFinishManifest($event, element)"
            [disabled]="element.finished_at"
          >
            <mat-icon>check_circle_outline</mat-icon>
            <span>Finalizar</span>
          </button>
          <button mat-menu-item (click)="deleteManifest($event, element.id)">
            <mat-icon>delete</mat-icon>
            <span>Remover</span>
          </button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <!-- <button mat-icon-button matTooltip="Finalizar manifesto" (click)="onFinishManifest($event, element)" [disabled]="element.finished_at"> -->
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="
        displayedColumns;
        sticky: !breakpointObserver.isMatched(breakpoints.Handset)
      "
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
  ></mat-paginator>
</div>
