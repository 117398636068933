import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NewDashService {

  constructor(
    private http: HttpClient
  ) { }

  dinamicCharts(options?: any): Observable<any> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/dashboard/dinamic-charts`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }
}
