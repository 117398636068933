<div class="monitor-info-box">
  <h1 mat-dialog-title>Box {{data.item.resource.box}}</h1>
  <div mat-dialog-content>


    <table class="dock-monitor-info-table table table-hover">

      <tbody>
        <tr>
          <td>
            <div>Transp.: <label class="monitor-info-values color-blue">
                {{data.item.resource.carrier ? data.item.resource.carrier.name : ''}}
              </label></div>
          </td>
          <td>
            <div>Status NF:
              <label class="monitor-info-values">
                {{nfStatus()}}
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Letras Box: <label class="monitor-info-values color-blue">
                {{showBoxes()}}
              </label></div>
          </td>
          <!-- <td>
            <div>Prev. de Término: <label class="monitor-info-values"
                [ngClass]="[(data.item.step_percent && data.item.step_percent) == 100 ? 'color-green' : 'color-yellow']">-
                - - - - - - -</label></div>
          </td> -->
          <td>
            <div>Processado: <label class="monitor-info-values"
                [ngClass]="[processedPercentage == 100 ? 'color-green' : 'color-yellow']">
                {{processedPercentage}}%
              </label></div>
          </td>
        </tr>
      </tbody>
    </table>

    <mat-card class="monitor-timeline-box">
      <table class="dock-monitor-timeline-table table table-hover">
        <thead>
          <tr class="bg-blue">
            <th>Etapa</th>
            <th>Data</th>
          </tr>
        </thead>

        <tbody *ngIf="data.item.steps.length">
          <tr *ngFor="let dataLog of data.item.steps">
            <td>{{showStep(dataLog.step)}}</td>
            <td>{{dataLog.ts | date:'short'}}</td>
            <!-- <td>{{tsStep(dataLog)}}</td> -->
          </tr>
        </tbody>
      </table>
    </mat-card>



  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="" cdkFocusInitial>Fechar</button>
  </div>
</div>
