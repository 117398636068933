import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsListComponent } from './steps-list/steps-list.component';
import { StepsRoutingModule } from './steps-routing.module'
import { CoreModule } from '../core/core.module';

@NgModule({
  declarations: [StepsListComponent],
  imports: [
    CommonModule,
    CoreModule,
    StepsRoutingModule
  ]
})
export class StepsModule { }
