import { Component, OnInit } from '@angular/core';
import { CarrierService } from 'src/app/carrier/shared/carrier.service';
import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { Subject, merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, tap, takeUntil, startWith, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-steps-time',
  templateUrl: './steps-time.component.html',
  styleUrls: ['./steps-time.component.scss']
})
export class StepsTimeComponent implements OnInit {
  carriers: any;
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  protected _onDestroy = new Subject<void>();
  form = this.formBuilder.group({
    carrier_id: [''],
  });

  dayStartDate = new UntypedFormControl(new Date());
  periodStartDate = new UntypedFormControl();
  periodEndDate = new UntypedFormControl();
  options: any = {
    orderBy: '',
    carrier_id: this.form.value.carrier_id,
    sortedBy: 'asc',
    date: moment().format('YYYY-MM-DD'),
    page: 1,
    pageSize: 100,
  };
  showCompareLastDay: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private carrierService: CarrierService,
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.getCarrierList();
    this.carrierFilter.valueChanges
    .pipe(
      filter(search => !!search),
      tap(() => this.searching = true),
      takeUntil(this._onDestroy),
      startWith({}),
      debounceTime(500),
      switchMap(() => {
        const options = {};

        if (this.carrierFilter.value) {
          Object.assign(options, {
            search: this.carrierFilter.value,
          });
        }

        return this.carrierService.index(options);
      }),
      map((response: any) => response.data)
    ).subscribe(data => {
      this.carriers = data;
    });
  }

  getCarrierList() {
    this.carrierService.index().subscribe((r: any) => {
      this.carriers = r.data;
    })
  }

  dayFilter() {
    this.options.period = false
    this.options.date = this.datePipe.transform(this.dayStartDate.value, 'yyyy-MM-dd')

  }

  changeFilter(e: any) {
    const obj = {
      'period': false,
      'day': true
    }

    this.showCompareLastDay = obj[e.value];
  }

  periodFilter() {
    if (!this.periodStartDate.value && !this.periodEndDate.value) {
      this.snackBar.open('Escolha a data inicial e final para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else if (!this.periodStartDate.value) {
      this.snackBar.open('Escolha a data inicial para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else if (!this.periodEndDate.value) {
      this.snackBar.open('Escolha a data final para filtrar!', 'OK', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      });
    }
    else {
      this.options.period = true;
      this.options.date = "";
      this.options.startDate = this.datePipe.transform(this.periodStartDate.value, 'yyyy-MM-dd');
      this.options.endDate = this.datePipe.transform(this.periodEndDate.value, 'yyyy-MM-dd');


    }
  }

  getFilterCarrier() {
    this.options.carrier_id = this.form.value.carrier_id;
  }

  cleanCarrierFilter() {
    this.getCarrierList();
  }
}
