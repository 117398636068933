<h2 mat-dialog-title>Turno</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline" fxFlex="100%" style="width: 100%;">
    <mat-label>Nome</mat-label>
    <input formControlName="name" type="text" placeholder="Nome" matInput>
  </mat-form-field>
</form>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="refreshOnClose">
    {{ data.action != dialogAction.VIEW ? "Cancelar" : "Fechar" }}
  </button>
  <button
    *ngIf="data.action != dialogAction.VIEW"
    mat-button
    cdkFocusInitial
    color="primary"
    (click)="onSubmit()"
    [disabled]="!form.valid"
  >
    Salvar
  </button>
</mat-dialog-actions>
