<app-loader></app-loader>
<div class="tkg-sidenav-container">
  <mat-sidenav-container>
    <mat-sidenav class="mat-elevation-z4" [(opened)]="sideNavOpened" #sidenav>
      <mat-toolbar color="red">
        <img class="logotipo" src="assets/img/logo_maestro.svg" alt="Logotipo">
      </mat-toolbar>
      <mat-nav-list>
        <ng-container
          *ngFor="let item of menuList"
        >
          <a
            mat-list-item
            routerLinkActive="active"
            (click)="go2Route(item.resource.route)"
          >
            {{item.name}}
          </a>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>
    <div [ngClass]="{'dark-theme': searchShow}">
      <mat-toolbar color="primary" *ngIf="!isAuth" class="mat-elevation-z2">
        <button mat-icon-button (click)="sidenav.toggle()" *ngIf="!searchShow">
          <mat-icon>menu</mat-icon>
        </button>

        <div class="buttons-bar">
          <div class="buttons-bar-header">
            <div class="app-header-title" *ngIf="!searchShow">
              <span *ngIf="showLogo">
                <img src="assets/img/logo-customer.svg" alt="Logotipo"> -
              </span>
              <span>{{appTitle}}</span>
            </div>
            <app-search scope="global" class="app-search" *ngIf="displaySearch"></app-search>

            <!-- buttons -->
            <div class="buttons-bar-btn-itens">
              <button mat-icon-button (click)="openFilter($event)" matTooltip="Filtrar" *ngIf="filterEnabled">
                <mat-icon aria-label="Open search bar">{{filterCount}}</mat-icon>
              </button>
<!-- *ngIf="!searchShow" -->
                <button mat-icon-button (click)="openSearch($event)" matTooltip="Pesquisar" *ngIf="searchEnabled && !searchShow">
                  <mat-icon aria-label="Open search bar">search</mat-icon>
                </button>

                <button mat-icon-button *ngIf="export && !searchShow" (click)="exportDetails($event)" matTooltip="Export para CSV"
                  [disabled]="loading">
                  <mat-icon aria-label="Export Csv">system_update_alt</mat-icon>
                </button>

                <!-- <button mat-icon-button (click)="onRefresh($event)" matTooltip="Atualizar" *ngIf="refreshEnabled"
                  [disabled]="loading">
                  <mat-icon aria-label="Refresh">refresh</mat-icon>
                </button> -->

                <button mat-mini-fab class="app-avatar-button" color="warn" [matMenuTriggerFor]="appMenu"
                  *ngIf="account?.avatar && !searchShow" [ngStyle]="{background: 'url(' + account?.avatar?.xs + ')'}">
                </button>
                <mat-menu #appMenu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="accountDetail($event)">
                    <mat-icon>person</mat-icon>
                    Minha conta
                  </button>
                  <button (click)="logout($event)" mat-menu-item>
                    <mat-icon>power_settings_new</mat-icon>
                    <span>Logout</span>
                  </button>
                </mat-menu>
            </div>
            <!-- fim buttons -->

          </div>
        </div>
      </mat-toolbar>
    </div>
    <div id="app-content">
      <div>
        <main>
          <router-outlet></router-outlet>
        </main>
      </div>
    </div>
  </mat-sidenav-container>
</div>
