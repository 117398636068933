import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Wait } from '../wait';
import { AccountService } from 'src/app/account/shared/account.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class WaitService {
  @Output() event: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private account: AccountService
  ) { }

  index(options?: any): Observable<Wait> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/waits`;

    return this.http.get<Wait>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  waitList(options?: any): Observable<Wait> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/wait-list`;

    return this.http.get<Wait>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, id: string, params?: any): Observable<Wait> {
    const url = `${environment.apiUrl}/waits/${id}`;
    const options = {params: params};

    return this.http.put<Wait>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  create(data: any, params?: any): Observable<Wait> {
    const url = `${environment.apiUrl}/waits`;
    const options = {params: params};

    return this.http.post<Wait>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/waits/${id}`;
    const options = {params: params};

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  download(): string {
    return `${environment.apiUrl}/waits/download`;
  }

}
