import { CarrierService } from './../../carrier/shared/carrier.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dock-filters-mobile',
  templateUrl: './dock-filters-mobile.component.html',
  styleUrls: ['./dock-filters-mobile.component.scss']
})
export class DockFiltersMobileComponent implements OnInit {
  steps = [
    { name: 'PICKING', value: 'pk', class: 'btn-pk' },
    { name: 'PICKING CONCLUÍDO', value: 'pkc', class: 'btn-pkc' },
    { name: 'DUPLA CONFERÊNCIA', value: 'dc', class: 'btn-dc' },
    { name: 'ANALISAR', value: 'ana', class: 'btn-ana' },
    { name: 'REQ. ESPECIAL', value: 'req', class: 'btn-req' },
    { name: 'AG. IMPRESSÃO NF', value: 'anf', class: 'btn-anf' },
    { name: 'CONSULTA PIN', value: 'cpi', class: 'btn-cpi' },
    { name: 'LEITURA ETQ.', value: 'let', class: 'btn-let' },
    { name: 'AG. VEÍCULO', value: 'ave', class: 'btn-ave' },
    { name: 'CARREGAMENTO', value: 'ld', class: 'btn-ld' },
    { name: 'CONF. RELATÓRIO', value: 'rel', class: 'btn-rel' },
    { name: 'CONCLUÍDO', value: 'cc', class: 'btn-cc' }
  ];
  carriers: any;
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  stepsFilter: UntypedFormControl = new UntypedFormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private carrierService: CarrierService,
    private dialogRef: MatDialogRef<DockFiltersMobileComponent>,
  ) { }

  ngOnInit() {
    this.getCarrierList();
    this.recoverFilters();
  }

  getCarrierList() {
    const options = {
      pageSize: 1000
    };
    this.carrierService.index(options).subscribe((r: any) => {
      this.carriers = r.data;
      const carrier_id = this.data.carrier_id ? this.data.carrier_id.split(',') : [];
      this.carrierFilter.patchValue(carrier_id.map(Number));
    });
  }

  recoverFilters() {
    const steps = this.data.last_step ? this.data.last_step.split(',') : [];
    this.stepsFilter.setValue(steps);
    this.stepsFilter.updateValueAndValidity();
  }

  applyFilters() {
    const fts = {
      carriers: this.carrierFilter.value.toString(),
      steps: this.stepsFilter.value.toString()
    };
    this.dialogRef.close(fts);
  }

  clearFilters() {
    const fts = {
      carriers: '',
      steps: ''
    };
    this.dialogRef.close(fts);
  }

}
