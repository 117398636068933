<mat-grid-list cols="2" rowHeight="2:1">
  <mat-grid-tile>
    <mat-card class="report-card">
      <mat-card-header>
        <div mat-card-avatar>
          <i class="material-icons">
            watch
          </i>
        </div>
        <mat-card-title>Tempo entre Etapas</mat-card-title>
      </mat-card-header>
      <mat-card-actions  align="end">
        <button mat-button routerLink="/admin/report-step-time">VER</button>
      </mat-card-actions>
    </mat-card>

  </mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>
</mat-grid-list>
