import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { CarrierRoutingModule } from '../carrier/carrier-routing.module';
import { CarrierListComponent } from '../carrier/carrier-list/carrier-list.component';
import { CarrierDetailComponent } from '../carrier/carrier-detail/carrier-detail.component';
import { CarrierService } from '../carrier/shared/carrier.service';
import { ConferenceListComponent } from './conference-list/conference-list.component';
import { ConferenceDialogComponent } from './conference-dialog/conference-dialog.component';
import { ConferenceValidationComponent } from './conference-validation/conference-validation.component';
import { CarrierConferenceValidationDialogComponent } from './carrier-conference-validation-dialog/carrier-conference-validation-dialog.component';
import { BarcodeScannerLivestreamModule } from "ngx-barcode-scanner";
import { TkgBarcodeScannerComponent } from './tkg-barcode-scanner/tkg-barcode-scanner.component';
import { ConferenceUcDetailComponent } from './conference-uc-detail/conference-uc-detail.component';
import { PickingPercentageComponent } from './picking-percentage/picking-percentage.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    CarrierRoutingModule,
    BarcodeScannerLivestreamModule
  ],
  declarations: [
    CarrierListComponent,
    CarrierDetailComponent,
    ConferenceListComponent,
    ConferenceDialogComponent,
    ConferenceValidationComponent,
    CarrierConferenceValidationDialogComponent,
    TkgBarcodeScannerComponent,
    ConferenceUcDetailComponent,
    PickingPercentageComponent
  ],
  providers: [
    CarrierService,
  ],
  entryComponents: [
    CarrierDetailComponent,
    ConferenceDialogComponent,
    CarrierConferenceValidationDialogComponent,
    TkgBarcodeScannerComponent,
    ConferenceUcDetailComponent
  ],
})
export class CarrierModule { }
