import { ConferenceDialogComponent } from './../conference-dialog/conference-dialog.component';
import { CarrierService } from 'src/app/carrier/shared/carrier.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Howl } from 'howler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment.prod';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-conference-list',
  templateUrl: './conference-list.component.html',
  styleUrls: ['./conference-list.component.scss']
})
export class ConferenceListComponent implements OnInit, OnDestroy {
  account: any;
  columnSize = 2;
  conferenceList = [];

  statusList = {
    conferred: {
      class: 'mat-raised-button tkg-green',
      text: 'CONFERIDO',
      disabled: true
    },
    waiting: {
      class: 'mat-raised-button tkg-orange',
      text: 'REALIZAR CONFERÊNCIA',
      disabled: false
    },
    in_conference: {
      class: 'mat-raised-button tkg-blue',
      text: 'EM CONFERÊNCIA',
      disabled: false
    }
  };
  wsConference:WebSocketSubject<void>;
  private notification: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private carrierService: CarrierService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.notification = new Howl({
      src: ['assets/sounds/ding1.mp3'],
      autoplay: false,
    });
    this.account = JSON.parse(localStorage.getItem('account'));
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      console.log('smart phone');
      this.columnSize = 1;
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      console.log('tablet');
      this.columnSize = 2;
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      this.columnSize = 3;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.columnSize = 4;
    }
  }

  ngOnInit() {


    this.getBoxConference();

      // Conecta no websocket e fica escutando por atualizações para dar refresh na página
      const subject = webSocket({
        url: `${environment.wsUrl}/carrier/${this.account.carrier_id}`,
        deserializer: () => {},
      });

      subject.subscribe(
        (msg) => {
          this.getBoxConference();
          this.notification.play();
          this.snackBar.open("Novo box para conferência.", 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });

        },
        (err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
        () => console.log("complete") // Called when connection is closed (for whatever reason).
      );
  }



  ngOnDestroy(): void {
    this.wsConference?.unsubscribe();
  }

  getBoxConference() {
    this.carrierService.carrierConferenceList().subscribe((r: any) => {
      this.conferenceList = r.data;
    });
  }

  showBoxes(element) {
    const boxes = [];
    element.forEach(e => {
      boxes.push(e.vbox);
    });

    return boxes.toString().replace(/,/g, ', ');
  }

  getCarrier(resource: any) {
    const carriers = [];
    resource.vbox.forEach(item => {
      if (item.operation.carrier) {
        if (!carriers.includes(item.operation.carrier.name)) {
          carriers.push(item.operation.carrier.name);
        }
      } else {
        carriers.push(resource.carrier.name);
      }
    });

    if (carriers.length > 0) {
      return carriers[0];
    } else {
      return '';
    }
  }

  showDCStatus(st: string, att: string) {
    if(st) {
      return this.statusList[st][att];
    } else {
      return this.statusList['waiting'][att];
    }
  }

  conference(obj: any) {
    const opt = {
      box_id: obj.box_id
    };
    if (!obj.has_dc_list) {
      this.carrierService.createConferenceFromBox(opt).subscribe((r: any) => {
        this.getBoxConference();
        this.listConferenceDialog(obj.box_id);
      });
    } else {
      this.listConferenceDialog(obj.box_id);
    }
  }

  listConferenceDialog(box_id) {
    const dialogRef = this.dialog.open(ConferenceDialogComponent, {
      panelClass: ['dialog-fullscreen'],
      data: {box_id: box_id}
    });

    dialogRef.afterClosed().subscribe(data => {
      this.getBoxConference();
    });
  }

  getClient(item: any) {
    let client = [];
    item.vbox.forEach(element => {
      if(element.operation.client_name.length > 0) {
        if(client.length === 0) {
          client.push(element.operation.client_name);
        } else {
          if(!client.includes(element.operation.client_name)){
            client.push(element.operation.client_name);
          }
        }
      }
    });
    if(client.length === 1) {
      return {show: true, client: client.toString()};
    } else {
      return {show: false, client: ''};
    }
  }

}
