import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleListComponent } from './role-list/role-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';

const permissionRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'roles',
        component: RoleListComponent,
        data: {
          title: 'Grupos',
          search: true,
          refresh: true,
        },
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(permissionRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PermissionRoutingModule {}
