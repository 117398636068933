<div class="top-bar-filters">
  <div class="tkg-form">
    <div class="tkg-form-item-25">
      <mat-form-field appearance="outline">
        <mat-label>Etapa</mat-label>
        <mat-select multiple [formControl]="stepsFilter">
          <mat-select-trigger>
            <span *ngIf="stepsFilter.value?.length > 0" class="example-additional-selection">
              {{stepsFilter.value.length}} {{stepsFilter.value?.length > 1 ? 'filtros' : 'filtro'}}
            </span>
          </mat-select-trigger>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let item of steps" [value]="item.id" (click)="setFilterStep(item)">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="tkg-form-item-25" [formGroup]="form">

      <mat-form-field appearance="outline">
        <mat-label>Transportadora</mat-label>
        <mat-select multiple [formControl]="carrierFilter">
          <mat-select-trigger>
            <span *ngIf="carrierFilter.value?.length > 0" class="example-additional-selection">
              {{carrierFilter.value.length}} {{carrierFilter.value?.length > 1 ? 'filtros' : 'filtro'}}
            </span>
          </mat-select-trigger>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let item of carriers" [value]="item.id" (click)="getFilterCarrier(item)">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="tkg-form-item-25">
      <div class="tkg-container">
        <div class="tkg-row">
          <div class="tkg-icon" [ngClass]="tkg_receive_icon"></div>
          <p class="tkg-title">Recebimento 3M <small class="tkg-date-short">{{lastUpdate?.recebeu_operacao | date:'short'}}</small></p>
          </div>

        <div class="tkg-row">
          <div class="tkg-row">
            <div class="tkg-icon" [ngClass]="tkg_processing_icon"></div>
            <p class="tkg-title">Importação TKG <small class="tkg-date-short">{{lastUpdate?.processou_arquivos | date:'short'}}</small></p>
        </div>
      </div>
    </div>
    </div>
    <div class="tkg-form-item-25 tkg-btn-grid">
      <button class="tkg-btn-download" mat-icon-button (click)="generatePDF()" matTooltip="Gerar Arquivo PDF"
        [disabled]="loading">
        <mat-icon aria-label="Refresh">picture_as_pdf</mat-icon>
        Baixar PDF
      </button>
      <button class="tkg-btn-download" mat-button matTooltip="Baixar CSV"
        [disabled]="loading" (click)="downloadReport()">
        <mat-icon aria-label="Baixar CSV">get_app</mat-icon>
        Baixar CSV
      </button>
    </div>
  </div>
</div>

<!-- <mat-card class="card-filter"  *ngIf="stepsFiltered.length > 0">
  <p class="left-item" *ngIf="stepsFiltered.length > 0">Etapa</p>
  <span class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon" *ngFor="let item of stepsFiltered; index as idx">
    {{item.name}}
    <span class="material-icons tkg-chips-icon" (click)="removeStepFilterItem(item)">
      cancel
    </span>
  </span>
</mat-card> -->
<app-list-empty *ngIf="showListEmpty"></app-list-empty>
<div class="box-container mat-elevation-z8" [hidden]="showListEmpty">

  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <ng-container matColumnDef="box" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header="box" class="col-5 aumentar">Box F</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{element.resource.box}}</td>
    </ng-container>


    <ng-container matColumnDef="carrier_carriers:carrier_id|carrier_carriers.name">
      <th mat-header-cell *matHeaderCellDef class="col-10 aumentar" mat-sort-header="carrier_carriers.name">Transportadora</th>
      <!-- <td mat-cell *matCellDef="let element" class="aumentar">{{element.resource.carrier?.name}}</td> -->
      <td mat-cell *matCellDef="let element" class="col-10 aumentar">{{getCarrier(element.resource)}}</td>
    </ng-container>

    <ng-container matColumnDef="assigned">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Associado</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{checkIfAssigned(element.resource.vbox)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="vboxes">
      <th mat-header-cell *matHeaderCellDef class="col-10 aumentar">Boxes Virtuais</th>
      <td mat-cell *matCellDef="let element" class="col-10 aumentar">{{showBoxes(element.resource.vbox)}}</td>
    </ng-container>


    <ng-container matColumnDef="tvolume">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Vol Total</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{sumOrderVolums(element)}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Status</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{showStatus(element.resource)}}</td>
    </ng-container>

    <ng-container matColumnDef="invoice">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">NF</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">
        {{element.resource.has_invoice ? 'Sim' : 'Não'}}
      </td>
    </ng-container>


    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Obs</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{element.notes ? element.notes.notes : '-'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="just">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Qtd Erros</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">{{element.resource.justification_list ? element.resource.justification_list.length : '-'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="arrival_prev">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Prev. chegada</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar" [matTooltip]="element?.arrival_prev | date:'short'">
        {{element?.arrival_prev | date:'shortTime'}}</td>
    </ng-container>
    <ng-container matColumnDef="min_start">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-5 aumentar">Início sep.</th>
      <!-- <td mat-cell *matCellDef="let element" class="aumentar">{{element.min_dt | date:'shortTime'}}</td> -->
      <td mat-cell *matCellDef="let element" class="col-5 aumentar" [matTooltip]="element.min_start | date:'short'">
        {{ element.min_start | date:'shortTime'}}</td>
    </ng-container>
    <ng-container matColumnDef="max_dt">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Última bipagem</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar"
        [matTooltip]="getMaxMoment(element.resource.vbox ) | date:'short'">
        {{ getMaxMoment(element.resource.vbox ) | date:'shortTime'}}</td>
    </ng-container>
    <ng-container matColumnDef="print_map">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Imp mapa</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar" [matTooltip]="printMapTime(element) | date:'short'">
        {{printMapTime(element) | date:'shortTime'}}</td>
    </ng-container>
    <ng-container matColumnDef="dc_end">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Final DC</th>
      <!-- <td mat-cell *matCellDef="let element" class="col-5 aumentar">
        {{endDC(element) ? (endDC(element) | date:'shortTime') : ''}}</td> -->
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">
        {{element.end_dc | date:'shortTime' }}</td>
    </ng-container>
    <ng-container matColumnDef="conference_started">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar">Conf. Transp.</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">
        <div [ngClass]="element.conference_started.length > 0 ? 'col-dci' : ''">{{element.conference_started}}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="steps" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="col-10 aumentar" mat-sort-header="step">Etapa</th>
      <td mat-cell *matCellDef="let element" class="col-10 aumentar">
        <mat-chip-list aria-label="Steps">
          <mat-chip class="tkg-chip-text-color" [matMenuTriggerFor]="stepMenu" [style.background]="showStep(element.step).color">
            <div>{{showStep(element.step).name}}</div>
            <div class="step-datetime">{{showStepTime(element.steps) | date:'short':'-0300'}} </div>
          </mat-chip>
        </mat-chip-list>
        <mat-menu #stepMenu="matMenu">
          <button *ngFor="let item of steps" class="tkg-chip-text-color" mat-menu-item (click)="onStartStep(element, item.id)" [style.background]="item.color">
            <span>{{item.name}}</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <ng-container matColumnDef="time_in_step">
      <th mat-header-cell *matHeaderCellDef class="col-5 aumentar"  mat-sort-header="time_in_step">Tempo na etapa</th>
      <td mat-cell *matCellDef="let element" class="col-5 aumentar">
        {{durationHumanize(element.time_in_step)}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="col-7 aumentar">Ações</th>
      <td mat-cell *matCellDef="let element" class="col-7 aumentar">

        <mat-menu #menu1="matMenu">
          <button mat-menu-item (click)="onOpenArrivalPrev(element)">
            <mat-icon>access_time</mat-icon>
            <span>Previsão chegada</span>
          </button>
          <button mat-menu-item (click)="justifyAnalisis(element)">
            <mat-icon>edit_note</mat-icon>
            <span>Justificativa</span>
          </button>
          <button mat-menu-item (click)="authorizeDC(element)" [disabled]="disableAuthorizeDC(element)">
            <mat-icon>fact_check</mat-icon>
            <span>Autorizar D.C.</span>
          </button>
          <button mat-menu-item (click)="printConferenceMap(element.resource.box_id)">
            <mat-icon>print</mat-icon>
            <span>Imprimir Mapa da DC</span>
          </button>
          <button mat-menu-item (click)="printMap(element.resource)">
            <mat-icon>print</mat-icon>
            <span>Imprimir Mapa de Picking</span>
          </button>
          <button mat-menu-item (click)="send2Invoice($event, element)" [disabled]="element.resource.has_invoice">
            <mat-icon>list_alt</mat-icon>
            <span>Passar para NF</span>
          </button>
          <button mat-menu-item (click)="registerNotes($event, element)">
            <mat-icon>chat_bubble_outline</mat-icon>
            <span>Adicionar Observação</span>
          </button>
          <button mat-menu-item (click)="onOpenDockRow($event, element)">
            <mat-icon>personal_video</mat-icon>
            <span>Ver Detalhes</span>
          </button>
          <button mat-menu-item (click)="assingCharge($event, element)">
            <mat-icon>playlist_add</mat-icon>
            <span>Associar Carga</span>
          </button>
          <button mat-menu-item (click)="cancelLoad($event, element)">
            <mat-icon>cancel_presentation</mat-icon>
            <span>Cancelar Carga</span>
          </button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="menu1">
          <mat-icon>more_vert</mat-icon>
        </button>


        <button type="button" mat-icon-button (click)="expandedElement = expandedElement === element ? null : element"
          [matTooltip]="element == expandedElement ? 'Recolher' : 'Expandir'">
          <mat-icon>{{ element == expandedElement ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumns.length">
        <div class="element-detail mat-elevation-z4 margin-bottom"
          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

          <table mat-table [dataSource]="element.resource.vbox">

            <ng-container matColumnDef="box">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Box V.</th>
              <td mat-cell *matCellDef="let element" class="aumentar">{{ element.vbox }}</td>
            </ng-container>

            <ng-container matColumnDef="vbox_min_dt">
              <th mat-header-cell *matHeaderCellDef class="aumentar">H. Início</th>
              <td mat-cell *matCellDef="let element" class="aumentar"
                [matTooltip]="element.min_start_at | date:'short'">
                {{ element.min_start_at | date:'shortTime' }}</td>
            </ng-container>

            <ng-container matColumnDef="wave">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Onda</th>
              <td mat-cell *matCellDef="let element" class="aumentar">{{ element.wave }}</td>
            </ng-container>

            <ng-container matColumnDef="client">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Cliente</th>
              <td mat-cell *matCellDef="let element" class="aumentar">{{ element.operation?.client_name }}</td>
            </ng-container>

            <ng-container matColumnDef="volumes">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Vol. Pedido</th>
              <td mat-cell *matCellDef="let element" class="aumentar">{{ element.operation?.order_vol }}</td>
            </ng-container>

            <ng-container matColumnDef="volume">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Vol. Conferido</th>
              <td mat-cell *matCellDef="let element" class="aumentar">
                {{ element.order_vol_confered ? element.order_vol_confered  : 0 }}</td>
            </ng-container>

            <ng-container matColumnDef="vol_percent">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Vol. %</th>
              <td mat-cell *matCellDef="let element" class="aumentar">
                {{ (element.order_vol_confered * 100)/element.operation?.order_vol | number:'1.1-2' }}%</td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Qtd. Pedido</th>
              <td mat-cell *matCellDef="let element" class="aumentar">{{ element.operation?.order_amount }}</td>
            </ng-container>

            <ng-container matColumnDef="amount_confered">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Qtd. Conferido</th>
              <td mat-cell *matCellDef="let element" class="aumentar">
                {{ element.order_amount_confered ? element.order_amount_confered : 0 }}</td>
            </ng-container>

            <ng-container matColumnDef="qtd_percent">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Qtd. %</th>
              <td mat-cell *matCellDef="let element" class="aumentar">
                {{ (element.order_amount_confered * 100)/element.operation?.order_amount | number:'1.1-2' }}%</td>
            </ng-container>
            <ng-container matColumnDef="vboxStatus">
              <th mat-header-cell *matHeaderCellDef class="aumentar">Status</th>
              <td mat-cell *matCellDef="let element" class="aumentar">
                {{ element.operation?.minor_status }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element; let index = index;">
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="onOpenPendencies($event, element)">
                    <mat-icon>info</mat-icon>
                    <span [translate]="'dock.action.pendencies'"></span>
                  </button>
                  <!-- <button mat-menu-item (click)="deleteVBox($event, element)">
                    <mat-icon>delete</mat-icon>
                    <span>Remover Item</span>
                  </button> -->
                </mat-menu>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row
              *matHeaderRowDef="displayedShipmentColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);">
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedShipmentColumns;"></tr>
          </table>

        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);">
    </tr>
    <tr mat-row class="element-row" [class.expanded-row]="expandedElement === element"
      *matRowDef="let element; columns: displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; let even = even; columns: ['expandedDetail']" class="detail-row"
      [ngClass]="{gray: even}"></tr>
  </table>
  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>
