import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { StepChangeMapDialogComponent } from '../step-change-map-dialog/step-change-map-dialog.component';

@Component({
  selector: 'app-step-change-map-list',
  templateUrl: './step-change-map-list.component.html',
  styleUrls: ['./step-change-map-list.component.scss']
})
export class StepChangeMapListComponent implements OnInit {
  stepsList: any;
  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  search: string;
  pageSize: number;
  length: number;
  displayedColumns: string[];
  showListEmpty = false;
  dataSource = new MatTableDataSource<any>();
  data: any[] = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  storedStepList?: any = [];
  sd_list_name = 'step-change-map';
  constructor(
    private supportDataService: SupportDataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    // public breakpointObserver: BreakpointObserver,
    // private route: ActivatedRoute
  ) {
    this.displayedColumns = ['name', 'to_steps', 'actions'];
  }

  ngOnInit(): void {
    this.getSteps();
    this.getStepChangeMap();
  }

  getSteps() {
    this.supportDataService.resourceName('steps-list').subscribe((r: any) => {
      this.stepsList = r.data.resource.filter((i: any) => i.active === true).sort((a, b) => a.order - b.order);
    });
  }

  getStepChangeMap() {
    this.supportDataService.resourceName(this.sd_list_name).subscribe((r: any) => {
      this.dataSource = this.processSteps(r.data.resource);
    });
  }

  processSteps(stepsO) {
    this.storedStepList = [];
    const result = stepsO;
    const keys = Object.keys(result);
    keys.forEach((i: string) => {
      const o = {id: i, name: this.stepName(i), to_steps: this.stepsName(result[i]), steps_id: result[i]}
      this.storedStepList.push(o);
    });

    return this.storedStepList;
  }

  stepName(stepId: string) {
    return this.stepsList.filter((ft: any) => ft.id === stepId)[0].name;
  }

  stepsName(list: any) {
    let stepList = [];
    list.forEach((l: string) => {
      stepList.push(this.stepName(l))
    });

    return stepList.join(', ');
  }

  addEdit(event, action: string, item: any = {}) {
    const dialogRef = this.dialog.open(StepChangeMapDialogComponent, {
      panelClass: ['dialog-small', 'dialog-fullscreen'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        list_name: 'step-change-map',
        allSteps: this.stepsList,
        storedSteps: this.storedStepList,
        action: action
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log(data);
      if (data) {
        if (action === 'EDIT') {
          this.snackBar.open('Registro atualizado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        } else if (action === 'ADD') {
          this.snackBar.open('Registro criado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        }
        this.dataSource = this.processSteps(data.resource);
      }
    });
  }

  delete(event, data) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirma a exclusão?',
        message: `Tem certeza que deseja excluir o item ${data.name}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const item = {
          list_name: this.sd_list_name,
          key: data.id
        }

        this.supportDataService.deleteItemOnObject(item).subscribe((r: any) => {
          this.snackBar.open('Item removido com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
          this.dataSource = this.processSteps(r.data.resource);
        });
      }
    });
  }
}
