import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-groups-detail',
  templateUrl: './groups-detail.component.html',
  styleUrls: ['./groups-detail.component.scss']
})
export class GroupsDetailComponent implements OnInit {
  form = this.formBuilder.group({
    id: [this.data.item.id || null],
    name: [this.data.item.name, Validators.required],
    module: ['']
  });

  modules: any = [];
  modules_ids = [];
  pageModules = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<GroupsDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.getPageModules();
  }

  getPageModules() {
    this.userService.pageModules().subscribe((r: any) => {
      this.pageModules = r.data;
      // Caso edição, pega os ids dos módulos e filtra
      // pageModules em modules
      if (this.data.action === 'EDIT') {
        const menu_pages_ids = JSON.parse(this.data.item.menu_pages_id);
        this.pageModules.forEach(item => {
          if (menu_pages_ids.includes(item.id)) {
            this.modules.push(item);
          }
        });
      }
    });
  }

  addPage() {
    console.log(this.form.value.module);
    this.modules.push(this.form.value.module);
    console.log(this.modules);
  }

  removePage(idx) {
    this.modules.splice(idx, 1);
  }

  onSubmit() {
    let pages_ids = [];
    this.modules.forEach(item => {
      pages_ids.push(item.id);
    });
    const data = {
      id: this.form.value.id,
      name: this.form.value.name,
      menu_pages_id: pages_ids
    }
    if (this.data.action == 'ADD') {
      this.userService.createGroups(data).subscribe((r: any) => {
        this.dialogRef.close(r);
      });
    }
    if (this.data.action == 'EDIT') {
      this.userService.updateGroups(data).subscribe((r: any) => {
        this.dialogRef.close(r);
      });
    }

  }

}
