import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit {
  @Input('label') label;
  @Input('list') listObservable: Observable<any[]>;
  @Input('fe-search') fe_search: boolean|false;
  @Input('be-search') be_search: boolean|false;
  search: string = '';
  searchString = new Subject<string>();
  list: any[];
  listOrinal: any[];
  constructor() {
    this.searchString.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        console.log(value);
        this.searchTerm(value);
      });
  }

  ngOnInit(): void {
    this.getList();
  }

  getList(){
    this.listObservable.subscribe((r: any)=> {
      this.listOrinal = r.data.resource;
      this.list = this.listOrinal;
    });
  }

  clearSearchString() {
    this.search = '';
    this.list = this.listOrinal;
  }

  searchTerm(term: string) {
    if(this.fe_search) {
      this.list = this.listOrinal.filter((item: any) => item.name.toLowerCase().includes(term.toLocaleLowerCase()))
    } else {
      console.log('filtra no backend');
    }
  }

}
