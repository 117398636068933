import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { WaitService } from '../shared/wait.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Wait } from '../wait';
import { WaitList } from './wait-list';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { WaitDetailComponent } from '../wait-detail/wait-detail.component';
import { WaitManageComponent } from '../wait-manage/wait-manage.component';
import { SearchService } from '../../shared/search.service';
import { Search } from '../../shared/search';
import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component';
import { RefreshService } from '../../shared/refresh.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { WaitReleaseService } from '../shared/wait-release.service';
import { FirebaseService } from '../../shared/firebase.service';
import { SupportDataService } from '../../shared/support-data.service';
import { environment } from "./../../../environments/environment.prod";
import { webSocket } from "rxjs/webSocket";

import { Howl, Howler } from 'howler';

@Component({
  selector: 'app-wait-list',
  templateUrl: './wait-list.component.html',
  styleUrls: ['./wait-list.component.scss']
})
export class WaitListComponent implements OnInit, OnDestroy {
  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  search: string;
  pageSize: number;
  length: number;
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<WaitList>();
  data: WaitList[] = [];
  translateSource: any;
  dockSpaces: any;
  updates: any;

  notification = new Howl({
    src: ['assets/sounds/notify.mp3']
  });

  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private waitService: WaitService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private searchService: SearchService,
    private refreshService: RefreshService,
    private waitReleaseService: WaitReleaseService,
    private translate: TranslateService,
    public breakpointObserver: BreakpointObserver,
    private firebaseService: FirebaseService,
    // public angularFire: AngularFireDatabase,
    private supportDataService: SupportDataService
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });

    translate.get('wait.wait-list').subscribe((res: string) => {
      this.translateSource = res;
    });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.displayedColumns = [
        'wait_waits.board_horse', 'wait_waits.board_cart', 'actions'
      ];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.displayedColumns = [
        'wait_waits.board_horse', 'wait_waits.board_cart', 'wait_waits.arrival_at', 'driver', 'client', 'actions'
      ];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = [
        'wait_waits.board_horse', 'wait_waits.board_cart', 'wait_waits.arrival_at', 'driver', 'client', 'charge_number', 'carrier_carriers.name',
        'status', 'wait_directions.dock_number', 'actions'
      ];
    }
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  onOpen(event, action: string, item: any = {}) {
    const dialogRef = this.dialog.open(WaitDetailComponent, {
      panelClass: ['dialog-small', 'dialog-fullscreen'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (action === 'DIRECT') {
          this.reload();
          this.snackBar.open('Veículo direcionado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  manage(event, action: string, item: any = {}) {

    const dialogRef = this.dialog.open(WaitManageComponent, {
      panelClass: ['dialog-large', 'dialog-fullscreen'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.reload();
      }
    });
  }

  deleteWait(id: string) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Apagar registro',
        message: `Tem certeza que deseja apagar o registro do motorista?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if(confirm) {
        this.waitService.destroy(id).subscribe((r: any) => {
          this.reload();
          this.snackBar.open('Registro apagado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        }, (e: any) => {
          console.log('erro ao apagar');
        });
      }
    });
  }

  releaseVehicle(event, item) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirma a autorização?',
        message: `Tem certeza que deseja autorizar o motorista ${item.driver.toUpperCase()} a entrar?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.waitReleaseService.create(item.id).subscribe(response => {
          this.reload();
          this.snackBar.open('Veículo autorizado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        });
      }
    });
  }

  showStatus(item) {
    if (item.released_at) {
      return this.translateSource.status.released;
    } else if (item.directed_at) {
      return this.translateSource.status.directed;
    }

    return this.translateSource.status.default;
  }

  showTooltip(item) {
    if (!item.directed_at) {
      return 'Veículo não direcionado';
    } else if (item.directed_at && !item.released_at) {
      return 'Autorizar entrada';
    } else if (item.released_at) {
      return `Veículo autorizado em ${this.datePipe.transform(item.released_at, 'short')}`;
    }
  }

  ngOnInit() {
    this.getDocks();
    this.listening4Updates();
    this.listening4DirectionUpdates();

    this.sort.sort(<MatSortable>{
      id: '',
      start: 'desc'
    }
    );

    // If the wait changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.searchWatcher = this.searchService.value
      .subscribe((search: Search) => {
        this.search = search.value;
        this.reload({ search: search.value });
      });

    this.refreshWatcher = this.refreshService.refresh
      .subscribe((refresh: Event) => {
        this.reload({ refresh: refresh });
      });

    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: "",
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            with: 'direction;carrier',
          };

          if (this.sort.active != 'status') {
            options['orderBy'] = this.sort.active;
          }

          if (this.sort.active == 'status' && this.sort.direction != '') {
            options['orderByStatus'] = 'released_at|directed_at';
          }

          if (this.search) {
            Object.assign(options, {
              search: this.search,
            });
          }

          return this.waitService.waitList(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }
          this.showListEmpty = response.data.length === 0;
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          response.data.forEach(function (item) {
            item.status = {
              name: 'Não direcionado',
              code: 4,
              type: 'shipment',
            };
          });

          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => this.dataSource.data = data);


  }

  listening4Updates() {
    const user = JSON.parse(localStorage.getItem('account'));
    const subject = webSocket({
      url: `${environment.wsUrl}/onupdate/waiting`,
    });

    subject.subscribe(
      (msg: any) => {
      if (msg.user_id != user.id) {
        this.reload();
        this.notification.play();
        this.snackBar.open(msg.msg, 'OK', {
          duration: 5000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
        });
      }
      },
      (err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      () => console.log("complete") // Called when connection is closed (for whatever reason).
    );
    return this.updates;
  }

  listening4DirectionUpdates() {
    const user = JSON.parse(localStorage.getItem('account'));
    const subject = webSocket({
      url: `${environment.wsUrl}/onupdate/direction`,
    });

    subject.subscribe(
      (msg: any) => {
      if (msg.user_id != user.id) {
        this.reload();
        this.notification.play();
        this.snackBar.open(msg.msg, 'OK', {
          duration: 5000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
        });
      }
      },
      (err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      () => console.log("complete") // Called when connection is closed (for whatever reason).
    );
  }

  getDocks() {
    this.supportDataService.dockSpaces().subscribe((r: any) => {
      this.dockSpaces = r.data.resource;
    });
  }

  dockById(dockId: number) {
    if (dockId !== null) {
      if (dockId === 0) {
        return 'Bolsão';
      } else {
        const dockSpace = this.dockSpaces.filter((item: any) => {
          return item.id === dockId;
        });
        return dockSpace[0].name;
      }
    } else {
      return '';
    }
  }

  disableRelease(element:any) {
    let has_dock_number;
    let has_released_at;
    if(element.dock_number >= 0) {
      has_dock_number = true;
    }
    if (element.released_at) {
      has_released_at = true;
    }
    if(has_released_at && has_dock_number) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.searchWatcher.unsubscribe();
    this.refreshWatcher.unsubscribe();
  }

}
