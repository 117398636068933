<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8">

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="wait_waits.board_horse">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">
        {{'wait.wait-list.column.board-horse' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{ element.board_horse | uppercase}}</td>
    </ng-container>
    <ng-container matColumnDef="wait_waits.board_cart">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">
        {{'wait.wait-list.column.board-cart' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{ element.board_cart | uppercase}}</td>
    </ng-container>
    <ng-container matColumnDef="wait_waits.arrival_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">
        {{'wait.wait-list.column.time' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{element.arrival_at | date: 'shortTime'}}</td>
    </ng-container>

    <ng-container matColumnDef="driver">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">
        {{'wait.wait-list.column.driver' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="ellipsis" fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="180px"
            class="aumentar">{{element.driver | uppercase}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">
        {{'wait.wait-list.column.client' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="ellipsis" fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="180px"
            class="aumentar">{{element.client | uppercase}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="charge_number">
      <th mat-header-cell *matHeaderCellDef class="aumentar">
        OBS</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="ellipsis" fxFlex="180px" fxFlex.gt-xs="150px" fxFlex.gt-sm="180px" fxFlex.gt-md="180px"
            class="aumentar">{{element.charge_number | uppercase}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="carrier_carriers.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">
        {{'wait.wait-list.column.carrier' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="ellipsis td-cell" fxFlex="30" fxFlex.gt-xs="15" fxFlex.gt-sm="20" fxFlex.gt-md="180px"
            class="aumentar">{{element.carrier_name ? element.carrier_name : '' | uppercase}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">
        {{'wait.wait-list.column.status' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="aumentar-chips">
        <mat-chip-list>
          <mat-chip [ngClass]="{
            'app-chip-yellow': !element.directed_at,
            'app-chip-blue': element.directed_at && !element.released_at,
            'app-chip-green': element.released_at
          }" class="aumentar">{{showStatus(element)}}</mat-chip>
        </mat-chip-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="wait_directions.dock_number">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header class="aumentar">{{'wait.wait-list.column.dock' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{ element.directed_at ? dockById(element.dock_number) : ''}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="aumentar">{{'wait.wait-list.column.actions' | translate}}</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button [matTooltip]="showTooltip(element)" (click)="releaseVehicle($event, element)"
        [disabled]="disableRelease(element)">
          <mat-icon>check_circle</mat-icon>
        </button>
        <button mat-icon-button [matTooltip]="showTooltip(element)" (click)="manage($event, 'EDIT', element)">
          <mat-icon>create</mat-icon>
        </button>
        <button color="warn" mat-icon-button [matTooltip]="showTooltip(element)" (click)="deleteWait(element.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!-- <mat-paginator [pageSize]="pageSize" showFirstLastButtons></mat-paginator> -->

  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>


</div>
<button mat-mini-fab (click)="manage($event, 'ADD')" class="mat-fab-bottom-right" color="primary">
  <mat-icon>add</mat-icon>
</button>
