import { DialogConfirmComponent } from './../../shared/dialog-confirm/dialog-confirm.component';
import { ConferenceUcDetailComponent } from './../conference-uc-detail/conference-uc-detail.component';
import { CarrierConferenceValidationDialogComponent } from './../carrier-conference-validation-dialog/carrier-conference-validation-dialog.component';
import { CarrierService } from 'src/app/carrier/shared/carrier.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from 'src/app/account/shared/account.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from "./../../../environments/environment.prod";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { Howl } from 'howler';
import { DialogAnaliseComponent } from 'src/app/dock/dialog-analise/dialog-analise.component';
import { DockService } from 'src/app/dock/shared/dock.service';

@Component({
  selector: 'app-conference-validation',
  templateUrl: './conference-validation.component.html',
  styleUrls: ['./conference-validation.component.scss']
})
export class ConferenceValidationComponent implements OnInit, OnDestroy {
  displayedColumns: string[];
  dataSource = new MatTableDataSource<any>();
  showListEmpty = false;
  pageSize = 10;
  account: any;
  notification = new Howl({
    src: ['assets/sounds/notify-conference.mp3']
  });

  wsConference:WebSocketSubject<void>;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private carrierService: CarrierService,
    private dialog: MatDialog,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private dockService: DockService
  ) { }

  ngOnDestroy(): void {
    this.wsConference?.unsubscribe();
  }

  ngOnInit() {
    this.accountService.identity().then(identity => {
      this.account = identity;
      console.log(this.account);
    });

    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
    this.getValidationList();
    this.soundNotification();
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.displayedColumns = ['carrier_name', 'conf_user', 'pallet', 'box', 'vbox', 'updated_at', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.displayedColumns = ['carrier_name', 'conf_user', 'pallet', 'box', 'vbox', 'updated_at', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['carrier_name', 'conf_user', 'pallet', 'box', 'vbox', 'updated_at', 'actions'];
    }

    // const permissions = ['superuser', 'carriers.show', 'carriers.update'];
  }

  getValidationList():Subject<any[]> {
    const validations = new Subject<any[]>()
    this.carrierService.conferenceValidationList()
    .subscribe((r: any) => {
      this.dataSource.data = r.data;
      this.showListEmpty = r.data.length === 0;
      validations.next(this.dataSource.data);
    });

    return validations;
  }

  validate(element: any): void {
    const dialogRef = this.dialog.open(CarrierConferenceValidationDialogComponent, {
      data: {
        item: JSON.parse(JSON.stringify(element))
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('fechou');
      console.log(data);
      if(data.show_justify) {
        this.justifyAnalisis(element);
      }
      this.getValidationList();
    });
  }

  viewDetails(obj: any): void {
    const dialogRef = this.dialog.open(ConferenceUcDetailComponent, {
      panelClass: ['dialog-medium'],
      data: {...obj}
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('fechou');
      this.getValidationList();
    });
  }

  // HABILITAR APENAS PARA O GRUPO DE GESTORES
  deleteConferenceItem(id: string) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: ['dialog-small'],
      data: {message: 'Tem certeza que deseja apagar esta UC?'}
    });

    dialogRef.afterClosed().subscribe(del => {
      if(del) {
        this.carrierService.deleteUC(id,{}).subscribe((r: any) => {
          console.log(r.msg);
          this.getValidationList();
        });
      }
    });
  }

  private soundNotification() {
    this.wsConference = webSocket({
      url:  `${environment.wsUrl}/conference/validation`,
    });

    this.wsConference.subscribe(
      (msg: any) => {
        const oldData = this.dataSource.data?.map<number>((oldItem)=><number>oldItem.id) || [];
        this.getValidationList()
        .subscribe((data:any[])=>{
          if(data.some(item=>!oldData.includes(item.id))){
            this.notification.play();
            this.snackBar.open("Validação de conferência solicitada.", 'OK', {
              duration: 5000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
            });
          }
        })
      },
      (err) => console.log(err),
      () => console.log("complete")
    );
  }

  // onStartStepAnalise(element: any, step: any) {

  //   const resource = {
  //     resource: {
  //       box_id: element.box_id
  //     }
  //   }
  //   const dialogRef = this.dialog.open(DialogAnaliseComponent, {
  //     panelClass: ['dialog-medium'],
  //     disableClose: true,
  //     data: {
  //       item: JSON.parse(JSON.stringify(resource)),
  //       toStep: step
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(data => {
  //     this.dockService.saveStep(data).subscribe((r: any) => {
  //       let message;
  //       if (r.success === true) {
  //         message = 'Informações gravadas com sucesso!';
  //       } else {
  //         message = r.message;
  //       }
  //       this.snackBar.open(message, 'OK', {
  //         duration: 3000,
  //         horizontalPosition: 'left',
  //         verticalPosition: 'bottom',
  //       });
  //     });
  //   });
  // }

  justifyAnalisis(element) {
    //novo
    console.log('element')
    console.log(element)
    const dialogRef = this.dialog.open(DialogAnaliseComponent, {
      panelClass: ['dialog-medium'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
        toStep: element.step
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      this.getValidationList();
    });
  }

}
