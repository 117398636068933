<h3>Escolha uma justificativa</h3>

<mat-dialog-content class="mat-typography">

  <!-- <mat-radio-group aria-labelledby="analise-radio-group-label" class="analise-radio-group" [(ngModel)]="justify_id">
    <mat-radio-button class="analise-radio-button" *ngFor="let justify of justification" [value]="justify.id">
      {{justify.name}}
    </mat-radio-button>
  </mat-radio-group> -->
  <form [formGroup]="form">
    <div class="tkg-row">
      <div class="tkg-cell-20">

        <mat-form-field>
          <mat-label>Erro</mat-label>
          <mat-select formControlName="justification_id">
            <mat-option *ngFor="let item of justifications" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <app-select-search [list]="justifyO" [label]="'Erro'" [fe-search]="true" [be-search]="false"></app-select-search> -->

      </div>
      <!-- <div class="tkg-cell-20">
        <mat-form-field>
          <mat-label>Tipo de erro</mat-label>
          <mat-select formControlName="error_type">
            <mat-option *ngFor="let item of tipoErro" [value]="item.tipo">
              {{item.tipo}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="tkg-cell-20">
        <app-ss-staff [label]="'Colaborador'" [fe-search]="false" [be-search]="true" [option-label-pattern]="['provider_name', ' - ', 'name']" [return-value]="'code'" formControlName="staff_code"></app-ss-staff>
      </div>
      <div class="tkg-cell-20">
        <mat-form-field class="example-full-width">
          <mat-label>Qtd</mat-label>
          <input matInput placeholder="Qtd" value="" formControlName="qtd">
        </mat-form-field>
      </div>
      <div class="">
      <button mat-button color="primary" (click)="addData()" [disabled]="!form.valid">Adicionar</button>
      </div>
    </div>
  </form>
  <table class="tkg-table">
    <thead>
      <tr>
        <th class="tkg-align-left">Erro</th>
        <!-- <th class="tkg-align-left">Tipo</th> -->
        <th class="tkg-align-left">Colaborador</th>
        <th class="tkg-align-center">Qtd</th>
        <th class="tkg-align-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of justificationList; index as idx">
        <td class="tkg-align-left">{{formatError(item.justification_id)}}</td>
        <!-- <td class="tkg-align-left">{{item.error_type}}</td> -->
        <td class="tkg-align-left">{{formatStaff(item.staff_code)}}</td>
        <td class="tkg-align-right">{{item.qtd}}</td>
        <td class="tkg-align-center">
          <button mat-icon-button color="warn" aria-label="Apagar" (click)="removeData(idx)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" (click)="cancel()">Cancelar</button>
  <button type="submit" mat-button color="primary" [disabled]="!(justificationList.length > 0)" (click)="save()">Salvar</button>
</mat-dialog-actions>
