import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SupportDataService } from '../../shared/support-data.service';

@Component({
  selector: 'app-justify-step',
  templateUrl: './justify-step.component.html',
  styleUrls: ['./justify-step.component.scss']
})
export class JustifyStepComponent implements OnInit {
  form = this.formBuilder.group({
    justify_id: ['', [Validators.required]]
  });
  justifications: any = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<JustifyStepComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public supportData: SupportDataService
  ) { }

  ngOnInit() {
    console.log('this.data')
    console.log(this.data)
    this.getJustifications()
  }

  submit() {
    const data = {
      resource: {box_id: this.data.box_id},
      step: this.data.step,
      justify_id: this.form.value.justify_id
    }
    this.dialogRef.close(data);
  }

  getJustifications() {
    this.supportData.resourceName('step-justifications').subscribe((r: any) => {
      this.justifications = r.data.resource;
    });
  }

}
