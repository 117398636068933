import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Load } from '../load';

@Injectable({
    providedIn: 'root'
})
export class LoadService {

    constructor(
        private http: HttpClient
    ) { }

    index(options?: any): Observable<Load> {
        const httpParams = Object.assign({
            page: 1,
            orderBy: 'created_at',
            sortedBy: 'desc',
        }, options);

        const params = new HttpParams({fromObject: httpParams})

        const url = `${environment.apiUrl}/loads`;

        return this.http.get<Load>(url, {params}).pipe(
            map((response: Response | any) => response || {}),
            catchError(err => throwError(err || 'Server error'))
        );
    }

    update(data: any, id: string, params?: any): Observable<Load> {
        const url = `${environment.apiUrl}/loads/${id}`;
        const options = {params: params};

        return this.http.put<Load>(url, data, options).pipe(
            map((response: Response | any) => response || {}),
            catchError(err => throwError(err || 'Server error'))
        );
    }

    create(data: any, params?: any): Observable<Load> {
        const url = `${environment.apiUrl}/loads`;
        const options = {params: params};

        return this.http.post<Load>(url, data, options).pipe(
            map((response: Response | any) => response || {}),
            catchError(err => throwError(err || 'Server error'))
        );
    }

    destroy(id: string, params?: any) {
        const url = `${environment.apiUrl}/loads/${id}`;
        const options = {params: params};

        return this.http.delete(url, options).pipe(
            map((response: Response | any) => response || {}),
            catchError(err => throwError(err || 'Server error'))
        );
    }
}
