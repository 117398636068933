
<h2 mat-dialog-title>Cancelar Carga</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="outline" class="tkg-input-select">
    <mat-label>Motivos do cancelamento</mat-label>
    <mat-select [formControl]="justificationSelected">
      <mat-option *ngFor="let j of justifications" [value]="j">{{j.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
  <button type="submit" mat-button color="primary" [disabled]="!justificationSelected.valid" (click)="submit()">Salvar</button>
</mat-dialog-actions>
