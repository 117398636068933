import { Component, Inject, OnInit } from '@angular/core';
import { PendencyService } from '../../shipment/shared/pendency.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-pendency-obs',
  templateUrl: './pendency-obs.component.html',
  styleUrls: ['./pendency-obs.component.scss']
})
export class PendencyObsComponent implements OnInit {
  form = this.formBuilder.group({
    justification: [this.data.item.justification, [Validators.required]],
    id: [this.data.item.id, [Validators.required]],
  });
  errors: any = {};
  
  // @Output() reloadEvent = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<PendencyObsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private pendencyService: PendencyService,
  ) { }


  ngOnInit() {
  }
  // reload(params?: any) {
  //   return this.reloadEvent.emit(params);
  // }
  onSubmit() {
    const data = {
      id: this.data.item.id,
      justification: this.form.value.justification
    }
    this.pendencyService.updateShipmentLoads(data).subscribe((r: any) => {
      this.dialogRef.close(data);
      // this.reload();
    }, (e: any) => {
      console.log('--erro--');
      console.log(e);
    });

  }




}
