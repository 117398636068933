import { ChangeDetectorRef, Component } from '@angular/core';
@Component({
  selector: 'app-shifts-list',
  templateUrl: './shifts-list.component.html',
  styleUrls: ['./shifts-list.component.scss']
})
export class ShiftsListComponent{
  constructor(
    private cd: ChangeDetectorRef
  ) {}

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }
}
