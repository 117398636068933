import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { ConfigurationService } from '../../configuration/shared/configuration.service';
import { PendencyService } from '../../shipment/shared/pendency.service';
import { StepService } from '../../shipment/shared/step.service';
import { DatePipe } from '@angular/common';
import { DockService } from '../shared/dock.service';

@Component({
  selector: 'app-dock-monitor',
  templateUrl: './dock-monitor.component.html',
  styleUrls: ['./dock-monitor.component.scss']
})
export class DockMonitorComponent implements OnInit {
  dataSource: any;
  formatedPercent: string;
  configurationRegressiveCounter: any;
  search: string;
  out_prevision: any;
  processedPercentage: any;

  constructor(
    private configurationService: ConfigurationService,
    private pendencyService: PendencyService,
    private stepService: StepService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<DockMonitorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dockService: DockService
  ) { }

  getConfigRegressiveCounter() {
    this.configurationService.index({ search: 'name:regressive-counter' }).subscribe((configuration: any) => {
      this.configurationRegressiveCounter = configuration.data[0];
    });
  }

  getShipmentSteps() {
    const options = {
      orderBy: 'created_at',
      sortedBy: 'asc',
      with: 'type;pendencies'
    };

    if (this.data.item.shipment_id) {
      Object.assign(options, {
        search: `shipment_id:${this.data.item.shipment_id}`
      });

      this.stepService.index(options).subscribe((responseSteps: Response | any) => {
        this.data.steps = responseSteps.data;
      });
    }
  }

  showBoxes() {
    let boxes = [];
    this.data.item.resource.vbox.forEach(e => {
      boxes.push(e.vbox);
    });

    return boxes.toString().replace(/,/g, ', ');
  }

  showStep(step) {
    const steps = {
      'pk': 'PICKING',
      'pkc': 'PICKING CONCLUÍDO',
      'dc': 'DUPLA CONFERÊNCIA',
      'ana': 'ANALISAR',
      'req': 'REQ. ESPECIAL',
      'anf': 'AG. IMPRESSÃO NF',
      'cpi': 'CONSULTA PIN',
      'let': 'LEITURA ETQ.',
      'ave': 'AG. VEÍCULO',
      'ld': 'CARREGAMENTO',
      'rel': 'CONF. RELATÓRIO',
      'cc': 'CONCLUÍDO'
    };

    return steps[step];
  }


  startStep(shipment) {
    if (shipment.step === 'PC') {
      return this.datePipe.transform(shipment.picking_started_at, 'HH:mm:ss');
    }
    if (shipment.step === 'DC') {
      return this.datePipe.transform(shipment.double_counts_started_at, 'HH:mm:ss');
    }
    if (shipment.step === 'LD') {
      return this.datePipe.transform(shipment.loading_started_at, 'HH:mm:ss');
    }
    if (shipment.step === 'SLD') {
      return this.datePipe.transform(shipment.loading_started_at, 'HH:mm:ss');
    }
  }

  tsStep(dataLog) {
    // const configs = this.data.configuration.value;
    // let forecast;
    // forecast = moment(dataLog.ts).add(configs.picking, 'm').toDate();
    return this.datePipe.transform(dataLog.ts, 'DD/MM/YYYY HH:mm:ss');

  }



  getStepFinishForecast(shipment) {
    const configs = this.data.configuration.value;
    let forecast;

    if (shipment.step === 'PC') {
      forecast = moment(shipment.picking_started_at).add(configs.picking, 'm').toDate();
      return this.datePipe.transform(forecast, 'HH:mm:ss');
    }

    if (shipment.step === 'DC') {
      forecast = moment(shipment.double_counts_started_at).add(configs.double_counting, 'm').toDate();
      return this.datePipe.transform(forecast, 'HH:mm:ss');
    }

    if (shipment.step === 'LD') {
      forecast = moment(shipment.loading_started_at).add(configs.shipment, 'm').toDate();
      return this.datePipe.transform(forecast, 'HH:mm:ss');
    }
  }

  getDockArrival() {
    if (this.data.item.wait) {
      const start_at = moment.tz(this.data.item.wait.arrival_at, 'America/Sao_Paulo');
      const configs = this.data.configuration.value;
      const sum = configs.picking + configs.double_counting + configs.shipment;
      this.out_prevision = moment.tz(start_at, 'America/Sao_Paulo').add(sum * this.data.item.shipments.length, 'm');
    }
  }

  getProcessedPercentage() {
    if (this.data.item.resource.vbox.length > 0) {
      const total = this.data.item.resource.vbox.map(item => item.operation.order_vol).reduce((a, b) => a + b);
      const confered = this.data.item.resource.vbox.map(item => item.order_vol_confered).reduce((a, b) => a + b);
      this.processedPercentage = ((confered / total) * 100).toFixed(2);
    } else {
      this.processedPercentage = 0;
    }
  }

  nfStatus() {
    if (this.data.item.resource.vbox) {
      let a = [];
      this.data.item.resource.vbox.forEach(element => {
        if (element.operation.minor_status === 'NF') {
          a.push(1);
        } else {
          a.push(0);
        }
      });
      let sumArray = a.reduce((a, b) => a + b, 0);
      if (sumArray === this.data.item.resource.vbox.length && this.data.item.resource.vbox.length > 0) {
        return 'Emitida';
      } else {
        return 'Aguardando';
      }
    } else {
      return 'Aguardando';
    }
  }

  ngOnInit() {

    this.getConfigRegressiveCounter();
    this.getShipmentSteps();

    this.getDockArrival();
    this.getProcessedPercentage();
    this.dataSource = this.data;
    console.log("-------------------");
    console.log(this.dataSource);
  }
}
