
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DockService } from '../shared/dock.service';

@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.scss']
})
export class TesteComponent implements OnInit {
  dataSource: any;
  displayedColumns: string[] = ['id', 'box', 'step', 'min_start'];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dockService: DockService
  ) { }

  ngOnInit() {

    this.sort.sortChange.subscribe((r: any) => {
      this.sortTable(r)
    });


    const options = {
      orderBy: 'box',
      sortedBy: this.sort.direction,
      page: 1,
      pageSize: 100,
    };
    this.dockService.boxes(options).subscribe(data => {
      console.log('--------')
      this.dataSource = new MatTableDataSource(data.data);
      // this.dataSource.sort = this.sort;
    })
  }

  sortTable(sortObject: any) {
    console.log(sortObject)
    const options = {
      orderBy: 'box',
      sortedBy: this.sort.direction,
      page: 11,
      pageSize: 100,
    };
    if (sortObject.active === 'min_start') {
      this.dataSource.sort = this.sort;
      console.log(this.dataSource)
    }
    else {
      this.dockService.boxes(options).subscribe((r: any) => {
        this.dataSource = r.data
      })
    }
  }

}
