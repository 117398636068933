interface Shift{
    action:ShiftsDialogAction,
    type: "shift" | "break" | "downtime";
    data:{
      id?:number;
      name:string;
      created_at:string;
      updated_at:string
    }
}

export type ShiftBreakInterface = Shift & {
  data:{
    resource:{
      shift_shifts_id?:number;
      name:string;
      start:string;
      end:string;
      weekday:number[];
      duration:string;
      date_exception:string;
    };
  }
};

export type DowntimeInterface = {
  action:ShiftsDialogAction,
  data:Array<Shift['data'] & {
    resource:{
      start:string;
      end:string;
    }
  }>
};

export enum ShiftsDialogAction {
  EDIT,
  ADD,
  VIEW,
}

export enum WeekDay {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const months = [
  {value:1, name: "Janeiro"},
  {value:2, name: "Fevereiro"},
  {value:3, name: "Março"},
  {value:4, name: "Abril"},
  {value:5, name: "Maio"},
  {value:6, name: "Junho"},
  {value:7, name: "Julho"},
  {value:8, name: "Agosto"},
  {value:9, name: "Setembro"},
  {value:10, name: "Outubro"},
  {value:11, name: "Novembro"},
  {value:12, name: "Dezembro"}
];

export const weekDay = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado"
];
