import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

  transform(collection: Array<any>, property: string): Array<any> {
    if (!collection) {
      return null;
    }

    const group = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }
      return previous;
    }, {});

    return Object.keys(group).map(key => ({ key, value: group[key] }));
  }
}
