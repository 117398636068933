<div class="main-gap-only-secure">
  <div>
    <div>
      <img class="logo" src="assets/img/logo-trackage-vertical.svg" alt="Trackage">
      <mat-card>
        <mat-card-title-group>
          <h2 mat-card-title>Entrar</h2>
        </mat-card-title-group>
        <mat-card-content>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div>
              <mat-form-field>
                <input type="email" formControlName="email" matInput placeholder="E-mail">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" matInput placeholder="Senha">
                <mat-icon matSuffix style="cursor: pointer;" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
              </mat-form-field>
            </div>
            <div ngClass="margin-top">
              <span>
                <button type="button" mat-button [matMenuTriggerFor]="menu" color="primary">Mais opções</button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item routerLink="/auth/register">Registrar</button>
                  <button mat-menu-item routerLink="/auth/remember">Esqueci a senha</button>
                </mat-menu>
              </span>
              <span>
                <button type="submit" [disabled]="!form.valid" mat-button mat-raised-button color="primary">
                  ENTRAR
                </button>
              </span>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
