<mat-grid-list [cols]="columnSize" rowHeight="284px">
  <mat-grid-tile *ngFor="let item of conferenceList">
    <mat-card class="tkg-card">
      <div class="tkg-row">
        <div class="tkg-column-50 tkg-header tkg-align-left">Box físico</div>
        <div class="tkg-column-50 tkg-header tkg-align-left">Box virtual</div>
      </div>
      <div class="tkg-row">
        <div class="tkg-column-50 tkg-header-data tkg-align-left">{{item.resource.box}}</div>
        <div class="tkg-column-50 tkg-header-data tkg-align-left">{{showBoxes(item.resource.vbox)}}</div>
      </div>
      <div class="tkg-row">
        <div class="tkg-column-100 tkg-header tkg-align-left">Transportadora</div>
      </div>
      <div class="tkg-row">
        <div class="tkg-column-100 tkg-header-data tkg-align-left">{{getCarrier(item.resource)}}</div>
      </div>
      <!-- <div *ngIf="getClient(item.resource).show"> -->
        <div class="tkg-row">
          <div class="tkg-column-100 tkg-header tkg-align-left">Cliente</div>
        </div>
        <div class="tkg-row">
          <div class="tkg-column-100 tkg-header-data tkg-align-left" >{{getClient(item.resource).show ? getClient(item.resource).client : '-'}}</div>
        </div>
        <div class="tkg-row">
          <div class="tkg-column-50 tkg-header tkg-align-left">NR Carga</div>
          <div class="tkg-column-50 tkg-header tkg-align-left">Picking</div>
        </div>
        <div class="tkg-row">
          <div class="tkg-column-50 tkg-header-data tkg-align-left" >{{getClient(item.resource).show ? item.load_number.toString().lenght > 0 ? item.load_number.toString() : '-' : '-'}}</div>
          <div class="tkg-column-50 tkg-header-data tkg-align-left" >
            <app-picking-percentage [box_id]="item.resource.box_id"></app-picking-percentage>
          </div>
        </div>
      <!-- </div> -->

      <mat-card-actions>


        <div class="tkg-row-detail">
          <button mat-button [class]="showDCStatus(item.resource.dc_status, 'class')" (click)="conference(item.resource)">{{showDCStatus(item.resource.dc_status, 'text')}}</button>
        </div>

      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
