import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Carrier } from '../carrier';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  constructor(
    private http: HttpClient
  ) { }

  index(options?: any): Observable<Carrier> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
      pageSize: 1000
    }, options);

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/carriers`;

    return this.http.get<Carrier>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, id: string, params?: any): Observable<Carrier> {
    const url = `${environment.apiUrl}/carriers/${id}`;
    const options = {params: params};

    return this.http.put<Carrier>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  create(data: any, params?: any): Observable<Carrier> {
    const url = `${environment.apiUrl}/carriers`;
    const options = {params: params};

    return this.http.post<Carrier>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/carriers/${id}`;
    const options = {params: params};

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  carrierConferenceList(options?: any): Observable<any> {

    const params = new HttpParams({fromObject: options});

    const url = `${environment.apiUrl}/carrier/conference`;

    return this.http.get<any>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  conferenceValidationList(options?: any): Observable<any> {

    const params = new HttpParams({fromObject: options});

    const url = `${environment.apiUrl}/carrier/conference/validation`;

    return this.http.get<any>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }


  createConferenceFromBox(data: any): Observable<any> {
    const url = `${environment.apiUrl}/carrier/conference`;
    // const options = {params: params};

    return this.http.post<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  getConferenceMap(data: any): Observable<any> {
    const url = `${environment.apiUrl}/carrier/conference/print`;
    // const options = {params: params};

    return this.http.post<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  showConferenceFromBox(box_id: number): Observable<any> {
    const url = `${environment.apiUrl}/carrier/conference/${box_id}`;

    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }


  updateConferenceFromBox(data: any): Observable<any> {
    const url = `${environment.apiUrl}/carrier/conference`;

    return this.http.put<Carrier>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  updateConferenceValidation(data: any): Observable<any> {
    const url = `${environment.apiUrl}/carrier/conference/validate`;

    return this.http.put<Carrier>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  deleteUC(id: string, params?: any) {
    const url = `${environment.apiUrl}/carrier/conference/delete/${id}`;
    const options = {params: params};

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

}
