import { Component, ViewChild, AfterViewInit, Inject } from "@angular/core";
import { BarcodeScannerLivestreamComponent } from "ngx-barcode-scanner";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tkg-barcode-scanner',
  templateUrl: './tkg-barcode-scanner.component.html'
})

export class TkgBarcodeScannerComponent implements AfterViewInit {
  @ViewChild(BarcodeScannerLivestreamComponent)
  barcodeScanner: BarcodeScannerLivestreamComponent;
  barcodeValue;
  msg;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TkgBarcodeScannerComponent>
  ){
    // ToDo: Recebe lista de UCs do componente pai
    console.log('recebe lista de ucs')
    console.log(this.data)
  }

  ngAfterViewInit() {
    this.barcodeScanner.start();
  }

  onValueChanges(result) {
    this.barcodeValue = result.codeResult.code;
    this.findBarcode(this.barcodeValue);
  }

  onStarted(started) {
    console.log(started);
  }

  // A cada leitura realizada pela câmera, busca na lista de UCs
  // se o código da leitura faz parte da lista informada.
  // Caso positivo, fecha o diálogo retornando o código de
  // barra encontrado.
  findBarcode(barcode: string) {
    this.data.find((item: any) => {
      if(item.pallet == barcode) {
        this.dialogRef.close({find: true, pallet: barcode})
      } else {
        this.msg = 'Código não encontrado para o box';
      }
    });
  }

  close(){
    this.dialogRef.close({find: false})
  }
}
