import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InvoiceService } from '../shared/invoice.service';

@Component({
  selector: 'app-invoice-notes',
  templateUrl: './invoice-notes.component.html',
  styleUrls: ['./invoice-notes.component.scss']
})
export class InvoiceNotesComponent implements OnInit {
  form = this.formBuilder.group({
    obs: [this.data.item.obs]
  });
  constructor(
    public dialogRef: MatDialogRef<InvoiceNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private invoiceService: InvoiceService
  ) { }

  ngOnInit() {
    console.log('---data');
    console.log(this.data);
  }

  onSubmit() {
    const data = {
      id: this.data.item.id,
      obs: this.form.value.obs
    }
    console.log(data);
    // this.dockService.updateNotes(data).subscribe((r: any) => {
    //   this.dialogRef.close(data);
    // }, (e: any) => {
    //   console.log('--erro--');
    //   console.log(e);
    // });
    this.invoiceService.update(data, this.data.item.id).subscribe((r: any) => {
      this.dialogRef.close(data);
    }, (e: any) => {
      console.log('--erro--');
      console.log(e);
    });
  }

}
