import { Component, OnInit, Inject } from '@angular/core';
import { DashboardService } from '../shared/dashboard.service'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-analise-details-dialog',
  templateUrl: './analise-details-dialog.component.html',
  styleUrls: ['./analise-details-dialog.component.scss']
})
export class AnaliseDetailsDialogComponent implements OnInit {
  justifications_list: any;
  constructor(
    public dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log('data');
    console.log(this.data);
    this.loadJustifications(this.data.date);
  }

  loadJustifications(date: any) {
    const options = {
      date: date
    }
    this.dashboardService.justificativas(options).subscribe((r: any) => {
      this.justifications_list = r;
    });
  }

}
