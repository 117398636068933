<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-select>
    <div *ngIf="fe_search || be_search" class="input-search">
      <form [formGroup]="searchForm" class="tkg-search-input-form">
        <input matInput placeholder="Pesquisar" formControlName="search">
      </form>
      <button mat-icon-button aria-label="Clear data" (click)="clearSearchString()" class="search-button-icon" [disabled]="searchForm.value.search.length === 0">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-option *ngFor="let item of list" [value]="item[return_value]" (click)="selectedOption(item[return_value])">
      {{showPattern(item)}}
    </mat-option>
  </mat-select>
</mat-form-field>
