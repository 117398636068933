<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <h2 mat-dialog-title>{{showAction(form.get('box').value)}}</h2>
  <mat-dialog-content class="mat-typography">

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <input type="text" formControlName="box" matInput placeholder="Box virtual">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.box">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-select formControlName="wave" placeholder="Onda">
          <!-- #substituir -->
          <!-- <ngx-mat-select-search [formControl]="loadFilter" placeholderLabel="Buscar onda"
            noEntriesFoundLabel="Nenhum resultado encontrado"></ngx-mat-select-search> -->
          <mat-option *ngFor="let item of LoadsFiltered" [value]="item.wave">
            {{ item.wave }} - {{ item.carrier.name }}
          </mat-option>
        </mat-select>
        <mat-hint>
          <div>Onda - transportadora</div>
          <div class="mat-error" *ngFor="let error of errors.wave">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
    <button type="submit" mat-button color="primary" [disabled]="!form.valid">Salvar</button>
  </mat-dialog-actions>
</form>
