import { Component, OnInit, Inject } from "@angular/core";
import { SupportDataService } from '../../shared/support-data.service';
import { DockService } from '../../dock/shared/dock.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StaffService } from 'src/app/staff/shared/staff.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";


@Component({
  selector: 'app-dialog-analise',
  templateUrl: './dialog-analise.component.html',
  styleUrls: ['./dialog-analise.component.scss']
})
export class DialogAnaliseComponent implements OnInit {
  justify_id: number;
  justifyO: any;
  form: UntypedFormGroup;


  justifications: string[] = ['teste'];
  justificationList: any[] = [];
  justificationListBase: any[] = [];
  staffList: any;
  tipoErro: any[] = [
    {tipo: "3M"},
    {tipo: "TPC"}
  ];

  constructor(
    private dialog: MatDialog,
    private spData: SupportDataService,
    private staffService: StaffService,
    private dockService: DockService,
    public dialogRef: MatDialogRef<DialogAnaliseComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.form = this.formBuilder.group({
      justification_id: ['', [Validators.required]],
      // error_type: ['', [Validators.required]],
      staff_code: ['', [Validators.required]],
      qtd: ['', [Validators.required]]
    });

    this.getStaff();
    this.loadJustifyType();
  }

  getJustificationList() {
    if(this.data.item.resource.hasOwnProperty('justification_list')) {
      console.log('tem lista');
      this.justificationList = this.data.item.resource.justification_list;
      this.justificationListBase = JSON.parse(JSON.stringify(this.data.item.resource.justification_list));
    }
  }

  getStaff() {
    this.staffService.index({}).subscribe((r: any) => {
      this.staffList = r.data;
    });
  }

  loadJustifyType() {
    this.spData.resourceName('justify_analysis').subscribe((r: any) => {
      this.justifications = r.data.resource.filter((ft: any) => ft.active === true);
      this.getJustificationList();
    }, (e: any) => { });
  }

  save() {
    const data = {
      box_id: this.data.item.resource.box_id,
      step: this.data.toStep,
      justification_list: this.justificationList
    }
    this.dockService.storeJustify(data).subscribe((r: any) => {
      this.dialogRef.close(data);
    });
  }

  cancel() {
    if(this.justificationList.length != this.justificationListBase.length){
      this.alteredRowsMessage();
    } else {
      this.dialogRef.close();
    }
  }

  alteredRowsMessage() {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Atenção',
        message: 'Os registros foram alterados. Tem certeza que deseja descartar as alterações?'
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.dialogRef.close();
      }
    });
  }

  addData() {
    const data =  {
      justification_id: this.form.value.justification_id,
      error_type: this.form.value.error_type,
      staff_code: this.form.value.staff_code,
      qtd: this.form.value.qtd,
      step: this.data.toStep,
    }
    this.justificationList.push(data);
    console.log(this.justificationList);
  }

  removeData(idx: number) {
    this.justificationList.splice(idx, 1);
  }

  formatError(error: number) {
    const erro: any = this.justifications.filter((item: any) => item.id === error)[0];
    return erro.name;
  }

  formatStaff(code: string) {
    if(code) {
      const staff: any = this.staffList.filter((item: any) => item.code === code)[0];
      if(staff) {
        return `${code} - ${staff.name}`;
      } else {
        return '';
      }

    } else {
      return '';
    }
  }

}
