<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ (form.value.first_name) ? form.value.first_name + ' ' + ((form.value.last_name) ? form.value.last_name : '') :
    ((data.action == 'EDIT') ? 'Salvar' : 'Adicionar') }}
  </h2>
  <mat-dialog-content class="mat-typography">

    <div class="tkg-form-row">
      <mat-form-field class="tkg-form-item-50">
        <input type="text" formControlName="first_name" matInput placeholder="Nome">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.first_name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="tkg-form-item-50">
        <input type="text" formControlName="last_name" matInput placeholder="Sobrenome">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.last_name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="tkg-form-row">
      <mat-form-field class="tkg-form-item-100">
        <input type="email" formControlName="email" matInput placeholder="E-mail">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.email">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
      </div>
      <div class="tkg-form-row">
      <mat-form-field class="tkg-form-item-100" *ngIf="data.action == 'ADD'">
        <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" matInput placeholder="Senha">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.password">{{ error }}</div>
        </mat-hint>
        <mat-icon matSuffix style="cursor: pointer;" (click)="hidePassword = !hidePassword">
          {{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
    </div>

    <div class="tkg-form-row">
      <div class="tkg-form-item-100 margin-bottom">
        <mat-form-field style="width: 100%;">
          <mat-label>Grupos</mat-label>
          <mat-select formControlName="group_id">
            <mat-option *ngFor="let group of groups" [value]="group.id">
              {{ group.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <h3>Permissões</h3>

    <div class="tkg-form-row">
      <!-- <div fxFlex="1 1 auto" fxLayoutGap="3px" class="margin-bottom">
        <div fxLayout="column" fxLayoutGap="3px">
          <mat-slide-toggle formControlName="is_superuser">Status de superusuário</mat-slide-toggle>
          <mat-hint>Indica que este usuário tem todas as permissões sem atribuí-las explicitamente.</mat-hint>
        </div>
      </div>

      <div fxFlex="1 1 auto" fxLayoutGap="3px" class="margin-bottom">
        <div fxLayout="column" fxLayoutGap="3px">
          <mat-slide-toggle formControlName="is_staff">Membro da equipe</mat-slide-toggle>
          <mat-hint>Indica que usuário consegue acessar este site de administração.</mat-hint>
        </div>
      </div> -->

        <div class="tkg-form-item-100 margin-bottom">
          <mat-slide-toggle formControlName="is_active">Ativo</mat-slide-toggle>
          <mat-hint>
            Indica que o usuário será tratado como ativo. Ao invés de excluir contas de usuário, desmarque isso.
          </mat-hint>
        </div>

        <div class="tkg-form-item-100 margin-bottom">
          <mat-slide-toggle formControlName="carrier_user">Usuário de transportadora</mat-slide-toggle>
          <mat-hint>
            Indica que o usuário vai estar vinculado a uma transportadora.
          </mat-hint>
        </div>

        <div class="tkg-form-row" *ngIf="form.value.carrier_user">
          <div class="tkg-form-item-100 margin-bottom">
            <mat-form-field style="width: 100%;">
              <mat-label>Transportadora</mat-label>
              <mat-select formControlName="carrier_id" multiple>
                <mat-option *ngFor="let carrier of carrierList" [value]="carrier.id">
                  {{ carrier.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

    </div>

    <!-- <h3>Retenção de Dados</h3>

    <div fxLayout="row wrap">
      <mat-form-field fxFlex>
        <input type="text" formControlName="retention_list_limit" matInput
          placeholder="Prazo de exibição de registros(em minutos)">
        <mat-hint>Indica qual é o prazo máximo para exibição de dados em todas as telas. Após esse prazo, os registros
          não são mais exibidos, mas se mantém no banco de dados.</mat-hint>
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.retention_list_limit">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div> -->

    <!-- <h3>Alertas</h3>

    <div fxLayout="column wrap" fxLayoutGap="10px">
      <mat-checkbox formControlName="is_alert_mail">Receber alertas por e-mail</mat-checkbox>
      <mat-checkbox formControlName="is_alert_phone">Receber alertas por celular</mat-checkbox>
    </div> -->


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
    <button type="submit" mat-button color="primary" cdkFocusInitial
      [disabled]="!form.valid">{{ (data.action == 'EDIT') ? 'Salvar' : 'Adicionar' }}</button>
  </mat-dialog-actions>
</form>
