import { SupportDataService } from "src/app/shared/support-data.service";
import { ShipmentService } from "./../../shipment/shared/shipment.service";
import { JustifyStepComponent } from "./../justify-step/justify-step.component";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DockService } from "../shared/dock.service";
import { CarrierService } from "../../carrier/shared/carrier.service";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Dock } from "../dock";
import { Subject, merge, of as observableOf, Subscription } from "rxjs";
import { catchError, tap, startWith, switchMap, map } from "rxjs/operators";
import { DockDetailComponent } from "../dock-detail/dock-detail.component";
import { DockArrivalComponent } from "../dock-arrival/dock-arrival.component";
import { AssignChargeComponent } from "../assign-charge/assign-charge.component";
import { BoxInvoiceComponent } from "../box-invoice/box-invoice.component";
import { BoxNoteComponent } from "../box-note/box-note.component";
import { SearchService } from "../../shared/search.service";
import { Search } from "../../shared/search";
import { RefreshService } from "../../shared/refresh.service";
import { PendencyListComponent } from "../pendency-list/pendency-list.component";
import { DockMonitorComponent } from "../dock-monitor/dock-monitor.component";
import { DialogAnaliseComponent } from "../dialog-analise/dialog-analise.component";
import { OperationDetailComponent } from "../operation-detail/operation-detail.component";
import { ConfigurationService } from "../../configuration/shared/configuration.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { DatePipe } from "@angular/common";
import { InvoiceService } from "../../invoice/shared/invoice.service";
import { environment } from "./../../../environments/environment.prod";
import { webSocket } from "rxjs/webSocket";
// import { FirebaseService } from '../../shared/firebase.service';
import * as moment from "moment";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import { Router } from "@angular/router";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { CancelLoadComponent } from "../cancel-load/cancel-load.component";
import {
  HumanizeDurationLanguage,
  HumanizeDuration,
} from "humanize-duration-ts";

@Component({
  selector: "app-dock-list",
  templateUrl: "./dock-list.component.html",
  styleUrls: ["./dock-list.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" }),
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"),
      ),
    ]),
  ],
})
export class DockListComponent implements OnInit, OnDestroy, AfterViewInit {
  langService: HumanizeDurationLanguage = new HumanizeDurationLanguage();
  humanizer: HumanizeDuration = new HumanizeDuration(this.langService);
  form = this.formBuilder.group({
    carrier_id: [""],
  });

  // searchWatcher: Subscription;
  // refreshWatcher: Subscription;
  search: string;
  pageSize: number;
  last_step: string = "";
  tkg_processing_icon: string = "tkg-icon-gray";
  tkg_receive_icon: string = "tkg-icon-gray";

  length: number;
  val: number;
  stepsFiltered = "";
  carrierFiltered = "";
  displayedColumns: string[];
  displayedShipmentColumns: string[];
  expandedElement = null;
  showListEmpty = false;
  breakpoints = Breakpoints;
  configurationRegressiveCounter: any;
  // dataSource = new MatTableDataSource<any>();
  dataSource: any;
  data: Dock[] = [];
  export = false;
  lastUpdate: any;
  refreshEnabled: any;
  loading: any;
  element: any;
  steps: any[] = [];
  carriers: any;
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  stepsFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  protected _onDestroy = new Subject<void>();

  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  orderBy = "box";

  options = {
    export: false,
    orderBy: "box",
    last_step: this.stepsFiltered ? this.stepsFiltered : "",
    carrier_id: this.carrierFiltered ? this.carrierFiltered : "",
    pageSize: 100,
  };

  constructor(
    private supportDataService: SupportDataService,
    private dockService: DockService,
    private carrierService: CarrierService,
    private shipmentService: ShipmentService,
    private invoiceService: InvoiceService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private searchService: SearchService,
    private refreshService: RefreshService,
    private datePipe: DatePipe,
    private router: Router,
    private configurationService: ConfigurationService,
    // private firebaseService: FirebaseService,
    public breakpointObserver: BreakpointObserver,
    private _liveAnnouncer: LiveAnnouncer,
  ) {
    this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      console.log("--> smart phone");
      this.router.navigate(["admin/docks-mobile"]);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      console.log("--> tablet");
      this.router.navigate(["admin/docks-mobile"]);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      console.log("--> web");
      this.displayedColumns = [
        "box",
        "carrier_carriers:carrier_id|carrier_carriers.name",
        "assigned",
        "vboxes",
        "tvolume",
        "status",
        "invoice",
        "notes",
        "just",
        "arrival_prev",
        "min_start",
        "max_dt",
        "print_map",
        "dc_end",
        "conference_started",
        "steps",
        "time_in_step",
        "actions",
      ];
      this.displayedShipmentColumns = [
        "box",
        "vbox_min_dt",
        "wave",
        "client",
        "volumes",
        "volume",
        "vol_percent",
        "amount",
        "amount_confered",
        "qtd_percent",
        "vboxStatus",
        "actions",
      ];
    }
  }

  reload(params?: any) {
    this.showLastUpdate();
    return this.reloadEvent.emit(params);
  }

  assingCharge(event, element) {
    const dialogRef = this.dialog.open(AssignChargeComponent, {
      panelClass: ["dialog-large"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.snackBar.open("Carga associada com sucesso", "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
        this.reload();
      }
    });
  }

  cancelLoad(event, element) {
    const dialogRef = this.dialog.open(CancelLoadComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.snackBar.open("Carga cancelada com sucesso", "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
        this.reload();
      }
    });
  }

  justify(message, data) {
    const dialogRef = this.dialog.open(JustifyStepComponent, {
      panelClass: ["dialog-fullscreen"],
      disableClose: true,
      data: {
        message: message,
        step: data.step,
        box_id: data.box_id,
        justifications: [
          {
            id: 1,
            name: "Teste",
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if (data) {
        this.onStartStep(data, data.step);
      }
    });
  }

  justifyAnalisis(element) {
    //novo
    console.log("element");
    console.log(element);
    const dialogRef = this.dialog.open(DialogAnaliseComponent, {
      panelClass: ["dialog-medium"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
        toStep: element.step,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.reload();
    });
  }

  onOpen(event, action: string, item: any = {}) {
    const dialogRef = this.dialog.open(DockDetailComponent, {
      panelClass: ["dialog-small", "dialog-fullscreen"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        let message;
        if (action === "DIRECT") {
          this.reload();
          message = "Veículo direcionado com sucesso!";
        } else if (action === "ADD") {
          this.reload();
          message = "Doca adicionada com sucesso!";
        }

        this.snackBar.open(message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
    });
  }

  onStartStep(element, step) {
    if (element.step === "ana") {
      this.onStartStepAnalise(element, step);
    } else {
      const data = {
        box_id: element.resource.box_id,
        step: step,
        justify_id: element.justify_id ? element.justify_id : "",
      };

      this.dockService.saveStep(data).subscribe((r: any) => {
        let message;
        if (r.success === true) {
          message = "Etapa registrada com sucesso!";
          this.showMessage(message);
          this.reload();
        } else {
          if (r.showDialog) {
            this.justify(r.message, data);
          } else {
            message = r.message;
            this.showMessage(message);
          }
        }
      });
    } //
  }

  showMessage(message) {
    this.snackBar.open(message, "OK", {
      duration: 3000,
      horizontalPosition: "left",
      verticalPosition: "bottom",
    });
  }

  onStartStepAnalise(element, step) {
    const data = {
      box_id: element.resource.box_id,
      step: step,
      justify_id: element.justify_id ? element.justify_id : "",
    };
    this.dockService.saveStep(data).subscribe((r: any) => {
      let message;
      if (r.success === true) {
        message = "Etapa registrada com sucesso!";
        this.reload();
      } else {
        message = r.message;
      }
      this.snackBar.open(message, "OK", {
        duration: 3000,
        horizontalPosition: "left",
        verticalPosition: "bottom",
      });
    });
  }

  onOpenPendencies(event, element: any = {}) {
    const dialogRef = this.dialog.open(PendencyListComponent, {
      panelClass: ["dialog-large"],
      disableClose: true,
      data: {
        dock: JSON.parse(JSON.stringify(element)),
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.reload();
      }
    });
  }

  onStartOperation(event, element: any = {}) {
    const dialogRef = this.dialog.open(OperationDetailComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        dock: JSON.parse(JSON.stringify(element)),
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.reload();
      }
    });
  }

  onOpenDockRow(event, element: any = {}) {
    this.dialog.open(DockMonitorComponent, {
      panelClass: ["dialog-fullscreen", "monitor-fullscreen"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
        configuration: this.configurationRegressiveCounter,
      },
    });
  }

  onOpenArrivalPrev(element: any) {
    const dialogRef = this.dialog.open(DockArrivalComponent, {
      panelClass: ["dialog-small", "dialog-fullscreen"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.success) {
        this.reload();
        let message = "Previsão de chegada, atualizada!";
        this.snackBar.open(message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
    });
  }

  showStatus(element) {
    const status = ["CQ", "PR", "CK", "NR", "NH", "NA", "NF"];
    let st_names = [];
    let st_idxs = [];
    element.vbox.forEach((element) => {
      if (!st_names.includes(element.operation.minor_status)) {
        st_names.push(element.operation.minor_status);
        st_idxs.push(status.indexOf(element.operation.minor_status));
      }
    });
    const min = Math.min.apply(null, st_idxs);

    return status[min];
  }

  showStep(step) {
    const stepFt: any = this.steps.filter((ft: any) => step === ft.id);
    if (stepFt.length > 0) {
      return stepFt[0];
    } else {
      return "-";
    }
  }

  showStepTime(steps) {
    return moment(steps[0]["ts"]);
  }

  showNow() {
    return moment();
  }

  formatTime(date) {
    return moment(date, "HH:mm").format("hh:mm");
  }

  sumConference(shipment) {
    return shipment.shipment_load.conferences.reduce(
      (prev, current) => prev + current.volume,
      0,
    );
  }

  percentCompleted(shipment) {
    const processed = shipment.shipment_load.conferences.reduce(
      (prev, current) => prev + current.volume,
      0,
    );
    const total = shipment.shipment_load.volumes;

    return ((processed / total) * 100).toFixed(2);
  }

  setFilterStep(step) {
    console.log(this.stepsFilter.value);
    this.options.orderBy = "step";
    this.stepsFiltered = this.stepsFilter.value.toString();
    this.options.last_step = this.stepsFiltered;
    this.reload();
  }

  getCarrierList() {
    const options = {
      pageSize: 1000,
    };
    this.carrierService.index(options).subscribe((r: any) => {
      this.carriers = r.data;
    });
  }

  getFilterCarrier() {
    this.carrierFiltered = this.carrierFilter.value.toString();
    this.options.carrier_id = this.carrierFiltered;
    this.reload();
  }

  printMapTime(element) {
    const filtered = element.steps.filter((time) => time.step === "dc");
    if (filtered.length > 0) {
      return filtered[0].ts.toString();
    } else {
      return "";
    }
  }

  ngOnInit() {
    this.getStepsList();

    // Conecta no websocket e fica escutando por atualizações para dar refresh na página
    const subject = webSocket({
      url: `${environment.wsUrl}/import/csv`,
      deserializer: () => {},
    });

    subject.subscribe(
      (msg) => {
        this.reload();
      },
      (err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      () => console.log("complete"), // Called when connection is closed (for whatever reason).
    );
  }

  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
    this.initWeb();
  }

  initWeb() {
    this.getCarrierList();

    // If the dock changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe((r: any) => {
      this.sortTable(r);
    });

    merge(this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          Object.assign(this.options, {
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
          });

          if (this.search) {
            Object.assign(this.options, {
              search: this.search,
            });
          }

          return this.dockService.boxes(this.options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.showListEmpty = response.data.length === 0;
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        }),
      )
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
      });

    this.showLastUpdate();
  }

  showLastUpdate() {
    this.dockService.getLastUpdate().subscribe((r: any) => {
      const now = moment(new Date());

      // Horário de backup da base 3M não deve mostrar como falha (vermelho).
      // O mostrador fica cinza para este caso
      const format = "hh:mm:ss";
      const time = moment(now, format);
      const beforeTime = moment("23:55:00", format);
      const afterTime = moment("01:45:00", format);
      const endOfDayTime = moment("23:59:59", format);
      const startOfDayTime = moment("00:00:00", format);
      if (
        time.isBetween(beforeTime, endOfDayTime) ||
        time.isBetween(startOfDayTime, afterTime)
      ) {
        this.tkg_receive_icon = "tkg-icon-gray";
      } else {
        const receive_operation = moment(r.data.recebeu_operacao);
        const receive_operation_duration = moment.duration(
          now.diff(receive_operation),
        );
        const receive_conference = moment(r.data.recebeu_conferencia);
        const receive_conference_duration = moment.duration(
          now.diff(receive_conference),
        );
        const processing = moment(r.data.processou_arquivos);
        const processing_duration = moment.duration(now.diff(processing));
        if (
          receive_operation_duration.asMinutes() > 0 &&
          receive_operation_duration.asMinutes() < 10 &&
          receive_conference_duration.asMinutes() > 0 &&
          receive_conference_duration.asMinutes() < 10
        ) {
          this.tkg_receive_icon = "tkg-icon-green";
        } else {
          this.tkg_receive_icon = "tkg-icon-red";
        }

        if (
          processing_duration.asMinutes() > 0 &&
          processing_duration.asMinutes() < 10
        ) {
          this.tkg_processing_icon = "tkg-icon-green";
        } else {
          this.tkg_processing_icon = "tkg-icon-red";
        }
      }
      this.lastUpdate = r.data;
    });
  }

  formatPlate(plate: string) {
    if (plate) {
      let p1 = plate.substring(0, 3).toUpperCase();
      let p2 = plate.substring(3, 7);
      return `${p1}-${p2}`;
    } else {
      return "";
    }
  }

  sortTable(sortObject: any) {
    console.log(sortObject);

    if (sortObject.active === "min_start") {
      this.dataSource.sort = this.sort;
    } else {
      Object.assign(this.options, {
        orderBy: this.sort.active,
        sortedBy: this.sort.direction,
      });

      this.dockService.boxes(this.options).subscribe((r: any) => {
        this.dataSource = new MatTableDataSource(r.data);
      });
    }
  }

  registerNotes(event, element) {
    const dialogRef = this.dialog.open(BoxNoteComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
      },
    });

    dialogRef.afterClosed().subscribe((r: any) => {
      this.reload();
    });
  }

  ngOnDestroy() {
    // this.searchWatcher.unsubscribe();
    // this.refreshWatcher.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  round(number: number) {
    return Math.round(number);
  }

  formatData(dt) {
    return this.datePipe.transform(dt, "dd/MM HH:mm");
  }

  showBoxes(element) {
    let boxes = [];
    element.forEach((e) => {
      boxes.push(e.vbox);
    });

    return boxes.toString().replace(/,/g, ", ");
  }

  sumOrderVolums(element) {
    const volume = element.resource.vbox.reduce((prev, current) => {
      if (current.operation) {
        return prev + current.operation.order_vol;
      } else {
        return 0;
      }
    }, 0);
    return volume;
  }

  getMinMoment(list) {
    let mList = [];
    list.forEach((element) => {
      if (element) {
        const minDt = element.min_start_at;
        if (minDt) {
          mList.push(moment(minDt));
        }
      }
    });

    return moment.min(mList);
  }

  getMaxMoment(list) {
    let mList = [];
    list.forEach((element) => {
      if (element) {
        const maxDt = element.max_end_at;
        if (maxDt) {
          mList.push(moment(maxDt));
        }
      }
    });

    return moment.max(mList);
  }

  getCarrier(resource: any) {
    const carriers = [];
    resource.vbox.forEach((item) => {
      if (item.operation.carrier) {
        if (!carriers.includes(item.operation.carrier.name)) {
          carriers.push(item.operation.carrier.name);
        }
      } else {
        carriers.push(resource.carrier.name);
      }
    });

    if (carriers.length > 0) {
      return carriers[0];
    } else {
      return "";
    }
  }

  send2Invoice(event, element) {
    const dialogRef = this.dialog.open(BoxInvoiceComponent, {
      panelClass: ["dialog-small", "dialog-fullscreen"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element.notes)),
      },
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        const data = {
          box_id: element.resource.box_id,
        };
        this.invoiceService.create(data).subscribe((r: any) => {
          this.reload();
          this.snackBar.open(r.message, "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        });
      }
    });
  }

  checkIfAssigned(vbox: any) {
    let assigneds = [];
    let size = vbox.length;
    vbox.forEach((element) => {
      if (element.assigned) {
        assigneds.push(1);
      }
    });
    if (assigneds.length === size) {
      return "Sim";
    } else {
      return "Não";
    }
  }

  jsonCopy(src) {
    return JSON.parse(JSON.stringify(src));
  }

  generatePDF() {
    let i;
    let doc = new jsPDF("l", "pt", "a4");
    doc.setFont("Courier");
    doc.setFontStyle("bold");
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(
      `Boxes Ativos | Última atualização: ${this.datePipe.transform(this.lastUpdate.processou_arquivos, "dd/MM/yyyy HH:mm")}`,
      20,
      40,
      null,
      0,
    );

    doc.setFontStyle("bold");
    doc.setFontSize(11);
    doc.setTextColor(107, 105, 103);
    doc.text("Box", 20, 60);
    doc.text("Transportadora", 50, 60);
    doc.text("Box V", 180, 60);
    doc.text("V. Total", 250, 60);
    doc.text("Ini Sep", 310, 60);
    doc.text("Term Sep", 370, 60);
    doc.text("Imp Mapa", 430, 60);
    doc.text("P. chegada", 490, 60);
    doc.text("Final DC", 570, 60);
    doc.text("Final Car", 630, 60);
    doc.text("Etapa", 710, 60);

    i = 70;
    doc.line(20, 70, 820, 70);

    i = 86;

    const dadosTela = this.jsonCopy(this.dataSource.data);
    console.log(dadosTela);
    dadosTela.forEach((item) => {
      doc.setFont("Courier");
      doc.setFontStyle("normal");
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(item.resource.box, 20, i);
      if (this.getCarrier(item.resource)) {
        doc.text(this.getCarrier(item.resource).substring(0, 20), 50, i);
      } else {
        doc.text("", 50, i);
      }

      doc.text(this.showBoxes(item.resource.vbox), 180, i);
      doc.text(this.sumOrderVolums(item).toString(), 250, i);
      // #substituir
      // doc.text(this.datePipe.transform(item.min_start, 'HH:mm'), 310, i);
      // doc.text(this.datePipe.transform(this.getMaxMoment(item.resource.vbox), 'HH:mm'), 370, i);
      doc.text(
        this.printMapTime(item)
          ? this.datePipe.transform(this.printMapTime(item), "HH:mm")
          : "",
        430,
        i,
      );
      doc.text(
        item.arrival_prev
          ? this.datePipe.transform(item.arrival_prev, "HH:mm")
          : "",
        490,
        i,
      );
      doc.text(
        item.end_dc ? this.datePipe.transform(item.end_dc, "HH:mm") : "",
        570,
        i,
      );
      doc.text(
        item.end_step ? this.datePipe.transform(item.end_step, "HH:mm") : "",
        630,
        i,
      );
      doc.text(
        item.step ? this.showStep(item.step).name.substring(0, 17) : "",
        710,
        i,
      );
      i = i + 6;
      doc.line(20, i, 820, i);
      i = i + 14;
      if (i > 550) {
        doc.addPage();
        i = 50;
      }
    });
    doc.output("dataurlnewwindow");
  }

  downloadReport(): void {
    const options = {
      export: true,
      orderBy: "box",
      last_step: Object.keys(this.stepsFiltered)
        .map((key) => this.stepsFiltered[key]["value"])
        .toString(),
      carrier_id: this.form.value.carrier_id,
      sortedBy: this.sort.direction,
    };

    this.dockService.boxes(options).subscribe((r: any) => {
      this.createFileFromBlob(r.csv, "report.csv");
    });
  }

  createFileFromBlob(file, fileName) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const blob = new Blob(["\uFEFF" + file], {
      type: "text/csv; charset=utf-18",
    });
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  printConferenceMap(box_id: number) {
    this.carrierService
      .getConferenceMap({ box_id: box_id })
      .subscribe((r: any) => {
        if (r.data.length > 0) {
          this.makeConferenceMapPDF(r.data);
        } else {
          this.snackBar.open(
            "Conferência da transportadora não iniciada",
            "OK",
            {
              duration: 3000,
              horizontalPosition: "left",
              verticalPosition: "bottom",
            },
          );
        }
      });
  }

  printMap(box: any) {
    console.log("print --------");
    console.log(box);
    const waves = [];
    let waveVbox = {};
    box.vbox.forEach((element) => {
      waves.push(element.wave);
      waveVbox[element.wave] = element.vbox.toString();
    });

    const data = {
      dock: box.box,
      waves: waves,
    };

    this.shipmentService.mapa(data).subscribe((response: any) => {
      const printItens: any[] = response.data;
      this.makeMapPDF(
        printItens,
        waveVbox,
        box.box,
        box.vbox[0].operation.carrier.name,
      );
    });
  }

  makeConferenceMapPDF(data: any) {
    let i;
    let doc = new jsPDF("p", "pt", "a4");
    let day = this.datePipe.transform(new Date(), "dd/M/Y HH:mm");
    let pageLabel = `Página ${doc.page}`;
    doc.text(
      pageLabel,
      570 - doc.getStringUnitWidth(pageLabel) * doc.internal.getFontSize(),
      820,
    );
    doc.text(`Relatório gerado em ${day}`, 20, 820);

    doc.setFont("Helvetica");
    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(
      `Mapa de Conferência | Box Físico: ${data[0].box} | Box Virtual: ${this.listVboxStr(data)}`,
      20,
      40,
      null,
      0,
    );
    doc.text(
      `UCs: ${data[0].uc_conf}/${data[0].uc_total} = ${((data[0].uc_conf * 100) / data[0].uc_total).toFixed(2)}% | Volumes: ${data[0].volume_conf}/${data[0].volume_total} = ${((data[0].volume_conf * 100) / data[0].volume_total).toFixed(2)}%`,
      20,
      55,
      null,
      0,
    );
    doc.text("Transportadora: ", 20, 70, null, 0).setFontStyle("normal");
    doc.text(`${data[0].carrier}`, 120, 70, null, 0);
    doc.setFont("Helvetica");
    doc.setFontStyle("bold");
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
    doc.text("UC", 20, 95);
    doc.text("Box V.", 120, 95);
    doc.text("Conferente", 210, 95);
    doc.text("Data e Hora", 350, 95);
    doc.text("Quantidade", 500, 95);

    doc.setLineWidth(1.0);
    doc.line(20, 100, 570, 100);
    doc.setLineWidth(0.5);
    i = 110;

    let count = 0;

    data.forEach((item) => {
      count = count + item.amount;
      doc.setFontStyle("normal");
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(item.pallet, 20, i);
      doc.text(item.vbox, 120, i);
      doc.text(`${item.first_name} ${item.last_name}`, 210, i);
      doc.text(
        item.updated_at
          ? this.datePipe.transform(item.updated_at, "dd/M/Y HH:mm")
          : "",
        350,
        i,
      );
      doc.text(item.amount.toString(), 500, i);

      i = i + 4;
      doc.line(20, i, 570, i);
      i = i + 10;
      if (i > 820) {
        doc.addPage();
        i = 50;
      }
    });
    doc.output("dataurlnewwindow");
  }

  makeMapPDF(data: any, vbox: any, box: any, carrier: string) {
    console.log(data);

    let i;
    let doc = new jsPDF("p", "pt", "a4");
    doc.page = 1;
    // Write the first page number
    doc.setFontSize(8);
    let day = this.datePipe.transform(new Date(), "dd/M/Y HH:mm");
    let pageLabel = `Página ${doc.page}`;
    doc.text(
      pageLabel,
      570 - doc.getStringUnitWidth(pageLabel) * doc.internal.getFontSize(),
      820,
    );
    doc.text(`Relatório gerado em ${day}`, 20, 820);
    doc.setFont("Helvetica");
    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    // get Vbox
    const vboxes: any[] = Object.values(vbox);
    let volumes = data.reduce(function (acc, obj) {
      return acc + obj.volume;
    }, 0);
    let vboxStr = vboxes.join(", ");
    // let vboxStr = '12, 13, 14, 15, 16, 16, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 45';
    let title = `Mapa de Picking | Box Físico: ${box} | Box Virtual: ${vboxStr}`;
    let lineSize = Math.round(doc.internal.pageSize.width - 40);
    let titleLines: any[] = [[], []];
    let titleArray = title.split(",");
    titleArray.forEach((i: any) => {
      if (
        doc.getStringUnitWidth(titleLines[0].join(",")) *
          doc.internal.getFontSize() +
          doc.getStringUnitWidth(i.toString()) * doc.internal.getFontSize() <
        lineSize
      ) {
        titleLines[0].push(i);
      } else {
        titleLines[1].push(i);
      }
    });

    let nextLine = 0;

    doc.text(titleLines[0].join(","), 20, 40, null, 0);
    if (titleLines[1].length > 0) {
      nextLine = 15;
      doc.text(titleLines[1].join(",").trim(), 20, 40 + nextLine, null, 0);
    }
    doc.text(
      `UCs: ${data.length} | Volumes: ${volumes}`,
      20,
      55 + nextLine,
      null,
      0,
    );
    doc
      .text("Transportadora: ", 20, 70 + nextLine, null, 0)
      .setFontStyle("normal");
    doc.text(`${carrier}`, 120, 70 + nextLine, null, 0);

    i = 95 + nextLine;
    doc.setFontStyle("bold");
    doc.setFontSize(10);
    doc.text("Seq.", 20, i, null, 0);
    doc.text("Início bip.", 55, i, null, 0);
    doc.text("Final bip.", 140, i, null, 0);
    doc.text("Pallet ID", 225, i, null, 0);
    doc.text("Usuário", 290, i, null, 0);
    doc.text("Box v.", 480, i, null, 0);
    doc.text("Vol.", 535, i, null, 0);
    i = i + 4;
    doc.line(20, i, 570, i);
    i = i + 10;

    // let volume = 0;
    data.forEach((item) => {
      doc.setFontStyle("normal");
      doc.setFontSize(10);
      doc.text(item.row_number.toString(), 20, i, null, 0);
      const start = item.start_at
        ? this.datePipe.transform(item.start_at, "dd/M/Y HH:mm")
        : "-";
      doc.text(start, 55, i, null, 0);
      const end = item.end_at
        ? this.datePipe.transform(item.end_at, "dd/M/Y HH:mm")
        : "-";
      doc.text(end, 140, i, null, 0);
      doc.text(item.pallet, 225, i, null, 0);
      doc.text(
        item.name.length > 30 ? item.name.substring(0, 30) : item.name,
        290,
        i,
        null,
        0,
      );
      doc.text(item.vbox, 480, i, null, 0);
      // volume = volume + item.volume;
      doc.text(
        parseInt(item.volume).toString(),
        570 -
          doc.getStringUnitWidth(parseInt(item.volume).toString()) *
            doc.internal.getFontSize(),
        i,
        null,
        0,
      );
      i = i + 4;
      doc.line(20, i, 570, i);
      i = i + 10;
      if (i > 800) {
        doc.addPage();
        doc.page++;
        doc.setFontSize(8);
        pageLabel = `Página ${doc.page}`;
        doc.text(
          pageLabel,
          570 - doc.getStringUnitWidth(pageLabel) * doc.internal.getFontSize(),
          820,
        );
        i = 50;
      }
    });

    doc.output("dataurlnewwindow");
  }

  listVboxStr(resource: any) {
    let vbox = [];
    resource.forEach((element) => {
      if (!vbox.includes(element.vbox)) {
        vbox.push(element.vbox);
      }
    });

    return vbox.toString();
  }

  disableAuthorizeDC(element: any) {
    const has_dc_list = element.resource.hasOwnProperty("has_dc_list");
    if (element.step === "pk" || element.step === "ana") {
      if (has_dc_list) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  authorizeDC(element: any) {
    const data = {
      box_id: element.resource.box_id,
    };
    this.carrierService.createConferenceFromBox(data).subscribe((r: any) => {
      this.snackBar.open("Conferência da transportadora liberada", "OK", {
        duration: 3000,
        horizontalPosition: "left",
        verticalPosition: "bottom",
      });
    });
  }

  getStepsList() {
    this.supportDataService.resourceName("steps-list").subscribe((r: any) => {
      this.steps = r.data.resource;
    });
  }

  durationHumanize(duration: number) {
    return this.humanizer.humanize(duration, {
      language: "pt",
      units: ["y", "mo", "w", "d", "h", "m"],
      round: true,
    });
  }
}
