import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DockService } from '../shared/dock.service';

@Component({
  selector: 'app-box-note',
  templateUrl: './box-note.component.html',
  styleUrls: ['./box-note.component.scss']
})
export class BoxNoteComponent implements OnInit {
  form = this.formBuilder.group({
    notes: [this.data.item.notes.notes, [Validators.required]]
  });
  constructor(
    public dialogRef: MatDialogRef<BoxNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private dockService: DockService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    const data = {
      id: this.data.item.resource.box_id,
      notes: this.form.value.notes
    }
    this.dockService.updateNotes(data).subscribe((r: any) => {
      this.dialogRef.close(data);
    }, (e: any) => {
      console.log('--erro--');
      console.log(e);
    });
  }

}
