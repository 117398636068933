<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <h2 mat-dialog-title>
    {{ (form.value.name) ? form.value.name : ((data.action == 'EDIT') ? 'Salvar' : 'Adicionar') }}
  </h2>
  <mat-dialog-content class="mat-typography">

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <input type="text" formControlName="name" matInput placeholder="Nome">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <mat-tab-group mat-stretch-tabs class="margin-bottom mat-elevation-z4">
      <mat-tab label="Usuários">
        <mat-selection-list (selectionChange)="onUserToggle($event)" #usersSelectionList class="no-padding">
          <mat-list-option checkboxPosition="before" *ngFor="let item of usersDataSource" [value]="item" [selected]="isUserSelected(item)">
            <h3 matLine>{{item.full_name}}</h3>
            <p matLine>{{item.email}}</p>
            <mat-divider></mat-divider>
          </mat-list-option>
        </mat-selection-list>
        <mat-paginator [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
      </mat-tab>
      <mat-tab label="Permissões">
        <div *ngFor="let module of permissionsDataSource | groupBy: 'module'; let last = last" class="roles">
          <div class="module">
            <button type="button" mat-icon-button (click)="onPermissionExpandedToggle(module)" [attr.aria-label]="'toggle ' + module.key">
              <mat-icon>
                {{isPermissionExpanded(module) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <mat-checkbox [checked]="isPermissionAllSelected(module.value)"
                          (change)="onPermissionAllToggle(module.value)"
                          [indeterminate]="isPermissionIndeterminate(module.value)"
                          color="primary"><span [translate]="'permission.'+module.key+'.'+module.key"></span></mat-checkbox>
          </div>

          <ng-container *ngIf="isPermissionExpanded(module)">
            <mat-selection-list (selectionChange)="onPermissionToggle($event)">
              <mat-list-option checkboxPosition="before" *ngFor="let item of module.value" [value]="item" [selected]="isPermissionSelected(item)">
                <span [translate]="'permission.'+module.key+'.'+item.title"></span>
              </mat-list-option>
            </mat-selection-list>
          </ng-container>
          <mat-divider *ngIf="!last"></mat-divider>
        </div>
      </mat-tab>
    </mat-tab-group>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="primary" mat-dialog-close>Fechar</button>
    <button type="submit" mat-button color="primary" [disabled]="!form.valid">{{ (data.action == 'EDIT') ? 'Salvar' : 'Adicionar' }}</button>
  </mat-dialog-actions>
</form>
