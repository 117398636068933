import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserListComponent } from './user-list/user-list.component';
import { AccountGuardService, RouteGuardService } from '../account/shared/account-guard.service';
import { GroupsListComponent } from './groups-list/groups-list.component';

const userRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService, RouteGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'users',
        component: UserListComponent,
        data: {
          title: 'Usuários',
          filter: false,
          search: true,
          refresh: true,
        },
      },
      {
        path: 'groups',
        component: GroupsListComponent,
        data: {
          title: 'Grupos',
          filter: false,
          search: false,
          refresh: false,
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(userRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class UserRoutingModule {}
