import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-dock-details-mobile',
  templateUrl: './dock-details-mobile.component.html',
  styleUrls: ['./dock-details-mobile.component.scss']
})
export class DockDetailsMobileComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log('no modal');
    console.log(this.data);
  }

  getCarrier(resource: any) {
    const carriers = [];
    resource.vbox.forEach(item => {
      if (item.operation.carrier) {
        if (!carriers.includes(item.operation.carrier.name)) {
          carriers.push(item.operation.carrier.name);
        }
      } else {
        carriers.push(resource.carrier.name);
      }
    });

    if (carriers.length > 0) {
      return carriers[0];
    } else {
      return '';
    }
  }

  checkIfAssigned(vbox: any) {
    let assigneds = [];
    let size = vbox.length;
    vbox.forEach(element => {
      if (element.assigned) {
        assigneds.push(1);
      }
    });
    if (assigneds.length === size) {
      return 'Sim';
    } else {
      return 'Não';
    }
  }

  showBoxes(element) {
    let boxes = [];
    element.forEach(e => {
      boxes.push(e.vbox);
    });

    return boxes.toString().replace(/,/g, ', ');
  }

  sumOrderVolums(element) {
    const volume = element.resource.vbox.reduce((prev, current) => {
      if (current.operation) {
        return prev + current.operation.order_vol;
      } else {
        return 0;
      }
    }, 0);
    return volume;
  }

  showStatus(element) {
    const status = ['CQ', 'PR', 'CK', 'NR', 'NH', 'NA', 'NF'];
    let st_names = [];
    let st_idxs = [];
    element.vbox.forEach(element => {
      if (!st_names.includes(element.operation.minor_status)) {
        st_names.push(element.operation.minor_status);
        st_idxs.push(status.indexOf(element.operation.minor_status));
      }
    });
    const min = Math.min.apply(null, st_idxs);

    return status[min];
  }

  getMaxMoment(list) {
    const mList = [];
    list.forEach(element => {
      if (element) {
        const maxDt = element.max_end_at;
        if (maxDt) {
          mList.push(moment(maxDt));
        }
      }
    });

    return moment.max(mList);
  }

  printMapTime(element) {
    const filtered = element.steps.filter(time => time.step === 'dc');
    if (filtered.length > 0) {
      return filtered[0].ts.toString();
    } else {
      return '';
    }
  }

}
