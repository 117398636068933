import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationListComponent } from './registration-list/registration-list.component';
import { RegistrationRoutingModule } from './registration-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { SupportDataListComponent } from './support-data-list/support-data-list.component';
import { SupportDataDialogComponent } from './support-data-dialog/support-data-dialog.component';
import { StepsListComponent } from './steps-list/steps-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StepsListDialogComponent } from './steps-list-dialog/steps-list-dialog.component';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { StepChangeMapListComponent } from './step-change-map-list/step-change-map-list.component';
import { StepChangeMapDialogComponent } from './step-change-map-dialog/step-change-map-dialog.component';

@NgModule({
  declarations: [
    RegistrationListComponent,
    SupportDataListComponent,
    SupportDataDialogComponent,
    StepsListComponent,
    StepsListDialogComponent,
    StepChangeMapListComponent,
    StepChangeMapDialogComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    CommonModule,
    RegistrationRoutingModule,
    DragDropModule,
    NgxMatColorPickerModule
  ],
  providers: [{ provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }]
})

export class RegistrationModule { }
