import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuardService, RouteGuardService } from '../account/shared/account-guard.service';
import { ShiftsListComponent } from './shifts-list/shifts-list.component';

//Lazy loading
const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService, RouteGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'shifts',
        component:ShiftsListComponent,
        data: {
          title: 'Gestão de turnos, pausas e dias sem operações',
          search: false,
          refresh: false,
        },
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ShiftsRoutingModule { }