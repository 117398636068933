 <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <h2 mat-dialog-title>Observação da Pendência</h2>
      <mat-dialog-content class="mat-typography">
  
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-form-field fxFlex>
          <textarea formControlName="justification" matInput placeholder="Observações"></textarea>
        </mat-form-field>
      </div>
 
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="button" mat-button color="warn" mat-dialog-close>Fechar</button>
      <button type="submit" mat-button color="primary" [disabled]="!form.valid">Salvar</button>
    </mat-dialog-actions>
  </form>