import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountGuardService } from '../account/shared/account-guard.service';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';

const newDashboardRoutes: Routes = [
  { runGuardsAndResolvers: 'always',
    path: '',
    redirectTo: '/admin/newdashboard',
    pathMatch: 'full' },
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'newdashboard',
        component: DashboardListComponent,
        data: {
          title: 'KPIs',
          filter: false,
          search: false,
          refresh: false
        },
      }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(newDashboardRoutes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [
    RouterModule
  ]
})
export class NewDashboardRoutingModule {
}
