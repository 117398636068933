import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Box } from '../box';

@Injectable({
  providedIn: 'root'
})
export class BoxService {

  constructor(
    private http: HttpClient
  ) { }

  index(options?: any): Observable<Box> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/boxes`;

    return this.http.get<Box>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  boxes(options?: any): Observable<Box> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/box-single`;

    return this.http.get<Box>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  assignBoxes(data: any): Observable<Box> {
    const url = `${environment.apiUrl}/box-assing`;
    const options = { params: {} };

    return this.http.put<Box>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }


  update(data: any, id: string, params?: any): Observable<Box> {
    const url = `${environment.apiUrl}/boxes/${id}`;
    const options = { params: params };

    return this.http.put<Box>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }


  create(data: any, params?: any): Observable<Box> {
    const url = `${environment.apiUrl}/boxes`;
    const options = { params: params };

    return this.http.post<Box>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/boxes/${id}`;
    const options = { params: params };

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  boxUcDetail(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/box/uc-detail`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }


}
