import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ShiftService } from '../shared/shift.service';
import { ShiftBreakInterface, ShiftsDialogAction, WeekDay } from '../shift';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-shift-break-dialog',
  templateUrl: './shift-break-dialog.component.html',
  styleUrls: ['./shift-break-dialog.component.scss']
})
export class ShiftBreakDialogComponent implements OnInit {
  formData:Record<string, any> = (this.data.data || {resource:{}}).resource;

  weekDay = WeekDay;
  shiftShifts:any[] = [];
  dialogAction = ShiftsDialogAction;
  exception = !!this.formData.date_exception;
  saving = false;

  shiftForm = this.formBuilder.group({
    name: [this.formData.name || "", [Validators.required]],
    shift_shifts_id: [this.formData.shift_shifts_id || "", [Validators.required]],
    start: [this.formData.start || "00:00", [Validators.required]],
    end: [this.formData.end || "00:00", [Validators.required]],
    duration:[this.formData.duration || "00:00"],
    date_exception:[ (this.formData.date_exception && new Date(this.formData.date_exception.replace("-","/"))) || null],
    weekday:[
      this.formData.weekday ||
      [ WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday],
      [Validators.required]
    ],
  })

  constructor(
    private dialog: MatDialog,
    private shiftService:ShiftService,
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ShiftBreakDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShiftBreakInterface
  ) { }

  ngOnInit() {
    this.shiftForm.valueChanges.subscribe((model:any)=>{
      const start = moment(model.start, 'HH:mm');
      const end = moment(model.end, 'HH:mm');
      const duration = moment.duration(end.diff(start));
      let durationAsHM = moment.utc(duration.asMilliseconds()).format('HH:mm');
      if(!durationAsHM.match(/[\d]/g))
        durationAsHM = "00:00";

      this.shiftForm.patchValue({duration:durationAsHM},{emitEvent: false, onlySelf: true})
    });

    this.shiftService.indexShift({orderBy:"name", sortedBy:"asc", pageSize:200})
    .pipe(map((response)=>response.data ?? []))
    .subscribe((data)=>{
      this.shiftShifts = data;
    })
    .add(()=>{
      if(this.data?.data?.resource?.shift_shifts_id)
        this.shiftForm.get("shift_shifts_id").setValue(this.data.data.resource.shift_shifts_id);
    })
  }

  onSubmit(){
    this.saving = true;
    this.save()
    .subscribe((data:any)=>{
      if(data){
        this.dialog.open(DialogAlertComponent,{
          data:{
            title:"Turno",
            message:"Dados salvos com sucesso."
          }
        })
        .beforeClosed()
        .subscribe(()=>this.dialogRef.close(true))
      }
    })
    .add(()=>this.saving = false)
  }

  onExceptionDate(bool:boolean){
    this.exception = bool;
    if(this.exception) {
      this.shiftForm.get('date_exception').setValidators([Validators.required])
    } else {
      this.shiftForm.get('date_exception').clearValidators();
    }
    this.shiftForm.get('date_exception').updateValueAndValidity();
  }

  private save():Observable<any>{
    const resource = this.shiftForm.value as unknown as ShiftBreakInterface["data"]["resource"];
    if(resource.date_exception)
      resource.date_exception = moment(resource.date_exception).format("YYYY-MM-DD");

    if(!this.exception)
      resource.date_exception = null;

    const data = {
      resource,
      name:this.data.type
    }

    if(this.data.action === ShiftsDialogAction.ADD)
      return this.shiftService.create(data)

    if(this.data.action === ShiftsDialogAction.EDIT)
      return this.shiftService.update({...data, id:this.data.data.id})

    return of(null)
  }

}
