<h2 mat-dialog-title>Validar Conferência</h2>
<mat-dialog-content class="mat-typography">
<p [class]="msgClass" *ngIf="showMsg">{{msg}}</p>
<form [formGroup]="form" novalidate *ngIf="has_justification_list">
  <mat-form-field class="tkg-form-input-100">
    <mat-label>Validar Quantidade</mat-label>
    <input matInput formControlName="amount_confered" placeholder="Validar Quantidade">
  </mat-form-field>
</form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()" color="warn">Fechar</button>
  <button mat-button (click)="validate()" cdkFocusInitial color="primary" [disabled]="!form.valid || saveBtnDisabled"  *ngIf="has_justification_list">Salvar</button>
</mat-dialog-actions>
