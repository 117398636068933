import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DockService } from '../shared/dock.service';

@Component({
  selector: 'app-dock-arrival',
  templateUrl: './dock-arrival.component.html',
  styleUrls: ['./dock-arrival.component.scss']
})
export class DockArrivalComponent implements OnInit {
  formArrival = this.formBuilder.group({
    id: [this.data.item.resource.box_id, [Validators.required]],
    arrival_prev: [this.data.item.arrival_prev || '', [Validators.required]],
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DockArrivalComponent>,
    private formBuilder: UntypedFormBuilder,
    private dockService: DockService
  ) { }

  ngOnInit() {
    console.log('----data-----');
    console.log(this.data);
  }

  onSubmit() {
    console.log('----salva o formulário----');
    console.log(this.formArrival.value);
    this.dockService.updateArrival(this.formArrival.value).subscribe((r: any) => {
      this.dialogRef.close(r);

    }, (e: any) => {
      console.log('erro: ');
      console.log(e);
    });

  }
}
