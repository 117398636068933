<div class="top-bar-filters">
  <mat-button-toggle-group #selector="matButtonToggleGroup" (change)="changeFilter($event)" value="day" name="fontStyle"
    aria-label="Font Style" class="filter-opt">
    <mat-button-toggle value="day">Dia</mat-button-toggle>
    <mat-button-toggle value="period">Período</mat-button-toggle>
  </mat-button-toggle-group>
  <span *ngIf="selector.value == 'day'">
    <mat-form-field>
      <input matInput #datePickerOnbar1 placeholder="Data" [matDatepicker]="picker1" [formControl]="dayStartDate">
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <button mat-icon-button aria-label="Data para exibição" (click)="picker1.open()"
      matTooltip="Escolha uma data para filtrar">
      <mat-icon>calendar_today</mat-icon>
    </button>
    <button mat-button color="primary" (click)="dayFilter()">
      <mat-icon>filter_list</mat-icon>
      Filtrar
    </button>
  </span>
  <span *ngIf="selector.value == 'period'">
    <mat-form-field>
      <input matInput #datePickerOnbar2 placeholder="Data inicial" [matDatepicker]="picker2"
        [formControl]="periodStartDate">
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <button mat-icon-button aria-label="Data para exibição" (click)="picker2.open()"
      matTooltip="Data inicial para filtrar">
      <mat-icon>calendar_today</mat-icon>
    </button>
    <mat-form-field>
      <input matInput #datePickerOnbar3 placeholder="Data final" [matDatepicker]="picker3"
        [formControl]="periodEndDate">
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
    <button mat-icon-button aria-label="Data para exibição" (click)="picker3.open()"
      matTooltip="Data final para filtrar">
      <mat-icon>calendar_today</mat-icon>
    </button>

    <button mat-button color="primary" (click)="periodFilter()">
      <mat-icon>filter_list</mat-icon>
      Filtrar
    </button>


  </span>
  <div fxLayout="row" fxFlex fxLayoutAlign="space-between" fxLayoutGap="8px">
    <div fxFlex="25%" fxFlex.xs="calc(50%-25px)" [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Transportadora</mat-label>
        <mat-select formControlName="carrier_id">
          <!-- #substituir -->
          <!-- <ngx-mat-select-search [formControl]="carrierFilter" placeholderLabel="Buscar transportadora"
            [noEntriesFoundLabel]="''"></ngx-mat-select-search> -->
          <mat-option (click)="cleanCarrierFilter()">Limpar Filtro</mat-option>
          <mat-option *ngFor="let element of carriers" [value]="element.id" (click)="getFilterCarrier()">
            {{element.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
