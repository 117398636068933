import { debounceTime, switchMap, map } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { element } from 'protractor';
import {SelectionModel} from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { environment } from "./../../../environments/environment.prod";

export interface Docks {
  name: string;
  id: number;
  type: string;
  busy: boolean;
}

@Component({
  selector: 'app-docks-settings',
  templateUrl: './docks-settings.component.html',
  styleUrls: ['./docks-settings.component.scss']
})
export class DocksSettingsComponent implements OnInit {
  docas = new MatTableDataSource<Docks>();
  // docas: any = [];
  docasAll: any = [];
  typeSpaces: any = [];
  user: any;
  filtered = false;
  beforeFilter: any = [];

  displayedColumns: string[] = ['select', 'dock', 'busy'];
  selection = new SelectionModel<Docks>(true, []);
  filterTypeSelected: UntypedFormControl = new UntypedFormControl();
  searchFilter: UntypedFormControl = new UntypedFormControl();
  modeAuto: boolean = true;
  private socket: WebSocketSubject<any>;

  constructor(
    private supportDataService: SupportDataService,
    private snackBar: MatSnackBar
  ) {
    const user = JSON.parse(localStorage.getItem('account'));
    if(user)
      this.user = user;
  }

  ngOnInit() {
    // this.getTipos();
    // this.checkModoAutomatico();
    this.searchObserver();

    // let id = JSON.parse(localStorage.getItem("account")).system_client.id;

    // const subject = webSocket({
    //   url: `${environment.wsUrl}/docks/${id}`,
    //   deserializer: () => {},
    // });

    // subject.subscribe(
    //   (msg) => {
    //     this.snackBar.open("Docas atualizadas", "OK", {
    //       duration: 3000,
    //       horizontalPosition: "left",
    //       verticalPosition: "bottom",
    //     });

    //     this.loadDocks();
    //   },
    //   (err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
    //   () => console.log("complete") // Called when connection is closed (for whatever reason).
    // );
    this.loadDocks();
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.docas.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.docas.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Docks): string {
    if (!row) {

      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }


  prepareFilter() {
    if(this.filtered){
      this.docas.data = this.beforeFilter;
    }
  }

  filterStatus(status: string) {

    if(this.filtered) {
      this.prepareFilter();
      this.docas.data = this.docas.data.filter((item: any) => {
        if(status === 'false') {
          return item.busy === false
        } else {
          return item.busy === true
        }
      });
      this.filtered = true;
    } else {

      this.docas.data = this.docas.data.filter((item: any) => {
        if(status === 'false') {
          return item.busy === false
        } else {
          return item.busy === true
        }
      });
      this.filtered = true;
    }

  }

  loadDocks(){
    const options = {
      search: this.searchFilter.value || "",
      type: this.filterTypeSelected.value ? this.filterTypeSelected.value : '',
    };
    this.supportDataService.getDockSpaces(options).subscribe(
      (res: any) => {
        console.log(res, 'res');
        this.docas.data = res.data;
        this.beforeFilter = this.docas.data;
        // console.log('this.typeSpaces === ', this.typeSpaces);
        this.docas.data.forEach((item) => {
          item['type_dock'] = this.typeSpaces.filter(
            (spaces: any) => spaces.id === item.type
          )[0];
        });
      },
      (e: any) => {
        console.log("erro");
        console.log(e);
      }
    );
  }

  // checkModoAutomatico(){
  //   this.supportDataService.generic({name: 'block_docks_spaces'}).subscribe((res: any) => {
  //       this.modeAuto = res.data.resource.auto;
  //     }
  //   );
  // }

  searchObserver(){
    let actualPage;
    this.searchFilter.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(() => {
          const options = {
            search: this.searchFilter.value || "",
            type: this.filterTypeSelected.value ? this.filterTypeSelected.value : '',
          };
          // return this.supportDataService.getDockSpaces(options).pipe(
          //   //catchError movido pra esta parte do script, objetivo, não cancelar o observer quando ocorrer erro.
          //   catchError(() => {
          //     this.searching = false;
          //     //retornar para a função map um objeto de response contornando o erro que ocorreu na resquest.
          //     return observableOf({total:0,per_page:0,data:[]});
          //   }));
          if(this.searchFilter.value.length == 0){
            this.loadDocks();
          }

          if(this.searchFilter.value != ""){
            this.docas.data = this.docas.data.filter(
              (doca: any) => doca.name.toLowerCase().indexOf(this.searchFilter.value.toLowerCase()) > -1
            );
          }else{
            this.loadDocks();
          }
          return this.docas.data;
        }),
        map((response: any) => {
          return response.data;
        })
      )
      .subscribe((data) => {

      });
  }

  salvar(action: string){
    if(this.selection.selected.length == 0){
      this.snackBar.open("Nenhuma doca foi selecionada", "OK", {
        duration: 3000,
        horizontalPosition: "left",
        verticalPosition: "bottom",
      });
    }else{

      let docksSeleted = [];
      for(let dock of this.selection.selected){
        docksSeleted.push(dock.id);
      }

      const options = {
        action: action,
        docks: JSON.parse(JSON.stringify(docksSeleted))
      };

      this.supportDataService
        .updateDock(options)
        .subscribe(
          (res: any) => {
            this.loadDocks();
            this.selection.clear();
          },
          (e: any) => {
            console.log(e);
          }
        );
    }
  }


  clearFilter() {
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
    }
  }

}
