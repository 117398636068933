<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <h2 mat-dialog-title>
    {{ (form.value.name) ? form.value.name : ((data.action == 'EDIT') ? 'Salvar' : 'Adicionar') }}
  </h2>
  <mat-dialog-content class="mat-typography">

    <div class="tkg-form-row">
      <mat-form-field class="tkg-form-item-100">
        <input type="text" formControlName="id_external" matInput placeholder="Código">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.id_external">{{ error }}</div>
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="tkg-form-item-100">
        <input type="text" formControlName="name" matInput placeholder="Nome">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <div>
      <!-- <mat-form-field>
        <input type="time" atp-time-picker formControlName="operation_start" matInput (click)="maskStartTime()"
          placeholder="Carga Inicial" />
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.operation_start">{{ error }}</div>
        </mat-hint>
      </mat-form-field> -->

      <!-- <mat-form-field>
        <input type="time" atp-time-picker formControlName="operation_end" matInput (click)="maskEndTime()"
          placeholder="Carga Final" />
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.operation_end">{{ error }}</div>
        </mat-hint>
      </mat-form-field> -->

    </div>

    <!-- <div>
      <div>
        <mat-slide-toggle formControlName="is_casual">Casual?</mat-slide-toggle>
      </div>
    </div> -->

    <div>
      <div>
        <mat-slide-toggle formControlName="is_active">Ativo</mat-slide-toggle>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
    <button type="submit" mat-button color="primary" cdkFocusInitial
      [disabled]="!form.valid">{{ (data.action == 'EDIT') ? 'Salvar' : 'Adicionar' }}</button>
  </mat-dialog-actions>

</form>
