import { Component, Inject, OnInit } from '@angular/core';
import { ManifestService } from '../shared/manifest.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { MaskUtil } from '../../shared/util/mask.util';

@Component({
  selector: 'app-manifest-detail',
  templateUrl: './manifest-detail.component.html',
  styleUrls: ['./manifest-detail.component.scss']
})
export class ManifestDetailComponent implements OnInit {
  form = this.formBuilder.group({
    name: [this.data.item.name, [Validators.required]],
    document: [this.data.item.document, [Validators.required]],
  });
  errors: any = {};
  maskUtil = MaskUtil;

  constructor(
    public dialogRef: MatDialogRef<ManifestDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private manifestService: ManifestService
  ) { }

  onSubmit() {
    if (this.data.action === 'EDIT') {
      this.manifestService.update(this.form.value, this.data.item.id)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    } else if (this.data.action === 'ADD') {
      this.manifestService.create(this.form.value)
        .subscribe(
          data => {
            this.dialogRef.close(data);
          },
          err => {
            if (err.status === 422) {
              this.errors = err.error.errors;
            } else {
              this.dialog.open(DialogAlertComponent, {
                data: {title: err.statusText, message: err.error.message}
              });
            }
          }
        );
    }
  }

  ngOnInit() { }
}
