import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { DockRoutingModule } from '../dock/dock-routing.module';
import { DockListComponent } from '../dock/dock-list/dock-list.component';
import { DockDetailComponent } from '../dock/dock-detail/dock-detail.component';
import { DockService } from '../dock/shared/dock.service';
import { PendencyListComponent } from './pendency-list/pendency-list.component';
import { PendencyObsComponent } from './pendency-obs/pendency-obs.component';
import { PendencyDetailComponent } from './pendency-detail/pendency-detail.component';
import { DockMonitorComponent } from './dock-monitor/dock-monitor.component';
import { OperationDetailComponent } from './operation-detail/operation-detail.component';
import { CallbackPipe } from './shared/pipes/callback.pipe';
import { OrderByPipe } from './shared/pipes/orderby.pipe';
import { BoxNoteComponent } from './box-note/box-note.component';
import { BoxFisicoComponent } from './box-fisico/box-fisico.component';
import { BoxInvoiceComponent } from './box-invoice/box-invoice.component';
import { AssignChargeComponent } from './assign-charge/assign-charge.component';
import { DockArrivalComponent } from './dock-arrival/dock-arrival.component';
// import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { DialogAnaliseComponent } from './dialog-analise/dialog-analise.component';
import { DockListClosedComponent } from './dock-list-closed/dock-list-closed.component';
import { TesteComponent } from './teste/teste.component';
import { JustifyStepComponent } from './justify-step/justify-step.component';
import { DockListMobileComponent } from './dock-list-mobile/dock-list-mobile.component';
import { DockDetailsMobileComponent } from './dock-details-mobile/dock-details-mobile.component';
import { DockFiltersMobileComponent } from './dock-filters-mobile/dock-filters-mobile.component';
import { ImportComponent } from './import/import.component';
import { CancelLoadComponent } from './cancel-load/cancel-load.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SsStaffComponent } from '../staff/shared/ss-staff/ss-staff.component';
const TRACKAGE_DATETIME_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  hour12: false,
  timeZone: 'America/Sao_Paulo'
}
@NgModule({
    imports: [
        CommonModule,
        MatSortModule,
        CoreModule,
        SharedModule,
        DockRoutingModule,
        SsStaffComponent,
        // OwlDateTimeModule,
        // OwlNativeDateTimeModule
    ],
    declarations: [
        DockListComponent,
        TesteComponent,
        DockDetailComponent,
        PendencyListComponent,
        PendencyObsComponent,
        PendencyDetailComponent,
        OperationDetailComponent,
        DockMonitorComponent,
        CallbackPipe,
        OrderByPipe,
        BoxNoteComponent,
        BoxFisicoComponent,
        BoxInvoiceComponent,
        AssignChargeComponent,
        DockArrivalComponent,
        DialogAnaliseComponent,
        DockListClosedComponent,
        JustifyStepComponent,
        DockListMobileComponent,
        DockDetailsMobileComponent,
        DockFiltersMobileComponent,
        ImportComponent,
        CancelLoadComponent,
        SelectSearchComponent
    ],
    providers: [
        DockService,
        OrderByPipe,
        // { provide: OWL_DATE_TIME_FORMATS, useValue: TRACKAGE_DATETIME_FORMATS }
    ]
})
export class DockModule { }
