<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <h2 mat-dialog-title>{{showAction(form.get('name').value)}}</h2>
  <mat-dialog-content class="mat-typography">

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <input type="text" formControlName="name" matInput placeholder="Pendência">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <input type="text" formControlName="assigned_by" matInput placeholder="Responsável">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.assigned_by">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
    <button type="submit" mat-button color="primary" [disabled]="!form.valid">{{ showAction() }}</button>
  </mat-dialog-actions>
</form>
