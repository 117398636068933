import { NgModule } from '@angular/core';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { WaitRoutingModule } from '../wait/wait-routing.module';
import { WaitListComponent } from '../wait/wait-list/wait-list.component';
import { WaitDetailComponent } from '../wait/wait-detail/wait-detail.component';
import { WaitService } from '../wait/shared/wait.service';
import { DirectListComponent } from './direct-list/direct-list.component';
import { DirectDetailComponent } from './direct-detail/direct-detail.component';
import { WaitDirectionService } from './shared/wait-direction.service';
import { WaitReleaseService } from './shared/wait-release.service';
import { WaitManageComponent } from './wait-manage/wait-manage.component';
import { NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_FORMATS, NgxMatMomentModule } from '@angular-material-components/moment-adapter';
// import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
// import { OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime-moment';
const TRACKAGE_DATETIME_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  hour12: false,
  timeZone: 'America/Sao_Paulo'
}
const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "l, LTS"
  },
  display: {
    dateInput: "l, LTS",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};
@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        WaitRoutingModule,
        MatInputModule,
        MatDatepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule
    ],
    declarations: [
        WaitListComponent,
        WaitDetailComponent,
        DirectListComponent,
        DirectDetailComponent,
        WaitManageComponent
    ],
    providers: [
        WaitService,
        WaitDirectionService,
        WaitReleaseService,
        { provide: NGX_MAT_MOMENT_FORMATS, useValue: CUSTOM_MOMENT_FORMATS }
        // { provide: OWL_DATE_TIME_FORMATS, useValue: TRACKAGE_DATETIME_FORMATS }
    ]
})
export class WaitModule { }
