<form [formGroup]="form">
  <h2 mat-dialog-title>{{ data.action === 'EDIT' ? 'Editar ' : 'Adicionar '}}</h2>
  <mat-dialog-content class="mat-typography">
    <div class="tkg-row">
      <mat-form-field appearance="outline" class="tkg-input-select">
        <mat-label>Etapa de origem</mat-label>
        <mat-select formControlName="from">
          <mat-option *ngFor="let step of stepsFrom" [value]="step.id">{{step.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="tkg-input-select">
        <mat-label>Pode ir para...</mat-label>
        <mat-select formControlName="to" multiple>
          <mat-option *ngFor="let step of allSteps" [value]="step.id">{{step.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  </form>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
    <button type="submit" mat-button color="primary" [disabled]="false" (click)="submit()">Salvar</button>
  </mat-dialog-actions>
