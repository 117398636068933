import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list/user-list.component';
import { UserRoutingModule } from './user-routing.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { UserService } from './shared/user.service';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { GroupsListComponent } from './groups-list/groups-list.component';
import { GroupsDetailComponent } from './groups-detail/groups-detail.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        UserRoutingModule,
    ],
    declarations: [
        UserListComponent,
        UserDetailComponent,
        GroupsListComponent,
        GroupsDetailComponent
    ],
    providers: [
        UserService,
    ]
})
export class UserModule { }
