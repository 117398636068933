<form [formGroup]="formArrival" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Previsão de Chegada</h2>
  <mat-dialog-content class="mat-typography">

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">

      <mat-form-field fxFlex.gt-sm="32">
        <!-- <input matInput placeholder="Data e Hora Prevista" formControlName="arrival_prev" [owlDateTimeTrigger]="dt1"
          [owlDateTime]="dt1"> -->
        <input matInput placeholder="Data e Hora Prevista" formControlName="arrival_prev">
        <!-- <owl-date-time #dt1></owl-date-time> -->
      </mat-form-field>

    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-raised-button mat-dialog-close color="warn">Cancelar</button>
    <button type="submit" mat-raised-button color="primary">Salvar</button>
  </mat-dialog-actions>

</form>
