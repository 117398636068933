import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManifestListComponent } from './manifest-list/manifest-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';

const manifestRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'manifests',
        component: ManifestListComponent,
        data: {
          title: 'Manifestos',
          search: true,
          filter: false,
          refresh: false,
        },
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(manifestRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ManifestRoutingModule { }
