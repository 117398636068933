import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ManifestService } from "../shared/manifest.service";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Manifest } from "../manifest";
import { merge, of as observableOf, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap, tap } from "rxjs/operators";
import { ManifestDetailComponent } from "../manifest-detail/manifest-detail.component";
import { LoadsDialogComponent } from "../loads-dialog/loads-dialog.component";
import { DialogConfirmComponent } from "../../shared/dialog-confirm/dialog-confirm.component";
import { SearchService } from "../../shared/search.service";
import { Search } from "../../shared/search";
import { MaskUtil } from "../../shared/util/mask.util";
import { DockService } from "../../dock/shared/dock.service";
import { RefreshService } from "../../shared/refresh.service";
import { ShipmentService } from "../../shipment/shared/shipment.service";
import { StatusService } from "../../status/shared/status.service";
import * as moment from "moment";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { WaitService } from "../../wait/shared/wait.service";
// import { FirebaseService } from "../../shared/firebase.service";

@Component({
  selector: "app-manifest-list",
  templateUrl: "./manifest-list.component.html",
  styleUrls: ["./manifest-list.component.scss"],
})
export class ManifestListComponent implements OnInit, OnDestroy {
  maskUtil = MaskUtil;
  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  search: string;
  pageSize: number;
  length: number;
  activeMediaQuery = "";
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;

  dataSource = new MatTableDataSource<Manifest>();
  data: Manifest[] = [];
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private manifestService: ManifestService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private searchService: SearchService,
    // private dockService: DockService,
    private refreshService: RefreshService,
    // private shipmentService: ShipmentService,
    // private waitService: WaitService,
    // private statusService: StatusService,
    public breakpointObserver: BreakpointObserver,
    // private firebaseService: FirebaseService
  ) {
    this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });
  }

  activateLayout() {
    // if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
    //   this.displayedColumns = ['vehicle_vehicles:board_horse_id|vehicle_vehicles.board', 'actions'];
    // } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
    //   this.displayedColumns = ['vehicle_vehicles:board_horse_id|vehicle_vehicles.board', 'actions'];
    // } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
    //   this.displayedColumns = [
    //     'vehicle_vehicles:board_horse_id|vehicle_vehicles.board',
    //     'carrier_carriers:carrier_id|carrier_carriers.name',
    //     'operation_operations:operation_id|operation_operations.name',
    //     'shipment_shipments:id|shipment_shipments.finish_at',
    //     'shipment_shipments:id|shipment_shipments.manifest_finish_at',
    //     'arrival_at',
    //     'status',
    //     'actions'
    //   ];
    // }

    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.displayedColumns = ["dock", "box", "wave", "actions"];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.displayedColumns = [
        "dock",
        "box",
        "wave",
        "vehicle_vehicles:board_horse_id|vehicle_vehicles.board",
        "volume",
        "actions",
      ];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      // this.displayedColumns = ['carrier_carriers:carrier_id|carrier_carriers.name', 'dock', 'box', 'wave', 'vehicle_vehicles:board_horse_id|vehicle_vehicles.board', 'load', 'volume', 'time_end', 'stopwatch', 'status_invoice', 'actions'];
      this.displayedColumns = [
        "carrier_carriers:carrier_id|carrier_carriers.name",
        "dock",
        "box",
        "wave",
        "vehicle_vehicles:board_horse_id|vehicle_vehicles.board",
        "volume",
        "time_end",
        "stopwatch",
        "status_invoice",
        "actions",
      ];
    }
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  onOpen(event, action: string, item: any = {}) {
    const dialogRef = this.dialog.open(ManifestDetailComponent, {
      panelClass: ["dialog-medium", "dialog-fullscreen"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (action === "EDIT") {
          this.reload();
          this.snackBar.open("Manifesto atualizado com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        } else if (action === "ADD") {
          // If has previous page navigate to first page or reload in self page.
          this.paginator.hasPreviousPage()
            ? this.paginator.firstPage()
            : this.reload();
          this.snackBar.open("Manifesto criado com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      }
    });
  }

  onFinishManifest(event, element) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a finalização?",
        message: `Tem certeza que deseja finalizar o manifesto da placa: ${element.wait.board_cart},
        transportadora: ${element.carrier.name}?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        const data = {
          // shipment_id: element.shipment.id,
          finish: true,
        };

        this.manifestService.update(data, element.id).subscribe(() => {
          this.reload();
          this.snackBar.open(
            `Manifesto da placa ${element.wait.board_cart} finalizado!`,
            "OK",
            {
              duration: 3000,
              horizontalPosition: "left",
              verticalPosition: "bottom",
            }
          );
        });
      }
    });
  }

  getCountUp(start: string) {
    let diff = 0;
    if (start) {
      let hours: string;
      let minutes: string;
      let seconds: string;
      const startDate = moment(start).unix() * 1000;
      const endDate = moment(new Date()).unix() * 1000;

      diff = endDate - startDate;
      seconds = this.padLeft(Math.floor((diff / 1000) % 60), "0", 2);
      minutes = this.padLeft(Math.floor((diff / 1000 / 60) % 60), "0", 2);
      hours = this.padLeft(Math.floor(diff / 1000 / 60 / 60), "0", 2);
      return [hours, minutes, seconds].join(":");
    }
    return 0;
  }

  padLeft(text: number, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  showStatusInvoice(element) {
    if (element.load) {
      switch (element.load.load_status) {
        case "OP":
          return "Pedido em fase de planejamento de carga";
        case "PI":
          return "Pedido aguardando impressão de etiqueta de separação";
        case "CQ":
          return "Pedido em fase de separação com controle de qualidade";
        case "PR":
          return "Pedido em fase de separação sem controle de qualidade";
        case "CK":
          return "Pedido separado aguardando a impressão de nota fiscal";
        case "NA":
          return "Nota fiscal enviada para impressão";
        case "NH":
          return "Nota fiscal enviada em processo de impressão";
        case "NF":
          return "Nota fiscal impressa";
        case "NR":
          return "Nota fiscal rejeitada";
        default:
          return "Pedido em fase de planejamento de carga";
      }
    }
  }

  ngOnInit() {
    this.sort.sort(<MatSortable>{
      id: "created_at",
      start: "desc",
    });

    // If the manifest changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.searchWatcher = this.searchService.value.subscribe(
      (search: Search) => {
        this.search = search.value;
        this.reload({ search: search.value });
      }
    );

    this.refreshWatcher = this.refreshService.refresh.subscribe(
      (refresh: Event) => {
        this.reload({ refresh: refresh });
      }
    );

    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: this.sort.active,
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            with:
              "shipment.shipmentLoad;shipment.dock.wait.boardHorse;shipment.dock.wait.carrier",
            // with: 'shipment.dock;shipment.steps;boardHorse;lobby;operation;carrier',
            // with: 'shipment.dock;shipment.steps;boardHorse;lobby;operation;carrier',
          };

          if (this.search) {
            Object.assign(options, {
              search: this.search,
            });
          }

          return this.manifestService.index(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.showListEmpty = response.data.length === 0;
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          // const filterData = [];
          // response.data.forEach(function (item) {
          //     if (item.shipment && item.shipment.finish_at) {
          //         filterData.push(item);
          //     }
          // });
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  calcTime(finish: any, start: any): String {
    const f = moment(finish);
    const s = moment(start);
    const time = f.diff(s);
    const duracao = moment.utc(time).format("HH:mm:ss");

    return duracao;
  }

  showLoad($event, element: any = {}) {
    console.log("mostra as cargas");

    const dialogRef = this.dialog.open(LoadsDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log("fechou");
    });
  }

  deleteManifest(event, id: string) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o manifesto?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.manifestService.destroy(id).subscribe((r: any) => {
          this.reload();
          this.snackBar.open(r.message, "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        });
      }
    });
  }

  ngOnDestroy() {
    this.searchWatcher.unsubscribe();
  }

  // listening4Updates() {
  //   const user = JSON.parse(localStorage.getItem("account"));
  //   this.firebaseService.getImportsUpdates().subscribe(
  //     (r: any) => {
  //       if (r[0].user_id != user.id) {
  //         this.reload();
  //       }
  //     },
  //     (e: any) => {
  //       console.log("erro");
  //       console.log(e);
  //     }
  //   );
  // }
}
