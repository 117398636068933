<mat-grid-list [cols]="columnSize" rowHeight="224px">
  <mat-grid-tile *ngFor="let item of boxList">
    <mat-card class="tkg-card">
      <div class="tkg-row">
        <div class="tkg-column-20 tkg-header tkg-align-left">Box físico</div>
        <div class="tkg-column-middle tkg-header tkg-align-left">
          Box virtual
        </div>
        <div class="tkg-column-10 tkg-align-right">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon aria-label="Ações">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="justifyAnalisis(item)">
              <mat-icon>edit_note</mat-icon>
              <span>Justificativa</span>
            </button>
            <button
              mat-menu-item
              (click)="authorizeDC(item)"
              [disabled]="disableAuthorizeDC(item)"
            >
              <mat-icon>fact_check</mat-icon>
              <span>Autorizar D.C.</span>
            </button>
            <button mat-menu-item (click)="registerNotes($event, item)">
              <mat-icon>chat_bubble_outline</mat-icon>
              <span>Adicionar Observação</span>
            </button>
            <button mat-menu-item (click)="assingCharge($event, item)">
              <mat-icon>playlist_add</mat-icon>
              <span>Associar Carga</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="tkg-row">
        <div class="tkg-column-20 tkg-header-data tkg-align-left">
          {{item.resource.box}}
        </div>
        <div class="tkg-column-middle tkg-header-data tkg-align-left">
          {{showBoxes(item.resource.vbox)}}
        </div>
      </div>
      <div class="tkg-row">
        <div class="tkg-column-100 tkg-header tkg-align-left">
          Transportadora
        </div>
      </div>
      <div class="tkg-row">
        <div class="tkg-column-100 tkg-header-data tkg-align-left">
          {{getCarrier(item.resource)}}
        </div>
      </div>
      <mat-card-actions>
        <mat-chip-list aria-label="Steps">
          <mat-chip
            class="tkg-chip-text-color"
            [style.background]="showStep(item.step).color"
            [matMenuTriggerFor]="stepMenu"
          >
            <div>{{showStep(item.step).name}}</div>
            <div class="step-datetime">
              {{showStepTime(item.steps) | date:'short':'-0300'}}
            </div>
          </mat-chip>
        </mat-chip-list>
        <mat-menu #stepMenu="matMenu">
          <button
            *ngFor="let step of steps"
            class="tkg-chip-text-color"
            mat-menu-item
            (click)="onStartStep(item, step.id)"
            [style.background]="step.color"
          >
            <span>{{step.name}}</span>
          </button>
        </mat-menu>
        <div class="tkg-row-detail">
          <button
            mat-button
            class="tkg-column-middle-center"
            (click)="details(item)"
          >
            VER DETALHES
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
