<div cdkDropList class="tkg-list" (cdkDropListDropped)="drop($event)">
  <div class="tkg-box" *ngFor="let item of dataSource" cdkDrag>
    <div class="tkg-custom-placeholder" *cdkDragPlaceholder></div>
    <div class="el1">
      <div class="tkg-color-ex" [style.background]="item.color">{{item.order + 1}}</div>
      <div class="tkg-name">{{item.name}}</div>
    </div>
      <div class="el3">
        <button mat-icon-button matTooltip="Abrir" (click)="addEdit($event, 'EDIT', item)">
          <mat-icon>folder_open</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Deletar" (click)="delete($event, item)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

  </div>
</div>
<button mat-mini-fab (click)="addEdit($event, 'ADD')" class="mat-fab-bottom-right" color="primary">
  <mat-icon>add</mat-icon>
</button>
