import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StaffListComponent } from './staff-list/staff-list.component';
import { AccountGuardService, RouteGuardService } from '../account/shared/account-guard.service';


const staffRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService, RouteGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'staff',
        component: StaffListComponent,
        data: {
          title: 'Colaboradores',
          filter: false,
          search: true,
          refresh: true,
        },
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(staffRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class StaffRoutingModule {}
