<div class="tkg-form-row">
  <h2 class="tkg-from-item-70" mat-dialog-title>Conferência</h2>
  <div class="tkg-form-item-30">
    <button class="tkg-barcode" mat-icon-button matTooltip="Ler código de barras" (click)="openBarcodeScanner()" *ngIf="showBtnBarcode">
      <mat-icon class="material-symbols-outlined">barcode_scanner</mat-icon>
    </button>
  </div>

</div>
<p [class]="msgClass" *ngIf="showMsg">{{msg}}</p>
<p class="picking">Picking: <app-picking-percentage [box_id]="data.box_id"></app-picking-percentage></p>
<mat-dialog-content class="mat-typography">
  <p class="supervisor" *ngIf="supervisorMsg">Aguarde a validação do conferente</p>
<div *ngFor="let item of palletListFt">
  <form>
    <mat-form-field class="tkg-form-row" appearance="fill">
      <span matPrefix class="tkg-uc">UC {{item.pallet}}: &nbsp;</span>
      <input #message type="number" pattern="\d*" matInput [(ngModel)]="formValues[item.id].value" [ngModelOptions]="{standalone: true}" [disabled]="item.value">
      <mat-icon matSuffix (click)="saveConference(formValues[item.id].value, item.id)" *ngIf="!item.value" class="btn-save-icon" [disabled]="!showBtnSave">save</mat-icon>
      <mat-icon matSuffix *ngIf="item.value" class="btn-done-icon material-symbols-outlined">task_alt</mat-icon>
    </mat-form-field>
  </form>
  <p class="attempts">{{showAttempts(item.resource)}}</p>

</div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warm" *ngIf="filter" (click)="cleanFt()">Limpar Filtro</button>
  <button mat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Fechar</button>
</mat-dialog-actions>
