<form [formGroup]="form"
      (ngSubmit)="onSubmit()"
      novalidate>
  <h2 mat-dialog-title>
    Exportar dados para CSV
  </h2>
  <mat-dialog-content class="mat-typography">

    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="10px">
      <mat-form-field>
        <input matInput
               [matDatepicker]="startDate"
               formControlName="startDate"
               placeholder="Data início">

        <mat-datepicker-toggle matSuffix
                               [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input matInput
               [matDatepicker]="endDate"
               formControlName="endDate"
               placeholder="Data final">

        <mat-datepicker-toggle matSuffix
                               [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>

      </mat-form-field>

    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button"
            mat-button
            color="primary"
            mat-dialog-close>Fechar</button>
    <button type="submit"
            mat-button
            color="primary"
            [disabled]="!form.valid">Exportar</button>
  </mat-dialog-actions>
</form>