import { CommonModule } from '@angular/common';
import { StaffService } from './../staff.service';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { AbstractControl, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-ss-staff',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatSelectModule, MatInputModule, MatIconModule, ReactiveFormsModule],
  templateUrl: './ss-staff.component.html',
  styleUrls: ['./ss-staff.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SsStaffComponent
    },
    // {
    //   provide: NG_VALIDATORS,
    //   multi: true,
    //   useExisting: SsStaffComponent
    // }
  ]
})
export class SsStaffComponent implements OnInit {

  @Input('label') label; // Valor que será exibido com label do select
  @Input('return-value') return_value: string = 'id'; // Valor que vai ser retornado
  @Input('option-label-pattern') option_label: any[] = ['name']; // Padrão de exibição do option
  @Input('fe-search') fe_search: boolean|false; // Utiliza busca no front end (função JS)
  @Input('be-search') be_search: boolean|false; // Utiliza busca no back end (envia via API)
  searchString = new Subject<string>();
  list: any[];
  listOrinal: any[];
  searchForm = new FormGroup({
    search: new FormControl('')
  });

  selected!: string;
  disabled = false;
  touched = false;
  onTouched: any = () => {};
  onChanged: any = () => {};



  constructor(
    private staffService: StaffService,
  ) {}

  ngOnInit(): void {
    // Escuta o input de busca para detectar digitação
    this.searchForm.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        // Realiza a busca do termo digitado
        this.searchTerm(value.search);
    });
    this.getList();
  }

  getList(){
    this.staffService.index({}).subscribe((r: any)=> {
      this.listOrinal = r.data;
      this.list = this.listOrinal;
    });
  }

  // Limpa o campo de busca
  clearSearchString() {
    this.searchForm.patchValue({search: ''});
    console.log(this.searchForm.value);
    if(this.fe_search) {
      this.list = this.listOrinal;
    } else {
      this.getList();
    }

  }

  // Realiza a busca do termo digitado no campo de busca
  searchTerm(term: string) {
    if(this.fe_search) {
      this.list = this.listOrinal.filter((item: any) => item.name.toLowerCase().includes(term.toLocaleLowerCase()))
    } else {
      this.staffService.index({search: term}).subscribe((r: any) => {
        this.listOrinal = r.data;
        this.list = this.listOrinal;
      });
    }
  }

  // Padrão de exibição do label de cada option do select
  showPattern(item: any) {
    let label = '';
    this.option_label.forEach((i: string) => {
      // Se um item de option_label não estiver na lista de chaves da lista,
      // considera que são apenas caracteres para concatenar na variável label;
      if(item.hasOwnProperty(i)) {
        label += item[i];
      } else {
        label += i;
      }
    });
    return label;
  }

  selectedOption(opt: string) {
    this.onTouched(); // <-- mark as touched
    this.selected = opt;
    this.onChanged(opt); // <-- call function to let know of a change
  }

  /// Requisitos para que o componente possa interagir com outros componentes
  writeValue(value: string): void {
    this.selected = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // validate(control: AbstractControl): ValidationErrors | null {
  //   const value = control.value;
  //   if (!value) {
  //     return {
  //       mustBePositive: {
  //         value
  //       }
  //     };
  //   }
  // }
}
