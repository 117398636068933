<h3>{{data.message}}</h3>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <mat-select formControlName="justify_id">
      <mat-option *ngFor="let element of justifications" [value]="element.id">
        <span class="driver-name">{{element.name | uppercase}}</span>
      </mat-option>
    </mat-select>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
  <button type="submit" mat-button color="primary" [disabled]="!form.valid" (click)="submit()">Salvar</button>
</mat-dialog-actions>

