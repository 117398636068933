import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogAlertComponent } from './dialog-alert/dialog-alert.component';
import { CoreModule } from '../core/core.module';
import { StateBooleanPipe } from './state-boolean.pipe';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { SearchComponent } from './search/search.component';
import { DocumentPipe } from './document.pipe';
import { PostcodePipe } from './postcode.pipe';
import { WebsocketService } from './websocket.service';
import { MessageService } from './message.service';
import { ListEmptyComponent } from './list-empty/list-empty.component';

import { GroupByPipe } from './group-by.pipe';
// import { MomentDatePipe } from './moment-date.pipe';
import { ExportDataComponent } from './export-data/export-data.component';
import { DatePickerComponent } from '../shared/date-picker/date-picker.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
    ],
    declarations: [
        DialogAlertComponent,
        DialogConfirmComponent,
        StateBooleanPipe,
        SearchComponent,
        DocumentPipe,
        PostcodePipe,
        ListEmptyComponent,
        GroupByPipe,
        // MomentDatePipe,
        ExportDataComponent,
        DatePickerComponent
    ],
    providers: [
        WebsocketService,
        MessageService,
        GroupByPipe,
    ],
    exports: [
        DialogAlertComponent,
        DialogConfirmComponent,
        ListEmptyComponent,
        StateBooleanPipe,
        SearchComponent,
        DocumentPipe,
        PostcodePipe,
        GroupByPipe,
        // MomentDatePipe,
        DatePickerComponent
    ]
})
export class SharedModule { }
