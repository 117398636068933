import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Dock } from '../dock';

@Injectable({
  providedIn: 'root'
})
export class DockService {

  constructor(
    private http: HttpClient
  ) { }

  index(options?: any): Observable<Dock> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
      pageSize: 100,
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/docks`;

    return this.http.get<Dock>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  wavesInBox(options?: any): Observable<Dock> {
    const httpParams = Object.assign(options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/docks-waves`;

    return this.http.get<Dock>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  boxCarrier(carrier_id: string): Observable<Dock> {
    const url = `${environment.apiUrl}/box/carrier/${carrier_id}`;

    return this.http.get<Dock>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  boxes(options?: any): Observable<any> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
      pageSize: 100,
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/box`;

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }


  closeds(options?: any): Observable<Dock> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
      pageSize: 100,
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/closed-box`;

    return this.http.get<Dock>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, id: string, params?: any): Observable<Dock> {
    const url = `${environment.apiUrl}/docks/${id}`;
    const options = { params: params };

    return this.http.put<Dock>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }


  updateNotes(data: any, params?: any) {
    const url = `${environment.apiUrl}/docks-notes`;
    const options = { params: params };

    return this.http.put(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  updateBoxFisico(data: any, params?: any) {
    const url = `${environment.apiUrl}/docks-boxFisico`;
    const options = { params: params };

    return this.http.put(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }


  create(data: any, params?: any): Observable<Dock> {
    const url = `${environment.apiUrl}/docks`;
    const options = { params: params };

    return this.http.post<Dock>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  assignWait2Waves(data: any, params?: any): Observable<Dock> {
    const url = `${environment.apiUrl}/assign-waves`;
    const options = { params: params };

    return this.http.post<Dock>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/docks/${id}`;
    const options = { params: params };

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  // showStep(shipment) {
  //   if (!shipment.double_count && !shipment.loading) {
  //     return 'Picking';
  //   } else if (shipment.double_count && !shipment.loading) {
  //     return 'Dupla contagem';
  //   } else if (shipment.double_count && shipment.loading) {
  //     return 'Carregamento';
  //   }
  // }
  showStep(step) {
    const steps = {
      'pk': 'Picking',
      'pkc': 'Picking Concluído',
      'ana': 'Analisar',
      'dc': 'Dupla Conferência',
      'dci': 'D.C. Iniciada',
      'dcc': 'D.C. Concluída',
      'req': 'Req. Especial',
      'anf': 'Ag. Impressão NF',
      'cpi': 'Consulta PIN',
      'let': 'Leitura ETQ',
      'ave': 'Ag. Veículo',
      'ld': 'Carregamento',
      'cc': 'Concluído'
    };

    return steps[step];
  }

  showStatus(status) {
    const st = {
      'OP': 'Pedido em fase de planejamento de carga',
      'PI': 'Pedido aguardando impressão de etiqueta de separação',
      'CQ': 'Pedido em fase de separação com controle de qualidade',
      'PR': 'Pedido em fase de separação sem controle de qualidade',
      'CK': 'Pedido separado aguardando impressão de nota fiscal',
      'NA': 'Nota fiscal enviada para impressão',
      'NH': 'Nota fiscal em processo de impressão',
      'NF': 'Nota fiscal impressa',
      'NR': 'Nota fiscal rejeitada'
    };
  }

  getLastUpdate() {
    const url = `${environment.apiUrl}/docks-lastupdate`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  saveStep(data: any, params?: any): Observable<Dock> {
    const url = `${environment.apiUrl}/log-dock`;
    const options = { params: params };

    return this.http.post<Dock>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  updateArrival(data: any, params?: any) {
    const url = `${environment.apiUrl}/box/arrival-prev`;
    const options = { params: params };

    return this.http.put(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  storeJustify(data: any, params?: any) {
    const url = `${environment.apiUrl}/docks-justify`;
    const options = { params: params };

    return this.http.put(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }


  pickingPercentage(data: any, params?: any): Observable<Dock> {
    const url = `${environment.apiUrl}/docks-percentage`;
    const options = { params: params };

    return this.http.post<Dock>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }
}
