import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Pendency } from '../pendency';
import { Pendencies } from '../../shipment/pendencies';

@Injectable({
    providedIn: 'root'
})
export class PendencyService {

    constructor(
        private http: HttpClient
    ) { }

    index(options?: any): Observable<Pendency> {
        const httpParams = Object.assign({
            page: 1,
            orderBy: 'created_at',
            sortedBy: 'desc',
        }, options);

        const params = new HttpParams({fromObject: httpParams})

        const url = `${environment.apiUrl}/pendencies`;

        return this.http.get<Pendency>(url, {params}).pipe(
            map((response: Response | any) => response || {}),
            catchError(err => throwError(err || 'Server error'))
        );
    }

    shipmentPendencies(options?: any): Observable<Pendencies> {
      const httpParams = Object.assign({
          page: 1,
          orderBy: 'created_at',
          sortedBy: 'desc',
      }, options);

      const params = new HttpParams({fromObject: httpParams})

      const url = `${environment.apiUrl}/shipment-pendencies`;

      return this.http.get<Pendency>(url, {params}).pipe(
          map((response: Response | any) => response || {}),
          catchError(err => throwError(err || 'Server error'))
      );
  }

    update(data: any, id: string, params?: any): Observable<Pendency> {
        const url = `${environment.apiUrl}/pendencies/${id}`;
        const options = {params: params};

        return this.http.put<Pendency>(url, data, options).pipe(
            map((response: Response | any) => response || {}),
            catchError(err => throwError(err || 'Server error'))
        );
    }

    // updateShipmentLoads(data: any, id: string, params?: any): Observable<Pendency> {
    //     const url = `${environment.apiUrl}/shipment-loads/${id}`;
    //     const options = {params: params};
    //     return this.http.put<Pendency>(url, data, options).pipe(
    //         map((response: Response | any) => response || {}),
    //         catchError(err => throwError(err || 'Server error'))
    //     );
    // }

    updateShipmentLoads(data: any, params?: any) {
        const url = `${environment.apiUrl}/shipment-loads`;
        const options = {params: params};
    
        return this.http.put(url, data, options).pipe(
          map((response: Response | any) => response || {}),
          catchError(err => throwError(err || 'Server error'))
        );
      }
    





    create(data: any, params?: any): Observable<Pendency> {
        const url = `${environment.apiUrl}/pendencies`;
        const options = {params: params};

        return this.http.post<Pendency>(url, data, options).pipe(
            map((response: Response | any) => response || {}),
            catchError(err => throwError(err || 'Server error'))
        );
    }

    destroy(id: string, params?: any) {
        const url = `${environment.apiUrl}/pendencies/${id}`;
        const options = {params: params};

        return this.http.delete(url, options).pipe(
            map((response: Response | any) => response || {}),
            catchError(err => throwError(err || 'Server error'))
        );
    }
}
