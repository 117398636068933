import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {

  constructor(
    private http: HttpClient
  ) { }

  index(options:any={}): Observable<any> {
    const httpParams = Object.assign(
        {
            page: 1,
            orderBy: 'created_at',
            sortedBy: 'desc',
        },
        options
    );

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/shift`;

    return this.http.get<any>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: Record<string, any> & {id:number}): Observable<any> {
    const url = `${environment.apiUrl}/shift/${data.id}`;

    return this.http.put<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  updateDowntimes(data:{resource: any[]; name: string}): Observable<any> {
    const url = `${environment.apiUrl}/shift-downtimes`;

    return this.http.put<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  create(data:any): Observable<any> {
    const url = `${environment.apiUrl}/shift`;

    return this.http.post<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroy(id: string | number) {
    const url = `${environment.apiUrl}/shift/${id}`;

    return this.http.delete(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  destroyShift(id: string | number) {
    const url = `${environment.apiUrl}/shift-shifts/${id}`;

    return this.http.delete(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  createShift(data:any): Observable<any> {
    const url = `${environment.apiUrl}/shift-shifts`;

    return this.http.post<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  indexShift(options:any={}): Observable<any> {
    const httpParams = Object.assign(
        {
            page: 1,
            orderBy: 'created_at',
            sortedBy: 'desc',
        },
        options
    );

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/shift-shifts`;

    return this.http.get<any>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  updateShift(data: Record<string, any> & {id:number}): Observable<any> {
    const url = `${environment.apiUrl}/shift-shifts/${data.id}`;

    return this.http.put<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }
}
