<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ (form.value.name) ? form.value.name :
    ((data.action == 'EDIT') ? 'Salvar' : 'Adicionar') }}
  </h2>
  <mat-dialog-content class="mat-typography">

    <div class="tkg-form-row">
      <mat-form-field class="tkg-form-item-100">
        <input type="text" formControlName="name" matInput placeholder="Nome">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="tkg-form-item-100">
        <mat-label>Equipe</mat-label>
        <mat-select formControlName="provider_id">
          <mat-option *ngFor="let item of providerList" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="tkg-form-item-100">
        <input type="text" formControlName="code" matInput placeholder="UPIN">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.code">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="tkg-form-item-100">
        <mat-label>Turno</mat-label>
        <mat-select formControlName="shift_id">
          <mat-option *ngFor="let item of shiftList" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-slide-toggle formControlName="active" *ngIf="data.action == 'EDIT'">{{form.value.active ? 'Ativado' : 'Desativado'}}</mat-slide-toggle>

    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
    <button type="submit" mat-button color="primary" cdkFocusInitial
      [disabled]="!form.valid">{{ (data.action == 'EDIT') ? 'Salvar' : 'Adicionar' }}</button>
  </mat-dialog-actions>
</form>
