import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DockService } from '../../dock/shared/dock.service';

@Component({
  selector: 'app-box-invoice',
  templateUrl: './box-invoice.component.html',
  styleUrls: ['./box-invoice.component.scss']
})
export class BoxInvoiceComponent implements OnInit {
  pins = [
    { "name": "Sim", "value": true },
    { "name": "Não", "value": false }
  ];

  form = this.formBuilder.group({
    pin: [this.data.item.pin, [Validators.required]]
  });
  constructor(
    public dialogRef: MatDialogRef<BoxInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private dockService: DockService
  ) { }

  ngOnInit() {

  }

  onSubmit() {
    const data = {
      id: this.data.item.id,
      has_invoice: true,
      pin: this.form.value.pin
    }
    this.dockService.update(data, this.data.item.id).subscribe((r: any) => {
      this.dialogRef.close(data);
    }, (e: any) => {
      console.log('--erro--');
      console.log(e);
    });

  }
}
