<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <h2 mat-dialog-title>Direcionar veículo</h2>
  <mat-dialog-content class="mat-typography">

    <div class="tkg-form">
      <mat-form-field class="tkg-form-item-100">
        <mat-select formControlName="dock_number" placeholder="Doca">
          <ng-container *ngFor="let item of dockNumbers">
            <mat-option [value]="item.id">
              {{ item.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field fxFlex="100%" *ngIf="form.value.dock_number > 0">
        <mat-select formControlName="dock_id" placeholder="Box Físico">
          <ng-container *ngFor="let item of docks">
            <mat-option [value]="item.id" *ngIf="(!item.current || !item.current.wait_id)">
              {{ item.name }}
              <span *ngIf="item?.current?.shipment_load">
                -
                {{item?.current?.shipment_load?.carrier?.name}}
              </span>
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.dock_id">{{ error }}</div>
        </mat-hint>
      </mat-form-field> -->
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
    <button type="submit" mat-button color="primary" cdkFocusInitial [disabled]="!form.valid">Direcionar</button>
  </mat-dialog-actions>
</form>
