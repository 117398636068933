
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { DataLogService } from '../shared/data-log.service';

@Component({
  selector: 'app-cancel-load',
  templateUrl: './cancel-load.component.html',
  styleUrls: ['./cancel-load.component.scss']
})
export class CancelLoadComponent implements OnInit {
  justifications: any;
  operators: any;
  justificationSelected: FormControl = new FormControl<number|null>(null);
  operatorSelected: FormControl = new FormControl<number|null>(null);
  constructor(
    public dialogRef: MatDialogRef<CancelLoadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supportDataService: SupportDataService,
    private dataLogService: DataLogService
  ) { }

  ngOnInit(): void {
    console.log('cancelar carga');
    console.log(this.data);
    this.getJustifications();
  }

  submit() {
    const justification = {
      log_name: 'load-cancel',
      justification_load_cancel_id: this.justificationSelected.value.id,
      box_id: this.data.item.resource.box_id,
      box_history_id: this.data.item.id
    }
    this.dataLogService.create(justification).subscribe((r: any) => {
      this.dialogRef.close(r);
    });
  }

  getJustifications() {
    this.supportDataService.resourceName('justifications_load_cancel').subscribe((r: any) => {
      this.justifications = r.data.resource;
    });
  }


}
