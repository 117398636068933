<table #sort="matSort" mat-table [dataSource]="shift.dataSource" class="mat-elevation-z8" style="width: 100%;" matSort>
  <ng-container [matColumnDef]="column.key" *ngFor="let column of shift.columns;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sort" class="aumentar"> {{column.name}} </th>
    <td mat-cell *matCellDef="let element" class="aumentar">
      {{
        column.key == "created_at"
        ? (element[column.key] | date: 'dd/MM/yyyy hh:mm')
        : element[column.key]
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions" end>
    <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
    <td mat-cell *matCellDef="let element" style="white-space: nowrap">
      <button mat-icon-button matTooltip="Abrir" (click)="open(actionEnum.EDIT, element)">
        <mat-icon>folder_open</mat-icon>
      </button>

      <button mat-icon-button matTooltip="Deletar" (click)="destroy(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="shift.displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns:shift.displayedColumns"></tr>
</table>
<mat-paginator #paginator [pageSize]="pageSize" showFirstLastButtons></mat-paginator>

<div *ngIf="activatedTab" class="mat-fab-bottom-right add-edit-button">
  <button
    mat-mini-fab
    (click)="open(actionEnum.ADD)"
    color="primary"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
