<mat-button-toggle-group #selector="matButtonToggleGroup" (change)="changeFilter($event)" value="day" name="fontStyle"
  aria-label="Font Style" class="filter-opt">
  <mat-button-toggle value="day">Dia</mat-button-toggle>
  <mat-button-toggle value="period">Período</mat-button-toggle>
</mat-button-toggle-group>
<span *ngIf="selector.value == 'day'">
  <mat-form-field appearance="outline">
    <mat-label>Data Inicial</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="dayStartDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</span>
<span *ngIf="selector.value == 'period'">
  <mat-form-field appearance="outline">
    <mat-label>Data Inicial</mat-label>
    <input matInput #datePickerOnbar2 [matDatepicker]="picker2"
      [formControl]="periodStartDate">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Data Final</mat-label>
    <input matInput #datePickerOnbar3 [matDatepicker]="picker3" [formControl]="periodEndDate">
    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
    <mat-datepicker #picker3></mat-datepicker>
  </mat-form-field>
</span>
<!-- <mat-form-field appearance="outline">
  <mat-label>Transportadora</mat-label>
  <mat-select [formControl]="carrierForm">
    <mat-option (click)="cleanFilter('carrier')"
      >Limpar filtro</mat-option
    >
    <mat-divider></mat-divider>
    <mat-option *ngFor="let carrier of carriers" [value]="carrier.id">
      {{ carrier.name }}
    </mat-option>
  </mat-select>
</mat-form-field> -->

<mat-form-field appearance="outline">
  <mat-label>Transportadora</mat-label>
  <mat-select multiple [formControl]="carrierFilter">
    <mat-select-trigger>
      <span *ngIf="carrierFilter.value?.length > 0" class="example-additional-selection">
        {{carrierFilter.value.length}} {{carrierFilter.value?.length > 1 ? 'filtros' : 'filtro'}}
      </span>
    </mat-select-trigger>
    <mat-divider></mat-divider>
    <mat-option *ngFor="let item of carriers" [value]="item.id" (click)="getFilterCarrier(item)">
      {{item.name}}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="outline">
  <mat-label>Etapa Inicial</mat-label>
  <mat-select multiple [formControl]="stepsFilter">
    <mat-select-trigger>
      <span *ngIf="stepsFilter.value?.length > 0" class="example-additional-selection">
        {{stepsFilter.value.length}} {{stepsFilter.value?.length > 1 ? 'filtros' : 'filtro'}}
      </span>
    </mat-select-trigger>
    <mat-divider></mat-divider>
    <mat-option *ngFor="let item of stepsList" [value]="item.id" (click)="getFilterStepsList(item)">
      {{item.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
<button mat-button color="primary" (click)="dayFilter()" *ngIf="showCompareLastDay">
  <mat-icon>filter_list</mat-icon>
  Filtrar
</button>
<button mat-button color="primary" (click)="periodFilter()" *ngIf="!showCompareLastDay">
  <mat-icon>filter_list</mat-icon>
  Filtrar
</button>
<!-- <div fxFlex="10%" fxFlex.xs="calc(50%-25px)"> -->
  <button mat-button matTooltip="Baixar CSV" (click)="downloadReport()">
    <mat-icon aria-label="Baixar CSV">get_app</mat-icon>
    Baixar CSV
  </button>
<!-- </div> -->
<!-- <mat-tab-group (selectedTabChange)="changeTab($event)">
  <mat-tab label="Relatorio 1">

  </mat-tab>
  <mat-tab label="Relatório 2"> Content 2 </mat-tab>
</mat-tab-group> -->

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">


  <!-- Position Column -->
  <ng-container matColumnDef="box_fisico">
    <th mat-header-cell *matHeaderCellDef class="col-5"> Box F.</th>
    <td mat-cell *matCellDef="let element"> {{element.box_fisico}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="transportadora">
    <th mat-header-cell *matHeaderCellDef class="col-15"> Transportadora </th>
    <td mat-cell *matCellDef="let element"> {{element.transportadora}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="criado_em">
    <th mat-header-cell *matHeaderCellDef class="col-10"> Ini. Etapa </th>
    <td mat-cell *matCellDef="let element"> {{element.criado_em | date:'short'}} </td>
  </ng-container>
  <ng-container matColumnDef="finalizado_em">
    <th mat-header-cell *matHeaderCellDef class="col-10"> Fim Etapa </th>
    <td mat-cell *matCellDef="let element"> {{element.finalizado_em | date:'short'}} </td>
  </ng-container>
  <ng-container matColumnDef="vbox">
    <th mat-header-cell *matHeaderCellDef class="col-10"> Box Virtual </th>
    <td mat-cell *matCellDef="let element"> {{element.vbox}} </td>
  </ng-container>
  <ng-container matColumnDef="waves">
    <th mat-header-cell *matHeaderCellDef class="col-5"> Waves </th>
    <td mat-cell *matCellDef="let element"> {{element.waves}} </td>
  </ng-container>
  <ng-container matColumnDef="client_name">
    <th mat-header-cell *matHeaderCellDef class="col-10"> Cliente </th>
    <td mat-cell *matCellDef="let element"> {{element.client_name}} </td>
  </ng-container>
  <ng-container matColumnDef="count">
    <th mat-header-cell *matHeaderCellDef class="col-5"> Linhas </th>
    <td mat-cell *matCellDef="let element"> {{element.count}} </td>
  </ng-container>
  <ng-container matColumnDef="volumes">
    <th mat-header-cell *matHeaderCellDef class="col-5"> Volumes </th>
    <td mat-cell *matCellDef="let element"> {{element.volumes}} </td>
  </ng-container>

  <ng-container matColumnDef="etapas">
    <th mat-header-cell *matHeaderCellDef class="col-15"> Etapa Origem -> Destino </th>
    <td mat-cell *matCellDef="let element"> {{element.etapas}} </td>
  </ng-container>

  <ng-container matColumnDef="tempo_medio">
    <th mat-header-cell *matHeaderCellDef class="col-10"> Tempo (min) </th>
    <td mat-cell *matCellDef="let element"> {{element.tempo_medio}} </td>
  </ng-container>

  <ng-container matColumnDef="justificativas">
    <th mat-header-cell *matHeaderCellDef class="col-15"> Justificativa </th>
    <td mat-cell *matCellDef="let element"> {{element.justificativas}} </td>
  </ng-container>
  <!-- Id Column -->
  <ng-container matColumnDef="box_id">
    <th mat-header-cell *matHeaderCellDef class="col-5">#</th>
    <td mat-cell *matCellDef="let element"> {{element.box_id}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

