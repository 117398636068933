import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../../account/shared/account.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-detail',
  templateUrl: './login-detail.component.html',
  styleUrls: ['./login-detail.component.scss']
})
export class LoginDetailComponent implements OnInit {
  form: UntypedFormGroup;
  data: any;
  hidePassword = true;
  translateSource: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {
    translate.get('account.login-detail').subscribe((res: string) => {
        this.translateSource = res;
    });
  }

  onSubmit() {
    this.accountService.login(this.form.value).subscribe(
      data => {},
      err => {
        this.dialog.open(DialogAlertComponent, {
          data: {
            title: this.translateSource.status.Unauthorized,
            message: this.translateSource.status.errorMessage,
          }
        });
      }
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }
}
