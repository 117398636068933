<!--
  Para utilizar o conceito de Gestão de Grupos do Maestro alguns metodos do pacote ngx-permissions serão
  comentados.
  *ngxPermissionsOnly comentado para data-ngxPermissionsOnly
-->
<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon
        *ngIf="!activatedCarriers"
        style="margin-right: 8px; width: 32px;"
        [ngClass]="{'active-carrier-switcher': !activatedCarriers}"
      >
        not_interested
      </mat-icon>
      <span
        [ngClass]="{'active-carrier-switcher': !activatedCarriers}"
      >
        Transportadoras {{!activatedCarriers ? "- Inativas" : ""}}
      </span>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon [ngClass]="{'active-carrier-switcher': !activatedCarriers}">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <mat-list role="list">
          <mat-list-item role="listitem">
            <mat-slide-toggle
              color="primary"
              [checked]="!activatedCarriers"
              (change)="activeCarriersSwitcher()"
            >
              Registros inativos
            </mat-slide-toggle>
          </mat-list-item>
        </mat-list>
      </mat-menu>
    </ng-template>
    <app-list-empty *ngIf="showListEmpty"></app-list-empty>
    <div class="mat-elevation-z8" [hidden]="showListEmpty">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id_external">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Código 3M</th>
          <td mat-cell *matCellDef="let element" class="aumentar">{{element.id_external}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Nome</th>
          <td mat-cell *matCellDef="let element" class="aumentar">
            <div>
              <span class="ellipsis" class="aumentar">{{element.name}}</span>
            </div>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="is_casual">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Tipo</th>
          <td mat-cell *matCellDef="let element" class="aumentar">{{ (element.is_casual) ? 'Casual' : 'Convencional' }}</td>
        </ng-container> -->

        <!-- <ng-container matColumnDef="operation_start">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Carga Inicial</th>
          <td mat-cell *matCellDef="let element" class="aumentar">{{element.operation_start}}</td>
        </ng-container>

        <ng-container matColumnDef="operation_end">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Carga Final</th>
          <td mat-cell *matCellDef="let element" class="aumentar">{{element.operation_end}}</td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Criado em</th>
          <td mat-cell *matCellDef="let element" class="aumentar">{{element.created_at | date}}</td>
        </ng-container> -->

        <!-- <ng-container matColumnDef="is_active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Ativo</th>
          <td mat-cell *matCellDef="let element" class="aumentar">{{element.is_active ? "Sim" : "Não"}}</td>
        </ng-container> -->

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap">
            <button mat-icon-button matTooltip="Abrir" (click)="onOpen($event, 'EDIT', element)" data-ngxPermissionsOnly="['superuser', 'carriers.show', 'carriers.update']">
              <mat-icon>folder_open</mat-icon>
            </button>

            <button mat-icon-button matTooltip="Deletar" (click)="delete($event, element)" data-ngxPermissionsOnly="['superuser', 'carriers.destroy']">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <!-- <mat-paginator [pageSize]="pageSize" showFirstLastButtons></mat-paginator> -->
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>

    <button mat-mini-fab (click)="onOpen($event, 'ADD')" class="mat-fab-bottom-right" color="primary" data-ngxPermissionsOnly="['superuser', 'users.store']">
      <mat-icon>add</mat-icon>
    </button>
  </mat-tab>
</mat-tab-group>
