<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" [hidden]="showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="dock">
      <th mat-header-cell *matHeaderCellDef class="aumentar">B. Físico</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{element.box.name}}</td>
    </ng-container>

    <ng-container matColumnDef="box">
      <th mat-header-cell *matHeaderCellDef class="aumentar">B. Virtual</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{element.vbox}}</td>
    </ng-container>


    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Cliente</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{element.client_name}}</td>
    </ng-container>

    <ng-container matColumnDef="load">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Carga</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{element.load_number}}</td>
    </ng-container>

    <ng-container matColumnDef="pin">
      <th mat-header-cell *matHeaderCellDef class="aumentar">PIN</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{element.box.pin ? "Sim" : "Não"}}</td>
    </ng-container>

    <ng-container matColumnDef="datetime">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Horário</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{element.created_at | date:'shortTime'}}</td>
    </ng-container>

    <ng-container matColumnDef="obs">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Obs.</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{element.obs}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Status</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        {{element.load_status}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <mat-menu #menu="matMenu">
          <button mat-menu-item matTooltip="Carga" (click)="showLoad($event, element)">
            <mat-icon>list_alt</mat-icon>
            <span>Ver carga</span>
          </button>
          <button mat-menu-item matTooltip="Adicionar Observação" (click)="saveNotes(element)">
            <mat-icon>chat_bubble_outline</mat-icon>
            <span>Adicionar Observação</span>
          </button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <!-- <button mat-icon-button matTooltip="Finalizar manifesto" (click)="onFinishManifest($event, element)" [disabled]="element.finished_at"> -->
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>


</div>
